/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SearchVehicleIdentifier,
    SearchVehicleIdentifierFromJSON,
    SearchVehicleIdentifierToJSON,
    UpdateVINCheckerActivity,
    UpdateVINCheckerActivityFromJSON,
    UpdateVINCheckerActivityToJSON,
    VINCheckerDto,
    VINCheckerDtoFromJSON,
    VINCheckerDtoToJSON,
    VINCheckerResultResponse,
    VINCheckerResultResponseFromJSON,
    VINCheckerResultResponseToJSON,
    VINCheckersResponse,
    VINCheckersResponseFromJSON,
    VINCheckersResponseToJSON,
} from '../models';

export interface ApiVINCheckerAddPostRequest {
    searchVehicleIdentifier?: SearchVehicleIdentifier;
}

export interface ApiVINCheckerAllGetRequest {
    pageNumber?: number;
    searchQuery?: string | null;
    onlyWanted?: boolean;
    onlyActiveVinCheckers?: boolean;
    size?: number;
}

export interface ApiVINCheckerRemoveDeleteRequest {
    vinCheckerId?: string;
}

export interface ApiVINCheckerResultPostRequest {
    vinCheckerId?: string;
}

export interface ApiVINCheckerUpdatePostRequest {
    updateVINCheckerActivity?: UpdateVINCheckerActivity;
}

/**
 * 
 */
export class VINCheckerApi extends runtime.BaseAPI {

    /**
     */
    async apiVINCheckerAddPostRaw(requestParameters: ApiVINCheckerAddPostRequest): Promise<runtime.ApiResponse<VINCheckerDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/VINChecker/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchVehicleIdentifierToJSON(requestParameters.searchVehicleIdentifier),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VINCheckerDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiVINCheckerAddPost(requestParameters: ApiVINCheckerAddPostRequest): Promise<VINCheckerDto> {
        const response = await this.apiVINCheckerAddPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiVINCheckerAllGetRaw(requestParameters: ApiVINCheckerAllGetRequest): Promise<runtime.ApiResponse<VINCheckersResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        if (requestParameters.onlyWanted !== undefined) {
            queryParameters['onlyWanted'] = requestParameters.onlyWanted;
        }

        if (requestParameters.onlyActiveVinCheckers !== undefined) {
            queryParameters['onlyActiveVinCheckers'] = requestParameters.onlyActiveVinCheckers;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/VINChecker/All`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VINCheckersResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiVINCheckerAllGet(requestParameters: ApiVINCheckerAllGetRequest): Promise<VINCheckersResponse> {
        const response = await this.apiVINCheckerAllGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiVINCheckerRemoveDeleteRaw(requestParameters: ApiVINCheckerRemoveDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.vinCheckerId !== undefined) {
            queryParameters['vinCheckerId'] = requestParameters.vinCheckerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/VINChecker/Remove`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiVINCheckerRemoveDelete(requestParameters: ApiVINCheckerRemoveDeleteRequest): Promise<void> {
        await this.apiVINCheckerRemoveDeleteRaw(requestParameters);
    }

    /**
     */
    async apiVINCheckerResultPostRaw(requestParameters: ApiVINCheckerResultPostRequest): Promise<runtime.ApiResponse<VINCheckerResultResponse>> {
        const queryParameters: any = {};

        if (requestParameters.vinCheckerId !== undefined) {
            queryParameters['vinCheckerId'] = requestParameters.vinCheckerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/VINChecker/Result`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VINCheckerResultResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiVINCheckerResultPost(requestParameters: ApiVINCheckerResultPostRequest): Promise<VINCheckerResultResponse> {
        const response = await this.apiVINCheckerResultPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiVINCheckerUpdatePostRaw(requestParameters: ApiVINCheckerUpdatePostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/VINChecker/Update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateVINCheckerActivityToJSON(requestParameters.updateVINCheckerActivity),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiVINCheckerUpdatePost(requestParameters: ApiVINCheckerUpdatePostRequest): Promise<string> {
        const response = await this.apiVINCheckerUpdatePostRaw(requestParameters);
        return await response.value();
    }

}
