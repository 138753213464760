/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefreshRequest
 */
export interface RefreshRequest {
    /**
     * 
     * @type {string}
     * @memberof RefreshRequest
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshRequest
     */
    refreshToken: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshRequest
     */
    teamId?: string | null;
}

export function RefreshRequestFromJSON(json: any): RefreshRequest {
    return RefreshRequestFromJSONTyped(json, false);
}

export function RefreshRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'refreshToken': json['refreshToken'],
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
    };
}

export function RefreshRequestToJSON(value?: RefreshRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'refreshToken': value.refreshToken,
        'teamId': value.teamId,
    };
}


