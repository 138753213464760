import React from "react";
import { ColDef, DataGrid, RowParams, RowSelectedParams, RowsProp, SortModelParams } from "@material-ui/data-grid";
import { LoadingState } from "../../../hooks/loading";
import { CustomDataGridHeader, DataGridHeaderProps } from "./CustomDataGridHeader";
import { CustomDataGridFooter } from "./CustomDataGridFooter";

export const CustomDataGrid: React.FC<{
  rows: RowsProp;
  columns: ColDef[];
  pageSize: number;
  page: number;
  setPage: Function;
  loadingState: LoadingState;
  totalRows: number | undefined;
  handleSortModelChange?: (data: SortModelParams) => void;
  dataDisplayText: string;
  headerProps: DataGridHeaderProps;
  onRowClick?: (data: RowParams) => void;
  onRowSelected?: (data: RowSelectedParams) => void;
}> = (
  props
) => {
    const rowHeight = 40;
    const headerHeight = 40;

    const grid = () => {
      return <DataGrid
        onRowClick={(data) => props.onRowClick != undefined ? props.onRowClick(data) : null}
        onRowSelected={(data) => props.onRowSelected != undefined ? props.onRowSelected(data) : null}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        rows={props.rows}
        columns={props.columns}
        pageSize={props.pageSize}
        page={props.page}
        onPageChange={(params) => {props.setPage(params.page); }}
        loading={props.loadingState == LoadingState.Loading}
        rowCount={props.totalRows}
        onSortModelChange={(params) => props.handleSortModelChange != undefined ? props.handleSortModelChange(params) : null}
        pagination
        paginationMode="server"
        sortingMode="server"
        showCellRightBorder={true}
        showColumnRightBorder={true}
        hideFooterSelectedRowCount
        disableColumnMenu
        components={{ 
          Footer: (data) => <CustomDataGridFooter totalString={props.dataDisplayText} data={data}></CustomDataGridFooter>
       }}
      ></DataGrid>;
    }

    return (
      <>
      <CustomDataGridHeader headerProps={props.headerProps}></CustomDataGridHeader>
      <div style={{minHeight: "400px", height: (((props.rows.length) * rowHeight) + headerHeight + 52) + "px"/*height of footer: 52*/ }}>
        <div style={{ height: "100%", display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            {grid()}
          </div>
        </div>
      </div>
      </>
    );
  };
