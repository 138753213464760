import { useState, useEffect } from "react";
import { LoadingState } from "./loading";
import useApi from "./api";
import { useQueryDelay } from "./queryDelay";

export function useModelSuggestions(
  make: string | undefined | null,
  searchPhrase: string | undefined | null
) {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [state, setState] = useState(LoadingState.None);

  const { searchApi } = useApi();

  const loadModels = async () => {
    if(!searchPhrase || !make){
      return;
    }
    setState(LoadingState.Loading);

    try {
      const suggestions = await searchApi.apiSearchModelSuggestionsPost({
        searchPhrase: searchPhrase,
        make: make,
      });
      setSuggestions(suggestions);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    loadModels();
  }, [make, searchPhrase]);

  return {
    suggestions,
    suggestionsState: state,
    reloadModels: loadModels,
  };
}
