import { Grid, Hidden, Link, List, ListItem, ListItemText, makeStyles, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useHistory } from "react-router";
import useAuth from "../../../hooks/auth";
import { LoadingState } from "../../../hooks/loading";
import { useTeamInfo } from "../../../hooks/teamInfo";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  },
  form: theme.form,
  middle: {
    borderRadius: "0px 0px 0px 0px",
    backgroundColor: "white",
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    minHeight: "296px"
  },
  middleHeader: {
    borderRadius: "0px 0px 0px 0px",
    borderLeft: "none",
    borderRight: "none",
  },
  right: {
    borderRadius: "0px 0px 3px 0px",
    backgroundColor: "white",
    borderTop: "none",
    minHeight: "296px"
  },
  rightHeader: {
    borderRadius: "0px 3px 0px 0px"
  },
  left: {
    borderRadius: "0px 0px 0px 3px",
    [theme.breakpoints.down('xs')]: {
      borderRadius: "0px 0px 3px 3px"
    },
    backgroundColor: "white",
    borderTop: "none",
    minHeight: "296px"
  },
  leftHeader: {
    borderRadius: "3px 0px 0px 0px",
    [theme.breakpoints.down('xs')]: {
      borderRadius: "3px 3px 0px 0px"
    }
  },
  headerPaper: {
    paddingTop: "8px",
    paddingBottom: "8px"
  },
  container: {
    marginTop: "0px",
    marginBottom: "0px"
  }
}));

const UserInfo = () => {

  const classes = useStyles();
  const { user, logout } = useAuth();
  const history = useHistory();

  const { teamInfo, teamInfoState } = useTeamInfo();
  
  return teamInfoState == LoadingState.Success ? 
    <Grid container className={classes.container}>
    <Grid item xs={12} sm={4}>
      <Paper className={classes.leftHeader + " " + classes.headerPaper}>
        <Grid alignItems="center" spacing={1} container>
        <Grid item xs={6}>
            <Typography variant="h5">USER INFO</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right"><Link onClick={() => logout()} color="secondary">Logout</Link></Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.left}>
        <Grid container spacing={1}>
          
          <Grid item xs={12}>
            <List disablePadding>
              <ListItem>
                <ListItemText primary="NAME" secondary={user?.screenName}></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText primary="EMAIL" secondary={user?.email}></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText primary="PASSWORD" secondary={<>******* <Link color="secondary" onClick={() => history.push("/myaccount/editpassword")}>Change</Link></>}></ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
    <Hidden xsDown>
    <Grid item sm={4}>
      <Paper className={classes.middleHeader + " " + classes.headerPaper}>
      <Grid spacing={1} container>
        <Grid item xs={6}>
            <Typography variant="h5">TEAM INFO</Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.middle}>
      <Grid container spacing={1}>
      <Grid item xs={12}>
            <List disablePadding>
              <ListItem>
                <ListItemText primary="TEAM NAME" secondary={user?.teamName}></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText primary="TEAM ADMIN" secondary={user?.teamOwnerName}></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText primary="SUBSCRIPTION" secondary={teamInfo?._package?.name + " (" + teamInfo?._package?.priceMonthly + " per. month)"}></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText primary="PRICE PER LOOKUP" secondary={teamInfo?._package?.pricePerLookup + " kr."}></ListItemText>
              </ListItem>
            </List>
          </Grid>
      </Grid>
      </Paper>
    </Grid>
    </Hidden>
    <Hidden xsDown>
    <Grid item sm={4}>
      <Paper className={classes.rightHeader + " " + classes.headerPaper}>
      <Grid spacing={1} container>
        <Grid item xs={6}>
            <Typography variant="h5">YOUR STATS</Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.right}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <List disablePadding>
            <ListItem>
                <ListItemText primary="MONITORED SEARCH AGENTS" secondary={teamInfo?.activeSearchAgents + "/" + teamInfo?._package?.maxActiveSearchAgents}></ListItemText>
              </ListItem>
            <ListItem>
                <ListItemText primary="MONITORED VINS" secondary={teamInfo?.activeVINCheckers + "/" + teamInfo?._package?.maxActiveVINCheckers}></ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
    </Hidden>
    </Grid> : 
    <Paper>
    <Skeleton variant="rect" width="100%" height="344px"></Skeleton>
  </Paper>; 
};

export default UserInfo;
