import { useState, useEffect } from "react";
import { VINCheckersResponse } from "../lib-api/models";
import { LoadingState } from "./loading";
import useApi from "./api";
import { useQueryDelay } from "./queryDelay";
import { useSnackbar } from "notistack";
import useAuth from "./auth";

export function useVINCheckers(
  searchQuery: string = "",
  onlyWanted: boolean = false,
  onlyActive: boolean = false,
  pageNumber = 1,
  size = 10
) {
  const [vinCheckers, setVINCheckers] = useState<VINCheckersResponse>({
    all: [],
    totalCount: 0,
  });

  const [state, setState] = useState(LoadingState.None);
  const { enqueueSnackbar } = useSnackbar();

  const { delay } = useQueryDelay(searchQuery);

  const { vinCheckersApi } = useApi();
  const {user} = useAuth();

  const addVinChecker = async (vehicleId: string) => {
    setState(LoadingState.Loading);
    var regNr = null;
    var chassisNr = null;
    if (vehicleId.length > 10) {
      chassisNr = vehicleId;
    } else {
      regNr = vehicleId;
    }

    try {
      await vinCheckersApi.apiVINCheckerAddPost({
        searchVehicleIdentifier: {
          chassisNumber: chassisNr,
          regNr: regNr
        },
      });
      loadVinCheckers();
      setState(LoadingState.Success);
      enqueueSnackbar("Monitor added", { variant: "success" });
    } catch (error) {
      setState(LoadingState.BadRequest);
    }
  };

  const deleteVinChecker = async (vehicleId: string) => {
    await vinCheckersApi.apiVINCheckerRemoveDelete({
      vinCheckerId: vehicleId
    });
    loadVinCheckers();
    enqueueSnackbar("Monitor deleted.", { variant: "success" });
  };

  const loadVinCheckers = async () => {
    setState(LoadingState.Loading);
    try {
      const allVinCheckers = await vinCheckersApi.apiVINCheckerAllGet({
        pageNumber: pageNumber,
        searchQuery: searchQuery,
        onlyActiveVinCheckers: onlyActive,
        onlyWanted: onlyWanted,
        size: size,
      });
      setVINCheckers(allVinCheckers);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    loadVinCheckers();
  }, [delay, onlyActive, onlyWanted, pageNumber, user?.teamId]);

  return {
    vinCheckers,
    vinCheckerState: state,
    addVinChecker,
    reloadVinCheckers: loadVinCheckers,
    deleteVinChecker
  };
}
