import { useState, useEffect } from "react";

function getWindowDimensions(elementId?: string, ref?: React.MutableRefObject<HTMLDivElement|undefined>) {
  if (elementId != undefined) {
    const doc = document.getElementById(elementId);
    if (doc == null) {
      return {
        width: undefined, height: undefined
      };
    }
    const { clientWidth: width, clientHeight: height } = doc;

    return {
      width,
      height
    };
  } else if (ref != undefined && ref.current != undefined) {
    const { clientWidth: width, clientHeight: height } = ref.current;
    return {
      width,
      height
    };
  }else {
    return {
      width: undefined,
      height: undefined
    };
  }
}

export function useDimensions(elementId?: string, ref?: React.MutableRefObject<HTMLDivElement|undefined>) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(elementId, ref)
  );

  useEffect(() => {
    setWindowDimensions(getWindowDimensions(elementId, ref));
    function handleResize() {
      setWindowDimensions(getWindowDimensions(elementId, ref));
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setWindowDimensions(getWindowDimensions(elementId, ref));
  }, [ref?.current]);
  return windowDimensions;
}
