/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchAgentMetaDto,
    SearchAgentMetaDtoFromJSON,
    SearchAgentMetaDtoFromJSONTyped,
    SearchAgentMetaDtoToJSON,
    SearchAgentSource,
    SearchAgentSourceFromJSON,
    SearchAgentSourceFromJSONTyped,
    SearchAgentSourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchAgentDto
 */
export interface SearchAgentDto {
    /**
     * 
     * @type {number}
     * @memberof SearchAgentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentDto
     */
    regNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentDto
     */
    chassisNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentDto
     */
    gearType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentDto
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentDto
     */
    registrationStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentDto
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentDto
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentDto
     */
    fuelType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAgentDto
     */
    motorSizeFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAgentDto
     */
    motorSizeTo?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAgentDto
     */
    weightFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAgentDto
     */
    weightTo?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAgentDto
     */
    horsePowerFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAgentDto
     */
    horsePowerTo?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SearchAgentDto
     */
    registrationDateFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SearchAgentDto
     */
    registrationDateTo?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentDto
     */
    teamMemberId?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentDto
     */
    creatorName?: string | null;
    /**
     * 
     * @type {SearchAgentMetaDto}
     * @memberof SearchAgentDto
     */
    searchAgentMeta?: SearchAgentMetaDto;
    /**
     * 
     * @type {SearchAgentSource}
     * @memberof SearchAgentDto
     */
    source?: SearchAgentSource;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAgentDto
     */
    parallelImported?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SearchAgentDto
     */
    leasingDateFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SearchAgentDto
     */
    leasingDateTo?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAgentDto
     */
    currentlyLeased?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SearchAgentDto
     */
    newInDenmarkFrom?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAgentDto
     */
    inspectionStationId?: number | null;
}

export function SearchAgentDtoFromJSON(json: any): SearchAgentDto {
    return SearchAgentDtoFromJSONTyped(json, false);
}

export function SearchAgentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchAgentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'chassisNumber': !exists(json, 'chassisNumber') ? undefined : json['chassisNumber'],
        'gearType': !exists(json, 'gearType') ? undefined : json['gearType'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'registrationStatus': !exists(json, 'registrationStatus') ? undefined : json['registrationStatus'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
        'motorSizeFrom': !exists(json, 'motorSizeFrom') ? undefined : json['motorSizeFrom'],
        'motorSizeTo': !exists(json, 'motorSizeTo') ? undefined : json['motorSizeTo'],
        'weightFrom': !exists(json, 'weightFrom') ? undefined : json['weightFrom'],
        'weightTo': !exists(json, 'weightTo') ? undefined : json['weightTo'],
        'horsePowerFrom': !exists(json, 'horsePowerFrom') ? undefined : json['horsePowerFrom'],
        'horsePowerTo': !exists(json, 'horsePowerTo') ? undefined : json['horsePowerTo'],
        'registrationDateFrom': !exists(json, 'registrationDateFrom') ? undefined : (json['registrationDateFrom'] === null ? null : new Date(json['registrationDateFrom'])),
        'registrationDateTo': !exists(json, 'registrationDateTo') ? undefined : (json['registrationDateTo'] === null ? null : new Date(json['registrationDateTo'])),
        'teamMemberId': !exists(json, 'teamMemberId') ? undefined : json['teamMemberId'],
        'creatorName': !exists(json, 'creatorName') ? undefined : json['creatorName'],
        'searchAgentMeta': !exists(json, 'searchAgentMeta') ? undefined : SearchAgentMetaDtoFromJSON(json['searchAgentMeta']),
        'source': !exists(json, 'source') ? undefined : SearchAgentSourceFromJSON(json['source']),
        'parallelImported': !exists(json, 'parallelImported') ? undefined : json['parallelImported'],
        'leasingDateFrom': !exists(json, 'leasingDateFrom') ? undefined : (json['leasingDateFrom'] === null ? null : new Date(json['leasingDateFrom'])),
        'leasingDateTo': !exists(json, 'leasingDateTo') ? undefined : (json['leasingDateTo'] === null ? null : new Date(json['leasingDateTo'])),
        'currentlyLeased': !exists(json, 'currentlyLeased') ? undefined : json['currentlyLeased'],
        'newInDenmarkFrom': !exists(json, 'newInDenmarkFrom') ? undefined : (json['newInDenmarkFrom'] === null ? null : new Date(json['newInDenmarkFrom'])),
        'inspectionStationId': !exists(json, 'inspectionStationId') ? undefined : json['inspectionStationId'],
    };
}

export function SearchAgentDtoToJSON(value?: SearchAgentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'regNr': value.regNr,
        'chassisNumber': value.chassisNumber,
        'gearType': value.gearType,
        'color': value.color,
        'registrationStatus': value.registrationStatus,
        'make': value.make,
        'model': value.model,
        'type': value.type,
        'fuelType': value.fuelType,
        'motorSizeFrom': value.motorSizeFrom,
        'motorSizeTo': value.motorSizeTo,
        'weightFrom': value.weightFrom,
        'weightTo': value.weightTo,
        'horsePowerFrom': value.horsePowerFrom,
        'horsePowerTo': value.horsePowerTo,
        'registrationDateFrom': value.registrationDateFrom === undefined ? undefined : (value.registrationDateFrom === null ? null : value.registrationDateFrom.toISOString()),
        'registrationDateTo': value.registrationDateTo === undefined ? undefined : (value.registrationDateTo === null ? null : value.registrationDateTo.toISOString()),
        'teamMemberId': value.teamMemberId,
        'creatorName': value.creatorName,
        'searchAgentMeta': SearchAgentMetaDtoToJSON(value.searchAgentMeta),
        'source': SearchAgentSourceToJSON(value.source),
        'parallelImported': value.parallelImported,
        'leasingDateFrom': value.leasingDateFrom === undefined ? undefined : (value.leasingDateFrom === null ? null : value.leasingDateFrom.toISOString()),
        'leasingDateTo': value.leasingDateTo === undefined ? undefined : (value.leasingDateTo === null ? null : value.leasingDateTo.toISOString()),
        'currentlyLeased': value.currentlyLeased,
        'newInDenmarkFrom': value.newInDenmarkFrom === undefined ? undefined : (value.newInDenmarkFrom === null ? null : value.newInDenmarkFrom.toISOString()),
        'inspectionStationId': value.inspectionStationId,
    };
}


