import { useState, useEffect } from "react";
import { VINCheckerResultResponse } from "../lib-api/models";
import { LoadingState } from "./loading";
import useApi from "./api";

export function useVINChecker(vinCheckerId: string) {
  const [vinChecker, setVINChecker] = useState<VINCheckerResultResponse|null>(null);

  const [state, setState] = useState(LoadingState.None);
  const { vinCheckersApi } = useApi();


  const loadVinChecker = async () => {
    setState(LoadingState.Loading);
    try {
      const vinCheckerResponse = await vinCheckersApi.apiVINCheckerResultPost({vinCheckerId: vinCheckerId});
      setVINChecker(vinCheckerResponse);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    loadVinChecker();
  }, [vinCheckerId]);

  return {
    vinChecker,
    vinCheckerState: state,
    reloadVinCheckers: loadVinChecker,
  };
}
