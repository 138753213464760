/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExportStatus {
    NotStarted = 'NotStarted',
    Processing = 'Processing',
    Finished = 'Finished',
    Errored = 'Errored'
}

export function ExportStatusFromJSON(json: any): ExportStatus {
    return ExportStatusFromJSONTyped(json, false);
}

export function ExportStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportStatus {
    return json as ExportStatus;
}

export function ExportStatusToJSON(value?: ExportStatus | null): any {
    return value as any;
}

