/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxChanges
 */
export interface TaxChanges {
    /**
     * 
     * @type {string}
     * @memberof TaxChanges
     */
    newPrice?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TaxChanges
     */
    dateOfChange?: Date;
}

export function TaxChangesFromJSON(json: any): TaxChanges {
    return TaxChangesFromJSONTyped(json, false);
}

export function TaxChangesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxChanges {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newPrice': !exists(json, 'newPrice') ? undefined : json['newPrice'],
        'dateOfChange': !exists(json, 'dateOfChange') ? undefined : (new Date(json['dateOfChange'])),
    };
}

export function TaxChangesToJSON(value?: TaxChanges | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newPrice': value.newPrice,
        'dateOfChange': value.dateOfChange === undefined ? undefined : (value.dateOfChange.toISOString()),
    };
}


