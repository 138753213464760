import { createStyles, Grid, makeStyles, MenuItem, Theme, withStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { InitialFilterData, ToldsynRequest } from "../../../lib-api";
import { Form, Formik } from "formik";
import { MakeField } from "../../shared/forms/MakeField";
import { ModelField } from "../../shared/forms/ModelField";
import { DateField } from "../../shared/forms/DateField";
import { Skeleton } from "@material-ui/lab";
import { SelectField } from "../../shared/forms/SelectField";
import moment from "moment";
import { ChartInfo, StatisticsStateContext } from "../../../state/statistics";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  end: {
    textAlign: "end"
  },
  center: {
    textAlign: "center"
  },
  subHeader: {
    color: "rgba(0, 0, 0, 0.54)",
    paddingLeft: "30px",
    fontWeight: 500
  },
  form: theme.form
}));

const CombinedField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& .MuiFormControl-root": {
        flexGrow: 1,
        marginBottom: "8px"
      },
      "& span": {
        width: "16px",
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
      }
    }
  }),
)(Grid);

export const ChartFilters: React.FC<{
  request: {
    info: ChartInfo;
    setRequest: (request: ToldsynRequest) => void;
  };
  initialLoading: boolean;
  initialFilterData?: InitialFilterData;
}> = (props) => {

  const classes = useStyles();
  const [_, dispatch] = useContext(StatisticsStateContext);
  const [currentYear] = useState(new Date().getFullYear());

  const getYears = () => {
    let years = [];
    for (let i = 1970; i <= currentYear; i++) {
      years.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return years;
  }

  const createMenuItems = (companyoverGroup: string[]) => {
    const items = [];

    items.push(<MenuItem value={companyoverGroup}>{companyoverGroup[0].split(" ")[0]}</MenuItem>);
    for (var i = 0; i < companyoverGroup.length; i++) {
      items.push(<MenuItem className={classes.subHeader} value={companyoverGroup[i]}>{companyoverGroup[i]}</MenuItem>);
    }
    return items;
  }

  useEffect(() => {
    return () => {
      dispatch({type: "cleanupFilters"});
    }
  },[]);

  return (<>
    <Formik
      enableReinitialize={true}
      initialValues={{ ...props.request.info.request }}
      validateOnBlur
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if(typeof(values.insuranceCompanies) == "string" && values.insuranceCompanies != ""){
          values.insuranceCompanies = [values.insuranceCompanies!.toString()];
        }
        setSubmitting(true);
        props.request.setRequest(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, validateField, handleSubmit }) => (
        <Form className={classes.form}>
          {props.initialLoading ?
          <Grid container spacing={2}>
            <Grid item xs={10}><Skeleton height={76} width={"100%"} /></Grid>
            </Grid>
            :
            <>
              <Grid container spacing={2}>
                {props.request.info.filters.make ? <Grid item xs={12} sm={2}>
                  <MakeField onFinish={() => handleSubmit()} name="make" placeholder="Make"></MakeField>
                </Grid> : null}
                {props.request.info.filters.model ? <Grid item xs={12} sm={2}>
                  <ModelField onFinish={() => handleSubmit()} name="model" placeholder="Model"></ModelField>
                </Grid> : null}
                {props.request.info.filters.year ? <Grid item xs={12} sm={2}>
                  <SelectField
                  onFinished={() => handleSubmit()}
                    name="year"
                    placeholder="All years"
                  >
                    {getYears()}
                  </SelectField>
                </Grid> : null}
                {props.request.info.filters.leasingState ? <Grid item xs={12} sm={2}>
                  <SelectField
                    name="leasingState"
                    placeholder="All cars"
                    onFinished={() => handleSubmit()}
                  >
                    <MenuItem key={0} value="Uden leasingkontrakt">Uden leasingkontrakt</MenuItem>
                    <MenuItem key={1} value="Aktiv leasingkontrakt">Aktiv leasingkontrakt</MenuItem>
                    <MenuItem key={2} value="Har haft leasingkontrakt">Har haft leasingkontrakt</MenuItem>
                  </SelectField>
                </Grid> : null}
                {props.request.info.filters.insuranceCompany && props.initialFilterData != undefined ? <Grid item xs={12} sm={2}>
                  <SelectField
                    name="insuranceCompanies"
                    placeholder="Insurance company"
                    onFinished={() => handleSubmit()}
                  >
                    {props.initialFilterData.insuranceCompanies != null ? props.initialFilterData.insuranceCompanies.map((companyoverGroup, index) => {
                      if (companyoverGroup.length > 1) {
                        return createMenuItems(companyoverGroup);
                      }
                      return <MenuItem key={index} value={companyoverGroup + ""}>{companyoverGroup[0]}</MenuItem>

                    }) : null}
                  </SelectField>
                </Grid> : null}
                {props.request.info.filters.inspectionStation && props.initialFilterData != undefined ? <Grid item xs={12} sm={2}>
                  <SelectField
                  onFinished={() => handleSubmit()}
                    name="inspectionStation"
                    placeholder="Inspection station"
                  >
                    {props.initialFilterData.inspectionStations != null ? props.initialFilterData.inspectionStations.map((station, index) => (
                      <MenuItem key={index} value={station.id}>{station.name}</MenuItem>
                    )) : null}
                  </SelectField>
                </Grid> : null}
                {props.request.info.filters.inspectionDateFrom && props.request.info.filters.inspectionDateTo ? <CombinedField item xs={12} sm={4}>
                  <DateField
                  onFinished={() => {
                    if(moment(values.inspectionDateFrom, true).isValid() || values.inspectionDateFrom == null){
                      handleSubmit();
                    }
                  }} 
                  name="inspectionDateFrom" 
                  placeholder="From" 
                  type="date"></DateField>
                  <span>-</span>
                  <DateField
                  onFinished={() => {
                    if(moment(values.inspectionDateFrom, true).isValid() || values.inspectionDateFrom == null){
                      handleSubmit();
                    }
                  }}
                  name="inspectionDateTo" 
                  placeholder="To" 
                  type="date"></DateField>
                </CombinedField> : null}
              </Grid>
            </>
          }
        </Form>
      )}
    </Formik>
  </>
  );
};
