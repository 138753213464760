/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ManagedAssetDto,
    ManagedAssetDtoFromJSON,
    ManagedAssetDtoFromJSONTyped,
    ManagedAssetDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleReportManagedAssetDto
 */
export interface VehicleReportManagedAssetDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleReportManagedAssetDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleReportManagedAssetDto
     */
    vehicleReportId?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleReportManagedAssetDto
     */
    managedAssetId?: string;
    /**
     * 
     * @type {ManagedAssetDto}
     * @memberof VehicleReportManagedAssetDto
     */
    managedAsset?: ManagedAssetDto;
}

export function VehicleReportManagedAssetDtoFromJSON(json: any): VehicleReportManagedAssetDto {
    return VehicleReportManagedAssetDtoFromJSONTyped(json, false);
}

export function VehicleReportManagedAssetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleReportManagedAssetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vehicleReportId': !exists(json, 'vehicleReportId') ? undefined : json['vehicleReportId'],
        'managedAssetId': !exists(json, 'managedAssetId') ? undefined : json['managedAssetId'],
        'managedAsset': !exists(json, 'managedAsset') ? undefined : ManagedAssetDtoFromJSON(json['managedAsset']),
    };
}

export function VehicleReportManagedAssetDtoToJSON(value?: VehicleReportManagedAssetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vehicleReportId': value.vehicleReportId,
        'managedAssetId': value.managedAssetId,
        'managedAsset': ManagedAssetDtoToJSON(value.managedAsset),
    };
}


