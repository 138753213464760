/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamMemberDto
 */
export interface TeamMemberDto {
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    screenName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    teamName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeamMemberDto
     */
    roles?: Array<string> | null;
}

export function TeamMemberDtoFromJSON(json: any): TeamMemberDto {
    return TeamMemberDtoFromJSONTyped(json, false);
}

export function TeamMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamMemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'screenName': !exists(json, 'screenName') ? undefined : json['screenName'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'teamName': !exists(json, 'teamName') ? undefined : json['teamName'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
    };
}

export function TeamMemberDtoToJSON(value?: TeamMemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'screenName': value.screenName,
        'userName': value.userName,
        'email': value.email,
        'teamName': value.teamName,
        'roles': value.roles,
    };
}


