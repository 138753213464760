/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminEditVehicleDataRequest
 */
export interface AdminEditVehicleDataRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminEditVehicleDataRequest
     */
    vehicleInformationReportDataId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEditVehicleDataRequest
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminEditVehicleDataRequest
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminEditVehicleDataRequest
     */
    year?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminEditVehicleDataRequest
     */
    variant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminEditVehicleDataRequest
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminEditVehicleDataRequest
     */
    type?: string | null;
}

export function AdminEditVehicleDataRequestFromJSON(json: any): AdminEditVehicleDataRequest {
    return AdminEditVehicleDataRequestFromJSONTyped(json, false);
}

export function AdminEditVehicleDataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminEditVehicleDataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleInformationReportDataId': !exists(json, 'vehicleInformationReportDataId') ? undefined : json['vehicleInformationReportDataId'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'variant': !exists(json, 'variant') ? undefined : json['variant'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function AdminEditVehicleDataRequestToJSON(value?: AdminEditVehicleDataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleInformationReportDataId': value.vehicleInformationReportDataId,
        'make': value.make,
        'model': value.model,
        'year': value.year,
        'variant': value.variant,
        'color': value.color,
        'type': value.type,
    };
}


