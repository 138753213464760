/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SaveSearchAgentRequest,
    SaveSearchAgentRequestFromJSON,
    SaveSearchAgentRequestToJSON,
    SearchAgentDto,
    SearchAgentDtoFromJSON,
    SearchAgentDtoToJSON,
    SearchForm,
    SearchFormFromJSON,
    SearchFormToJSON,
    SearchInitDataResponse,
    SearchInitDataResponseFromJSON,
    SearchInitDataResponseToJSON,
    SearchResult,
    SearchResultFromJSON,
    SearchResultToJSON,
} from '../models';

export interface ApiSearchCsvVinPostRequest {
    amount?: number;
    page?: number;
    searchForm?: SearchForm;
}

export interface ApiSearchInitValuesPostRequest {
    searchAgentId?: number;
}

export interface ApiSearchMakeSuggestionsPostRequest {
    searchPhrase?: string | null;
}

export interface ApiSearchModelSuggestionsPostRequest {
    make?: string | null;
    searchPhrase?: string | null;
}

export interface ApiSearchSaveAgentPostRequest {
    searchAgentId?: number | null;
    saveSearchAgentRequest?: SaveSearchAgentRequest;
}

export interface ApiSearchSearchPostRequest {
    amount?: number;
    page?: number;
    searchForm?: SearchForm;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     */
    async apiSearchCsvVinPostRaw(requestParameters: ApiSearchCsvVinPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Search/csv-vin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchFormToJSON(requestParameters.searchForm),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiSearchCsvVinPost(requestParameters: ApiSearchCsvVinPostRequest): Promise<string> {
        const response = await this.apiSearchCsvVinPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSearchInitValuesPostRaw(requestParameters: ApiSearchInitValuesPostRequest): Promise<runtime.ApiResponse<SearchInitDataResponse>> {
        const queryParameters: any = {};

        if (requestParameters.searchAgentId !== undefined) {
            queryParameters['searchAgentId'] = requestParameters.searchAgentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Search/InitValues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchInitDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchInitValuesPost(requestParameters: ApiSearchInitValuesPostRequest): Promise<SearchInitDataResponse> {
        const response = await this.apiSearchInitValuesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSearchMakeSuggestionsPostRaw(requestParameters: ApiSearchMakeSuggestionsPostRequest): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.searchPhrase !== undefined) {
            queryParameters['searchPhrase'] = requestParameters.searchPhrase;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Search/MakeSuggestions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiSearchMakeSuggestionsPost(requestParameters: ApiSearchMakeSuggestionsPostRequest): Promise<Array<string>> {
        const response = await this.apiSearchMakeSuggestionsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSearchModelSuggestionsPostRaw(requestParameters: ApiSearchModelSuggestionsPostRequest): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        if (requestParameters.searchPhrase !== undefined) {
            queryParameters['searchPhrase'] = requestParameters.searchPhrase;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Search/ModelSuggestions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiSearchModelSuggestionsPost(requestParameters: ApiSearchModelSuggestionsPostRequest): Promise<Array<string>> {
        const response = await this.apiSearchModelSuggestionsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSearchSaveAgentPostRaw(requestParameters: ApiSearchSaveAgentPostRequest): Promise<runtime.ApiResponse<SearchAgentDto>> {
        const queryParameters: any = {};

        if (requestParameters.searchAgentId !== undefined) {
            queryParameters['searchAgentId'] = requestParameters.searchAgentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Search/SaveAgent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveSearchAgentRequestToJSON(requestParameters.saveSearchAgentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchAgentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchSaveAgentPost(requestParameters: ApiSearchSaveAgentPostRequest): Promise<SearchAgentDto> {
        const response = await this.apiSearchSaveAgentPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSearchSearchPostRaw(requestParameters: ApiSearchSearchPostRequest): Promise<runtime.ApiResponse<SearchResult>> {
        const queryParameters: any = {};

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Search/Search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchFormToJSON(requestParameters.searchForm),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResultFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchSearchPost(requestParameters: ApiSearchSearchPostRequest): Promise<SearchResult> {
        const response = await this.apiSearchSearchPostRaw(requestParameters);
        return await response.value();
    }

}
