/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetType,
    AssetTypeFromJSON,
    AssetTypeFromJSONTyped,
    AssetTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ManagedAssetDto
 */
export interface ManagedAssetDto {
    /**
     * 
     * @type {string}
     * @memberof ManagedAssetDto
     */
    id?: string;
    /**
     * 
     * @type {AssetType}
     * @memberof ManagedAssetDto
     */
    type?: AssetType;
    /**
     * 
     * @type {Date}
     * @memberof ManagedAssetDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ManagedAssetDto
     */
    url?: string | null;
}

export function ManagedAssetDtoFromJSON(json: any): ManagedAssetDto {
    return ManagedAssetDtoFromJSONTyped(json, false);
}

export function ManagedAssetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagedAssetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : AssetTypeFromJSON(json['type']),
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function ManagedAssetDtoToJSON(value?: ManagedAssetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': AssetTypeToJSON(value.type),
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'url': value.url,
    };
}


