import { createStyles, TextField, Theme, withStyles } from "@material-ui/core";

export const CustomInlineTextField = withStyles((theme: Theme) =>
createStyles({
  root: {
    '& .MuiInputBase-root':{
      fontSize: "16px",
      height: "24px",
      color: "#c9c9d1"
    },
    '& .MuiInputBase-input':{
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: "0px"
    },
    '& fieldset:hover':{
      borderColor: "rgb(178, 182, 185)"
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: "3px",
    },
    '& .MuiInput-underline:before': {
      borderBottom: "none"
    },
    '& .MuiInput-underline:after': {
      borderBottom: "none"
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: "none"
    }
  }
}),
)(TextField);

export const CustomInlineSelect = withStyles((theme: Theme) =>
createStyles({
  root: {
    '& .MuiInputBase-root':{
      width: "100%",
      color: theme.palette.common.black
    },
    width: "100%"
  }
}),
)(CustomInlineTextField);