import React, { useEffect, useState } from "react";
import { LoadingState } from "../../../../hooks/loading";
import { ElementLoading } from "../../../shared/other/Loading";
import { Skeleton } from "@material-ui/lab";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { useDimensions } from "../../../../hooks/dimensions";
import { EventType, VehicleEvent, VehicleEventInfo } from "../../../../lib-api";
import { useHelper } from "../../../../hooks/helper";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useAuth from "../../../../hooks/auth";

const useStyles = makeStyles((theme) => ({
  skeleton: (props: { width: number }) => ({
    width: props.width,
    height: props.width / 2
  }),
  pdfIcon: theme.icon,
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    flexBasis: '5%'
  },
  end: {
    textAlign: "right"
  }
}));

export const EventHistory: React.FC<{
  loadingState: LoadingState;
  initialLoading: boolean;
  allEvents?: VehicleEventInfo[];
}> = (props) => {

  const { width } = useDimensions("eventhistory-width");
  const classes = useStyles({ width: width as number });
  const { getContryFromCode, getRemoteSourceFromEnum, getIconFromEventType, getFlagFromCode } = useHelper();
  const {isAdmin} = useAuth();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [allExpanded, setAllExpanded] = useState<boolean>(false);

  const handleChange = (panel: string) => async(event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getHeading = (eventType?: EventType) => {
    switch (eventType) {
      case EventType.Assessment:
        return "Valuated";
        case EventType.InspectionReport:
        return "Inspection check";
        case EventType.InsuranceStatus:
        return "Insurance change";
        case EventType.SalesAd:
        return "Set for sale";
        case EventType.TaxTransaction:
        return "Tax payed";
        case EventType.MileageReading:
        return "Mileage reading";
        case EventType.RegistrationRestriction:
        return "Restricted from registering";
        case EventType.ManufacturedDontShow:
        return "Manufactured";
      default:
        return eventType?.toString();
    }
  }

  return (<div id="eventhistory-width" style={{ position: "relative" }}>
    {props.initialLoading ? <Skeleton variant="rect" className={classes.skeleton}></Skeleton>
      :
      <>
        {props.loadingState == LoadingState.Loading ?
          <ElementLoading position="absolute"></ElementLoading> : null}
          <Grid container>
            <Grid className={classes.end} item xs={12}>
            <Button color="primary" onClick={() => {setAllExpanded(!allExpanded);}}>Expand/Collapse all</Button>
            </Grid>
          </Grid>
        {props.allEvents?.map((event, index) => (
          <Accordion key={index} expanded={expanded === 'panel' + index || allExpanded} onChange={handleChange('panel' + index)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={12} sm={3}>
              <Typography className={classes.heading}>{event.eventDate?.toDateString()}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
              <Grid spacing={1} container alignItems="flex-end">
                <Grid item>
                <Typography className={classes.icon}>{getIconFromEventType(event.eventType)}</Typography>
                </Grid>
                <Grid item>
                <Typography className={classes.secondaryHeading}>{getHeading(event.eventType)}</Typography>
                </Grid>
              </Grid>
              </Grid>
              <Hidden xsDown>
              <Grid item xs={12} sm={3}>
              <Typography className={classes.secondaryHeading}>{getContryFromCode(event.country)}</Typography>
              </Grid>
              </Hidden>
              {isAdmin() ? <Grid item xs={12} sm={3}>
                <Typography className={classes.secondaryHeading}>{getRemoteSourceFromEnum(event.remoteType)}</Typography>
                </Grid> : null}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {event.info ? event.info : "No additional data."}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </>} </div>)
}
