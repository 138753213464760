/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldSimiliarAmount,
    FieldSimiliarAmountFromJSON,
    FieldSimiliarAmountFromJSONTyped,
    FieldSimiliarAmountToJSON,
    VehicleBlockedStatusData,
    VehicleBlockedStatusDataFromJSON,
    VehicleBlockedStatusDataFromJSONTyped,
    VehicleBlockedStatusDataToJSON,
    VehicleEstimatedValuationReportData,
    VehicleEstimatedValuationReportDataFromJSON,
    VehicleEstimatedValuationReportDataFromJSONTyped,
    VehicleEstimatedValuationReportDataToJSON,
    VehicleEvent,
    VehicleEventFromJSON,
    VehicleEventFromJSONTyped,
    VehicleEventToJSON,
    VehicleInformationReportData,
    VehicleInformationReportDataFromJSON,
    VehicleInformationReportDataFromJSONTyped,
    VehicleInformationReportDataToJSON,
    VehicleLeasingData,
    VehicleLeasingDataFromJSON,
    VehicleLeasingDataFromJSONTyped,
    VehicleLeasingDataToJSON,
    VehicleMileageReportData,
    VehicleMileageReportDataFromJSON,
    VehicleMileageReportDataFromJSONTyped,
    VehicleMileageReportDataToJSON,
    VehicleNextInspectionData,
    VehicleNextInspectionDataFromJSON,
    VehicleNextInspectionDataFromJSONTyped,
    VehicleNextInspectionDataToJSON,
    VehiclePictureReportData,
    VehiclePictureReportDataFromJSON,
    VehiclePictureReportDataFromJSONTyped,
    VehiclePictureReportDataToJSON,
    VehicleReportManagedAssetDto,
    VehicleReportManagedAssetDtoFromJSON,
    VehicleReportManagedAssetDtoFromJSONTyped,
    VehicleReportManagedAssetDtoToJSON,
    VehicleValuationReportData,
    VehicleValuationReportDataFromJSON,
    VehicleValuationReportDataFromJSONTyped,
    VehicleValuationReportDataToJSON,
    VehicleValuationTaxReportData,
    VehicleValuationTaxReportDataFromJSON,
    VehicleValuationTaxReportDataFromJSONTyped,
    VehicleValuationTaxReportDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleReportDto
 */
export interface VehicleReportDto {
    /**
     * 
     * @type {Date}
     * @memberof VehicleReportDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof VehicleReportDto
     */
    score?: number | null;
    /**
     * 
     * @type {Array<VehicleMileageReportData>}
     * @memberof VehicleReportDto
     */
    mileageHistory?: Array<VehicleMileageReportData> | null;
    /**
     * 
     * @type {Array<VehicleValuationReportData>}
     * @memberof VehicleReportDto
     */
    valuationHistory?: Array<VehicleValuationReportData> | null;
    /**
     * 
     * @type {Array<VehicleValuationTaxReportData>}
     * @memberof VehicleReportDto
     */
    valuationTaxHistory?: Array<VehicleValuationTaxReportData> | null;
    /**
     * 
     * @type {Array<VehicleEvent>}
     * @memberof VehicleReportDto
     */
    vehicleEvents?: Array<VehicleEvent> | null;
    /**
     * 
     * @type {Array<VehiclePictureReportData>}
     * @memberof VehicleReportDto
     */
    pictures?: Array<VehiclePictureReportData> | null;
    /**
     * 
     * @type {Array<VehicleReportManagedAssetDto>}
     * @memberof VehicleReportDto
     */
    vehicleReportAssets?: Array<VehicleReportManagedAssetDto> | null;
    /**
     * 
     * @type {Array<VehicleEstimatedValuationReportData>}
     * @memberof VehicleReportDto
     */
    estimatedValutationHistory?: Array<VehicleEstimatedValuationReportData> | null;
    /**
     * 
     * @type {Array<VehicleInformationReportData>}
     * @memberof VehicleReportDto
     */
    vehicleInformation?: Array<VehicleInformationReportData> | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleReportDto
     */
    vinValidityCount?: number | null;
    /**
     * 
     * @type {Array<FieldSimiliarAmount>}
     * @memberof VehicleReportDto
     */
    fieldSimiliarAmounts?: Array<FieldSimiliarAmount> | null;
    /**
     * 
     * @type {VehicleLeasingData}
     * @memberof VehicleReportDto
     */
    leasingData?: VehicleLeasingData;
    /**
     * 
     * @type {VehicleNextInspectionData}
     * @memberof VehicleReportDto
     */
    nextInspectionData?: VehicleNextInspectionData;
    /**
     * 
     * @type {VehicleBlockedStatusData}
     * @memberof VehicleReportDto
     */
    blockedStatusData?: VehicleBlockedStatusData;
    /**
     * 
     * @type {string}
     * @memberof VehicleReportDto
     */
    summary?: string | null;
}

export function VehicleReportDtoFromJSON(json: any): VehicleReportDto {
    return VehicleReportDtoFromJSONTyped(json, false);
}

export function VehicleReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleReportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'score': !exists(json, 'score') ? undefined : json['score'],
        'mileageHistory': !exists(json, 'mileageHistory') ? undefined : (json['mileageHistory'] === null ? null : (json['mileageHistory'] as Array<any>).map(VehicleMileageReportDataFromJSON)),
        'valuationHistory': !exists(json, 'valuationHistory') ? undefined : (json['valuationHistory'] === null ? null : (json['valuationHistory'] as Array<any>).map(VehicleValuationReportDataFromJSON)),
        'valuationTaxHistory': !exists(json, 'valuationTaxHistory') ? undefined : (json['valuationTaxHistory'] === null ? null : (json['valuationTaxHistory'] as Array<any>).map(VehicleValuationTaxReportDataFromJSON)),
        'vehicleEvents': !exists(json, 'vehicleEvents') ? undefined : (json['vehicleEvents'] === null ? null : (json['vehicleEvents'] as Array<any>).map(VehicleEventFromJSON)),
        'pictures': !exists(json, 'pictures') ? undefined : (json['pictures'] === null ? null : (json['pictures'] as Array<any>).map(VehiclePictureReportDataFromJSON)),
        'vehicleReportAssets': !exists(json, 'vehicleReportAssets') ? undefined : (json['vehicleReportAssets'] === null ? null : (json['vehicleReportAssets'] as Array<any>).map(VehicleReportManagedAssetDtoFromJSON)),
        'estimatedValutationHistory': !exists(json, 'estimatedValutationHistory') ? undefined : (json['estimatedValutationHistory'] === null ? null : (json['estimatedValutationHistory'] as Array<any>).map(VehicleEstimatedValuationReportDataFromJSON)),
        'vehicleInformation': !exists(json, 'vehicleInformation') ? undefined : (json['vehicleInformation'] === null ? null : (json['vehicleInformation'] as Array<any>).map(VehicleInformationReportDataFromJSON)),
        'vinValidityCount': !exists(json, 'vinValidityCount') ? undefined : json['vinValidityCount'],
        'fieldSimiliarAmounts': !exists(json, 'fieldSimiliarAmounts') ? undefined : (json['fieldSimiliarAmounts'] === null ? null : (json['fieldSimiliarAmounts'] as Array<any>).map(FieldSimiliarAmountFromJSON)),
        'leasingData': !exists(json, 'leasingData') ? undefined : VehicleLeasingDataFromJSON(json['leasingData']),
        'nextInspectionData': !exists(json, 'nextInspectionData') ? undefined : VehicleNextInspectionDataFromJSON(json['nextInspectionData']),
        'blockedStatusData': !exists(json, 'blockedStatusData') ? undefined : VehicleBlockedStatusDataFromJSON(json['blockedStatusData']),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
    };
}

export function VehicleReportDtoToJSON(value?: VehicleReportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'score': value.score,
        'mileageHistory': value.mileageHistory === undefined ? undefined : (value.mileageHistory === null ? null : (value.mileageHistory as Array<any>).map(VehicleMileageReportDataToJSON)),
        'valuationHistory': value.valuationHistory === undefined ? undefined : (value.valuationHistory === null ? null : (value.valuationHistory as Array<any>).map(VehicleValuationReportDataToJSON)),
        'valuationTaxHistory': value.valuationTaxHistory === undefined ? undefined : (value.valuationTaxHistory === null ? null : (value.valuationTaxHistory as Array<any>).map(VehicleValuationTaxReportDataToJSON)),
        'vehicleEvents': value.vehicleEvents === undefined ? undefined : (value.vehicleEvents === null ? null : (value.vehicleEvents as Array<any>).map(VehicleEventToJSON)),
        'pictures': value.pictures === undefined ? undefined : (value.pictures === null ? null : (value.pictures as Array<any>).map(VehiclePictureReportDataToJSON)),
        'vehicleReportAssets': value.vehicleReportAssets === undefined ? undefined : (value.vehicleReportAssets === null ? null : (value.vehicleReportAssets as Array<any>).map(VehicleReportManagedAssetDtoToJSON)),
        'estimatedValutationHistory': value.estimatedValutationHistory === undefined ? undefined : (value.estimatedValutationHistory === null ? null : (value.estimatedValutationHistory as Array<any>).map(VehicleEstimatedValuationReportDataToJSON)),
        'vehicleInformation': value.vehicleInformation === undefined ? undefined : (value.vehicleInformation === null ? null : (value.vehicleInformation as Array<any>).map(VehicleInformationReportDataToJSON)),
        'vinValidityCount': value.vinValidityCount,
        'fieldSimiliarAmounts': value.fieldSimiliarAmounts === undefined ? undefined : (value.fieldSimiliarAmounts === null ? null : (value.fieldSimiliarAmounts as Array<any>).map(FieldSimiliarAmountToJSON)),
        'leasingData': VehicleLeasingDataToJSON(value.leasingData),
        'nextInspectionData': VehicleNextInspectionDataToJSON(value.nextInspectionData),
        'blockedStatusData': VehicleBlockedStatusDataToJSON(value.blockedStatusData),
        'summary': value.summary,
    };
}


