import React, { useEffect, useState } from "react";
import { VehicleDocumentWithToldsyn } from "../../../../lib-api";
import { LoadingState } from "../../../../hooks/loading";
import { RowsProp } from "@material-ui/data-grid";
import { ElementLoading } from "../../../shared/other/Loading";
import { SearchResultGrid } from "./SearchResultGrid";
import { Grid } from "@material-ui/core";


export const SearchResults: React.FC<{
  results: VehicleDocumentWithToldsyn[] | null | undefined;
  totalCount?: number;
  perPage: number;
  active: number;
  onPageSelected: React.Dispatch<React.SetStateAction<number>>;
  searchState: LoadingState;
}> = (props) => {

  const [rows, setRows] = useState<RowsProp | null>(null);

  useEffect(() => {
    setRows(props.results !== null && props.results !== undefined && props.results?.length !== 0 ? (
      props.results?.map((result, index) => {
        return {
          id: index,
          col1: result.data?.stelNr,
          col2: result.data?.regNr,
          col3: result.data,
          col4: result.data?.status,
          col5: result.data?.farveTypeNavn,
          col6: result.data?.foersteRegistreringDato,
          col7: result.inspection?.createdDate
        }
      })) : []);
  }, [props.results]);

  return (
    <>
      {props.searchState == LoadingState.Loading && props.results == null ?
          <ElementLoading position="relative"></ElementLoading>
        :
        props.results !== null && props.results != undefined && rows != null ? (
          <Grid item xs={12}>
            <SearchResultGrid onPageSelected={props.onPageSelected} rows={rows} perPage={props.perPage} active={props.active} totalCount={props.totalCount} searchState={props.searchState}></SearchResultGrid>
          </Grid>) : null
      }
    </>
  );
};