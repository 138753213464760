/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VINCheckerChangesDto,
    VINCheckerChangesDtoFromJSON,
    VINCheckerChangesDtoFromJSONTyped,
    VINCheckerChangesDtoToJSON,
    VINCheckerDto,
    VINCheckerDtoFromJSON,
    VINCheckerDtoFromJSONTyped,
    VINCheckerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface VINCheckerResultResponse
 */
export interface VINCheckerResultResponse {
    /**
     * 
     * @type {VINCheckerChangesDto}
     * @memberof VINCheckerResultResponse
     */
    changes?: VINCheckerChangesDto;
    /**
     * 
     * @type {VINCheckerDto}
     * @memberof VINCheckerResultResponse
     */
    vinChecker: VINCheckerDto;
}

export function VINCheckerResultResponseFromJSON(json: any): VINCheckerResultResponse {
    return VINCheckerResultResponseFromJSONTyped(json, false);
}

export function VINCheckerResultResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VINCheckerResultResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'changes': !exists(json, 'changes') ? undefined : VINCheckerChangesDtoFromJSON(json['changes']),
        'vinChecker': VINCheckerDtoFromJSON(json['vinChecker']),
    };
}

export function VINCheckerResultResponseToJSON(value?: VINCheckerResultResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'changes': VINCheckerChangesDtoToJSON(value.changes),
        'vinChecker': VINCheckerDtoToJSON(value.vinChecker),
    };
}


