/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchForm,
    SearchFormFromJSON,
    SearchFormFromJSONTyped,
    SearchFormToJSON,
} from './';

/**
 * 
 * @export
 * @interface SaveSearchAgentRequest
 */
export interface SaveSearchAgentRequest {
    /**
     * 
     * @type {SearchForm}
     * @memberof SaveSearchAgentRequest
     */
    searchForm?: SearchForm;
    /**
     * 
     * @type {string}
     * @memberof SaveSearchAgentRequest
     */
    name: string;
}

export function SaveSearchAgentRequestFromJSON(json: any): SaveSearchAgentRequest {
    return SaveSearchAgentRequestFromJSONTyped(json, false);
}

export function SaveSearchAgentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveSearchAgentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchForm': !exists(json, 'searchForm') ? undefined : SearchFormFromJSON(json['searchForm']),
        'name': json['name'],
    };
}

export function SaveSearchAgentRequestToJSON(value?: SaveSearchAgentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchForm': SearchFormToJSON(value.searchForm),
        'name': value.name,
    };
}


