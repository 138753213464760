/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountTeamDto,
    AccountTeamDtoFromJSON,
    AccountTeamDtoFromJSONTyped,
    AccountTeamDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    screenName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    teamName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    teamId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    teamOwnerName?: string | null;
    /**
     * 
     * @type {Array<AccountTeamDto>}
     * @memberof AccountDto
     */
    teams?: Array<AccountTeamDto> | null;
}

export function AccountDtoFromJSON(json: any): AccountDto {
    return AccountDtoFromJSONTyped(json, false);
}

export function AccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'screenName': !exists(json, 'screenName') ? undefined : json['screenName'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'teamName': !exists(json, 'teamName') ? undefined : json['teamName'],
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
        'teamOwnerName': !exists(json, 'teamOwnerName') ? undefined : json['teamOwnerName'],
        'teams': !exists(json, 'teams') ? undefined : (json['teams'] === null ? null : (json['teams'] as Array<any>).map(AccountTeamDtoFromJSON)),
    };
}

export function AccountDtoToJSON(value?: AccountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'screenName': value.screenName,
        'userName': value.userName,
        'email': value.email,
        'teamName': value.teamName,
        'teamId': value.teamId,
        'teamOwnerName': value.teamOwnerName,
        'teams': value.teams === undefined ? undefined : (value.teams === null ? null : (value.teams as Array<any>).map(AccountTeamDtoToJSON)),
    };
}


