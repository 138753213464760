/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VehicleDocumentWithToldsyn,
    VehicleDocumentWithToldsynFromJSON,
    VehicleDocumentWithToldsynFromJSONTyped,
    VehicleDocumentWithToldsynToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    totalHits?: number;
    /**
     * 
     * @type {Array<VehicleDocumentWithToldsyn>}
     * @memberof SearchResult
     */
    cars?: Array<VehicleDocumentWithToldsyn> | null;
}

export function SearchResultFromJSON(json: any): SearchResult {
    return SearchResultFromJSONTyped(json, false);
}

export function SearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalHits': !exists(json, 'totalHits') ? undefined : json['totalHits'],
        'cars': !exists(json, 'cars') ? undefined : (json['cars'] === null ? null : (json['cars'] as Array<any>).map(VehicleDocumentWithToldsynFromJSON)),
    };
}

export function SearchResultToJSON(value?: SearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalHits': value.totalHits,
        'cars': value.cars === undefined ? undefined : (value.cars === null ? null : (value.cars as Array<any>).map(VehicleDocumentWithToldsynToJSON)),
    };
}


