import React, { useEffect, useState } from "react";
import useAuth from "../../../../hooks/auth";
import { makeStyles } from "@material-ui/core";
import { useTeamInfo } from "../../../../hooks/teamInfo";
import { useVINCheckers } from "../../../../hooks/VINCheckers";
import { ColDef, RowParams, RowsProp, ValueFormatterParams } from "@material-ui/data-grid";
import { VINCheckerDto } from "../../../../lib-api";
import { useHistory } from "react-router-dom";
import useApi from "../../../../hooks/api";
import { CustomDataGrid } from "../../../shared/datagrid/CustomDataGrid";
import { CustomSwitch } from "../../../shared/forms/CustomSwitch";
import { DeletionIcon } from "../../../shared/other/Icons";
import { useWindowSize } from "../../../../hooks/windowSize";

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center"
  },
  end: {
    textAlign: "end"
  },
  form: theme.form,
  link: theme.link
}));

function MonitorChangesTab() {
  const [searchQuery, setSearchQuery] = useState("");
  const [onlyActive, setActive] = useState(false);
  const [onlyWanted] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchAmount] = useState(10);
  const {
    vinCheckers,
    addVinChecker,
    vinCheckerState,
    deleteVinChecker
  } = useVINCheckers(
    searchQuery,
    onlyWanted,
    onlyActive,
    pageNumber,
    searchAmount
  );
  const { vinCheckersApi } = useApi();
  const [actives, setActives] = useState<Array<{ id: string, value: boolean }>>([]);
  const [rows, setRows] = useState<RowsProp>([]);
  const history = useHistory();
  const {xsDown} = useWindowSize();

  const { incrementActiveVehicleCount, decrementActiveVehicleCount } = useTeamInfo();

  const addNewVinChecker = async(vehicleId: string) => {
    try{
      await addVinChecker(vehicleId);
    }catch{

    }
  }

  useEffect(() => {
    setActives(vinCheckers.all.map((checker) => { return { id: checker.vinCheckerMeta?.id!, value: checker.vinCheckerMeta?.active! } }));
    setRows(vinCheckers.all.length !== 0 ? (
      vinCheckers.all.map((checker, index) => {
        return {
          id: index,
          first: checker,
          col1: checker?.vin,
          col2: checker?.lastChecked,
          col3: checker?.creatorName,
          col4: checker
        }
      })) : []);
  }, [vinCheckers.all]);

  const handleCheckBoxClick = async (event: any, params: ValueFormatterParams) => {
    var checked = event.target.checked;

    try {
      await vinCheckersApi.apiVINCheckerUpdatePost({
        updateVINCheckerActivity: {
          vinCheckerId: (params.value as VINCheckerDto).id,
          value: checked
        }
      });
      var temp = actives.filter(p => p.id != (params.value as VINCheckerDto).vinCheckerMeta?.id);
      temp.push({ value: checked, id: (params.value as VINCheckerDto).vinCheckerMeta?.id! });
      setActives(temp);

      if (!checked) {
        incrementActiveVehicleCount();
      } else {
        decrementActiveVehicleCount();
      }
    } catch {
    }
  }

  const handleDelete = async (params: ValueFormatterParams) => {
    var data = (params.value as VINCheckerDto);
    try {
      await deleteVinChecker(data.id!);
      if (data.vinCheckerMeta?.active) {
        decrementActiveVehicleCount();
      }
    } catch {
    }
  }

  const columns: ColDef[] = [
    { field: 'col1', headerName: 'VIN', sortable: false, flex: 3 },
    { field: 'col2', headerName: 'LAST CHECK', hide: xsDown, flex: 7, valueFormatter: (params: ValueFormatterParams) => params.value != null ? (params.value as Date).toDateString() : null },
    { field: 'col3', headerName: 'CREATOR', hide: xsDown, sortable: false, flex: 3 },
    {
      field: 'first', headerName: 'MONITOR', flex: 2, renderCell: (params: ValueFormatterParams) => (<>
        <CustomSwitch color="primary" checked={actives.find(p => p.id == (params.value as VINCheckerDto).vinCheckerMeta?.id)?.value}
          onClick={(event) => {
            handleCheckBoxClick(event, params);
          }}></CustomSwitch>
      </>), disableClickEventBubbling: true, sortable: false
    },
    {
      field: 'col4', headerName: ' ',
      renderCell: (params: ValueFormatterParams) => (<>
        <DeletionIcon onClick={() => handleDelete(params)} hoverText="Delete VIN Monitor"></DeletionIcon>
      </>), sortable: false, flex: 1, disableClickEventBubbling: true
    }
  ];

  const classes = useStyles();
  return (
        <>
                <CustomDataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={searchAmount}
                  setPage={setPageNumber}
                  page={pageNumber}
                  totalRows={vinCheckers.totalCount}
                  loadingState={vinCheckerState}
                  onRowClick={(row: RowParams) => row.row["first"].lastChecked != null ? history.push(`/monitoring/vinmonitors/${row.row["first"].id}`) : null}
                  dataDisplayText="VIN monitors"
                  headerProps={{
                    filters: [
                      {
                        name: "Search",
                        onChange: (event) => setSearchQuery(event.currentTarget.value),
                        type: "search",
                        position: "left",
                      },
                      {
                        name: "Show only monitored",
                        position: "left",
                        onChange: (event) => setActive(event.currentTarget.checked),
                        type: "bool"
                      },
                      {
                        name: "+ Setup a new VIN monitor",
                        placeholder: "VIN",
                        onSubmitVehicleId: addNewVinChecker,
                        type: "submit",
                        position: "right",
                      }
                    ],
                    isTab: true
                  }}
                ></CustomDataGrid>
        </>
  );
}

export default MonitorChangesTab;
