/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExportStatus,
    ExportStatusFromJSON,
    ExportStatusFromJSONTyped,
    ExportStatusToJSON,
    ExportVehicleDto,
    ExportVehicleDtoFromJSON,
    ExportVehicleDtoFromJSONTyped,
    ExportVehicleDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExportUrlDto
 */
export interface ExportUrlDto {
    /**
     * 
     * @type {string}
     * @memberof ExportUrlDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportUrlDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportUrlDto
     */
    url?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ExportUrlDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExportUrlDto
     */
    teamMemberId?: string;
    /**
     * 
     * @type {Array<ExportVehicleDto>}
     * @memberof ExportUrlDto
     */
    exportVehicles?: Array<ExportVehicleDto> | null;
    /**
     * 
     * @type {ExportStatus}
     * @memberof ExportUrlDto
     */
    exportStatus?: ExportStatus;
}

export function ExportUrlDtoFromJSON(json: any): ExportUrlDto {
    return ExportUrlDtoFromJSONTyped(json, false);
}

export function ExportUrlDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportUrlDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'teamMemberId': !exists(json, 'teamMemberId') ? undefined : json['teamMemberId'],
        'exportVehicles': !exists(json, 'exportVehicles') ? undefined : (json['exportVehicles'] === null ? null : (json['exportVehicles'] as Array<any>).map(ExportVehicleDtoFromJSON)),
        'exportStatus': !exists(json, 'exportStatus') ? undefined : ExportStatusFromJSON(json['exportStatus']),
    };
}

export function ExportUrlDtoToJSON(value?: ExportUrlDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'url': value.url,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'teamMemberId': value.teamMemberId,
        'exportVehicles': value.exportVehicles === undefined ? undefined : (value.exportVehicles === null ? null : (value.exportVehicles as Array<any>).map(ExportVehicleDtoToJSON)),
        'exportStatus': ExportStatusToJSON(value.exportStatus),
    };
}


