import { Button, Grid, makeStyles, MenuItem } from "@material-ui/core";
import { ColDef, RowParams, RowsProp, SortItem, ValueFormatterParams } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useApi from "../../../../hooks/api";
import { useWindowSize } from "../../../../hooks/windowSize";
import { useAdminTeam } from "../../../../hooksAdmin/team";
import { TeamDto, TeamSort } from "../../../../lib-api";
import { CustomDataGrid } from "../../../shared/datagrid/CustomDataGrid";
import { CustomInlineSelect } from "../../../shared/forms/CustomInlineTextField";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  },
  form: theme.form,
  link: theme.link
}));

const AdminTeamsPage: React.FC<{
}> = () => {

  var [page, setPage] = useState<number>(1);
  var [pageSize] = useState<number>(20);
  var [searchQuery, setSearchQuery] = useState<string>("");
  var [packageName, setPackageName] = useState<string>("");
  var [sortMode, setSortMode] = useState<TeamSort>(TeamSort.Default);
  var { adminUserApi } = useApi();
  const history = useHistory();
  const { xsDown } = useWindowSize();
  var { totalTeams, results, searchState, packages } = useAdminTeam(page, pageSize, sortMode, searchQuery, packageName);

  const [rows, setRows] = useState<RowsProp>([]);
  const [currentPackages, setCurrentPackages] = useState<Array<{ id: string, value: string }>>([]);

  const classes = useStyles();

  useEffect(() => {
    setCurrentPackages(results.map((team) => { return { id: team?.id!, value: team?._package?.name! } }));
    setRows(results.length !== 0 ? (
      results.map((team, index) => {
        return {
          id: index,
          first: team.id,
          name: team.name,
          owner: team.owner?.screenName,
          ownerEmail: team.owner?.email,
          package: team
        }
      })) : []);
  }, [results]);

  const handleSortModelChange = (model: SortItem) => {
    if (model == null || model == undefined) {
      setSortMode(TeamSort.Default);
      return;
    }
    if (model.field == "name" && model.sort == "asc") {
      setSortMode(TeamSort.Name);
      return;
    }
    if (model.field == "name" && model.sort == "desc") {
      setSortMode(TeamSort.NameDesc);
      return;
    }
  }

  const updatePackageForTeam = async (event: any, params: ValueFormatterParams) => {
    if (event.target.value == 0) {
      return;
    }
    var temp = currentPackages.filter(p => p.id != (params.value as TeamDto)?.id);
    temp.push({ value: event.target.value, id: (params.value as TeamDto)?.id! });
    setCurrentPackages(temp);

    await adminUserApi.apiAdminUserUpdatepackagePut({
      adminUpdatePackageRequest: {
        packageName: event.target.value,
        teamId: (params.value as TeamDto).id
      }
    });
  }

  const columns: ColDef[] = [
    { field: 'name', headerName: 'NAME', flex: 2 },
    { field: 'owner', headerName: 'OWNER', flex: 2, sortable: false, hide: xsDown },
    { field: 'ownerEmail', headerName: 'OWNER EMAIL', flex: 2, sortable: false, hide: xsDown },
    {
      field: 'package', headerName: 'PACKAGE', flex: 2, renderCell: (params: ValueFormatterParams) => (<>
        <CustomInlineSelect
          color="primary"
          select
          value={currentPackages.find(p => p.id == (params.value as TeamDto)?.id)?.value == undefined ? "" : currentPackages.find(p => p.id == (params.value as TeamDto)?.id)?.value!}
          onClick={(event) => {
            updatePackageForTeam(event, params);
          }}>
          {packages?.map((packageItem, index) => (
            <MenuItem key={index} value={packageItem.name!}>{packageItem.name}</MenuItem>
          ))}
        </CustomInlineSelect>
      </>), sortable: false, disableClickEventBubbling: true
    },
  ];

  return (
    <CustomDataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      page={page}
      setPage={(page: number) => { setPage(page) }}
      loadingState={searchState}
      totalRows={totalTeams}
      handleSortModelChange={(params: any) => handleSortModelChange(params.sortModel[0])}
      dataDisplayText="teams"
      onRowClick={(row: RowParams) => history.push(`/admin/teams/${row.row["first"]}`)}
      headerProps={{
        filters: [{
          name: "All packages",
          onChange: (e) => setPackageName(e.target.value),
          type: "select",
          initVal: "",
          position: "left",
          selectOptions: packages?.map((pack) => { return { name: pack.name!, value: pack.name! } })
        },
        {
          name: "Add team",
          onClick: (e) => history.push("/admin/createteam"),
          type: "button",
          position: "right",
          loadingText: "Add team",
          isLoading: false
        },
        {
          name: "Search",
          onChange: (e) => setSearchQuery(e.target.value),
          type: "search",
          position: "right",
        }],
        isTab: true
      }}
    ></CustomDataGrid>
  );
};

export default AdminTeamsPage;
