import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import ResetPassword from "./ResetPassword";

const SystemPage = () => {
  const history = useHistory();
  
  const getPage = () => {
    switch(history.location.pathname){
      case "/resetpassword":
        return <ResetPassword></ResetPassword>
      default:
        return <></>
    }
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography align="center" variant="h4">System</Typography>
      </Grid>
      <Grid item sm={4} xs={12}></Grid>
      <Grid item sm={4} xs={12}>
        {getPage()}
      </Grid>
    </Grid>
  );
};

export default SystemPage;
