/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthInfo,
    AuthInfoFromJSON,
    AuthInfoToJSON,
    PasswordSignInRequest,
    PasswordSignInRequestFromJSON,
    PasswordSignInRequestToJSON,
    RefreshRequest,
    RefreshRequestFromJSON,
    RefreshRequestToJSON,
    ResetPasswordRequest,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
} from '../models';

export interface ApiAuthPasswordsigninPostRequest {
    passwordSignInRequest: PasswordSignInRequest;
}

export interface ApiAuthRefreshPostRequest {
    refreshRequest: RefreshRequest;
}

export interface ApiAuthResetpasswordPostRequest {
    resetPasswordRequest?: ResetPasswordRequest;
}

export interface ApiAuthResettokenPostRequest {
    email?: string | null;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async apiAuthPasswordsigninPostRaw(requestParameters: ApiAuthPasswordsigninPostRequest): Promise<runtime.ApiResponse<AuthInfo>> {
        if (requestParameters.passwordSignInRequest === null || requestParameters.passwordSignInRequest === undefined) {
            throw new runtime.RequiredError('passwordSignInRequest','Required parameter requestParameters.passwordSignInRequest was null or undefined when calling apiAuthPasswordsigninPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Auth/passwordsignin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordSignInRequestToJSON(requestParameters.passwordSignInRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthInfoFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthPasswordsigninPost(requestParameters: ApiAuthPasswordsigninPostRequest): Promise<AuthInfo> {
        const response = await this.apiAuthPasswordsigninPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAuthRefreshPostRaw(requestParameters: ApiAuthRefreshPostRequest): Promise<runtime.ApiResponse<AuthInfo>> {
        if (requestParameters.refreshRequest === null || requestParameters.refreshRequest === undefined) {
            throw new runtime.RequiredError('refreshRequest','Required parameter requestParameters.refreshRequest was null or undefined when calling apiAuthRefreshPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshRequestToJSON(requestParameters.refreshRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthInfoFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthRefreshPost(requestParameters: ApiAuthRefreshPostRequest): Promise<AuthInfo> {
        const response = await this.apiAuthRefreshPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAuthResetpasswordPostRaw(requestParameters: ApiAuthResetpasswordPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Auth/resetpassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAuthResetpasswordPost(requestParameters: ApiAuthResetpasswordPostRequest): Promise<string> {
        const response = await this.apiAuthResetpasswordPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAuthResettokenPostRaw(requestParameters: ApiAuthResettokenPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Auth/resettoken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAuthResettokenPost(requestParameters: ApiAuthResettokenPostRequest): Promise<string> {
        const response = await this.apiAuthResettokenPostRaw(requestParameters);
        return await response.value();
    }

}
