import { Button, Grid, Hidden, makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import React, { useState } from "react";
import useApi from "../../../hooks/api";
import VehicleIdSearchForm from "../../shared/forms/search/VehicleIdSearchForm";
import { TransferList } from "../../shared/forms/TransferList";
import * as XLSX from 'xlsx';
import { useSnackbar } from "notistack";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import {trackGaEvent} from '../../../util/gtm';
import useAuth from "../../../hooks/auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0)
  },
  multipleButton: {
    marginTop: "16px",
    padding: "8px 11px !important"
  }
}));

export const CreateReport: React.FC<{
}> = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const { reportApi } = useApi();
  const classes = useStyles();
  const {user} = useAuth();

  const [vinsFromFile, setVinsFromFile] = useState<string[]>([]);

  const createOrder = async (vin: string, projectId?: string) => {
    try {
      const order = await reportApi.apiReportAddPost({ createOrderRequest: { vin: vin, projectId: projectId } });
      enqueueSnackbar("Order created!", {variant: "success"});
      trackGaEvent("Generate report", [{name: "VIN", value: vin}, {name: "Team_name", value: user?.teamName}, {name: "User", value: user?.screenName}]);
    } catch {
    }
  }

  const createMulitpleOrders = async (vins: string[], projectId?: string) => {
    try {
      await reportApi.apiReportAddMorePost({ createMoreOrdersRequest: { vins: vins, projectId: projectId } });
      enqueueSnackbar("Orders created!", {variant: "success"});
      setVinsFromFile([]);
    } catch {
    }
  }

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { skipHidden: true, FS: ";" });
      processData(data);
    };
    reader.readAsBinaryString(file);
  }

  const processData = (dataString: string) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    var amountOfRows = Math.min(dataStringLines.length, 101);
    const list: string[] = [];
    for (let i = 1; i < amountOfRows; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        for (let j = 0; j < headers.length; j++) {
          let d = row[j].split(";")[0]
          if(d != ""){
            list.push(d);
          }
        }
      }
    }
    setVinsFromFile(list);
  }

  return (
    <Paper className={classes.paper}>
      <Grid container alignItems="center">
      <Grid item xs={12} sm={9}>
        <VehicleIdSearchForm showIdField showProjects submitText="Generate report" label="Generate a new report" placeholderText="Search for a new VIN number" onSubmit={createOrder}></VehicleIdSearchForm>
      </Grid>
      <Hidden smDown>
      <Grid item sm={3}>
        <input accept=".csv,.txt,.xlsx,.xls" onChange={(data) => { handleUpload(data) }} id="inputRef" type="file" hidden />
        <Grid container justify="flex-end" alignItems="center">
          <Grid item>
            <Tooltip placement="right-start" title={
              <>
              <Typography variant="body1">Upload .csv or .txt file, to choose which to order reports for. Max 100 VINs per upload.</Typography>
              <Typography variant="body1">See example below.</Typography>
              <img width="100%" src="/csv_example.png"/>
              </>
            }>
              <Button className={classes.multipleButton} color="secondary" size="large" onClick={() => { document.getElementById("inputRef")?.click(); }}><PlaylistAddIcon></PlaylistAddIcon> Upload multiple vins</Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      </Hidden>
      <Grid item xs={12}>
      {vinsFromFile.length != 0 ?
          <TransferList onSubmit={createMulitpleOrders} initialData={vinsFromFile}></TransferList>
          : null}
      </Grid>
    </Grid>
    </Paper>
  );
};
