/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VehicleField,
    VehicleFieldFromJSON,
    VehicleFieldFromJSONTyped,
    VehicleFieldToJSON,
} from './';

/**
 * 
 * @export
 * @interface FieldSimiliarAmount
 */
export interface FieldSimiliarAmount {
    /**
     * 
     * @type {string}
     * @memberof FieldSimiliarAmount
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldSimiliarAmount
     */
    vehicleReportId?: string;
    /**
     * 
     * @type {VehicleField}
     * @memberof FieldSimiliarAmount
     */
    field?: VehicleField;
    /**
     * 
     * @type {number}
     * @memberof FieldSimiliarAmount
     */
    amount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FieldSimiliarAmount
     */
    name?: string | null;
}

export function FieldSimiliarAmountFromJSON(json: any): FieldSimiliarAmount {
    return FieldSimiliarAmountFromJSONTyped(json, false);
}

export function FieldSimiliarAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldSimiliarAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vehicleReportId': !exists(json, 'vehicleReportId') ? undefined : json['vehicleReportId'],
        'field': !exists(json, 'field') ? undefined : VehicleFieldFromJSON(json['field']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function FieldSimiliarAmountToJSON(value?: FieldSimiliarAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vehicleReportId': value.vehicleReportId,
        'field': VehicleFieldToJSON(value.field),
        'amount': value.amount,
        'name': value.name,
    };
}


