/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleEstimatedValuationReportData
 */
export interface VehicleEstimatedValuationReportData {
    /**
     * 
     * @type {string}
     * @memberof VehicleEstimatedValuationReportData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleEstimatedValuationReportData
     */
    vehicleReportId?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleEstimatedValuationReportData
     */
    low?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleEstimatedValuationReportData
     */
    high?: number;
    /**
     * 
     * @type {Date}
     * @memberof VehicleEstimatedValuationReportData
     */
    date?: Date;
}

export function VehicleEstimatedValuationReportDataFromJSON(json: any): VehicleEstimatedValuationReportData {
    return VehicleEstimatedValuationReportDataFromJSONTyped(json, false);
}

export function VehicleEstimatedValuationReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleEstimatedValuationReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vehicleReportId': !exists(json, 'vehicleReportId') ? undefined : json['vehicleReportId'],
        'low': !exists(json, 'low') ? undefined : json['low'],
        'high': !exists(json, 'high') ? undefined : json['high'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
    };
}

export function VehicleEstimatedValuationReportDataToJSON(value?: VehicleEstimatedValuationReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vehicleReportId': value.vehicleReportId,
        'low': value.low,
        'high': value.high,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
    };
}


