/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TjekBilInspection,
    TjekBilInspectionFromJSON,
    TjekBilInspectionFromJSONTyped,
    TjekBilInspectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface TjekBilInspectionStation
 */
export interface TjekBilInspectionStation {
    /**
     * 
     * @type {number}
     * @memberof TjekBilInspectionStation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspectionStation
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspectionStation
     */
    streetName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspectionStation
     */
    streetNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilInspectionStation
     */
    zipCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspectionStation
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspectionStation
     */
    country?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilInspectionStation
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilInspectionStation
     */
    longitude?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspectionStation
     */
    cvr?: string | null;
    /**
     * 
     * @type {Array<TjekBilInspection>}
     * @memberof TjekBilInspectionStation
     */
    tjekBilInspections?: Array<TjekBilInspection> | null;
}

export function TjekBilInspectionStationFromJSON(json: any): TjekBilInspectionStation {
    return TjekBilInspectionStationFromJSONTyped(json, false);
}

export function TjekBilInspectionStationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TjekBilInspectionStation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'cvr': !exists(json, 'cvr') ? undefined : json['cvr'],
        'tjekBilInspections': !exists(json, 'tjekBilInspections') ? undefined : (json['tjekBilInspections'] === null ? null : (json['tjekBilInspections'] as Array<any>).map(TjekBilInspectionFromJSON)),
    };
}

export function TjekBilInspectionStationToJSON(value?: TjekBilInspectionStation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
        'zipCode': value.zipCode,
        'city': value.city,
        'country': value.country,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'cvr': value.cvr,
        'tjekBilInspections': value.tjekBilInspections === undefined ? undefined : (value.tjekBilInspections === null ? null : (value.tjekBilInspections as Array<any>).map(TjekBilInspectionToJSON)),
    };
}


