import { Button, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import FilterListIcon from '@material-ui/icons/FilterList';
import LoopIcon from '@material-ui/icons/Loop';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    border: "1px solid #c5ced6",
    padding: "8px!important",
    backgroundColor: "white",
    color: "black"
  },
  filterButtonToggled: {
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "8px!important",
    minWidth: "0px",
    backgroundColor: "white",
    color: theme.palette.secondary.main
  },
  filterButton: {
    border: "1px solid #c5ced6",
    padding: "8px!important",
    minWidth: "0px",
    backgroundColor: "white",
    color: "black"
  }
}));

export const PdfIcon: React.FunctionComponent<{
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hoverText: string;
}> = (props) => {
  const classes = useStyles();
  return (<>
    <Tooltip title={props.hoverText}>
      <Button color="primary" className={classes.iconButton} onClick={(event) => props.onClick(event)} >
        <PictureAsPdfRoundedIcon>
        </PictureAsPdfRoundedIcon>
      Download pdf
    </Button>
    </Tooltip>
  </>);
};

export const DownloadIcon: React.FunctionComponent<{
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hoverText: string;
}> = (props) => {
  const classes = useStyles();
  return (<>
    <Tooltip title={props.hoverText}>
      <Button color="primary" className={classes.iconButton} onClick={(event) => props.onClick(event)} >
        <GetAppIcon>
        </GetAppIcon>
      Export VINS
      </Button>
    </Tooltip></>);
};

export const FilterIcon: React.FunctionComponent<{
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hoverText: string;
  hoverTextToggled: string;
  toggled: boolean;
}> = (props) => {
  const classes = useStyles();
  return (<>
    <Tooltip title={props.toggled ? props.hoverTextToggled : props.hoverText}>
      <Button color="primary" className={props.toggled ? classes.filterButtonToggled : classes.filterButton} onClick={(event) => props.onClick(event)} >
        <FilterListIcon>
        </FilterListIcon>
      </Button>
    </Tooltip></>);
};

export const DeletionIcon: React.FunctionComponent<{
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  hoverText: string;
}> = (props) => {
  return (<><Tooltip title={props.hoverText}><DeleteIcon color="secondary" onClick={(event) => props.onClick != undefined ? props.onClick(event) : null}></DeleteIcon></Tooltip></>);
};

export const CreateIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<><Tooltip title={props.hoverText}><AddIcon color="secondary"></AddIcon></Tooltip></>);
};

export const GenerateIcon: React.FunctionComponent<{
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  hoverText: string;
}> = (props) => {
  return (<><Tooltip title={props.hoverText}><LoopIcon color="secondary" onClick={(event) => props.onClick(event)}></LoopIcon></Tooltip></>);
};

export const ResetIcon: React.FunctionComponent<{
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  hoverText: string;
}> = (props) => {
  return (<><Tooltip title={props.hoverText}><RotateLeftIcon color="secondary" onClick={(event) => props.onClick(event)}></RotateLeftIcon></Tooltip></>);
};

export const PensilIcon: React.FunctionComponent<{
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  hoverText: string;
  className?: string;
}> = (props) => {
  return (<><Tooltip title={props.hoverText}><EditIcon className={props.className} fontSize="small" color="inherit" onClick={(event) => props.onClick(event)}></EditIcon></Tooltip></>);
};

export const CompleteIcon: React.FunctionComponent<{
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  hoverText: string;
  className?: string;
}> = (props) => {
  return (<><Tooltip title={props.hoverText}><DoneIcon className={props.className} fontSize="small" color="inherit" onClick={(event) => props.onClick(event)}></DoneIcon></Tooltip></>);
};

export const ToolTipIcon: React.FunctionComponent<{
  hoverText: string;
  className?: string;
}> = (props) => {
  return (<><Tooltip title={props.hoverText}><InfoIcon className={props.className} fontSize="small" color="secondary"></InfoIcon></Tooltip></>);
};
