import { useState, useEffect } from "react";

export function useQueryDelay(query: string) {
  const [timer, setTimer] = useState(0);
  const [id, setId] = useState<NodeJS.Timeout | null | "init">(null);

  useEffect(() => {
    if(query == undefined || query == null){
      return;
    }
    if(id != null && id != "init"){
      clearTimeout(id);
    }else if(id != "init"){
      setId("init");
      return;
    }
    var timeoutId = setTimeout(() => {
      setTimer(timer + 1);
    }, 500); //antal milisekunder før timer sættes
    setId(timeoutId);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [query]);

  return {
    delay: timer,
  };
}
