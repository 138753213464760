import { Button, Grid, IconButton, makeStyles, MenuItem, Paper, Typography } from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { Fragment } from "react";
import { useHistory, useParams } from "react-router";
import { array, object } from "yup";
import useApi from "../../../hooks/api";
import { useProjects } from "../../../hooks/projects";
import { useReports } from "../../../hooks/reports";
import { ProjectSort, ReportSort } from "../../../lib-api";
import { SelectField } from "../../shared/forms/SelectField";
import { Breadcrumb } from "../../shared/other/Breadcrumb";
import { CreateIcon, DeletionIcon } from "../../shared/other/Icons";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  },
  form: theme.form
}));

const ConnectOrdersPage: React.FC<{
}> = (props) => {
  var params = useParams<{ projectId: string }>();
  var { reportApi } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  var { results } = useReports(1, 100, ReportSort.Default, "", false, true, "");
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item sm={3}></Grid>
      <Grid item sm={9}>
        <Breadcrumb currentPath="Connect to project"></Breadcrumb>
      </Grid>
      <Grid item sm={3}></Grid>
      <Grid item sm={6}>
        <Paper>
          <Typography align="center" variant="h4">Connect orders to project</Typography>
          <Formik
            enableReinitialize={true}
            initialValues={{
              orderIds: [""]
            } as { orderIds: string[] }}
            validationSchema={object({
              orderIds: array<string>().required("Atleast one order must be picked")
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                await reportApi.apiReportAddToFolderPost({
                  connectOrdersToProjectRequest: {
                    projectId: params.projectId,
                    orderIds: values.orderIds.filter(p => p != "")
                  }
                });
                setSubmitting(false);
                history.push(`/myaccount`);
              } catch (error) {
                resetForm();
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, values, setValues }) => (
              <Form className={classes.form}>
                <Grid container alignItems="center">
                  <FieldArray
                    name="orderIds"
                    render={arrayHelpers => values.orderIds && values.orderIds.length > 0 ? (
                      values.orderIds.map((orderId, index) => (
                        <Fragment key={index}>
                          {index == values.orderIds.length - 1 ? <Grid item xs={1}>
                            <IconButton onClick={() => { arrayHelpers.push("")}}>
                              <CreateIcon hoverText="Add order"></CreateIcon>
                            </IconButton>
                          </Grid> :
                            <Grid item xs={1}>
                              <IconButton onClick={() => { arrayHelpers.remove(index)}}>
                                <DeletionIcon hoverText="Remove order"></DeletionIcon>
                              </IconButton>
                            </Grid>}
                          <Grid item xs={11}>
                            <SelectField
                              name={`orderIds[${index}]`}
                              placeholder="VIN"
                            >
                              {results.map((order, index) => (
                                <MenuItem key={index} value={order.id}>{`${order.vin}, ${order.make} ${order.model}`}</MenuItem>
                              ))}
                            </SelectField>
                          </Grid>
                        </Fragment>
                      ))
                    ) : (
                        <Grid item xs={1}>
                          <IconButton onClick={() => { arrayHelpers.push("") }}>
                            <CreateIcon hoverText="Add order"></CreateIcon>
                          </IconButton>
                        </Grid>
                      )}
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.end}>
                      <Button color="primary" type="submit">
                        {isSubmitting ? "Adding to project..." : "Add to project"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ConnectOrdersPage;
