import React, { useState } from "react";

interface DialogProviderRenderProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface Props {
  render: (props: DialogProviderRenderProps) => React.ReactNode;
}

const DialogHelper: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {props.render({
        open,
        setOpen,
      })}
    </>
  );
};

export default DialogHelper;
