import React from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Hidden,
  MenuItem,
  TextField
} from "@material-ui/core";
import HeaderCollapsed from "./HeaderCollapsed";
import Header from "./Header";
import useAuth from "../../hooks/auth";
import {useWindowSize} from "../../hooks/windowSize";
import { Link } from "react-router-dom";
import FaceIcon from '@material-ui/icons/Face';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    color: "#FFF",
    textDecoration: "none",
  },
  container: {
    padding: theme.spacing(3)
  },
  containerMobile: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  faceIcon: {
    marginLeft: theme.spacing(1),
  },
  link: theme.link,
  mypageLink: {
    paddingLeft: theme.spacing(2),
    borderLeft: "black 1px solid",
    color: "white",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  chooseTeam: {
    paddingRight: theme.spacing(2),
    "& .MuiInput-underline": {
      color: "white",
      "&:before": {
        border: "none!important"
      },
      "&:after": {
        border: "none!important"
      },
      "& .MuiSelect-icon": {
        color: "white!important"
      }
    }
  }
}));

const Layout: React.FC = (props) => {
  const classes = useStyles();
  const { isLoggedIn, user, setCurrentTeam } = useAuth();
  const {xsDown} = useWindowSize();
  
  return (
    <>
      <AppBar position="sticky">
        <Container>
          {isLoggedIn() ? (
            <>
              <Toolbar disableGutters>
                <Link to="/reports">
                  <img height="43px" src="/vindicate-logo-negative.png"></img>
                </Link>
                <Hidden smDown>
                  <Header></Header>
                </Hidden>
                <div className={classes.root}></div>
                <Hidden smDown>
                <TextField
                  className={classes.chooseTeam}
                  select
                  value={user?.teamId}
                  onChange={(event) => setCurrentTeam(event.target.value)}>
                  <MenuItem disabled value="">Choose team</MenuItem>
                  {user?.teams?.map((team, index) =>
                    <MenuItem key={index} value={team.id}>{team.name}</MenuItem>
                  )}
                </TextField>
                </Hidden>
                <Link className={classes.link + " " + classes.mypageLink} to="/myaccount">
                  <Typography variant="button" color="textSecondary">
                    {user?.screenName}
                  </Typography>
                </Link>
                <Hidden smDown>
                  <FaceIcon className={classes.faceIcon}></FaceIcon>
                </Hidden>
                <Hidden mdUp>
                  <HeaderCollapsed></HeaderCollapsed>
                </Hidden>
              </Toolbar>
            </>
          ) : <></>}
        </Container>
      </AppBar>
      {isLoggedIn() ? (
        <Container className={xsDown ? classes.containerMobile : classes.container}>
          <>
            {props.children}
          </>
        </Container>) : <>{props.children}</>}
    </>
  );
};

export default Layout;
