import React, { useEffect, useMemo, useState } from "react";
import { ChartOptions } from "chart.js";
import { LoadingState } from "../../../../hooks/loading";
import { ElementLoading } from "../../../shared/other/Loading";
import { ChartData, Line } from "react-chartjs-2";
import { Skeleton } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core";
import { useDimensions } from "../../../../hooks/dimensions";
import useAuth from "../../../../hooks/auth";
import { VehicleValuationReportData, VehicleValuationTaxReportData } from "../../../../lib-api";
import { useChartOptions } from "../../../../hooks/chartOptions";
import { VehicleEstimatedValuationReportData } from "../../../../lib-api/models/VehicleEstimatedValuationReportData";
import { useWindowSize } from "../../../../hooks/windowSize";

const useStyles = makeStyles((theme) => ({
  skeleton: (props: {width: number}) => ({
    width: props.width,
    height: props.width / 2
  }),
  pdfIcon: theme.icon
}));

export const ValuationHistory: React.FC<{
  loadingState: LoadingState;
  dataWithoutTax?: Array<VehicleValuationReportData> | null;
  dataWithTax?: Array<VehicleValuationTaxReportData> | null;
  estimatedData?: Array<VehicleEstimatedValuationReportData> | null;
  initialLoading: boolean;
}> = (props) => {

  const { width } = useDimensions("valuationhistory-width");
  
  const classes = useStyles({ width: width as number });
  
  const theme = useTheme();
  const {xsDown} = useWindowSize();

  useEffect(() => {
    if (props.dataWithoutTax != null && props.dataWithoutTax != undefined) {
      createChart();
    }
  }, [props.dataWithoutTax]);

  const chartRef = useMemo(() => {
    return React.createRef<Line>();
  }, []);

  const [chartData, setChartData] = useState<ChartData<Chart.ChartData>>({});
  const [options] = useChartOptions<Line>(chartRef, true, true, !xsDown);

  const createChart = () => {
    var chartData = props.dataWithoutTax?.sort((p, p1) => (p.date?.getTime() as number) - (p1.date?.getTime() as number));
    var chartDataWithTax = props.dataWithTax?.sort((p, p1) => (p.date?.getTime() as number) - (p1.date?.getTime() as number));
    var estimatedData = props.estimatedData?.sort((p, p1) => (p.date?.getTime() as number) - (p1.date?.getTime() as number));
    
    var data = {
      datasets: [{
        label: 'Value without tax',
        data: chartData?.map(p => {return {"x": p.date?.toDateString(), "y": p.vehicleValue}}),
        borderColor: theme.primaryChart.background,
      },
      {
        label: 'Value with tax',
        data: chartDataWithTax?.map(p => {return {"x": p.date?.toDateString(), "y": p.vehicleWithTaxValue}}),
        borderColor: theme.tertiaryChart.background,
      },
      {
        label: 'Estimated value',
        data: estimatedData?.map(p => {return {"x": p.date?.toDateString(), "y": (p.high! + p.low! / 2)}}),
        borderColor: theme.secondaryChart.background,
      }]
    };
    setChartData(data);
  }

  return (<div id="valuationhistory-width" style={{ position: "relative" }}> 
  {props.initialLoading ? <Skeleton variant="rect" className={classes.skeleton}></Skeleton>
    :
    <>
      {props.loadingState == LoadingState.Loading ?
        <ElementLoading position="absolute"></ElementLoading> : null}
      <Line ref={chartRef} data={chartData} options={options}></Line>
    </>} </div>)
}
