/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InspectionChanges,
    InspectionChangesFromJSON,
    InspectionChangesFromJSONTyped,
    InspectionChangesToJSON,
    InsuranceChanges,
    InsuranceChangesFromJSON,
    InsuranceChangesFromJSONTyped,
    InsuranceChangesToJSON,
    OwnerChanges,
    OwnerChangesFromJSON,
    OwnerChangesFromJSONTyped,
    OwnerChangesToJSON,
    TaxChanges,
    TaxChangesFromJSON,
    TaxChangesFromJSONTyped,
    TaxChangesToJSON,
} from './';

/**
 * 
 * @export
 * @interface VINCheckerChangesDto
 */
export interface VINCheckerChangesDto {
    /**
     * 
     * @type {Array<InspectionChanges>}
     * @memberof VINCheckerChangesDto
     */
    inspectionChanges: Array<InspectionChanges>;
    /**
     * 
     * @type {Array<OwnerChanges>}
     * @memberof VINCheckerChangesDto
     */
    ownerChanges: Array<OwnerChanges>;
    /**
     * 
     * @type {Array<TaxChanges>}
     * @memberof VINCheckerChangesDto
     */
    taxChanges: Array<TaxChanges>;
    /**
     * 
     * @type {Array<InsuranceChanges>}
     * @memberof VINCheckerChangesDto
     */
    insuranceChanges: Array<InsuranceChanges>;
}

export function VINCheckerChangesDtoFromJSON(json: any): VINCheckerChangesDto {
    return VINCheckerChangesDtoFromJSONTyped(json, false);
}

export function VINCheckerChangesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VINCheckerChangesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionChanges': ((json['inspectionChanges'] as Array<any>).map(InspectionChangesFromJSON)),
        'ownerChanges': ((json['ownerChanges'] as Array<any>).map(OwnerChangesFromJSON)),
        'taxChanges': ((json['taxChanges'] as Array<any>).map(TaxChangesFromJSON)),
        'insuranceChanges': ((json['insuranceChanges'] as Array<any>).map(InsuranceChangesFromJSON)),
    };
}

export function VINCheckerChangesDtoToJSON(value?: VINCheckerChangesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionChanges': ((value.inspectionChanges as Array<any>).map(InspectionChangesToJSON)),
        'ownerChanges': ((value.ownerChanges as Array<any>).map(OwnerChangesToJSON)),
        'taxChanges': ((value.taxChanges as Array<any>).map(TaxChangesToJSON)),
        'insuranceChanges': ((value.insuranceChanges as Array<any>).map(InsuranceChangesToJSON)),
    };
}


