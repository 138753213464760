import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { ColDef, DataGrid, RowsProp, SortItem, ValueFormatterParams } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import useApi from "../../../hooks/api";
import useAuth from "../../../hooks/auth";
import { useProjects } from "../../../hooks/projects";
import { DeletionIcon } from "../../shared/other/Icons";
import { CustomDataGrid } from "../../shared/datagrid/CustomDataGrid";
import { DataGridHeaderFilter } from "../../shared/datagrid/CustomDataGridHeader";
import { ProjectFolderDto, ProjectSort } from "../../../lib-api";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../../hooks/windowSize";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.secondary.main
  }
}));

export const Projects: React.FunctionComponent<{

}> = (props) => {
  var { isTeamOwner } = useAuth();
  var [page, setPage] = useState<number>(1);
  var [searchQuery, setSearchQuery] = useState<string>("");
  var [pageSize] = useState<number>(20);
  var [sortMode, setSortMode] = useState<ProjectSort>(ProjectSort.Default);
  var { reportApi } = useApi();
  var { totalProjects, projects, searchState, reload } = useProjects(page, pageSize, sortMode, searchQuery);

  const classes = useStyles();
  const {xsDown} = useWindowSize();
  const [rows, setRows] = useState<RowsProp>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setRows(projects.length !== 0 ? (
      projects.map((project, index) => {
        return {
          id: index,
          first: project.id,
          link: project.id,
          name: project.name,
          reports: project,
          vins: project
        }
      })) : []);
  }, [projects]);

  const addProject = async (name: string) => {
    try {
      await reportApi.apiReportCreateProjectFolderPost({ name: name });
      enqueueSnackbar("Project created", { variant: "success" });
      reload();
    } catch {

    }
  }

  const getFilters = () => {
    var filters: DataGridHeaderFilter[] = [];

    filters.push({
      name: "+ Add project",
      placeholder: "Project name",
      onSubmitVehicleId: addProject,
      type: "submit",
      position: "right",
    });
    filters.push({ type: "search", onChange: (e) => setSearchQuery(e.target.value), position: "right", name: "Search" });
    return filters;
  }

  const handleSortModelChange = (model: SortItem) => {
    if (model == null || model == undefined) {
      setSortMode(ProjectSort.Default);
      return;
    }
    if (model.field == "name" && model.sort == "asc") {
      setSortMode(ProjectSort.Name);
      return;
    }
    if (model.field == "name" && model.sort == "desc") {
      setSortMode(ProjectSort.NameDesc);
      return;
    }
  }

  const deleteProject = async (folderId: string) => {
    try {
      if (isTeamOwner()) {
        await reportApi.apiReportRemoveProjectFolderPost({ folderId: folderId });
        enqueueSnackbar("Project has been removed!", { variant: "success" });
        reload();
      } else {
        enqueueSnackbar("Only the team owner can delete projects.", { variant: "error" });
      }
    } catch {
    }
  }

  const columns: ColDef[] = [
    { field: 'name', headerName: 'NAME', flex: 4 },
    { field: 'vins', headerName: 'VEHICLES', flex: 2, sortable: false, hide: xsDown,
      renderCell: (params: ValueFormatterParams) => (<><Link className={classes.link} to={`/myaccount/allvehicles/project-${(params.value as ProjectFolderDto).id}`}>See all ({(params.value as ProjectFolderDto).projectPortfolioVinsCount})</Link>
      </>)
    },
    { field: 'reports', headerName: 'REPORTS', flex: 2, sortable: false, hide: xsDown,
      renderCell: (params: ValueFormatterParams) => (<><Link className={classes.link} to={`/reports/project-${(params.value as ProjectFolderDto).id}`}>See all ({(params.value as ProjectFolderDto).projectItemsCount})</Link>
      </>)
    },
    {
      field: 'link', headerName: ' ', flex: 4, disableClickEventBubbling: true, sortable: false,
      renderCell: (params: ValueFormatterParams) => (<Link className={classes.link} to={`/myaccount/projects/${params.value}/connecttoorders`}>+ Add reports to project</Link>)
    },
    {
      field: 'first', headerName: " ", disableClickEventBubbling: true, flex: 1,
      renderCell: (params: ValueFormatterParams) => (<DeletionIcon onClick={() => deleteProject(params.value as string)} hoverText="Delete project (no reports will be deleted)"></DeletionIcon>),
      sortable: false
    }
  ];

  return (
    <>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        page={page}
        setPage={(pageNumber: number) => { setPage(pageNumber) }}
        loadingState={searchState}
        totalRows={totalProjects}
        handleSortModelChange={(params: any) => handleSortModelChange(params.sortModel[0])}
        dataDisplayText="projects"
        headerProps={{
          displayHeadline: "PROJECTS", filters: getFilters(), isTab: true
        }}
      ></CustomDataGrid>
    </>
  );
};
