import React, { useState } from "react";
import { CheckboxField } from "../../../shared/forms/CheckboxField";
import { DateField } from "../../../shared/forms/DateField";
import { ModelField } from "../../../shared/forms/ModelField";
import { MakeField } from "../../../shared/forms/MakeField";
import {
  PopulationFields,
  SearchForm as FraudSearchForm,
} from "../../../../lib-api/models";
import { InputField } from "../../../shared/forms/InputField";
import { SelectField } from "../../../shared/forms/SelectField";
import { Form, useFormikContext } from "formik";
import { Accordion, AccordionDetails, AccordionSummary, Button, createStyles, Grid, Hidden, makeStyles, MenuItem, Theme, Typography, withStyles } from "@material-ui/core";
import { useParams } from "react-router";
import VehicleIdSearchForm from "../../../shared/forms/search/VehicleIdSearchForm";
import { useWindowSize } from "../../../../hooks/windowSize";

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center"
  },
  end: {
    textAlign: "end"
  },
  form: theme.form,
  closeText: {
    marginLeft: "10px",
    cursor: "pointer"
  }
}));

const CombinedField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& .MuiFormControl-root": {
        flexGrow: 1,
        marginBottom: "8px"
      },
      "& span": {
        width: "16px",
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
      }
    }
  }),
)(Grid);

export const SearchForm: React.FC<{
  populationValues: PopulationFields;
  onPreFillField: (id: string) => void;
}> = (props) => {
  const { values, handleSubmit } = useFormikContext<
    FraudSearchForm & { searchAgentName: string }
  >();
  const classes = useStyles();
  const params = useParams<{ agentId: string; vin: string }>();
  const agentId = params.agentId ? Number.parseInt(params.agentId) : undefined;
  const [showSearchForm, setShowSearchForm] = useState(params.vin == undefined);
  const {xsDown} = useWindowSize();

  const changeSearchForm = (state: boolean) => {
    setShowSearchForm(state);
  }

  return (
    <>
      <Form className={classes.form}>
        <Accordion expanded={showSearchForm}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={11}>
                <Grid container spacing={2} alignItems="baseline">
                  <Grid item xs={12}>
                    <Typography variant="h5">AUTOMATICALLY SETUP FILTER</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <VehicleIdSearchForm showIdField showProjects={false} onSubmit={async (id: string) => {
                      await props.onPreFillField(id);
                      handleSubmit();
                      changeSearchForm(true);
                    }} label="Vin or reg. number" placeholderText="Enter a VIN or registration number to setup filter" submitText="Fetch data and search"></VehicleIdSearchForm>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {!showSearchForm ? <Typography style={{ cursor: "pointer" }} onClick={() => { changeSearchForm(true) }} color="secondary">Manually create filter</Typography> : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={11}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">OR FILL OUT MANUALLY</Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <InputField
                      name="regNr"
                      type="text"
                      placeholder="Reg nr."
                      tooltip="End with an asterix (*) to match all vehicles that start with these characters"
                    ></InputField>
                  </Grid>
                  <Grid xs={6} item sm={3}>
                    <InputField
                      name="chassisNr"
                      type="text"
                      placeholder="Vin"
                      tooltip="End with an asterix (*) to match all vehicles that start with these characters"
                    ></InputField>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <MakeField
                      tooltip="End with an asterix (*) to match all vehicles that start with these characters"
                      name="make"
                      placeholder="Make"></MakeField>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <ModelField
                      tooltip="End with an asterix (*) to match all vehicles that start with these characters"
                      name="model"
                      placeholder="Model"></ModelField>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <SelectField
                      name="registrationStatus"
                      placeholder="Registrationstatus"
                    >
                      {props.populationValues.registrationStatuses?.map(
                        (status, index) => (
                          <MenuItem key={index} value={status}>
                            {status}
                          </MenuItem>
                        )
                      )}
                    </SelectField>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <SelectField name="color" placeholder="Color">
                      {props.populationValues.colors?.map((color, index) => (
                        <MenuItem key={index} value={color}>
                          {color}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <SelectField name="type" placeholder="Type">
                      {props.populationValues.types?.map((type, index) => (
                        <MenuItem key={index} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <SelectField name="fuelType" placeholder="Fueltype">
                      {props.populationValues.fuelTypes?.map((type, index) => (
                        <MenuItem key={index} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  <CombinedField item xs={12} sm={6}>
                    <InputField
                      name="motorSizeFrom"
                      type="number"
                      placeholder="Min enginesize"
                    ></InputField>
                    <span>-</span>
                    <InputField
                      name="motorSizeTo"
                      type="number"
                      placeholder="Max enginesize"
                    ></InputField>
                  </CombinedField>
                  <CombinedField item xs={12} sm={6}>
                    <InputField
                      name="horsePowerFrom"
                      type="number"
                      placeholder="Min horsepower"
                    ></InputField>
                    <span>-</span>
                    <InputField
                      name="horsePowerTo"
                      type="number"
                      placeholder="Max horsepower"
                    ></InputField>
                  </CombinedField>
                  <CombinedField item xs={12} sm={6}>
                    <InputField
                      name="weightFrom"
                      type="number"
                      placeholder="Min weight"
                    ></InputField>
                    <span>-</span>
                    <InputField
                      name="weightTo"
                      type="number"
                      placeholder="Max weight"
                    ></InputField>
                  </CombinedField>
                  <CombinedField item xs={12} sm={6}>
                    <DateField
                      name="firstRegDateFrom"
                      type="date"
                      placeholder="1st registration date from"
                    ></DateField>
                    <span>-</span>
                    <DateField
                      name="firstRegDateTo"
                      type="date"
                      placeholder="1st registration date to"
                    ></DateField>
                  </CombinedField>
                  <CombinedField item xs={12} sm={6}>
                    <DateField
                      disabled={values.currentlyLeased}
                      name="leasingDateFrom"
                      type="date"
                      placeholder="Leasing period from"
                    ></DateField>
                    <span>-</span>
                    <DateField
                      disabled={values.currentlyLeased}
                      name="leasingDateTo"
                      type="date"
                      placeholder="Leasing period to"
                    ></DateField>
                  </CombinedField>
                  <Grid item xs={12} sm={6}>
                    <DateField
                      name="newInDenmarkFrom"
                      type="date"
                      placeholder="Registered after"
                    ></DateField>
                  </Grid>
                  <Grid item xs={12} sm={3} style={xsDown ? {} : { marginTop: "16px"}}>
                    <CheckboxField name="currentlyLeased" type="checkbox">
                      Only currently leased cars
                    </CheckboxField>
                  </Grid>
                  <Grid item xs={12} sm={3} style={xsDown ? {} : { marginTop: "16px"}}>
                    <CheckboxField 
                    name="onlyImpossibleModelYear" 
                    type="checkbox"
                    tooltip={`Searches for vehicles with model year ${new Date().getFullYear() + 2} and above.`}
                    >
                      Only impossible model year
            </CheckboxField>
                  </Grid>
                  <Grid item xs={12} sm={3} style={xsDown ? {} : { marginTop: "16px"}}>
                    <CheckboxField 
                    name="onlyMismatchInModelYearAndFirstRegistrationYear" 
                    type="checkbox"
                    tooltip="Searches for vehicles where model year and first registration is more than 1 year apart."
                    >
                      Only year mismatch
            </CheckboxField>
                  </Grid>
                  <Hidden xsDown>
                  <Grid item sm={3}>
                  </Grid>
                  </Hidden>
                  <Grid item xs={12} sm={3} style={xsDown ? {} : { marginTop: "16px"}}>
                    <CheckboxField name="parallelImported" type="checkbox">
                      Only imported used cars
            </CheckboxField>
                  </Grid>
                  {values.parallelImported ?
                    <>
                      <Grid item xs={12} sm={3} style={xsDown ? {} : { marginTop: "16px"}}>
                        <CheckboxField 
                        tooltip="Searches for vehicles with taxinspections where the time of inspection is outside opening hours (8AM - 4PM)." 
                        name="onlyInspectedAfterHours" 
                        type="checkbox">
                          Only irregular inspection
            </CheckboxField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectField name="inspectionStationId" placeholder="Choose tax inspection station">
                          {props.populationValues.inspectionStationNames?.map((station, index) => (
                            <MenuItem key={index} value={station.id}>
                              {station.name}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>
                    </> : <Hidden xsDown><Grid item xs={12} sm={6}></Grid></Hidden>}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button size="large" color="primary" onClick={(event) => handleSubmit()}>Search</Button>
                {agentId != undefined ? null : <Typography className={classes.closeText} display="inline" onClick={() => changeSearchForm(false)} color="secondary">Close filter</Typography>}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Form>
    </>
  );
};
