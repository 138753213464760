/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConnectOrdersToProjectRequest,
    ConnectOrdersToProjectRequestFromJSON,
    ConnectOrdersToProjectRequestToJSON,
    CreateMoreOrdersRequest,
    CreateMoreOrdersRequestFromJSON,
    CreateMoreOrdersRequestToJSON,
    CreateOrderRequest,
    CreateOrderRequestFromJSON,
    CreateOrderRequestToJSON,
    GetEventRequest,
    GetEventRequestFromJSON,
    GetEventRequestToJSON,
    GetEventsRequest,
    GetEventsRequestFromJSON,
    GetEventsRequestToJSON,
    PingOrderRequest,
    PingOrderRequestFromJSON,
    PingOrderRequestToJSON,
    PingOrderResponse,
    PingOrderResponseFromJSON,
    PingOrderResponseToJSON,
    ProjectFolderResponse,
    ProjectFolderResponseFromJSON,
    ProjectFolderResponseToJSON,
    SearchOrderRequest,
    SearchOrderRequestFromJSON,
    SearchOrderRequestToJSON,
    SearchProjectRequest,
    SearchProjectRequestFromJSON,
    SearchProjectRequestToJSON,
    ValuationDataResponse,
    ValuationDataResponseFromJSON,
    ValuationDataResponseToJSON,
    VehicleEventInfo,
    VehicleEventInfoFromJSON,
    VehicleEventInfoToJSON,
    VehicleMileageReportData,
    VehicleMileageReportDataFromJSON,
    VehicleMileageReportDataToJSON,
    VehicleOrderDto,
    VehicleOrderDtoFromJSON,
    VehicleOrderDtoToJSON,
    VehicleOrderResponse,
    VehicleOrderResponseFromJSON,
    VehicleOrderResponseToJSON,
    VehiclePictureReportData,
    VehiclePictureReportDataFromJSON,
    VehiclePictureReportDataToJSON,
    VehicleReportManagedAssetDto,
    VehicleReportManagedAssetDtoFromJSON,
    VehicleReportManagedAssetDtoToJSON,
    WimCheckResponse,
    WimCheckResponseFromJSON,
    WimCheckResponseToJSON,
} from '../models';

export interface ApiReportAddMorePostRequest {
    createMoreOrdersRequest?: CreateMoreOrdersRequest;
}

export interface ApiReportAddPostRequest {
    createOrderRequest?: CreateOrderRequest;
}

export interface ApiReportAddToFolderPostRequest {
    connectOrdersToProjectRequest?: ConnectOrdersToProjectRequest;
}

export interface ApiReportAllCompletedOrderEventsPostRequest {
    getEventsRequest?: GetEventsRequest;
}

export interface ApiReportAllFoldersPostRequest {
    searchProjectRequest?: SearchProjectRequest;
}

export interface ApiReportAllReportsPostRequest {
    searchOrderRequest?: SearchOrderRequest;
}

export interface ApiReportCompletedOrderAssetsPostRequest {
    orderId?: string;
}

export interface ApiReportCompletedOrderEventPostRequest {
    getEventRequest?: GetEventRequest;
}

export interface ApiReportCompletedOrderMileageHistoryPostRequest {
    orderId?: string;
}

export interface ApiReportCompletedOrderPicturesPostRequest {
    orderId?: string;
}

export interface ApiReportCompletedOrderPostRequest {
    orderId?: string;
}

export interface ApiReportCompletedOrderValuationDataPostRequest {
    orderId?: string;
}

export interface ApiReportCreateProjectFolderPostRequest {
    name?: string | null;
}

export interface ApiReportPingPostRequest {
    pingOrderRequest?: PingOrderRequest;
}

export interface ApiReportRemoveFromFolderPostRequest {
    projectItemId?: string;
}

export interface ApiReportRemoveProjectFolderPostRequest {
    folderId?: string;
}

export interface ApiReportWIMCheckPostRequest {
    orderId?: string;
}

/**
 * 
 */
export class ReportApi extends runtime.BaseAPI {

    /**
     */
    async apiReportAddMorePostRaw(requestParameters: ApiReportAddMorePostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Report/AddMore`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMoreOrdersRequestToJSON(requestParameters.createMoreOrdersRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiReportAddMorePost(requestParameters: ApiReportAddMorePostRequest): Promise<string> {
        const response = await this.apiReportAddMorePostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportAddPostRaw(requestParameters: ApiReportAddPostRequest): Promise<runtime.ApiResponse<VehicleOrderDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Report/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrderRequestToJSON(requestParameters.createOrderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleOrderDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiReportAddPost(requestParameters: ApiReportAddPostRequest): Promise<VehicleOrderDto> {
        const response = await this.apiReportAddPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportAddToFolderPostRaw(requestParameters: ApiReportAddToFolderPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Report/AddToFolder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectOrdersToProjectRequestToJSON(requestParameters.connectOrdersToProjectRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiReportAddToFolderPost(requestParameters: ApiReportAddToFolderPostRequest): Promise<void> {
        await this.apiReportAddToFolderPostRaw(requestParameters);
    }

    /**
     */
    async apiReportAllCompletedOrderEventsPostRaw(requestParameters: ApiReportAllCompletedOrderEventsPostRequest): Promise<runtime.ApiResponse<Array<VehicleEventInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Report/AllCompletedOrderEvents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetEventsRequestToJSON(requestParameters.getEventsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleEventInfoFromJSON));
    }

    /**
     */
    async apiReportAllCompletedOrderEventsPost(requestParameters: ApiReportAllCompletedOrderEventsPostRequest): Promise<Array<VehicleEventInfo>> {
        const response = await this.apiReportAllCompletedOrderEventsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportAllFoldersPostRaw(requestParameters: ApiReportAllFoldersPostRequest): Promise<runtime.ApiResponse<ProjectFolderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Report/AllFolders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchProjectRequestToJSON(requestParameters.searchProjectRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFolderResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportAllFoldersPost(requestParameters: ApiReportAllFoldersPostRequest): Promise<ProjectFolderResponse> {
        const response = await this.apiReportAllFoldersPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportAllReportsPostRaw(requestParameters: ApiReportAllReportsPostRequest): Promise<runtime.ApiResponse<VehicleOrderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Report/AllReports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchOrderRequestToJSON(requestParameters.searchOrderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleOrderResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportAllReportsPost(requestParameters: ApiReportAllReportsPostRequest): Promise<VehicleOrderResponse> {
        const response = await this.apiReportAllReportsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportCompletedOrderAssetsPostRaw(requestParameters: ApiReportCompletedOrderAssetsPostRequest): Promise<runtime.ApiResponse<Array<VehicleReportManagedAssetDto>>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Report/CompletedOrderAssets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleReportManagedAssetDtoFromJSON));
    }

    /**
     */
    async apiReportCompletedOrderAssetsPost(requestParameters: ApiReportCompletedOrderAssetsPostRequest): Promise<Array<VehicleReportManagedAssetDto>> {
        const response = await this.apiReportCompletedOrderAssetsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportCompletedOrderEventPostRaw(requestParameters: ApiReportCompletedOrderEventPostRequest): Promise<runtime.ApiResponse<VehicleEventInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Report/CompletedOrderEvent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetEventRequestToJSON(requestParameters.getEventRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleEventInfoFromJSON(jsonValue));
    }

    /**
     */
    async apiReportCompletedOrderEventPost(requestParameters: ApiReportCompletedOrderEventPostRequest): Promise<VehicleEventInfo> {
        const response = await this.apiReportCompletedOrderEventPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportCompletedOrderMileageHistoryPostRaw(requestParameters: ApiReportCompletedOrderMileageHistoryPostRequest): Promise<runtime.ApiResponse<Array<VehicleMileageReportData>>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Report/CompletedOrderMileageHistory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleMileageReportDataFromJSON));
    }

    /**
     */
    async apiReportCompletedOrderMileageHistoryPost(requestParameters: ApiReportCompletedOrderMileageHistoryPostRequest): Promise<Array<VehicleMileageReportData>> {
        const response = await this.apiReportCompletedOrderMileageHistoryPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportCompletedOrderPicturesPostRaw(requestParameters: ApiReportCompletedOrderPicturesPostRequest): Promise<runtime.ApiResponse<Array<VehiclePictureReportData>>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Report/CompletedOrderPictures`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehiclePictureReportDataFromJSON));
    }

    /**
     */
    async apiReportCompletedOrderPicturesPost(requestParameters: ApiReportCompletedOrderPicturesPostRequest): Promise<Array<VehiclePictureReportData>> {
        const response = await this.apiReportCompletedOrderPicturesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportCompletedOrderPostRaw(requestParameters: ApiReportCompletedOrderPostRequest): Promise<runtime.ApiResponse<VehicleOrderDto>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Report/CompletedOrder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleOrderDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiReportCompletedOrderPost(requestParameters: ApiReportCompletedOrderPostRequest): Promise<VehicleOrderDto> {
        const response = await this.apiReportCompletedOrderPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportCompletedOrderValuationDataPostRaw(requestParameters: ApiReportCompletedOrderValuationDataPostRequest): Promise<runtime.ApiResponse<ValuationDataResponse>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Report/CompletedOrderValuationData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValuationDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportCompletedOrderValuationDataPost(requestParameters: ApiReportCompletedOrderValuationDataPostRequest): Promise<ValuationDataResponse> {
        const response = await this.apiReportCompletedOrderValuationDataPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportCreateProjectFolderPostRaw(requestParameters: ApiReportCreateProjectFolderPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Report/CreateProjectFolder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiReportCreateProjectFolderPost(requestParameters: ApiReportCreateProjectFolderPostRequest): Promise<void> {
        await this.apiReportCreateProjectFolderPostRaw(requestParameters);
    }

    /**
     */
    async apiReportPingPostRaw(requestParameters: ApiReportPingPostRequest): Promise<runtime.ApiResponse<PingOrderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Report/Ping`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PingOrderRequestToJSON(requestParameters.pingOrderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PingOrderResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportPingPost(requestParameters: ApiReportPingPostRequest): Promise<PingOrderResponse> {
        const response = await this.apiReportPingPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportRemoveFromFolderPostRaw(requestParameters: ApiReportRemoveFromFolderPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.projectItemId !== undefined) {
            queryParameters['projectItemId'] = requestParameters.projectItemId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Report/RemoveFromFolder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiReportRemoveFromFolderPost(requestParameters: ApiReportRemoveFromFolderPostRequest): Promise<void> {
        await this.apiReportRemoveFromFolderPostRaw(requestParameters);
    }

    /**
     */
    async apiReportRemoveProjectFolderPostRaw(requestParameters: ApiReportRemoveProjectFolderPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.folderId !== undefined) {
            queryParameters['folderId'] = requestParameters.folderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Report/RemoveProjectFolder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiReportRemoveProjectFolderPost(requestParameters: ApiReportRemoveProjectFolderPostRequest): Promise<void> {
        await this.apiReportRemoveProjectFolderPostRaw(requestParameters);
    }

    /**
     */
    async apiReportWIMCheckPostRaw(requestParameters: ApiReportWIMCheckPostRequest): Promise<runtime.ApiResponse<WimCheckResponse>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Report/WIMCheck`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WimCheckResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportWIMCheckPost(requestParameters: ApiReportWIMCheckPostRequest): Promise<WimCheckResponse> {
        const response = await this.apiReportWIMCheckPostRaw(requestParameters);
        return await response.value();
    }

}
