/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminCreateTeamRequest
 */
export interface AdminCreateTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateTeamRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateTeamRequest
     */
    packageId?: string;
}

export function AdminCreateTeamRequestFromJSON(json: any): AdminCreateTeamRequest {
    return AdminCreateTeamRequestFromJSONTyped(json, false);
}

export function AdminCreateTeamRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminCreateTeamRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'packageId': !exists(json, 'packageId') ? undefined : json['packageId'],
    };
}

export function AdminCreateTeamRequestToJSON(value?: AdminCreateTeamRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'packageId': value.packageId,
    };
}


