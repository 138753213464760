import { Grid, makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Members } from "./Members";
import { Projects } from "./Projects";
import UserInfo from "./UserInfo";
import { a11yProps, TabPanel } from "../../shared/tabs/TabPanel";
import { Portfolio } from "./Portfolio";
import { Exports } from "./Exports";

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: "0px",
    borderRadius: "3px 3px 0px 0px",
  },
  form: theme.form,
  icon: {
    color: "grey",
    paddingRight: "10px",
  },
}));

const MyAccountPage = () => {
  const history = useHistory();

  const classes = useStyles();

  const getInitValue = () => {
    if (history.location.pathname.startsWith("/myaccount/members")) return 0;
    if (history.location.pathname.startsWith("/myaccount/projects")) return 1;
    if (history.location.pathname.startsWith("/myaccount/allvehicles"))
      return 2;
    if (history.location.pathname.startsWith("/myaccount/exports")) return 3;

    return 0;
  };

  const [value, setTabValue] = useState(getInitValue());

  useEffect(() => {
    setTabValue(getInitValue());
  }, [history.location.pathname]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UserInfo></UserInfo>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.tab}>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{ flexGrow: 1 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    onClick={() => history.push("/myaccount/members")}
                    label="MEMBERS"
                    {...a11yProps(0)}
                  />
                  <Tab
                    onClick={() => history.push("/myaccount/projects")}
                    label="PROJECTS"
                    {...a11yProps(1)}
                  />
                  <Tab
                    onClick={() => history.push("/myaccount/allvehicles")}
                    label="All VEHICLES"
                    {...a11yProps(2)}
                  />
                  <Tab
                    onClick={() => history.push("/myaccount/exports")}
                    label="EXPORTS"
                    {...a11yProps(3)}
                  />
                </Tabs>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <Members></Members>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Projects></Projects>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Portfolio></Portfolio>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Exports></Exports>
      </TabPanel>
    </>
  );
};

export default MyAccountPage;
