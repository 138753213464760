/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RegistrationType,
    RegistrationTypeFromJSON,
    RegistrationTypeFromJSONTyped,
    RegistrationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OwnerChanges
 */
export interface OwnerChanges {
    /**
     * 
     * @type {RegistrationType}
     * @memberof OwnerChanges
     */
    newStatus?: RegistrationType;
    /**
     * 
     * @type {Date}
     * @memberof OwnerChanges
     */
    dateOfChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof OwnerChanges
     */
    regNr?: string | null;
}

export function OwnerChangesFromJSON(json: any): OwnerChanges {
    return OwnerChangesFromJSONTyped(json, false);
}

export function OwnerChangesFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnerChanges {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newStatus': !exists(json, 'newStatus') ? undefined : RegistrationTypeFromJSON(json['newStatus']),
        'dateOfChange': !exists(json, 'dateOfChange') ? undefined : (new Date(json['dateOfChange'])),
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
    };
}

export function OwnerChangesToJSON(value?: OwnerChanges | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newStatus': RegistrationTypeToJSON(value.newStatus),
        'dateOfChange': value.dateOfChange === undefined ? undefined : (value.dateOfChange.toISOString()),
        'regNr': value.regNr,
    };
}


