import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useLocation, useParams } from "react-router";
import { object, ref, string } from "yup";
import useApi from "../../../hooks/api";
import { ResetPasswordRequest } from "../../../lib-api/models/ResetPasswordRequest";
import { InputField } from "../../shared/forms/InputField";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end",
  },
  form: theme.form,
}));

interface ResetPasswordParams {
  token?: string | undefined;
  email?: string | undefined;
}

const ResetPassword = () => {
  const { authApi } = useApi();
  const { search } = useLocation();
  var params = new URLSearchParams(search);

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  return (
    <Paper>
      <Typography align="center" variant="h5">
        Reset password
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={{
          newPassword: "",
          newPasswordValidate: "",
        }}
        validationSchema={object({
          newPassword: string()
            .required("No password provided")
            .min(14, "Password is too short - min 14 chars"),
          newPasswordValidate: string()
            .required("No password provided")
            .min(14, "Password is too short - min 14 chars")
            .oneOf([ref("newPassword")], "Passwords must match"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          var request: ResetPasswordRequest = {
            newPassword: values.newPassword,
            token: params.get("token"),
            email: params.get("email"),
          };
          try {
            const response = await authApi.apiAuthResetpasswordPost({
              resetPasswordRequest: request,
            });
            //update members
            enqueueSnackbar("Password updated!", { variant: "success" });
            resetForm();
            setSubmitting(false);
          } catch (error) {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.form}>
            <Grid container>
              <Grid item xs={12}>
                <InputField
                  name="newPassword"
                  type="password"
                  placeholder="New password"
                ></InputField>
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="newPasswordValidate"
                  type="password"
                  placeholder="Confirm password"
                ></InputField>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.end}>
                  <Button color="primary" type="submit">
                    {isSubmitting ? "Resetting password..." : "Reset"}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default ResetPassword;
