/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExportSort {
    Default = 'Default',
    Name = 'Name',
    NameDesc = 'NameDesc',
    Created = 'Created',
    CreatedDesc = 'CreatedDesc'
}

export function ExportSortFromJSON(json: any): ExportSort {
    return ExportSortFromJSONTyped(json, false);
}

export function ExportSortFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportSort {
    return json as ExportSort;
}

export function ExportSortToJSON(value?: ExportSort | null): any {
    return value as any;
}

