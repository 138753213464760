import React, { useEffect, useState } from "react";
import { useSearchInit } from "../../../../hooks/searchInit";
import { useSearch } from "../../../../hooks/search";
import { LoadingState } from "../../../../hooks/loading";
import { SearchForm } from "./SearchForm";
import { SearchResults } from "./SearchResults";
import { Formik } from "formik";
import { SearchForm as FraudSearchForm } from "../../../../lib-api";
import { object } from "yup";
import { useHistory, useParams } from "react-router-dom";
import { Button, createStyles, FormControl, Grid, makeStyles, Paper, Theme, withStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import useApi from "../../../../hooks/api";
import { InputField } from "../../../shared/forms/InputField";
import { useSnackbar } from "notistack";
import { Breadcrumb } from "../../../shared/other/Breadcrumb";

interface SearchPageParams {
  agentId?: string | undefined;
  vin?: string | undefined;
}

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  }
}));

const CustomButtonGroup = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      '& button': {
        marginTop: "16px",
        paddingLeft: "8px"
      },
      '& .MuiOutlinedInput-root': {
      },
      '& .MuiFormControl-root': {
        flexGrow: 1,
        display: "flex",
        paddingRight: "8px"
      }
    }
  }),
)(Grid);

function SearchPage() {
  const params = useParams<SearchPageParams>();
  const agentId = params.agentId ? Number.parseInt(params.agentId) : undefined;
  const [showSearchForm, setShowSearchForm] = useState(agentId != undefined && params.vin == undefined);

  const {
    initialValues,
    initialValuesState,
    populationValues,
    searchAgentName,
    setSearchAgentName,
    addSearchAgent
  } = useSearchInit(agentId);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchAmount] = useState<number>(20);

  const { results, totalCars, search, searchState } = useSearch(
    pageNumber,
    searchAmount
  );

  useEffect(() => {
    if (params.vin != undefined && initialValues != undefined && results == null) {

      search({ ...initialValues, chassisNr: params.vin });
    }
  }, [initialValues]);

  const { searchApi, searchAgentApi } = useApi();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const saveSearchAgent = async (values: any
    , isValid: boolean
    , validateForm: Function
    , setErrors: Function) => {
    try {
      
      var errors = await validateForm();
      //error for searchagentname
      if (values.searchAgentName == null || values.searchAgentName == "") {
        enqueueSnackbar("Name must be entered to later identify your search agent", { variant: "info" });
        return;
      }

      if (!isValid) {
        setErrors(errors);
        return;
      }
      await searchApi.apiSearchSaveAgentPost({
        saveSearchAgentRequest: {
          searchForm: values,
          name: values.searchAgentName
        },
        searchAgentId: agentId
      });
      setSearchAgentName(values.searchAgentName);
      enqueueSnackbar(
        agentId !== undefined
          ? "Search agent updated!"
          : "Search agent saved!", { variant: "success" }
      );
    } catch {
    }
  };

  const deleteSearchAgent = async (searchAgentId: number) => {
    await searchAgentApi.apiSearchAgentRemoveDelete({
      searchAgentId
    });
    enqueueSnackbar("Search agent deleted", { variant: "success" });
    history.push("/monitoring/searchagents");
  };

  return (
    <>{initialValuesState === LoadingState.Success && initialValues && populationValues ? (
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...initialValues,
          searchAgentName: searchAgentName
        }}
        validationSchema={object<FraudSearchForm>({})}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          //api kald med search
          setSubmitting(true);
          setPageNumber(1);
          try {
              await search(values);
          } catch (error) { }
          setSubmitting(false);
        }}
      >
        {({ values, handleSubmit, isValid, validateForm, setErrors, setFieldValue }) => (<>
          <Grid container spacing={3}>
            {history.location.pathname.startsWith("/search") ? <></> : 
            <Grid item xs={12}>
              <Breadcrumb
              currentPath={agentId != undefined ? searchAgentName : "New search"}
              onCurrentPathEdit={agentId != undefined ? (newName: string) => {setFieldValue("searchAgentName", newName);saveSearchAgent({...values, searchAgentName: newName}, isValid, validateForm, setErrors);} : undefined}
              ></Breadcrumb>
              </Grid>}
            <Grid item xs={12}>
              <SearchForm
                populationValues={populationValues}
                onPreFillField={addSearchAgent}
              ></SearchForm>
            </Grid>
            <SearchResults
              results={results}
              totalCount={totalCars}
              perPage={searchAmount}
              active={pageNumber}
              onPageSelected={setPageNumber}
              searchState={searchState}
            ></SearchResults>
            <Grid item sm={7}>
            </Grid>
            <Grid item sm={5}>
              <Paper>
                <CustomButtonGroup container>
                    {agentId != undefined
                      ? <FormControl>
                        <Button size="large" color="secondary" onClick={() => deleteSearchAgent(agentId)}>
                        Delete agent
                      </Button>
                      </FormControl>
                      : <InputField
                        name="searchAgentName"
                        type="text"
                        placeholder="Enter a title for the search agent"
                        label="Save as monitoring agent"
                      ></InputField>}
                    <Button size="large" color="primary" onClick={() => saveSearchAgent(values, isValid, validateForm, setErrors)}>
                      {agentId !== undefined
                        ? "Update agent"
                        : "Save agent"}
                    </Button>
                </CustomButtonGroup>
              </Paper>
            </Grid>
          </Grid>
        </>
        )}
      </Formik>
    ) : <Grid container spacing={3}>
      <Grid item xs={12}>
      <Paper>
        <Skeleton variant="rect" width="100%" height={agentId == undefined ? "132px" : "476px"}></Skeleton>
      </Paper>
      </Grid>
      </Grid>}
    </>
  );
}

export default SearchPage;
