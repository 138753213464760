import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useApi from "../../../../hooks/api";
import { FieldSimiliarAmount, VehicleField, WimCheckResponse } from "../../../../lib-api";

export const VinValidity: React.FC<{
  validityCount?: number | null;
  validityFields?: FieldSimiliarAmount[] | null;
  vin?: string | null;
  orderId?: string;
}> = (props) => {

  const {reportApi} = useApi();
  const [wimResult, setWimResult] = useState<WimCheckResponse>();

  useEffect(() => {
    (async () => {
      var result = await reportApi.apiReportWIMCheckPost({orderId: props.orderId});
      setWimResult(result);
    })();
  }, []);

  return (
    <Grid container>
      <Grid item sm={6} xs={12}>
      {props.validityCount ? <>
        <Typography variant="inherit">In Denmark, <Box display="inline-flex" fontWeight="fontWeightBold">{props.validityCount}</Box> other car{props.validityCount != 1 ? "s have" : " has"} the same elleven first characters ({props.vin?.substr(0, 11)}) in their VIN</Typography>
        <Typography>A low number can indicate fraud!</Typography>
        <br/>
        {props.validityFields && props.validityFields.filter(p => p.field == VehicleField.Model).length != 0 && props.validityCount != 0 ? 
        <>
          Of the {props.validityCount} vehicles, the models are distributed as follows:
          {props.validityFields.filter(p => p.field == VehicleField.Model).sort((p, n) => n?.amount! - p?.amount!).map((field, index) => (
            <Typography component="div" key={index}>{field.name} (<Box display="inline-flex" fontWeight="fontWeightBold">{field.amount}</Box> vehicles)</Typography>
          ))}
        </> : null}
      </> : null}
      </Grid>
      <Grid item sm={6} xs={12}>
      {wimResult != undefined ? 
      <>
      <Typography>Data derived from the VIN</Typography>
        <Typography>Status: {wimResult.checkStatus}</Typography>
        <Typography>Manufacturing country: {wimResult.country}</Typography>
        <Typography>Manufacturer: {wimResult.manufacturer}</Typography>
      </>
      : null}
      </Grid>
    </Grid>
  );
};
