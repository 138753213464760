/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectItemDto,
    ProjectItemDtoFromJSON,
    ProjectItemDtoFromJSONTyped,
    ProjectItemDtoToJSON,
    TeamMemberDto,
    TeamMemberDtoFromJSON,
    TeamMemberDtoFromJSONTyped,
    TeamMemberDtoToJSON,
    VehicleReportDto,
    VehicleReportDtoFromJSON,
    VehicleReportDtoFromJSONTyped,
    VehicleReportDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleOrderDto
 */
export interface VehicleOrderDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleOrderDto
     */
    id?: string;
    /**
     * 
     * @type {TeamMemberDto}
     * @memberof VehicleOrderDto
     */
    teamMember?: TeamMemberDto;
    /**
     * 
     * @type {VehicleReportDto}
     * @memberof VehicleOrderDto
     */
    vehicleReport?: VehicleReportDto;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleOrderDto
     */
    completed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleOrderDto
     */
    vin?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof VehicleOrderDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleOrderDto
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleOrderDto
     */
    model?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleOrderDto
     */
    shouldGenerate?: boolean | null;
    /**
     * 
     * @type {Array<ProjectItemDto>}
     * @memberof VehicleOrderDto
     */
    projectItems?: Array<ProjectItemDto> | null;
}

export function VehicleOrderDtoFromJSON(json: any): VehicleOrderDto {
    return VehicleOrderDtoFromJSONTyped(json, false);
}

export function VehicleOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleOrderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'teamMember': !exists(json, 'teamMember') ? undefined : TeamMemberDtoFromJSON(json['teamMember']),
        'vehicleReport': !exists(json, 'vehicleReport') ? undefined : VehicleReportDtoFromJSON(json['vehicleReport']),
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'shouldGenerate': !exists(json, 'shouldGenerate') ? undefined : json['shouldGenerate'],
        'projectItems': !exists(json, 'projectItems') ? undefined : (json['projectItems'] === null ? null : (json['projectItems'] as Array<any>).map(ProjectItemDtoFromJSON)),
    };
}

export function VehicleOrderDtoToJSON(value?: VehicleOrderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'teamMember': TeamMemberDtoToJSON(value.teamMember),
        'vehicleReport': VehicleReportDtoToJSON(value.vehicleReport),
        'completed': value.completed,
        'vin': value.vin,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'make': value.make,
        'model': value.model,
        'shouldGenerate': value.shouldGenerate,
        'projectItems': value.projectItems === undefined ? undefined : (value.projectItems === null ? null : (value.projectItems as Array<any>).map(ProjectItemDtoToJSON)),
    };
}


