/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateMoreOrdersRequest
 */
export interface CreateMoreOrdersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMoreOrdersRequest
     */
    vins?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMoreOrdersRequest
     */
    projectId?: string | null;
}

export function CreateMoreOrdersRequestFromJSON(json: any): CreateMoreOrdersRequest {
    return CreateMoreOrdersRequestFromJSONTyped(json, false);
}

export function CreateMoreOrdersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMoreOrdersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vins': !exists(json, 'vins') ? undefined : json['vins'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
    };
}

export function CreateMoreOrdersRequestToJSON(value?: CreateMoreOrdersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vins': value.vins,
        'projectId': value.projectId,
    };
}


