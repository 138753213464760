import { AuthApi, Middleware, AuthInfo } from "../lib-api";
import { useContext } from "react";
import { AuthStateContext } from "../state/auth";
import { defaultApiConfiguration } from "./api";
import { useSnackbar } from "notistack";

let mutex: Promise<AuthInfo> | null = null;

function defer<T>() {
  var deferred: { promise: Promise<T>; resolve: (value: T) => void } = {
    promise: null!,
    resolve: null!,
  };
  deferred.promise = new Promise((resolve) => {
    deferred.resolve = resolve;
  });
  return deferred;
}

const useMiddleware = () => {
  const [state, dispatch] = useContext(AuthStateContext);
  const { enqueueSnackbar } = useSnackbar();

  const middleware: Middleware = {
    post: async (context) => {
      if (
        context.response.status == 401 &&
        context.response.headers.has("token-expired")
      ) {
        console.log("denied");
        if (mutex == null) {
          let deferred = defer<AuthInfo>();
          mutex = deferred.promise;

          const authApi = new AuthApi(defaultApiConfiguration);
          try {
            const refreshResponse = await authApi.apiAuthRefreshPost({
              refreshRequest: {
                token: state.authInfo!.token!,
                refreshToken: state.authInfo!.refreshToken!,
              },
            });
            dispatch({ type: "login", authInfo: refreshResponse });
            deferred.resolve(refreshResponse);
            mutex = null;
            context.init.headers = {
              ...context.init.headers,
              Authorization: `Bearer ${refreshResponse.token!}`,
            };
          } catch (e) {
            dispatch({ type: "logout" });
          }
        } else {
          try {
            let refreshResponse = await mutex;
            context.init.headers = {
              ...context.init.headers,
              Authorization: `Bearer ${refreshResponse.token!}`,
            };
          } catch (e) {
            dispatch({ type: "logout" });
          }
        }
        return await context.fetch(context.url, context.init);
      } else if (context.response.status == 401) {
        dispatch({ type: "logout" });
      } else if (context.response.status == 403) {
        var text = await context.response.text();
        enqueueSnackbar(text == "" ? "Unauthorized" : text, {
          variant: "error",
        });
      } else if (
        context.response.status == 404 ||
        context.response.status == 400
      ) {
        var text = await context.response.text();
        enqueueSnackbar(text == "" ? "An error occured. Try again" : text, {
          variant: "error",
        });
      }
    },
  };

  return {
    state,
    dispatch,
    middleware,
  };
};

export default useMiddleware;
