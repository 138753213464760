/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SearchAgentsResponse,
    SearchAgentsResponseFromJSON,
    SearchAgentsResponseToJSON,
    SearchForm,
    SearchFormFromJSON,
    SearchFormToJSON,
    SearchVehicleIdentifier,
    SearchVehicleIdentifierFromJSON,
    SearchVehicleIdentifierToJSON,
    UpdateSearchAgentActivity,
    UpdateSearchAgentActivityFromJSON,
    UpdateSearchAgentActivityToJSON,
} from '../models';

export interface ApiSearchAgentAllGetRequest {
    pageNumber?: number;
    searchQuery?: string | null;
    onlyWanted?: boolean;
    onlyActive?: boolean;
    size?: number;
}

export interface ApiSearchAgentAutoCreateSearchAgentPostRequest {
    searchVehicleIdentifier?: SearchVehicleIdentifier;
}

export interface ApiSearchAgentRemoveDeleteRequest {
    searchAgentId?: number;
}

export interface ApiSearchAgentResultsSearchAgentGetRequest {
    searchAgentId?: number;
}

export interface ApiSearchAgentUpdatePostRequest {
    updateSearchAgentActivity?: UpdateSearchAgentActivity;
}

/**
 * 
 */
export class SearchAgentApi extends runtime.BaseAPI {

    /**
     */
    async apiSearchAgentAllGetRaw(requestParameters: ApiSearchAgentAllGetRequest): Promise<runtime.ApiResponse<SearchAgentsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        if (requestParameters.onlyWanted !== undefined) {
            queryParameters['onlyWanted'] = requestParameters.onlyWanted;
        }

        if (requestParameters.onlyActive !== undefined) {
            queryParameters['onlyActive'] = requestParameters.onlyActive;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/SearchAgent/All`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchAgentsResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchAgentAllGet(requestParameters: ApiSearchAgentAllGetRequest): Promise<SearchAgentsResponse> {
        const response = await this.apiSearchAgentAllGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSearchAgentAutoCreateSearchAgentPostRaw(requestParameters: ApiSearchAgentAutoCreateSearchAgentPostRequest): Promise<runtime.ApiResponse<SearchForm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/SearchAgent/AutoCreateSearchAgent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchVehicleIdentifierToJSON(requestParameters.searchVehicleIdentifier),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchFormFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchAgentAutoCreateSearchAgentPost(requestParameters: ApiSearchAgentAutoCreateSearchAgentPostRequest): Promise<SearchForm> {
        const response = await this.apiSearchAgentAutoCreateSearchAgentPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSearchAgentRemoveDeleteRaw(requestParameters: ApiSearchAgentRemoveDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.searchAgentId !== undefined) {
            queryParameters['searchAgentId'] = requestParameters.searchAgentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/SearchAgent/Remove`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSearchAgentRemoveDelete(requestParameters: ApiSearchAgentRemoveDeleteRequest): Promise<void> {
        await this.apiSearchAgentRemoveDeleteRaw(requestParameters);
    }

    /**
     */
    async apiSearchAgentResultsSearchAgentGetRaw(requestParameters: ApiSearchAgentResultsSearchAgentGetRequest): Promise<runtime.ApiResponse<SearchAgentsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.searchAgentId !== undefined) {
            queryParameters['searchAgentId'] = requestParameters.searchAgentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/SearchAgent/ResultsSearchAgent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchAgentsResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchAgentResultsSearchAgentGet(requestParameters: ApiSearchAgentResultsSearchAgentGetRequest): Promise<SearchAgentsResponse> {
        const response = await this.apiSearchAgentResultsSearchAgentGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSearchAgentUpdatePostRaw(requestParameters: ApiSearchAgentUpdatePostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/SearchAgent/Update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSearchAgentActivityToJSON(requestParameters.updateSearchAgentActivity),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiSearchAgentUpdatePost(requestParameters: ApiSearchAgentUpdatePostRequest): Promise<string> {
        const response = await this.apiSearchAgentUpdatePostRaw(requestParameters);
        return await response.value();
    }

}
