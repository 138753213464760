/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExportType,
    ExportTypeFromJSON,
    ExportTypeFromJSONTyped,
    ExportTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateRegTaxVinRequest
 */
export interface CreateRegTaxVinRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRegTaxVinRequest
     */
    vins?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRegTaxVinRequest
     */
    projectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRegTaxVinRequest
     */
    name?: string | null;
    /**
     * 
     * @type {ExportType}
     * @memberof CreateRegTaxVinRequest
     */
    exportType?: ExportType;
}

export function CreateRegTaxVinRequestFromJSON(json: any): CreateRegTaxVinRequest {
    return CreateRegTaxVinRequestFromJSONTyped(json, false);
}

export function CreateRegTaxVinRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRegTaxVinRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vins': !exists(json, 'vins') ? undefined : json['vins'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'exportType': !exists(json, 'exportType') ? undefined : ExportTypeFromJSON(json['exportType']),
    };
}

export function CreateRegTaxVinRequestToJSON(value?: CreateRegTaxVinRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vins': value.vins,
        'projectId': value.projectId,
        'name': value.name,
        'exportType': ExportTypeToJSON(value.exportType),
    };
}


