import { useState, useEffect } from "react";
import { VinInfo } from "../lib-api/models";
import { LoadingState } from "../hooks/loading";
import useApi from "../hooks/api";
import { useQueryDelay } from "../hooks/queryDelay";
import { useSnackbar } from "notistack";

export function useAdminVinInfo(page: number, amount: number, searchQuery: string) {
  const [state, setState] = useState(LoadingState.None);
  const [results, setResults] = useState<
    VinInfo[]
  >([]);
  const [totalOrders, setTotalOrders] = useState<number|undefined>(undefined);
  const {delay} = useQueryDelay(searchQuery);
  const {enqueueSnackbar} = useSnackbar();

  const { adminVinInfoApi } = useApi();

  const load = async () => {
    setState(LoadingState.Loading);

    try {
      const searchResult = await adminVinInfoApi.apiAdminVininfoAllinfoPost({
        page: page,
        size: amount,
        searchQuery: searchQuery
      });
      setResults(searchResult.all);
      setTotalOrders(searchResult.totalCount);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  const addClonedVIN = async (vin: string) => {
    setState(LoadingState.Loading);

    try {
      await adminVinInfoApi.apiAdminVininfoAddClonedPost({
        vin: vin
      });
      load();
      setState(LoadingState.Success);
      enqueueSnackbar("Cloned vin added", { variant: "success" });
    } catch (error) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    load();
  }, [page, amount, delay]);

  return {
    results: results,
    totalVins: totalOrders,
    searchState: state,
    addClonedVIN,
    reload: load
  };
}
