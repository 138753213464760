import React, { createContext, useReducer, Dispatch, useEffect } from "react";
import { AuthInfo, InitialGraphData } from "../../lib-api";

const loadFromStorage = () => {
  const persisted = localStorage.getItem("AUTH");
  if (!persisted) return null;
  try {
    return JSON.parse(persisted);
  } catch {
    return null;
  }
};

const defaultAuthState: {
  authInfo?: AuthInfo;
} = {
  authInfo: loadFromStorage(),
};

export type AuthState = typeof defaultAuthState;

type AuthAction = { type: "login"; authInfo: AuthInfo} | { type: "logout" };

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "login":
      return {
        ...state,
        authInfo: action.authInfo
      };
    case "logout": {
      return {
        ...state,
        authInfo: undefined
      };
    }
    default:
      throw new Error(`Case not handled in reducer`);
  }
};

export const AuthStateContext = createContext<[AuthState, Dispatch<AuthAction>]>(
  {} as [AuthState, Dispatch<AuthAction>]
);

export const AuthStateContextProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(authReducer, defaultAuthState);

  useEffect(() => {
    if (state.authInfo) {
      localStorage.setItem("AUTH", JSON.stringify(state.authInfo));
    } else {
      localStorage.removeItem("AUTH");
    }
  }, [state.authInfo]);

  return (
    <AuthStateContext.Provider value={[state, dispatch]}>
      {props.children}
    </AuthStateContext.Provider>
  );
};
