import { ToldsynRequest } from "../lib-api";
import useApi from "./api";

export function usePdfHelper() {

  const {statisticsApi} = useApi();

  const getAsPdf = async(file: string, params: ToldsynRequest, title: string) => {
    const data = await statisticsApi.apiStatisticsPdfPost({chartToPdfRequest: {
      imageString: file,
      title: title,
      params: params
    }});
    return data;
  }

  return {
    getAsPdf
  };
}
