import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        form: {
            width: string | number;
            "& .MuiFormControl-root": {
                display: string;
            },
            "& .MuiFormLabel-root": {
                overflow: string;
                width: string;
                "text-overflow": string;
                "white-space": string;
            },
            "& input[type='number']": {
                "-moz-appearance": string;
            },
            "& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button": {
                "-webkit-appearance": string;
                margin: string | number;
            }
        },
        icon: {
            position: "absolute";
            top: number | string;
            right: number | string;
            cursor: string;
        }
        link: {
            textDecoration: string;
            color: string;
        },
        primaryChart: {
            color: string;
            background: string;
        },
        secondaryChart: {
            color: string;
            background: string;
        },
        tertiaryChart: {
            color: string;
            background: string;
        }
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        form?: {
            width: string | number;
            "& .MuiFormControl-root": {
                display: string;
            },
            "& .MuiFormLabel-root": {
                overflow: string;
                width: string;
                "text-overflow": string;
                "white-space": string;
            },
            "& input[type='number']": {
                "-moz-appearance": string;
            },
            "& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button": {
                "-webkit-appearance": string;
                margin: string | number;
            }
        },
        icon?: {
            position: "absolute";
            top: number | string;
            right: number | string;
            cursor: string;
        },
        link: {
            textDecoration: string;
            color: string;
        },
        primaryChart: {
            color: string;
            background: string;
        },
        secondaryChart: {
            color: string;
            background: string;
        },
        tertiaryChart: {
            color: string;
            background: string;
        }
    }
}

let theme = createMuiTheme({
    form: {
        width: "100%",
        "& .MuiFormControl-root": {
            display: "flex"
        },
        "& .MuiFormLabel-root": {
            overflow: "hidden",
            width: "100%",
            "text-overflow": "ellipsis",
            "white-space": "nowrap"
        },
        "& input[type='number']": {
            "-moz-appearance": "textfield"
        },
        "& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        }
    },
    icon: {
        position: "absolute",
        top: "10px",
        right: 0,
        cursor: "pointer"
    },
    primaryChart: {
        color: "#57bcdd",
        background: "#57bcdd"
    },
    secondaryChart: {
        color: "#3fb7aa",
        background: "#3fb7aa"
    },
    tertiaryChart: {
        color: "#d32f2f",
        background: "#d32f2f"
    },
    link: {
        textDecoration: "none",
        color: "#000000"
    },
    typography: {
        fontFamily: "Prometo, sans-serif"
    },
    palette: {
        primary: {
            main: "#006287",
            dark: "#00445e",
            light: "#33819f",
            contrastText: ""
        },
        secondary: {
            main: "#FF7A11",
        },
        background: {
            default: "#F6F9FB",
            paper: "#FFFFFF"
        },
        error: {
            main: colors.red[500]
        }
    },
    overrides: {
        MuiButton: {
            textPrimary: {
                color: "#ffffff",
                backgroundColor: "#FF7A11",
                "&:hover": {
                    backgroundColor: "#ff892c!important",
                }
            },
            textSecondary: {
                color: "#FF7A11",
                backgroundColor: "#ffffff",
                border: "1px solid #FF7A11",
                "&:hover": {
                    backgroundColor: "#FF7A11!important",
                    color: "#FFFFFF"
                }
            },
            root: {
                borderRadius: 3,
                padding: "9px 40px!important",
                textTransform: "none",
                color: "#ffffff",
                backgroundColor: "#0A5F85",
                "&:hover": {
                    backgroundColor: "#095375!important",
                }
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#1f414d",
                boxShadow: "none"
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: 3,
                border: "1px solid #c5ced6",
                boxShadow: "0 30px 70px 0 rgba(226,232,236,0.5)!important",
                backgroundColor: "#f9fafb",
                padding: "16px",
            }
        },
        MuiTypography: {
            button: {
                textTransform: "none",
            },
            body1: {
                whiteSpace: "pre-line"
            },
            body2: {
                color: "#647481!important"
            },
            colorTextSecondary: {
                color: "#FFFFFF"
            },
            colorTextPrimary: {
                color: "#c9c9d1"
            },
            h5: {
                fontWeight: 900,
                color: "#84959d"
            },
            h6: {
                fontWeight: 900,
                color: "#84959d"
            }
        },
        MuiGrid: {
            container: {
                marginTop: "10px",
                marginBottom: "10px"
            }
        },
        MuiLinearProgress: {
            root: {
                height: "10px",
                borderRadius: 25
            }
        },
        MuiLink:{
            root: {
                cursor: "pointer"
            }
        },
        MuiListItemText: {
            multiline: {
                flex: "1 1"
            }
        },
        MuiDivider: {
            root: {
                marginBottom: "10px",
                marginTop: "10px"
            }
        },
        MuiTextField: {
            root: {
                "& input[type='number']": {
                    "-moz-appearance": "textfield"
                },
                "& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0
                }
            }
        },
        MuiTab: {
            textColorSecondary: {
                color: "#FFFFFF",
                minWidth: "0px",
                marginRight: "10px"
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: "0px!important"
            }
        },
        MuiSvgIcon: {
            root: {
                cursor: "pointer"
            }
        },
        MuiAccordion: {
            rounded: {
                borderRadius: "3px!important"
            },
        },
        MuiAccordionSummary: {
            root: {
                cursor: "auto!important",
                padding: "0px!important",
                backgroundColor: "initial!important"
            },
            content: {
                marginTop: "0px!important"
            }
        },
        MuiAccordionDetails: {
            root: {
                padding: "0px!important"
            }
        },
        MuiPopover: {
            paper: {
                padding: "0px!important"
            }
        }
    }
});

theme = responsiveFontSizes(theme, {factor: 30});

export default theme;