import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./components/pages/home/HomePage";
import Layout from "./components/shared/Layout";
import useAuth from "./hooks/auth";
import AuthenticatedRoute from "./components/shared/routing/AuthenticatedRoute";
import SearchPage from "./components/pages/search/advanced/SearchPage";
import MyAccountPage from "./components/pages/account/MyAccountPage";
import ConnectOrdersPage from "./components/pages/account/ConnectOrdersPage";
import StatisticsPage from "./components/pages/statistics/StatisticsPage";
import PreventionPage from "./components/pages/prevention/PreventionPage";
import ReportPage from "./components/pages/prevention/ReportPage";
import ChangesResultPage from "./components/pages/monitoring/overview/ChangesResultPage";
import PreviewPage from "./components/pages/prevention/PreviewPage";
import { PageViewTracker } from "./components/shared/other/PageViewTracker";
import SystemPage from "./components/pages/system/SystemPage";
import AdminAddTeamPage from "./components/pages/admin/Teams/AdminAddTeamPage";
import AdminAddUserPage from "./components/pages/admin/Teams/AdminAddUserPage";
import MonitorPage from "./components/pages/monitoring/overview/MonitorPage";
import EditPasswordPage from "./components/pages/account/EditPasswordPage";
import AddMemberPage from "./components/pages/account/AddMemberPage";
import AdminConnectToTeamsPage from "./components/pages/admin/Teams/AdminConnectToTeamsPage";
import AdminPage from "./components/pages/admin/AdminPage";

export default function AppRouter() {
  const { isLoggedIn, isAdmin } = useAuth();
  const isAuthenticated = isLoggedIn();
  const isAdministrator = isAdmin();
  return (
    <Router>
      <PageViewTracker />
      <Layout>
        <Switch>
        <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/myaccount/editpassword"
          >
            <EditPasswordPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/myaccount/addmember"
          >
            <AddMemberPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/myaccount/projects/:projectId/connecttoorders"
          >
            <ConnectOrdersPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/myaccount/allvehicles/project-:projectId"
          >
            <MyAccountPage />
          </AuthenticatedRoute>
        <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/myaccount"
          >
            <MyAccountPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/search"
          >
            <SearchPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/monitoring/vinmonitors/:VINCheckerId"
          >
            <ChangesResultPage/>
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/monitoring/searchagents/createsearch"
          >
            <SearchPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/monitoring/searchagents/:agentId/:vin"
          >
            <SearchPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/monitoring/searchagents/:agentId"
          >
            <SearchPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/monitoring"
          >
            <MonitorPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/statistics"
          >
            <StatisticsPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/reports/:orderId/preview"
          >
            <PreviewPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/reports/project-:projectId"
          >
            <PreventionPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/reports/:orderId"
          >
            <ReportPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/reports"
          >
            <PreventionPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAdministrator}
            path="/admin/createteam"
          >
            <AdminAddTeamPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAdministrator}
            path="/admin/teams/:teamId/createuser"
          >
            <AdminAddUserPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAdministrator}
            path="/admin/users/:userId/connecttoteams"
          >
            <AdminConnectToTeamsPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAdministrator}
            path="/admin/teams/:teamId"
          >
            <AdminPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAdministrator}
            path="/admin/vehicles"
          >
            <AdminPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            isAuthenticated={isAdministrator}
            path="/admin"
          >
            <AdminPage />
          </AuthenticatedRoute>
          <Route path="/resetpassword">
            <SystemPage />
          </Route>
          <Route path="/reset">
            <HomePage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}
