import { useState, useEffect } from "react";
import { SearchAgentsResponse } from "../lib-api/models";
import { LoadingState } from "./loading";
import useApi from "./api";
import { useQueryDelay } from "./queryDelay";
import { useSnackbar } from "notistack";
import useAuth from "./auth";

export function useSearchAgents(
  searchQuery: string = "",
  onlyWanted: boolean = false,
  onlyActive: boolean = false,
  pageNumber = 1,
  size = 10
) {
  const [searchAgents, setSearchAgents] = useState<SearchAgentsResponse>({
    all: [],
    totalCount: 0,
  });

  const [state, setState] = useState(LoadingState.None);
  const { delay } = useQueryDelay(searchQuery);
  const { enqueueSnackbar } = useSnackbar();

  const { searchAgentApi } = useApi();
  const {user} = useAuth();

  const deleteSearchAgent = async (searchAgentId: number) => {
    await searchAgentApi.apiSearchAgentRemoveDelete({
      searchAgentId
    });
    loadSearchAgents();
    enqueueSnackbar("Search agent deleted", { variant: "success" });
  };

  const loadSearchAgents = async () => {
    setState(LoadingState.Loading);
    try {
      const searchAgents = await searchAgentApi.apiSearchAgentAllGet({
        pageNumber: pageNumber,
        searchQuery: searchQuery,
        onlyActive: onlyActive,
        onlyWanted: onlyWanted,
        size: size,
      });

      setSearchAgents(searchAgents);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    loadSearchAgents();
  }, [onlyActive, onlyWanted, pageNumber, delay, user?.teamId]);

  return {
    searchAgents,
    searchAgentState: state,
    reloadSearchAgents: loadSearchAgents,
    deleteSearchAgent
  };
}
