import { useFormikContext } from "formik";
import { FormControlProps } from "@material-ui/core";
import React from "react";
import { AutoSuggestField } from "./AutoSuggestField";
import { SearchForm } from "../../../lib-api";
import { useMakeSuggestions } from "../../../hooks/makeSuggestions";

export const MakeField: React.FC<
  {
    name: string;
    placeholder: string;
    labelText?: string | undefined;
    tooltip?: string;
    onFinish?: () => void;
  } & FormControlProps
> = (props) => {
  const { values } = useFormikContext<SearchForm>();

  const { suggestions, suggestionsState } = useMakeSuggestions(values.make?.trim());

  return (
    <AutoSuggestField 
    {...props} 
    suggestions={values.make ? values.make.includes("*") ? suggestions.concat(values.make) : suggestions.concat(values.make + "*") : suggestions} 
    suggestionsState={suggestionsState}
    tooltip={props.tooltip}></AutoSuggestField>
  );
};
