/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSearchAgentActivity
 */
export interface UpdateSearchAgentActivity {
    /**
     * 
     * @type {number}
     * @memberof UpdateSearchAgentActivity
     */
    searchAgentId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSearchAgentActivity
     */
    value?: boolean;
}

export function UpdateSearchAgentActivityFromJSON(json: any): UpdateSearchAgentActivity {
    return UpdateSearchAgentActivityFromJSONTyped(json, false);
}

export function UpdateSearchAgentActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSearchAgentActivity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchAgentId': !exists(json, 'searchAgentId') ? undefined : json['searchAgentId'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function UpdateSearchAgentActivityToJSON(value?: UpdateSearchAgentActivity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchAgentId': value.searchAgentId,
        'value': value.value,
    };
}


