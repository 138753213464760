/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminEditVehicleDataRequest,
    AdminEditVehicleDataRequestFromJSON,
    AdminEditVehicleDataRequestToJSON,
    AdminSearchVehicleOrderRequest,
    AdminSearchVehicleOrderRequestFromJSON,
    AdminSearchVehicleOrderRequestToJSON,
    ManagedAssetDto,
    ManagedAssetDtoFromJSON,
    ManagedAssetDtoToJSON,
    VehicleInformationReportData,
    VehicleInformationReportDataFromJSON,
    VehicleInformationReportDataToJSON,
    VehicleOrderResponse,
    VehicleOrderResponseFromJSON,
    VehicleOrderResponseToJSON,
    VehicleReportManagedAssetDto,
    VehicleReportManagedAssetDtoFromJSON,
    VehicleReportManagedAssetDtoToJSON,
} from '../models';

export interface ApiAdminReportAllreportsPostRequest {
    adminSearchVehicleOrderRequest?: AdminSearchVehicleOrderRequest;
}

export interface ApiAdminReportDeleteorderPostRequest {
    vehicleOrderId?: string;
}

export interface ApiAdminReportEditVehicleDataPostRequest {
    adminEditVehicleDataRequest?: AdminEditVehicleDataRequest;
}

export interface ApiAdminReportReccreatereportPostRequest {
    vehicleOrderId?: string;
}

export interface ApiAdminReportRemoveAssetDeleteRequest {
    vehicleReportManagedId?: string;
}

export interface ApiAdminReportResetorderPostRequest {
    vehicleOrderId?: string;
}

export interface ApiAdminReportSetgeneratingPostRequest {
    vehicleOrderId?: string;
}

export interface ApiAdminReportSetscorePostRequest {
    vehicleOrderId?: string;
    score?: number;
    summary?: string | null;
}

export interface ApiAdminReportUploadAssetsPostRequest {
    vehicleOrderId?: string;
    file?: Blob | null;
}

export interface ApiAdminReportUploadUrlPostRequest {
    url?: string | null;
}

/**
 * 
 */
export class AdminReportApi extends runtime.BaseAPI {

    /**
     */
    async apiAdminReportAllreportsPostRaw(requestParameters: ApiAdminReportAllreportsPostRequest): Promise<runtime.ApiResponse<VehicleOrderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/report/allreports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminSearchVehicleOrderRequestToJSON(requestParameters.adminSearchVehicleOrderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleOrderResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminReportAllreportsPost(requestParameters: ApiAdminReportAllreportsPostRequest): Promise<VehicleOrderResponse> {
        const response = await this.apiAdminReportAllreportsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminReportDeleteorderPostRaw(requestParameters: ApiAdminReportDeleteorderPostRequest): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.vehicleOrderId !== undefined) {
            queryParameters['vehicleOrderId'] = requestParameters.vehicleOrderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/report/deleteorder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminReportDeleteorderPost(requestParameters: ApiAdminReportDeleteorderPostRequest): Promise<boolean> {
        const response = await this.apiAdminReportDeleteorderPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminReportEditVehicleDataPostRaw(requestParameters: ApiAdminReportEditVehicleDataPostRequest): Promise<runtime.ApiResponse<VehicleInformationReportData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/report/editVehicleData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminEditVehicleDataRequestToJSON(requestParameters.adminEditVehicleDataRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleInformationReportDataFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminReportEditVehicleDataPost(requestParameters: ApiAdminReportEditVehicleDataPostRequest): Promise<VehicleInformationReportData> {
        const response = await this.apiAdminReportEditVehicleDataPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminReportReccreatereportPostRaw(requestParameters: ApiAdminReportReccreatereportPostRequest): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.vehicleOrderId !== undefined) {
            queryParameters['vehicleOrderId'] = requestParameters.vehicleOrderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/report/reccreatereport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminReportReccreatereportPost(requestParameters: ApiAdminReportReccreatereportPostRequest): Promise<boolean> {
        const response = await this.apiAdminReportReccreatereportPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminReportRemoveAssetDeleteRaw(requestParameters: ApiAdminReportRemoveAssetDeleteRequest): Promise<runtime.ApiResponse<ManagedAssetDto>> {
        const queryParameters: any = {};

        if (requestParameters.vehicleReportManagedId !== undefined) {
            queryParameters['vehicleReportManagedId'] = requestParameters.vehicleReportManagedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/report/removeAsset`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagedAssetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminReportRemoveAssetDelete(requestParameters: ApiAdminReportRemoveAssetDeleteRequest): Promise<ManagedAssetDto> {
        const response = await this.apiAdminReportRemoveAssetDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminReportResetallPostRaw(): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/report/resetall`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminReportResetallPost(): Promise<boolean> {
        const response = await this.apiAdminReportResetallPostRaw();
        return await response.value();
    }

    /**
     */
    async apiAdminReportResetorderPostRaw(requestParameters: ApiAdminReportResetorderPostRequest): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.vehicleOrderId !== undefined) {
            queryParameters['vehicleOrderId'] = requestParameters.vehicleOrderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/report/resetorder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminReportResetorderPost(requestParameters: ApiAdminReportResetorderPostRequest): Promise<boolean> {
        const response = await this.apiAdminReportResetorderPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminReportSetgeneratingPostRaw(requestParameters: ApiAdminReportSetgeneratingPostRequest): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.vehicleOrderId !== undefined) {
            queryParameters['vehicleOrderId'] = requestParameters.vehicleOrderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/report/setgenerating`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminReportSetgeneratingPost(requestParameters: ApiAdminReportSetgeneratingPostRequest): Promise<boolean> {
        const response = await this.apiAdminReportSetgeneratingPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminReportSetscorePostRaw(requestParameters: ApiAdminReportSetscorePostRequest): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.vehicleOrderId !== undefined) {
            queryParameters['vehicleOrderId'] = requestParameters.vehicleOrderId;
        }

        if (requestParameters.score !== undefined) {
            queryParameters['score'] = requestParameters.score;
        }

        if (requestParameters.summary !== undefined) {
            queryParameters['summary'] = requestParameters.summary;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/report/setscore`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminReportSetscorePost(requestParameters: ApiAdminReportSetscorePostRequest): Promise<boolean> {
        const response = await this.apiAdminReportSetscorePostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminReportUploadAssetsPostRaw(requestParameters: ApiAdminReportUploadAssetsPostRequest): Promise<runtime.ApiResponse<VehicleReportManagedAssetDto>> {
        const queryParameters: any = {};

        if (requestParameters.vehicleOrderId !== undefined) {
            queryParameters['vehicleOrderId'] = requestParameters.vehicleOrderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/admin/report/uploadAssets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleReportManagedAssetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminReportUploadAssetsPost(requestParameters: ApiAdminReportUploadAssetsPostRequest): Promise<VehicleReportManagedAssetDto> {
        const response = await this.apiAdminReportUploadAssetsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminReportUploadUrlPostRaw(requestParameters: ApiAdminReportUploadUrlPostRequest): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/report/UploadUrl`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminReportUploadUrlPost(requestParameters: ApiAdminReportUploadUrlPostRequest): Promise<boolean> {
        const response = await this.apiAdminReportUploadUrlPostRaw(requestParameters);
        return await response.value();
    }

}
