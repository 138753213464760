/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VINCheckerDto,
    VINCheckerDtoFromJSON,
    VINCheckerDtoFromJSONTyped,
    VINCheckerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface VINCheckersResponse
 */
export interface VINCheckersResponse {
    /**
     * 
     * @type {Array<VINCheckerDto>}
     * @memberof VINCheckersResponse
     */
    all: Array<VINCheckerDto>;
    /**
     * 
     * @type {number}
     * @memberof VINCheckersResponse
     */
    totalCount: number;
}

export function VINCheckersResponseFromJSON(json: any): VINCheckersResponse {
    return VINCheckersResponseFromJSONTyped(json, false);
}

export function VINCheckersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VINCheckersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'all': ((json['all'] as Array<any>).map(VINCheckerDtoFromJSON)),
        'totalCount': json['totalCount'],
    };
}

export function VINCheckersResponseToJSON(value?: VINCheckersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all': ((value.all as Array<any>).map(VINCheckerDtoToJSON)),
        'totalCount': value.totalCount,
    };
}


