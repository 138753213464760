import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, Link } from "@material-ui/core";

export interface UpgradePackageDialogProps {
  open: boolean;
  onClose: () => void;
  packageToUpgradeTo: "Gold"|"Silver"|"Bronze";
}

export const UpgradePackageDialog = (props: UpgradePackageDialogProps) => {
  const { onClose, open } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Upgrade to {props.packageToUpgradeTo}!</DialogTitle>
      <DialogContent>
        To acccess this functionality, you need to upgrade to the {props.packageToUpgradeTo} package.
        <br/>
        <br/>
        Contact Sales at <Link href="mailto:sales@vindicate.io">sales@vindicate.io</Link> to upgrade!
      </DialogContent>
    </Dialog>
  );
}
