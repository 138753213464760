/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InitialFilterData,
    InitialFilterDataFromJSON,
    InitialFilterDataFromJSONTyped,
    InitialFilterDataToJSON,
    ToldsynByCvrResult,
    ToldsynByCvrResultFromJSON,
    ToldsynByCvrResultFromJSONTyped,
    ToldsynByCvrResultToJSON,
    ToldsynByLeasingYearEndResult,
    ToldsynByLeasingYearEndResultFromJSON,
    ToldsynByLeasingYearEndResultFromJSONTyped,
    ToldsynByLeasingYearEndResultToJSON,
    ToldsynByMakeResult,
    ToldsynByMakeResultFromJSON,
    ToldsynByMakeResultFromJSONTyped,
    ToldsynByMakeResultToJSON,
    ToldsynByModelYearResult,
    ToldsynByModelYearResultFromJSON,
    ToldsynByModelYearResultFromJSONTyped,
    ToldsynByModelYearResultToJSON,
    ToldsynByStateResult,
    ToldsynByStateResultFromJSON,
    ToldsynByStateResultFromJSONTyped,
    ToldsynByStateResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface InitialGraphData
 */
export interface InitialGraphData {
    /**
     * 
     * @type {Array<ToldsynByCvrResult>}
     * @memberof InitialGraphData
     */
    toldsynByCvrResults?: Array<ToldsynByCvrResult> | null;
    /**
     * 
     * @type {Array<ToldsynByMakeResult>}
     * @memberof InitialGraphData
     */
    toldsynByMakeResults?: Array<ToldsynByMakeResult> | null;
    /**
     * 
     * @type {Array<ToldsynByModelYearResult>}
     * @memberof InitialGraphData
     */
    toldsynByYearResults?: Array<ToldsynByModelYearResult> | null;
    /**
     * 
     * @type {Array<ToldsynByStateResult>}
     * @memberof InitialGraphData
     */
    toldsynByStateResults?: Array<ToldsynByStateResult> | null;
    /**
     * 
     * @type {Array<ToldsynByLeasingYearEndResult>}
     * @memberof InitialGraphData
     */
    toldsynByLeasingYearEndResults?: Array<ToldsynByLeasingYearEndResult> | null;
    /**
     * 
     * @type {Array<ToldsynByModelYearResult>}
     * @memberof InitialGraphData
     */
    toldsynByModelYearResults?: Array<ToldsynByModelYearResult> | null;
    /**
     * 
     * @type {InitialFilterData}
     * @memberof InitialGraphData
     */
    initialFilterData?: InitialFilterData;
}

export function InitialGraphDataFromJSON(json: any): InitialGraphData {
    return InitialGraphDataFromJSONTyped(json, false);
}

export function InitialGraphDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitialGraphData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'toldsynByCvrResults': !exists(json, 'toldsynByCvrResults') ? undefined : (json['toldsynByCvrResults'] === null ? null : (json['toldsynByCvrResults'] as Array<any>).map(ToldsynByCvrResultFromJSON)),
        'toldsynByMakeResults': !exists(json, 'toldsynByMakeResults') ? undefined : (json['toldsynByMakeResults'] === null ? null : (json['toldsynByMakeResults'] as Array<any>).map(ToldsynByMakeResultFromJSON)),
        'toldsynByYearResults': !exists(json, 'toldsynByYearResults') ? undefined : (json['toldsynByYearResults'] === null ? null : (json['toldsynByYearResults'] as Array<any>).map(ToldsynByModelYearResultFromJSON)),
        'toldsynByStateResults': !exists(json, 'toldsynByStateResults') ? undefined : (json['toldsynByStateResults'] === null ? null : (json['toldsynByStateResults'] as Array<any>).map(ToldsynByStateResultFromJSON)),
        'toldsynByLeasingYearEndResults': !exists(json, 'toldsynByLeasingYearEndResults') ? undefined : (json['toldsynByLeasingYearEndResults'] === null ? null : (json['toldsynByLeasingYearEndResults'] as Array<any>).map(ToldsynByLeasingYearEndResultFromJSON)),
        'toldsynByModelYearResults': !exists(json, 'toldsynByModelYearResults') ? undefined : (json['toldsynByModelYearResults'] === null ? null : (json['toldsynByModelYearResults'] as Array<any>).map(ToldsynByModelYearResultFromJSON)),
        'initialFilterData': !exists(json, 'initialFilterData') ? undefined : InitialFilterDataFromJSON(json['initialFilterData']),
    };
}

export function InitialGraphDataToJSON(value?: InitialGraphData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'toldsynByCvrResults': value.toldsynByCvrResults === undefined ? undefined : (value.toldsynByCvrResults === null ? null : (value.toldsynByCvrResults as Array<any>).map(ToldsynByCvrResultToJSON)),
        'toldsynByMakeResults': value.toldsynByMakeResults === undefined ? undefined : (value.toldsynByMakeResults === null ? null : (value.toldsynByMakeResults as Array<any>).map(ToldsynByMakeResultToJSON)),
        'toldsynByYearResults': value.toldsynByYearResults === undefined ? undefined : (value.toldsynByYearResults === null ? null : (value.toldsynByYearResults as Array<any>).map(ToldsynByModelYearResultToJSON)),
        'toldsynByStateResults': value.toldsynByStateResults === undefined ? undefined : (value.toldsynByStateResults === null ? null : (value.toldsynByStateResults as Array<any>).map(ToldsynByStateResultToJSON)),
        'toldsynByLeasingYearEndResults': value.toldsynByLeasingYearEndResults === undefined ? undefined : (value.toldsynByLeasingYearEndResults === null ? null : (value.toldsynByLeasingYearEndResults as Array<any>).map(ToldsynByLeasingYearEndResultToJSON)),
        'toldsynByModelYearResults': value.toldsynByModelYearResults === undefined ? undefined : (value.toldsynByModelYearResults === null ? null : (value.toldsynByModelYearResults as Array<any>).map(ToldsynByModelYearResultToJSON)),
        'initialFilterData': InitialFilterDataToJSON(value.initialFilterData),
    };
}


