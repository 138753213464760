import React, { useEffect, useRef, useState } from "react";
import { TekniskData, SearchForm } from "../../../../lib-api";
import { LoadingState } from "../../../../hooks/loading";
import {
  ColDef,
  DataGrid,
  RowsProp,
  ValueFormatterParams,
} from "@material-ui/data-grid";
import {
  createStyles,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CustomDataGrid } from "../../../shared/datagrid/CustomDataGrid";
import useApi from "../../../../hooks/api";
import { useFormikContext } from "formik";
import { useWindowSize } from "../../../../hooks/windowSize";
import useAuth from "../../../../hooks/auth";

const useStyles = makeStyles((theme) => ({
  link: theme.link,
  vehicleCell: {
    justifyContent: "flex-start!important",
  },
}));

export const SearchResultGrid: React.FC<{
  rows: RowsProp;
  totalCount?: number;
  perPage: number;
  active: number;
  onPageSelected: React.Dispatch<React.SetStateAction<number>>;
  searchState: LoadingState;
}> = (props) => {
  const resultRef = useRef<null | HTMLDivElement>(null);
  const classes = useStyles();
  const { isAdmin } = useAuth();
  const { searchApi } = useApi();
  const { xsDown } = useWindowSize();

  const { values } = useFormikContext<SearchForm>();
  const [downloadingVins, setDownloadingVins] = useState<LoadingState>(
    LoadingState.None
  );

  useEffect(() => {
    const node = resultRef.current;
    node?.scrollIntoView();
  }, [props.rows]);

  const downloadVins = async () => {
    try {
      setDownloadingVins(LoadingState.Loading);

      var result = await searchApi.apiSearchCsvVinPost({
        searchForm: values,
        amount: isAdmin() ? 5000 : 200,
        page: 1,
      });
      saveAs("data:application/csv;base64," + result, `searchvins.csv`);
      setDownloadingVins(LoadingState.Success);
    } catch {
      setDownloadingVins(LoadingState.Error);
    }
  };

  var columns: ColDef[] = [
    {
      field: "col1",
      headerName: "VIN",
      flex: 4,
      sortable: false,
      hide: xsDown,
    },
    {
      field: "col2",
      headerName: "REG NR.",
      sortable: false,
      flex: 2,
      hide: xsDown,
    },
    {
      field: "col3",
      headerName: "VEHICLE",
      cellClassName: classes.vehicleCell,
      renderCell: (params: ValueFormatterParams) => (
        <SearchResult doc={params.value as TekniskData}></SearchResult>
      ),
      flex: 7,
      sortable: false,
    },
    { field: "col4", headerName: "STATUS", flex: 2, hide: xsDown },
    {
      field: "col6",
      headerName: "1ST REGISTRATIONDATE",
      hide: xsDown,
      flex: 4,
      valueFormatter: (params: ValueFormatterParams) =>
        params.value != undefined
          ? (params.value as Date).toLocaleDateString("da")
          : null,
    },
    {
      field: "col7",
      headerName: "IMPORTED DATE",
      hide: xsDown,
      flex: 3,
      valueFormatter: (params: ValueFormatterParams) =>
        params.value != undefined
          ? (params.value as Date).toLocaleDateString("da")
          : null,
    },
  ];

  return (
    <Grid ref={resultRef} item xs={12}>
      <CustomDataGrid
        rows={props.rows}
        columns={columns}
        pageSize={props.perPage}
        page={props.active}
        setPage={(pageNumber: number) => props.onPageSelected(pageNumber)}
        loadingState={props.searchState}
        totalRows={props.totalCount ?? 0}
        //handleSortModelChange={(params: SortModelParams) => handleSortModelChange(params.sortModel[0])}
        //onRowClick={(row) => row.row["first"].completed ? history.push(`/reports/${row.row["first"].id}`) : null}
        dataDisplayText="results"
        headerProps={{
          displayHeadline: "Result",
          filters: [
            {
              type: "button",
              onClick: (e) => downloadVins(),
              position: "right",
              name: "Download VINS as csv",
              tooltip: `Max ${isAdmin() ? "5000" : "200"} VINS at a time`,
              loadingText: "Downloading VINS..",
              isLoading: downloadingVins == LoadingState.Loading,
            },
          ],
        }}
      ></CustomDataGrid>
    </Grid>
  );
};

export const SearchResult: React.FC<{
  doc?: TekniskData | null;
}> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Link
        className={classes.link}
        href={`https://tjekbil.dk${
          props.doc?.regNr == null || props.doc.regNr == undefined
            ? "/stelnummer/" + props.doc?.stelNr
            : "/nummerplade/" + props.doc.regNr
        }/overblik`}
        target="_blank"
      >
        <Typography color="secondary">
          <strong>
            {props.doc?.maerkeTypeNavn} {props.doc?.modelTypeNavn}
          </strong>
          {props.doc?.koeretoejArtNavn !== undefined &&
          props.doc?.koeretoejArtNavn !== null ? (
            <span>, {props.doc.koeretoejArtNavn}</span>
          ) : null}
          {props.doc?.modelAar !== undefined && props.doc.modelAar !== null ? (
            <span>, year {props.doc.modelAar}</span>
          ) : null}
        </Typography>
      </Link>
    </>
  );
};
