/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExportType,
    ExportTypeFromJSON,
    ExportTypeFromJSONTyped,
    ExportTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExportVehicleDto
 */
export interface ExportVehicleDto {
    /**
     * 
     * @type {string}
     * @memberof ExportVehicleDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportVehicleDto
     */
    vin?: string | null;
    /**
     * 
     * @type {ExportType}
     * @memberof ExportVehicleDto
     */
    exportType?: ExportType;
}

export function ExportVehicleDtoFromJSON(json: any): ExportVehicleDto {
    return ExportVehicleDtoFromJSONTyped(json, false);
}

export function ExportVehicleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportVehicleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'exportType': !exists(json, 'exportType') ? undefined : ExportTypeFromJSON(json['exportType']),
    };
}

export function ExportVehicleDtoToJSON(value?: ExportVehicleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vin': value.vin,
        'exportType': ExportTypeToJSON(value.exportType),
    };
}


