import { SvgIcon, Tooltip } from "@material-ui/core";
import React from "react";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PaymentIcon from '@material-ui/icons/Payment';
import PolicyIcon from '@material-ui/icons/Policy';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

export const MileageIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <SvgIcon color="secondary" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M10.002 8a2 2 0 11-4 0H1.004a6.997 6.997 0 1013.993 0h-4.995zM8 15.997A8 8 0 118 0a8 8 0 010 15.998zm-.029-6.714c.38 0 .75-.154 1.011-.422.382-.392.373-.856.37-1.356-.005-1.196-.011-2.393-.018-3.589 0-.134-.115-.216-.232-.216a.225.225 0 00-.197.11c-.568.951-1.133 1.903-1.713 2.847-.207.336-.509.746-.575 1.136-.105.614.336 1.251.955 1.432a1.519 1.519 0 00.4.058zM12.5 6a.5.5 0 10-.001-1.001A.5.5 0 0012.5 6zm-2-2a.5.5 0 10-.001-1.001A.5.5 0 0010.5 4zm-7 2a.5.5 0 10-.001-1.001A.5.5 0 003.5 6zm2-2a.5.5 0 10-.001-1.001A.5.5 0 005.5 4zM8 3a.5.5 0 10-.001-1.001A.5.5 0 008 3zm-3 8h1.2v2H5v-2zm2.398 0h1.2v2h-1.2v-2zm2.399 0h1.199v2h-1.2v-2z">
        </path>
      </SvgIcon>
    </Tooltip>
  </>);
};

export const WantedIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <SvgIcon color="secondary" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M7.493 16c-1.891 0-3.124-.922-3.777-1.413-.653-.492-1.648-1.81-2.11-3.017C.836 11.305 0 10.316 0 9.224c0-1.102.509-2.025 1.19-2.258a.2.2 0 01.042-.074c.101-.498.246-.976.43-1.43-.017.027-.035.046-.053.05-.423.09-.679-.344-.679-.582 0-.24.302-1.43.68-1.64.114-.064.275-.179.51-.317C2.515 1.87 4.763.05 7.494 0c2.738.042 4.992 1.868 5.386 2.972.236.138.397.254.512.318.377.21.679 1.398.679 1.64 0 .237-.256.67-.68.581-.017-.003-.035-.022-.052-.05.184.454.329.932.43 1.43a.2.2 0 01.042.074c.681.233 1.19 1.156 1.19 2.258 0 1.092-.836 2.081-1.606 2.346-.462 1.206-1.588 2.513-2.11 3.017C10.76 15.09 9.385 16 7.494 16zm4.823-5.204a.451.451 0 00.175.034c.5 0 1.178-.715 1.178-1.52 0-.678-.289-1.249-.68-1.41-.273.622-.941 1.469-2.02 1.658-1.453.254-2.156-.418-3.368-.418h-.047c-1.212 0-1.915.673-3.369.418-1.078-.188-1.747-1.035-2.02-1.657-.39.161-.679.731-.679 1.41 0 .805.678 1.52 1.178 1.52a.443.443 0 00.174-.034c.677 2.399 2.537 3.96 4.734 3.996 2.201-.031 4.066-1.594 4.744-3.997zM7.494 6.749h.03c1.179-.008 1.431-.677 2.85-.882.933-.135 1.453-.04 1.856.237a7.246 7.246 0 00-.566-1.274c-1.062-.376-2.522-.877-4.14-.877h-.048c-1.618 0-3.078.502-4.14.878-.224.394-.414.82-.566 1.273.403-.276.923-.371 1.856-.236 1.419.205 1.671.874 2.85.881h.018zm3.129 1.599c.87-.127 1.207-.568 1.207-.894 0-.327-1.082-.828-1.912-.284-.83.543-1.609.799-1.609.77 0 .326 1.443.535 2.314.408zm-6.26-.049c.87.127 2.314-.082 2.314-.408 0 .029-.779-.227-1.61-.77-.83-.543-1.911-.042-1.911.284 0 .326.336.767 1.207.894z"></path>
      </SvgIcon>
    </Tooltip>
  </>);
};

export const AccidentIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <SvgIcon color="secondary" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M1.245 9.624l1.2-1.775c.09-.177.267-.266.49-.266h5.558c.178 0 .4.089.489.266l1.2 1.775c.578.222 1.334.799 1.334 1.952v2.086c0 .577-.311.976-.711 1.153v.608c0 .31-.267.577-.578.577h-.934a.715.715 0 01-.49-.222c-.132-.133-.177-.519-.177-.696v.088c0-.222-.089-.4-.222-.577-.178-.177-.356-.221-.578-.221H3.602a.715.715 0 00-.49.221.712.712 0 00-.222.489v.133c0 .177-.044.474-.178.608a.571.571 0 01-.444.177h-.979a.588.588 0 01-.578-.577v-.74c0-.444-.711-.4-.711-1.199v-2.13c0-.976.711-1.508 1.245-1.73zm8.27 3.283c.367 0 .667-.3.667-.665a.668.668 0 00-1.334 0c0 .366.3.665.667.665zm-7.559 0c.367 0 .667-.3.667-.665a.668.668 0 00-1.334 0c0 .366.3.665.667.665zm3.758-1.753c1.957 0 3.223-.637 3.09-.848L8.226 9.21a.55.55 0 00-.49-.295h-4a.55.55 0 00-.49.295l-.622 1.055c-.133.253 1.135.89 3.091.89zM15.381 14h-1.996c-.32 0-.57-.234-.57-.534 0-.3.25-.534.57-.534h1.14L13.1 10.86a.555.555 0 01.107-.568l1.497-1.636-1.71-1.368c-.215-.167-.25-.434-.143-.668l.855-1.88-1.782-.368a.548.548 0 01-.463-.467l-.285-1.87-1.64 1.002a.578.578 0 01-.748-.1l-1.46-1.502-1.314 1.936a.55.55 0 01-.665.2L3.337 2.89l-.943 2.817c-.107.267-.428.4-.748.267a.545.545 0 01-.321-.701l1.157-3.318c.107-.267.463-.4.748-.3l2.12.716L6.792.234C6.9.1 7.043 0 7.22 0c.179 0 .357.067.464.167l1.603 1.67L11.247.6c.178-.1.392-.134.57-.033.178.066.32.233.357.434l.356 2.37 2.138.434c.178.033.32.133.392.3a.613.613 0 010 .501l-.998 2.147 1.746 1.47c.25.2.25.534.036.767l-1.64 1.77 1.64 2.505c.071.166.071.367-.071.5-.036.134-.214.234-.392.234z"></path>
      </SvgIcon>
    </Tooltip>
  </>);
};

export const ManufacturingIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <SvgIcon color="secondary" viewBox="0 0 25.084 25.473" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.27 0v9.158l3.494-1.233V0zm9.578 0v9.158l3.497-1.233V0zM0 25.473h25.084V16.94H0zm12.013-7.217h2.691v4.304h-2.69zm-4.756 0H9.95v4.304H7.257zm-4.932 0h2.69v4.304h-2.69z"></path>
        <path d="M9.755 11.888L8.71 8.403 0 11.888v3.715h25.083v-3.715H19.16l-.686-3.485z"></path>
      </SvgIcon>
    </Tooltip>
  </>);
};

export const RegistrationIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <SvgIcon color="secondary" viewBox="0 0 646.957 646.943" xmlns="http://www.w3.org/2000/svg">
        <defs><clipPath id="svg-fc3d10255836f13d1bcb60aff64a792d9ca2be6e1b9f12e852f74ed9fd1a7330a" clipPathUnits="userSpaceOnUse"><path d="M0 485.207h485.218V0H0z"></path></clipPath></defs>
        <g
          clipPath="url(#svg-fc3d10255836f13d1bcb60aff64a792d9ca2be6e1b9f12e852f74ed9fd1a7330a)"
          transform="matrix(1.33333 0 0 -1.33333 0 646.943)">
          <path d="M485.215 30.331H211.332V0h273.883zM139.251 94.652l-44.615 44.627-35.271-79.9zM427.88 320.656L191.992 84.786 0 0l84.772 192.026 235.9 235.837zm32.184 139.385c22.425-22.418 30.653-55.426 21.445-85.781l-32.161-32.161-107.24 107.219 32.17 32.166c30.355 9.238 63.347.989 85.786-21.443">
          </path>
        </g>
      </SvgIcon>
    </Tooltip>
  </>);
};

export const ServiceIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <SvgIcon color="secondary" viewBox="0 0 649.231 649.129" xmlns="http://www.w3.org/2000/svg">
        <defs><clipPath id="svg-55e015d9f9717e1e05ce51eebe6389330c8e7dde4df127c0ef423d39ff998fb0a" clipPathUnits="userSpaceOnUse"><path d="M0 486.847h486.922V0H0z"></path></clipPath></defs>
        <g
          clipPath="url(#svg-55e015d9f9717e1e05ce51eebe6389330c8e7dde4df127c0ef423d39ff998fb0a)"
          transform="matrix(1.33333 0 0 -1.33333 0 649.13)">
          <path d="M13.36 77.822c-17.814-17.814-17.814-46.663 0-64.478C22.267 4.437 33.94 0 45.614 0s23.347 4.437 32.254 13.344l149.717 149.75-64.508 64.446zm374.187 277.486l65.207 33.714 34.138 65.997-31.827 31.828-65.968-34.169-33.774-65.146-79.312-79.403 32.163-32.224z"></path><path d="M401.834 182.244l-6.14.607c-11.127 0-21.676-2.279-31.525-5.927L177 364.124c3.648 9.85 5.928 20.398 5.928 31.524l-.608 6.141c-3.222 47.454-42.316 85.058-90.59 85.058-14.045 0-27.208-3.435-39.094-9.12l60.586-60.586c3.192-3.192 5.381-6.96 6.84-10.944 3.982-10.76 1.824-23.347-6.84-32.04a30.315 30.315 0 00-21.492-8.908c-3.587 0-7.144.79-10.549 2.067-3.982 1.52-7.752 3.648-10.944 6.87L9.651 434.742c-5.685-11.886-9.12-25.049-9.12-39.094 0-48.274 37.604-87.368 85.058-90.59l6.141-.608c11.126 0 21.675 2.28 31.524 5.897l187.14-187.17c-3.587-9.849-5.867-20.398-5.867-31.524l.638-6.14c3.16-47.486 42.285-85.06 90.559-85.06 14.044 0 27.237 3.374 39.093 9.12L374.232 70.16c-3.162 3.162-5.38 6.961-6.9 10.913-3.983 10.761-1.825 23.377 6.9 32.07 5.867 5.93 13.71 8.908 21.492 8.908 3.588 0 7.113-.76 10.58-2.067 3.982-1.49 7.782-3.618 10.913-6.84l60.586-60.587c5.715 11.917 9.12 25.05 9.12 39.095-.03 48.276-37.635 87.37-85.089 90.592">
          </path>
        </g>
      </SvgIcon>
    </Tooltip>
  </>);
};

export const InspectionIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <SvgIcon color="secondary" viewBox="0 0 459 459" xmlns="http://www.w3.org/2000/svg">
        <path d="M124.95 181.05l-35.7 35.7L204 331.5l255-255-35.7-35.7L204 260.1l-79.05-79.05zM408 408H51V51h255V0H51C22.95 0 0 22.95 0 51v357c0 28.05 22.95 51 51 51h357c28.05 0 51-22.95 51-51V204h-51v204z"></path>
      </SvgIcon>
    </Tooltip>
  </>);
};

export const ValuationIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <AttachMoneyIcon color="secondary"/>
    </Tooltip>
  </>);
};

export const TaxIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <PaymentIcon color="secondary"/>
    </Tooltip>
  </>);
};

export const InsuranceIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <PolicyIcon color="secondary"/>
    </Tooltip>
  </>);
};

export const SaleIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <SyncAltIcon color="secondary"/>
    </Tooltip>
  </>);
};

export const BankruptcyIcon: React.FunctionComponent<{
  hoverText: string;
}> = (props) => {
  return (<>
    <Tooltip title={props.hoverText}>
      <AccountBalanceIcon color="secondary"/>
    </Tooltip>
  </>);
};