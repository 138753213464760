/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EventRemoteType {
    TjekBilDmr = 'TjekBil_DMR',
    TjekBilTrafikstyrrelsen = 'TjekBil_Trafikstyrrelsen',
    TjekBilPolitiet = 'TjekBil_Politiet',
    Vindecoder = 'Vindecoder',
    CarVertical = 'CarVertical',
    Bilhandel = 'Bilhandel',
    TjekBilTinglysningen = 'TjekBil_Tinglysningen',
    TheParkingUnknown = 'TheParking_unknown',
    TheParkingBilbasen = 'TheParking_Bilbasen',
    TheParkingAutomobileFr = 'TheParking_automobile_fr',
    TheParkingMobileDe = 'TheParking_mobile_de',
    TheParkingKlassikermarkedetDk = 'TheParking_klassikermarkedet_dk',
    TheParkingGuloggratisDk = 'TheParking_guloggratis_dk',
    TheParkingClassicTraderCom = 'TheParking_classic_trader_com',
    TheParkingDbaDk = 'TheParking_dba_dk',
    LarmTjanst = 'LarmTjanst',
    Instagram = 'Instagram'
}

export function EventRemoteTypeFromJSON(json: any): EventRemoteType {
    return EventRemoteTypeFromJSONTyped(json, false);
}

export function EventRemoteTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventRemoteType {
    return json as EventRemoteType;
}

export function EventRemoteTypeToJSON(value?: EventRemoteType | null): any {
    return value as any;
}

