import { Button, Grid, Hidden, makeStyles, Paper, Typography } from "@material-ui/core";
import { ColDef, DataGrid, RowsProp, SortItem, ValueFormatterParams } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import useApi from "../../../hooks/api";
import useAuth from "../../../hooks/auth";
import { usePortfolio } from "../../../hooks/portfolio";
import { DeletionIcon } from "../../shared/other/Icons";
import { CustomDataGrid } from "../../shared/datagrid/CustomDataGrid";
import { CustomDataGridHeader, DataGridHeaderFilter } from "../../shared/datagrid/CustomDataGridHeader";
import { PortfolioSort, ProjectSort } from "../../../lib-api";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useProjects } from "../../../hooks/projects";
import { useWindowSize } from "../../../hooks/windowSize";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.secondary.main
  }
}));

export const Portfolio: React.FunctionComponent<{

}> = (props) => {
  var { isTeamOwner } = useAuth();
  var [page, setPage] = useState<number>(1);
  var [searchQuery, setSearchQuery] = useState<string>("");
  var [pageSize] = useState<number>(20);
  const {projectId} = useParams<{projectId: string}>();
  var [sortMode, setSortMode] = useState<PortfolioSort>(PortfolioSort.Default);
  var [projectFolderId, setProjectFolderId] = useState<string>(projectId ?? "");
  var { portfolioApi } = useApi();
  var { totalVins, results, searchState, reload } = usePortfolio(page, pageSize, sortMode, searchQuery, projectFolderId);

  var {projects} = useProjects(1, 20, ProjectSort.Default, "");

  const [rows, setRows] = useState<RowsProp>([]);
  const { enqueueSnackbar } = useSnackbar();
  const {xsDown} = useWindowSize();

  useEffect(() => {
    setRows(results.length !== 0 ? (
      results.map((result, index) => {
        return {
          id: index,
          vin: result.vin,
          make: result.make,
          model: result.model,
          creator: result.teamMember?.screenName,
          first: result.id
        }
      })) : []);
  }, [results]);

  const addVehicleToPortfolio = async (vin: string, projectFolderId?: string) => {
    try {
      await portfolioApi.apiPortfolioAddPost ({ createPortfolioVinRequest: { vin: vin, projectId: projectFolderId}});
      enqueueSnackbar("Vehicle added", { variant: "success" });
      reload();
    } catch {

    }
  }

  const addVehiclesToPortfolio = async (vins: string[], projectFolderId?: string) => {
    try {
      await portfolioApi.apiPortfolioAddManyPost ({ createManyPortfolioVinRequest: { vins: vins, projectId: projectFolderId}});
      enqueueSnackbar("Vehicles added. Vehicle information is being updated.", { variant: "success" });
      reload();
    } catch {

    }
  }

  const getFilters = () => {
    var filters: DataGridHeaderFilter[] = [];

    filters.push({ name: "Choose project", 
    position: "right", 
    type: "select",
    initVal: projectFolderId,
    onChange: (e) => setProjectFolderId(e.target.value == "-1" ? "" : e.target.value), 
    selectOptions: projects.map((project) => {return {name: project.name!, value: project.id!}})
  },);
    filters.push({ type: "search", onChange: (e) => setSearchQuery(e.target.value), position: "right", name: "Search" });
    return filters;
  }

  const handleSortModelChange = (model: SortItem) => {
    if (model == null || model == undefined) {
      setSortMode(PortfolioSort.Default);
      return;
    }
    if (model.field == "make" && model.sort == "asc") {
      setSortMode(PortfolioSort.Make);
      return;
    }
    if (model.field == "make" && model.sort == "desc") {
      setSortMode(PortfolioSort.MakeDesc);
      return;
    }
    if (model.field == "model" && model.sort == "asc") {
      setSortMode(PortfolioSort.Model);
      return;
    }
    if (model.field == "model" && model.sort == "desc") {
      setSortMode(PortfolioSort.ModelDesc);
      return;
    }
  }

  const deleteVehicleFromPortfolio = async (portfolioVinId: string) => {
    try {
      if (isTeamOwner()) {
        await portfolioApi.apiPortfolioRemoveDelete({ portfolioVinId: portfolioVinId });
        enqueueSnackbar("Vehicle has been removed!", { variant: "success" });
        reload();
      } else {
        enqueueSnackbar("Only the team owner can delete vehicles.", { variant: "error" });
      }
    } catch {
    }
  }

  const columns: ColDef[] = [
    { field: 'vin', headerName: 'VIN', flex: 3, sortable: false, hide: xsDown },
    { field: 'make', headerName: 'MAKE', flex: 2 },
    { field: 'model', headerName: 'MODEL', flex: 2 },
    { field: 'creator', headerName: 'OWNER', flex: 2, disableClickEventBubbling: true, sortable: false, hide: xsDown },
    {
      field: 'first', headerName: " ", disableClickEventBubbling: true, flex: 1,
      renderCell: (params: ValueFormatterParams) => (<DeletionIcon onClick={() => deleteVehicleFromPortfolio(params.value as string)} hoverText="Delete vehicle"></DeletionIcon>),
      sortable: false
    }
  ];

  return (
    <>
    <Hidden xsDown>
    <CustomDataGridHeader headerProps={{
          filters: [
            {type: "submitwithprojects", name: "Add vehicle", onSubmitVehicleId: (id, projectId) => addVehicleToPortfolio(id, projectId == "-1" ? undefined : projectId), position: "left", link: "VIN"},
            {type: "submitmultiple", loadingText: "Adding vehicles..", name: "Add multiple vehicles", onSubmitVehicleId: (ids, projectId) => addVehiclesToPortfolio(ids, projectId == "-1" ? undefined : projectId), position: "right"}
          ], isTab: true
        }}
        ></CustomDataGridHeader>
    </Hidden>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        page={page}
        setPage={(pageNumber: number) => { setPage(pageNumber) }}
        loadingState={searchState}
        totalRows={totalVins}
        handleSortModelChange={(params) => handleSortModelChange(params.sortModel[0])}
        dataDisplayText="vehicles"
        headerProps={{
          displayHeadline: "PORTFOLIO", filters: getFilters(), isTab: true
        }}
      ></CustomDataGrid>
    </>
  );
};
