import React, { useContext, useEffect, useMemo, useState } from "react";
import { LoadingState } from "../../../../hooks/loading";
import { ElementLoading } from "../../../shared/other/Loading";
import { Bar, ChartData } from "react-chartjs-2";
import { Skeleton } from "@material-ui/lab";
import { Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { useDimensions } from "../../../../hooks/dimensions";
import { useChartOptions } from "../../../../hooks/chartOptions";
import { useDownloadStatistics } from "../../../../hooks/downloadStatistics";
import { usePdfHelper } from "../../../../hooks/pdfhelper";
import { ChartFunctions } from "../ChartFunctions";
import { ChartFilters } from "../ChartFilters";
import { useStatistics } from "../../../../hooks/statistics";
import ArrowRight from "@material-ui/icons/ArrowRight";
import { BreadCrumbHeadline } from "../../../shared/other/Breadcrumb";
import { StatisticsStateContext } from "../../../../state/statistics";
import { useChartStyles } from "../StatisticsPage";

export const ImportedByCvrChart: React.FC<{
  amount: number;
}> = (props) => {
  const [context, dispatch] = useContext(StatisticsStateContext);
  const { cvrState, initialData } = useStatistics({taxRequest: {cvrRequest: context.filterData.cvr.request}});
  const { width } = useDimensions("tax-inspection-by-cvr-paper-width");
  const classes = useChartStyles({ width: width as number });
  const theme = useTheme();
  const { downloadCvrVins, downloadCvrState } = useDownloadStatistics();

  useEffect(() => {
    if (initialData.toldsynByCvrResults != null && initialData.toldsynByCvrResults != undefined) {
      createChart();
    }
  }, [initialData.toldsynByCvrResults, props.amount]);

  const chartRef = useMemo(() => {
    return React.createRef<Bar>();
  }, []);
  const [options] = useChartOptions<Bar>(chartRef, false, false);
  const [chartData, setChartData] = useState<ChartData<Chart.ChartData>>({});
  const { getAsPdf } = usePdfHelper();

  const downloadPdf = async () => {
    var title = "Amount of tax inspections distributed over cvr of inspection station";
    try {
      var data = await getAsPdf(chartRef.current?.chartInstance.toBase64Image() as string, context.filterData.cvr.request, title);
      saveAs("data:application/pdf;base64," + data, title + ".pdf");
    } catch { }
  }

  const createChart = () => {
    var data = {
      labels: initialData.toldsynByCvrResults?.slice(0, props.amount).map(p => p.name as string),
      datasets: [{
        label: '# of imported tax inspections',
        data: initialData.toldsynByCvrResults?.slice(0, props.amount).map(p => p.toldsynResultsImported),
        backgroundColor: theme.primaryChart.color,
        borderColor: theme.primaryChart.background,
      },
      {
        label: '# of exported tax inspections',
        data: initialData.toldsynByCvrResults?.slice(0, props.amount).map(p => p.toldsynResultsExported),
        backgroundColor: theme.secondaryChart.color,
        borderColor: theme.secondaryChart.background,
        borderWidth: 1
      }]
    };
    setChartData(data);
  }
  return (
    <>
      <Paper className={classes.header}>
        <Grid container spacing={1} alignItems="center">
          <BreadCrumbHeadline item xs={12} sm={6}>
          <Typography variant="h5">Tax inspections</Typography>
          <ArrowRight fontSize="default" />
            <Typography variant="h5">Per inspection station</Typography>
          </BreadCrumbHeadline>
          <Grid item xs={12} sm={6}>
          <ChartFunctions toggled={context.filterData.cvr.showFilters} onToggleFilters={() => dispatch({type: "setShowCvrFilter"})} downloadPdf={downloadPdf} downloadVins={() => downloadCvrVins(context.filterData.cvr.request)}></ChartFunctions>
          </Grid>
        </Grid>
      </Paper>

      <Paper hidden={!context.filterData.cvr.showFilters} className={classes.filter}>
      <ChartFilters
            initialFilterData={initialData.initialFilterData}
            request={{info: context.filterData.cvr, setRequest: (request) => dispatch({type: "setCvrRequest", request: request})}}
            initialLoading={initialData.toldsynByCvrResults == undefined} />
      </Paper>

      <Paper className={classes.chart}>
        <div id="tax-inspection-by-cvr-paper-width" style={{ position: "relative" }}>
          {initialData.toldsynByCvrResults == undefined ? <Skeleton variant="rect" className={classes.skeleton}></Skeleton>
            :
            <>
              {cvrState == LoadingState.Loading || downloadCvrState == LoadingState.Loading ?
                <ElementLoading position="absolute"></ElementLoading> : null}
              <Bar ref={chartRef} data={chartData} options={options}></Bar>
            </>} </div>
      </Paper>
      </>)
}
