import { Button, Grid, List, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { EventRemoteType, VehicleInformationReportData, VehicleLeasingData } from "../../../../lib-api";
import { useHelper } from "../../../../hooks/helper";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import useApi from "../../../../hooks/api";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/auth";
import { InputField } from "../../../shared/forms/InputField";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  }
}));

export const VehicleInfo: React.FC<{
  vehicleInformation?: VehicleInformationReportData[] | null;
  make?: string;
  model?: string
}> = (props) => {

  const { getRemoteSourceFromEnum } = useHelper();
  const { adminReportApi } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { isAdmin } = useAuth();
  const classes = useStyles();

  return <>
    {props.vehicleInformation?.length == 1 &&
      props.vehicleInformation.find(p => p.remoteType == EventRemoteType.CarVertical) ?
      <Grid item xs={12} sm={6}></Grid>
      :
      null
    }
    {props.vehicleInformation?.map((info, index) =>
      <Grid key={index} item xs={12} sm={6}>
        <Typography variant="h5">
          Info from {getRemoteSourceFromEnum(info.remoteType)}
        </Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{
            make: info.make,
            model: info.model,
            variant: info.variant,
            year: info.modelYear,
            type: info.vehicleType,
            color: info.color
          }}
          validationSchema={object({
            make: string(),
            model: string(),
            variant: string(),
            color: string(),
            type: string(),
            year: string()
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              await adminReportApi.apiAdminReportEditVehicleDataPost({
                adminEditVehicleDataRequest: {
                  vehicleInformationReportDataId: info.id,
                  make: values.make,
                  model: values.model,
                  year: values.year,
                  type: values.type,
                  color: values.color,
                  variant: values.variant
                }
              });
              enqueueSnackbar("Data has been updated!", { variant: "success" });
              setSubmitting(false);
            } catch (error) {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <List>
                <ListItem>
                  {isAdmin() ?
                    <InputField
                      name="make"
                      type="text"
                      placeholder="Make" />
                    :
                    <ListItemText primary="Make" secondary={info.make == "" || !info.make ? "Unknown" : info.make}></ListItemText>}

                  {isAdmin() ?
                    <InputField
                      name="model"
                      type="text"
                      placeholder="Model" />
                    :
                    <ListItemText primary="Model" secondary={info.model == "" || !info.model ? "Unknown" : info.model}></ListItemText>}

                  {isAdmin() ?
                    <InputField
                      name="color"
                      type="text"
                      placeholder="Color" />
                    :
                    <ListItemText primary="Color" secondary={info.color == "" || !info.color ? "Unknown" : info.color}></ListItemText>}
                </ListItem>
                <ListItem>
                  {isAdmin() ?
                    <InputField
                      name="year"
                      type="text"
                      placeholder="Model year" />
                    :
                    <ListItemText primary="Model year" secondary={info.modelYear == "" || !info.modelYear ? "Unknown" : info.modelYear}></ListItemText>}
                  {isAdmin() ?
                    <InputField
                      name="variant"
                      type="text"
                      placeholder="Variant" />
                    :
                    <ListItemText primary="Variant" secondary={info.variant == "" || !info.variant ? "Unknown" : info.variant}></ListItemText>}
                  {isAdmin() ?
                    <InputField
                      name="type"
                      type="text"
                      placeholder="Type" />
                    :
                    <ListItemText primary="Type" secondary={info.vehicleType == "" || !info.vehicleType ? "Unknown" : info.vehicleType}></ListItemText>}
                </ListItem>
              </List>
              {isAdmin() ? <div className={classes.end}>
                <Button color="primary" type="submit">
                  {isSubmitting ? "Updating make/model..." : "Update make/model"}
                </Button>
              </div> : null}
            </Form>
          )}
        </Formik>
      </Grid>
    )}
  </>
};
