import { useState, useEffect } from "react";
import { PackageDto, TeamDto, TeamSort } from "../lib-api/models";
import { LoadingState } from "../hooks/loading";
import useApi from "../hooks/api";
import { useQueryDelay } from "../hooks/queryDelay";

export function useAdminTeam(page: number, amount: number, sortMode: TeamSort, searchQuery: string, packageName: string) {
  const [state, setState] = useState(LoadingState.None);
  const [results, setResults] = useState<
    TeamDto[]
  >([]);
  const { delay } = useQueryDelay(searchQuery);
  const [totalTeams, setTotalTeams] = useState<number|undefined>(undefined);
  const [packages, setPackages] = useState<PackageDto[] | null | undefined>([]);

  const { adminUserApi } = useApi();

  const load = async () => {
    setState(LoadingState.Loading);

    try {
      const searchResult = await adminUserApi.apiAdminUserAllteamsPost({
        adminSearchTeamsRequest: {
          page: page,
          pageSize: amount,
          sortMode: sortMode,
          searchQuery: searchQuery,
          packageName: packageName
        }
      });
      setResults(searchResult.all);
      setTotalTeams(searchResult.totalCount);
      setPackages(searchResult.packages);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    load();
  }, [page, amount, sortMode, delay, packageName]);

  return {
    results: results,
    totalTeams: totalTeams,
    searchState: state,
    packages: packages,
    reload: load
  };
}
