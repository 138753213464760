/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PackageDto,
    PackageDtoFromJSON,
    PackageDtoFromJSONTyped,
    PackageDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountTeamDto
 */
export interface AccountTeamDto {
    /**
     * 
     * @type {string}
     * @memberof AccountTeamDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountTeamDto
     */
    name?: string | null;
    /**
     * 
     * @type {PackageDto}
     * @memberof AccountTeamDto
     */
    _package?: PackageDto;
}

export function AccountTeamDtoFromJSON(json: any): AccountTeamDto {
    return AccountTeamDtoFromJSONTyped(json, false);
}

export function AccountTeamDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountTeamDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        '_package': !exists(json, 'package') ? undefined : PackageDtoFromJSON(json['package']),
    };
}

export function AccountTeamDtoToJSON(value?: AccountTeamDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'package': PackageDtoToJSON(value._package),
    };
}


