import { useField } from "formik";
import { FormControl, FormControlProps, InputLabel, Tooltip } from "@material-ui/core";
import React from "react";
import {CustomTextField} from "../forms/CustomTextField";

export const InputField: React.FC<
  {
    name: string;
    placeholder: string;
    type: string;
    tooltip?: string;
    label?: string;
    textarea?: number;
  } & FormControlProps
> = (props) => {
  const [field, meta] = useField(props.name);
  return (
    <>
    <Tooltip title={props.tooltip ?? ""}>
      <FormControl>
        <InputLabel shrink htmlFor={field.name}>{props.label?.toUpperCase() ?? props.placeholder.toUpperCase()}</InputLabel>
      <CustomTextField
        name={field.name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value == undefined ? "" : field.value}
        type={props.type}
        placeholder={props.placeholder}
        variant="outlined"
        size="small"
        multiline={props.textarea != undefined}
        rows={props.textarea != undefined ? props.textarea : 1}
      />
      </FormControl>
      </Tooltip>
      <p>{meta.touched && meta.error ? meta.error : null}</p>
    </>
  );
};
