import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router";
import { object, string } from "yup";
import useApi from "../../../hooks/api";
import { CreateRequest } from "../../../lib-api";
import { InputField } from "../../shared/forms/InputField";
import { Breadcrumb } from "../../shared/other/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end",
  },
  form: theme.form,
}));

const AddMemberPage = () => {
  const { userApi } = useApi();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item sm={3}></Grid>
      <Grid item sm={9}>
        <Breadcrumb currentPath="Add member"></Breadcrumb>
      </Grid>
      <Grid item sm={3}></Grid>
      <Grid item sm={6}>
        <Paper>
          <Typography variant="h5">Add member</Typography>
          <Formik
            enableReinitialize={true}
            initialValues={{
              newName: "",
              newEmail: "",
              newPassword: "",
            }}
            validationSchema={object({
              newName: string().required("No name provided"),
              newPassword: string()
                .required("No password provided")
                .min(14, "Password is too short - min 14 chars"),
              newEmail: string()
                .required("No email provided")
                .email("Not a valid email"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              var request: CreateRequest = {
                name: values.newName,
                password: values.newPassword,
                email: values.newEmail,
              };
              try {
                const response = await userApi.apiUserCreatePost({
                  createRequest: request,
                });
                //update members
                enqueueSnackbar(`${response.screenName} added!`, {
                  variant: "success",
                });
                history.push(`/myaccount`);
                resetForm();
                setSubmitting(false);
              } catch (error) {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className={classes.form}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputField
                      name="newName"
                      type="text"
                      placeholder="Name"
                    ></InputField>
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      name="newEmail"
                      type="email"
                      placeholder="Email"
                    ></InputField>
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      name="newPassword"
                      type="text"
                      placeholder="Password"
                    ></InputField>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.end}>
                      <Button color="primary" type="submit">
                        {isSubmitting ? "Adding new member..." : "Add"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AddMemberPage;
