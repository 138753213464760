/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportSort {
    Default = 'Default',
    Vin = 'Vin',
    VinDesc = 'VinDesc',
    Score = 'Score',
    ScoreDesc = 'ScoreDesc',
    DateComplete = 'DateComplete',
    DateCompleteDesc = 'DateCompleteDesc',
    DateOrder = 'DateOrder',
    DateOrderDesc = 'DateOrderDesc',
    GeneratedBy = 'GeneratedBy',
    GeneratedByDesc = 'GeneratedByDesc'
}

export function ReportSortFromJSON(json: any): ReportSort {
    return ReportSortFromJSONTyped(json, false);
}

export function ReportSortFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportSort {
    return json as ReportSort;
}

export function ReportSortToJSON(value?: ReportSort | null): any {
    return value as any;
}

