/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EventType {
    Registration = 'Registration',
    InspectionReport = 'InspectionReport',
    SalesAd = 'SalesAd',
    InsuranceStatus = 'InsuranceStatus',
    WantedStatus = 'WantedStatus',
    SearchAgentResult = 'SearchAgentResult',
    TaxTransaction = 'TaxTransaction',
    BankRuptcy = 'BankRuptcy',
    Loan = 'Loan',
    Manufactured = 'Manufactured',
    Accident = 'Accident',
    Other = 'Other',
    Ownership = 'Ownership',
    Maintenance = 'Maintenance',
    Assessment = 'Assessment',
    RegistrationRestriction = 'RegistrationRestriction',
    MileageReading = 'MileageReading',
    ManufacturedDontShow = 'Manufactured_dont_show',
    InspectionReportDontShow = 'InspectionReport_dont_show',
    RegistrationDontShow = 'Registration_dont_show',
    InspectionUpcomming = 'Inspection_upcomming',
    SocialMediaPost = 'Social_media_post'
}

export function EventTypeFromJSON(json: any): EventType {
    return EventTypeFromJSONTyped(json, false);
}

export function EventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventType {
    return json as EventType;
}

export function EventTypeToJSON(value?: EventType | null): any {
    return value as any;
}

