/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProjectSort {
    Default = 'Default',
    Name = 'Name',
    NameDesc = 'NameDesc'
}

export function ProjectSortFromJSON(json: any): ProjectSort {
    return ProjectSortFromJSONTyped(json, false);
}

export function ProjectSortFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSort {
    return json as ProjectSort;
}

export function ProjectSortToJSON(value?: ProjectSort | null): any {
    return value as any;
}

