import React, { useEffect, useState } from "react";
import { useSearchAgents } from "../../../../hooks/searchAgents";
import { useTeamInfo } from "../../../../hooks/teamInfo";
import { CustomDataGrid } from "../../../shared/datagrid/CustomDataGrid";
import { CustomSwitch } from "../../../shared/forms/CustomSwitch";
import { DeletionIcon } from "../../../shared/other/Icons";
import { ColDef, RowParams, RowsProp, ValueFormatterParams } from "@material-ui/data-grid";
import { SearchAgentDto } from "../../../../lib-api";
import { useHistory } from "react-router";
import useApi from "../../../../hooks/api";
import { useWindowSize } from "../../../../hooks/windowSize";

function MonitorLookalikesTab() {
  const [searchQuery, setSearchQuery] = useState("");
  const [onlyActive, setActive] = useState(false);
  const [onlyWanted] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchAmount] = useState(10);
  const {
    searchAgents,
    searchAgentState,
    deleteSearchAgent
  } = useSearchAgents(
    searchQuery,
    onlyWanted,
    onlyActive,
    pageNumber,
    searchAmount
  );

  const { incrementActiveSearchAgentCount, decrementActiveSearchAgentCount } = useTeamInfo();
  const { searchAgentApi } = useApi();
  const [rows, setRows] = useState<RowsProp>([]);
  const [actives, setActives] = useState<Array<{ id: number, value: boolean }>>([]);
  const history = useHistory();
  const {xsDown} = useWindowSize();

  useEffect(() => {
    setActives(searchAgents.all.map((agent) => { return { id: agent.searchAgentMeta?.id!, value: agent.searchAgentMeta?.active! } }));
    setRows(searchAgents.all.length !== 0 ? (
      searchAgents.all.map((agent, index) => {
        return {
          id: index,
          first: agent,
          col4: agent.searchAgentMeta?.name,
          col1: agent.chassisNumber,
          col2: agent.regNr,
          col3: `${agent.make ?? ""} ${agent.model ?? ""} ${agent.type ?? ""}`,
          col7: agent.parallelImported,
          col8: agent.currentlyLeased,
          col9: agent.creatorName,
          col10: agent
        }
      })) : []);
  }, [searchAgents.all]);

  const handleCheckboxClick = async (event: any, params: ValueFormatterParams) => {
    var checked = event.target.checked;
    try {
      await searchAgentApi.apiSearchAgentUpdatePost({
        updateSearchAgentActivity: {
          searchAgentId: (params.value as SearchAgentDto).id,
          value: checked
        }
      });
      var temp = actives.filter(p => p.id != (params.value as SearchAgentDto).searchAgentMeta?.id);
      temp.push({ value: checked, id: (params.value as SearchAgentDto).searchAgentMeta?.id! });
      setActives(temp);

      if (checked) {
        incrementActiveSearchAgentCount();
      } else {
        decrementActiveSearchAgentCount();
      }
    } catch {
    }
  }

  const handleDelete = async (params: ValueFormatterParams) => {
    var data = (params.value as SearchAgentDto);
    try {
      await deleteSearchAgent(data.id!);
      if (data.searchAgentMeta?.active) {
        decrementActiveSearchAgentCount();
      }
    } catch {
    }
  }

  const columns: ColDef[] = [
    { field: 'col4', headerName: 'AGENT TITLE', sortable: false, flex: 3 },
    { field: 'col3', headerName: 'VEHICLE', sortable: false, flex: 4, hide: xsDown },
    { field: 'col7', headerName: 'IMPORTED', sortable: false, hide: xsDown, flex: 2, valueFormatter: (params: ValueFormatterParams) => (params.value as boolean) ? "Yes" : "No" },
    { field: 'col8', headerName: 'LEASED', sortable: false, hide: xsDown, flex: 2, valueFormatter: (params: ValueFormatterParams) => (params.value as boolean) ? "Yes" : "No" },
    { field: 'col9', headerName: 'CREATOR', sortable: false, hide: xsDown, flex: 3 },
    {
      field: 'first', headerName: 'MONITOR', renderCell: (params: ValueFormatterParams) => (<>
        <CustomSwitch color="primary" checked={actives.find(p => p.id == (params.value as SearchAgentDto).searchAgentMeta?.id)?.value}
          onClick={async (event) => {
            handleCheckboxClick(event, params);
          }}></CustomSwitch>
      </>), sortable: false, flex: 2, disableClickEventBubbling: true
    },
    {
      field: 'col10', headerName: ' ',
      renderCell: (params: ValueFormatterParams) => (<>
        <DeletionIcon hoverText="Delete search agent"
          onClick={(event) => {
            handleDelete(params);
          }}></DeletionIcon>
      </>), sortable: false, flex: 1, disableClickEventBubbling: true
    }
  ];

  return (
    <>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        pageSize={searchAmount}
        page={pageNumber}
        setPage={setPageNumber}
        loadingState={searchAgentState}
        totalRows={searchAgents.totalCount}
        //handleSortModelChange={(params: SortModelParams) => handleSortModelChange(params.sortModel[0])}
        onRowClick={(row: RowParams) => history.push(`/monitoring/searchagents/${row.row["first"].id}`)}
        dataDisplayText="search agents"
        headerProps={{
          filters: [
            {
              name: "Search",
              onChange: (event) => setSearchQuery(event.currentTarget.value),
              type: "search",
              position: "left",
            },
            {
              name: "Show only monitored",
              position: "left",
              onChange: (event) => setActive(event.currentTarget.checked),
              type: "bool"
            },
            {
              name: "+ Create a new search agent",
              link: "/monitoring/searchagents/createsearch",
              type: "link",
              position: "right",
            }
          ],
          isTab: true
        }}
      ></CustomDataGrid>
    </>
  );
}

export default MonitorLookalikesTab;
