import {
  AuthApi,
  PasswordSignInRequest,
} from "../lib-api";
import useMiddleware from "./middleware";
import useApi, { defaultApiConfiguration } from "./api";
import { trackGaEvent } from "../util/gtm";

const useAuth = () => {
  const {state, dispatch} = useMiddleware();
  const {authApi} = useApi();

  const setCurrentTeam = async(teamId: string) => {
    const refreshResponse = await authApi.apiAuthRefreshPost({
      refreshRequest: {
        token: state.authInfo!.token!,
        refreshToken: state.authInfo!.refreshToken!,
        teamId: teamId
      },
    });
    dispatch({ type: "login", authInfo: refreshResponse});
  }
  return {
    isLoggedIn: () => {
      return state.authInfo != null;
    },
    isAdmin: () => {
      return state.authInfo != null && state.authInfo.roles != null && state.authInfo.roles.includes("Admin");
    },
    isTeamOwner: () => {
      return state.authInfo != null && state.authInfo.roles != null && state.authInfo.roles.includes("TeamOwner");
    },
    canWrite: () => {
      return state.authInfo != null && state.authInfo.roles != null && state.authInfo.roles.includes("Write");
    },
    canAccessGold: () => {
      return state.authInfo != null && state.authInfo.roles != null && state.authInfo.roles.includes("Gold");
    },
    canAccessSilver: () => {
      return state.authInfo != null && state.authInfo.roles != null && (state.authInfo.roles.includes("Silver") || state.authInfo.roles.includes("Gold"));
    },
    canAccessBronze: () => {
      return state.authInfo != null && state.authInfo.roles != null && (state.authInfo.roles.includes("Bronze") || state.authInfo.roles.includes("Silver") || state.authInfo.roles.includes("Gold"));
    },
    authInfo: state.authInfo,
    login: async (creds: PasswordSignInRequest) => {
      const authApi = new AuthApi(defaultApiConfiguration);
      const response = await authApi.apiAuthPasswordsigninPost({
        passwordSignInRequest: creds,
      });
      trackGaEvent("Login", [{name: "Team_name", value: response?.user?.teamName}, {name: "User", value: response?.user?.screenName}]);
      dispatch({ type: "login", authInfo: response});
    },
    user: state.authInfo?.user,
    logout: () => {
      dispatch({ type: "logout" });
    },
    setCurrentTeam
  };
};

export default useAuth;
