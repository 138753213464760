/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminConnectToTeamsRequest,
    AdminConnectToTeamsRequestFromJSON,
    AdminConnectToTeamsRequestToJSON,
    AdminCreateTeamRequest,
    AdminCreateTeamRequestFromJSON,
    AdminCreateTeamRequestToJSON,
    AdminCreateUserRequest,
    AdminCreateUserRequestFromJSON,
    AdminCreateUserRequestToJSON,
    AdminGetTeamRequest,
    AdminGetTeamRequestFromJSON,
    AdminGetTeamRequestToJSON,
    AdminSearchTeamsRequest,
    AdminSearchTeamsRequestFromJSON,
    AdminSearchTeamsRequestToJSON,
    AdminSearchUsersRequest,
    AdminSearchUsersRequestFromJSON,
    AdminSearchUsersRequestToJSON,
    AdminUpdatePackageRequest,
    AdminUpdatePackageRequestFromJSON,
    AdminUpdatePackageRequestToJSON,
    AdminUpdatePermissionsRequest,
    AdminUpdatePermissionsRequestFromJSON,
    AdminUpdatePermissionsRequestToJSON,
    AdminUpdateTeamNameRequest,
    AdminUpdateTeamNameRequestFromJSON,
    AdminUpdateTeamNameRequestToJSON,
    AdminUpdateTeamOwnerRequest,
    AdminUpdateTeamOwnerRequestFromJSON,
    AdminUpdateTeamOwnerRequestToJSON,
    DeleteRequest,
    DeleteRequestFromJSON,
    DeleteRequestToJSON,
    PackageDto,
    PackageDtoFromJSON,
    PackageDtoToJSON,
    TeamDto,
    TeamDtoFromJSON,
    TeamDtoToJSON,
    TeamMemberDto,
    TeamMemberDtoFromJSON,
    TeamMemberDtoToJSON,
    TeamsResponse,
    TeamsResponseFromJSON,
    TeamsResponseToJSON,
    UpdateRequest,
    UpdateRequestFromJSON,
    UpdateRequestToJSON,
    UsersResponse,
    UsersResponseFromJSON,
    UsersResponseToJSON,
} from '../models';

export interface ApiAdminUserAddToTeamsPutRequest {
    adminConnectToTeamsRequest?: AdminConnectToTeamsRequest;
}

export interface ApiAdminUserAllteamsPostRequest {
    adminSearchTeamsRequest?: AdminSearchTeamsRequest;
}

export interface ApiAdminUserAllusersPostRequest {
    adminSearchUsersRequest?: AdminSearchUsersRequest;
}

export interface ApiAdminUserCreateandconnectPostRequest {
    adminCreateUserRequest?: AdminCreateUserRequest;
}

export interface ApiAdminUserCreateteamPostRequest {
    adminCreateTeamRequest?: AdminCreateTeamRequest;
}

export interface ApiAdminUserDeleteDeleteRequest {
    deleteRequest?: DeleteRequest;
}

export interface ApiAdminUserGetteamPostRequest {
    adminGetTeamRequest?: AdminGetTeamRequest;
}

export interface ApiAdminUserUpdatepackagePutRequest {
    adminUpdatePackageRequest?: AdminUpdatePackageRequest;
}

export interface ApiAdminUserUpdatepermissionsPutRequest {
    adminUpdatePermissionsRequest?: AdminUpdatePermissionsRequest;
}

export interface ApiAdminUserUpdateteamnamePostRequest {
    adminUpdateTeamNameRequest?: AdminUpdateTeamNameRequest;
}

export interface ApiAdminUserUpdateteamownerPutRequest {
    adminUpdateTeamOwnerRequest?: AdminUpdateTeamOwnerRequest;
}

export interface ApiAdminUserUpdateuserPutRequest {
    updateRequest?: UpdateRequest;
}

/**
 * 
 */
export class AdminUserApi extends runtime.BaseAPI {

    /**
     */
    async apiAdminUserAddToTeamsPutRaw(requestParameters: ApiAdminUserAddToTeamsPutRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/AddToTeams`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminConnectToTeamsRequestToJSON(requestParameters.adminConnectToTeamsRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminUserAddToTeamsPut(requestParameters: ApiAdminUserAddToTeamsPutRequest): Promise<string> {
        const response = await this.apiAdminUserAddToTeamsPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminUserAllpackagesPostRaw(): Promise<runtime.ApiResponse<Array<PackageDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/user/allpackages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PackageDtoFromJSON));
    }

    /**
     */
    async apiAdminUserAllpackagesPost(): Promise<Array<PackageDto>> {
        const response = await this.apiAdminUserAllpackagesPostRaw();
        return await response.value();
    }

    /**
     */
    async apiAdminUserAllteamsPostRaw(requestParameters: ApiAdminUserAllteamsPostRequest): Promise<runtime.ApiResponse<TeamsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/allteams`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminSearchTeamsRequestToJSON(requestParameters.adminSearchTeamsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamsResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminUserAllteamsPost(requestParameters: ApiAdminUserAllteamsPostRequest): Promise<TeamsResponse> {
        const response = await this.apiAdminUserAllteamsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminUserAllusersPostRaw(requestParameters: ApiAdminUserAllusersPostRequest): Promise<runtime.ApiResponse<UsersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/allusers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminSearchUsersRequestToJSON(requestParameters.adminSearchUsersRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminUserAllusersPost(requestParameters: ApiAdminUserAllusersPostRequest): Promise<UsersResponse> {
        const response = await this.apiAdminUserAllusersPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminUserCreateandconnectPostRaw(requestParameters: ApiAdminUserCreateandconnectPostRequest): Promise<runtime.ApiResponse<TeamMemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/createandconnect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminCreateUserRequestToJSON(requestParameters.adminCreateUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminUserCreateandconnectPost(requestParameters: ApiAdminUserCreateandconnectPostRequest): Promise<TeamMemberDto> {
        const response = await this.apiAdminUserCreateandconnectPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminUserCreateteamPostRaw(requestParameters: ApiAdminUserCreateteamPostRequest): Promise<runtime.ApiResponse<TeamDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/createteam`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminCreateTeamRequestToJSON(requestParameters.adminCreateTeamRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminUserCreateteamPost(requestParameters: ApiAdminUserCreateteamPostRequest): Promise<TeamDto> {
        const response = await this.apiAdminUserCreateteamPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminUserDeleteDeleteRaw(requestParameters: ApiAdminUserDeleteDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteRequestToJSON(requestParameters.deleteRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAdminUserDeleteDelete(requestParameters: ApiAdminUserDeleteDeleteRequest): Promise<void> {
        await this.apiAdminUserDeleteDeleteRaw(requestParameters);
    }

    /**
     */
    async apiAdminUserGetteamPostRaw(requestParameters: ApiAdminUserGetteamPostRequest): Promise<runtime.ApiResponse<TeamDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/getteam`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminGetTeamRequestToJSON(requestParameters.adminGetTeamRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminUserGetteamPost(requestParameters: ApiAdminUserGetteamPostRequest): Promise<TeamDto> {
        const response = await this.apiAdminUserGetteamPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminUserUpdatepackagePutRaw(requestParameters: ApiAdminUserUpdatepackagePutRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/Updatepackage`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUpdatePackageRequestToJSON(requestParameters.adminUpdatePackageRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminUserUpdatepackagePut(requestParameters: ApiAdminUserUpdatepackagePutRequest): Promise<string> {
        const response = await this.apiAdminUserUpdatepackagePutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminUserUpdatepermissionsPutRaw(requestParameters: ApiAdminUserUpdatepermissionsPutRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/Updatepermissions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUpdatePermissionsRequestToJSON(requestParameters.adminUpdatePermissionsRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminUserUpdatepermissionsPut(requestParameters: ApiAdminUserUpdatepermissionsPutRequest): Promise<string> {
        const response = await this.apiAdminUserUpdatepermissionsPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminUserUpdateteamnamePostRaw(requestParameters: ApiAdminUserUpdateteamnamePostRequest): Promise<runtime.ApiResponse<TeamDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/updateteamname`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUpdateTeamNameRequestToJSON(requestParameters.adminUpdateTeamNameRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminUserUpdateteamnamePost(requestParameters: ApiAdminUserUpdateteamnamePostRequest): Promise<TeamDto> {
        const response = await this.apiAdminUserUpdateteamnamePostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminUserUpdateteamownerPutRaw(requestParameters: ApiAdminUserUpdateteamownerPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/Updateteamowner`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUpdateTeamOwnerRequestToJSON(requestParameters.adminUpdateTeamOwnerRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAdminUserUpdateteamownerPut(requestParameters: ApiAdminUserUpdateteamownerPutRequest): Promise<void> {
        await this.apiAdminUserUpdateteamownerPutRaw(requestParameters);
    }

    /**
     */
    async apiAdminUserUpdateuserPutRaw(requestParameters: ApiAdminUserUpdateuserPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/admin/user/Updateuser`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRequestToJSON(requestParameters.updateRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAdminUserUpdateuserPut(requestParameters: ApiAdminUserUpdateuserPutRequest): Promise<void> {
        await this.apiAdminUserUpdateuserPutRaw(requestParameters);
    }

}
