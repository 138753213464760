import React from "react";
import AppRouter from "./Router";
import { ThemeProvider } from "@material-ui/core";
import { AuthStateContextProvider } from "./state/auth";
import { StatisticsStateContextProvider } from "./state/statistics";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import theme from "./theme";
import { SnackbarProvider } from 'notistack';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthStateContextProvider>
        <StatisticsStateContextProvider>
          <SnackbarProvider maxSnack={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <AppRouter />
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </StatisticsStateContextProvider>
      </AuthStateContextProvider>
    </ThemeProvider>
  );
};

export default App;
