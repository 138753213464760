/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserSort,
    UserSortFromJSON,
    UserSortFromJSONTyped,
    UserSortToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminSearchUsersRequest
 */
export interface AdminSearchUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminSearchUsersRequest
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminSearchUsersRequest
     */
    pageSize?: number;
    /**
     * 
     * @type {UserSort}
     * @memberof AdminSearchUsersRequest
     */
    sortMode?: UserSort;
    /**
     * 
     * @type {string}
     * @memberof AdminSearchUsersRequest
     */
    teamId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSearchUsersRequest
     */
    searchQuery?: string | null;
}

export function AdminSearchUsersRequestFromJSON(json: any): AdminSearchUsersRequest {
    return AdminSearchUsersRequestFromJSONTyped(json, false);
}

export function AdminSearchUsersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminSearchUsersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'sortMode': !exists(json, 'sortMode') ? undefined : UserSortFromJSON(json['sortMode']),
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
        'searchQuery': !exists(json, 'searchQuery') ? undefined : json['searchQuery'],
    };
}

export function AdminSearchUsersRequestToJSON(value?: AdminSearchUsersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'sortMode': UserSortToJSON(value.sortMode),
        'teamId': value.teamId,
        'searchQuery': value.searchQuery,
    };
}


