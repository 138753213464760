import React from "react";
import {
  Typography,
  makeStyles,
  IconButton,
  Menu,
  MenuItem,
  Fade,
} from "@material-ui/core";
import useAuth from "../../hooks/auth";
import { NavLink } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(4),
  },
  title: {
    flexGrow: 1,
    color: "#FFF",
    textDecoration: "none",
  },
  container: {
    padding: theme.spacing(3)
  },
  faceIcon: {
    marginLeft: theme.spacing(0),
    color: "white"
  },
  link: theme.link
}));

const HeaderCollapsed: React.FC = (props) => {
  const classes = useStyles();
  const { isAdmin } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton className={classes.faceIcon} edge="start" onClick={handleClick} aria-label="menu">
        <MenuIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        TransitionComponent={Fade}
      >
        {isAdmin() ? <MenuItem onClick={handleClose}>
          <NavLink className={classes.menuButton + " " + classes.link} to="/admin">
            <Typography variant="button">
              ADMIN
                </Typography>
          </NavLink>
        </MenuItem> : null}
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.menuButton + " " + classes.link} to="/reports">
            <Typography variant="button">
              REPORTS
                </Typography>
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.menuButton + " " + classes.link} to="/monitoring">
            <Typography variant="button">
              MONITORING
                </Typography>
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.menuButton + " " + classes.link} to="/search">
            <Typography variant="button">
              SEARCH
                </Typography>
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.menuButton + " " + classes.link} to="/statistics">
            <Typography variant="button">
              STATISTICS
                </Typography>
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Typography variant="button">
            Close
                </Typography>
        </MenuItem>
      </Menu>
      {/* <Button color="secondary" onClick={() => logout()}>
                Logout
              </Button> */}
    </>
  );
};

export default HeaderCollapsed;
