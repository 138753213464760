import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Tabs,
  Tab,
} from "@material-ui/core";
import useAuth from "../../hooks/auth";
import { useHistory } from "react-router-dom";
import { a11yProps } from "./tabs/TabPanel";

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginLeft: theme.spacing(3)
  }
}));

const Header: React.FC = (props) => {
  const { isAdmin } = useAuth();
  const history = useHistory();
  const classes = useStyles();

  const getInitValue = () => {
    if (history.location.pathname.startsWith("/reports"))
      return 0;
    if (history.location.pathname.startsWith("/monitoring"))
      return 1;
    if (history.location.pathname.startsWith("/search"))
      return 2;
    if (history.location.pathname.startsWith("/statistics"))
      return 3;
    if (history.location.pathname.startsWith("/admin"))
      return 4;
    if (history.location.pathname.startsWith("/myaccount"))
      return -1;

      return 0;
  }

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    setTabValue(getInitValue());
  }, [history.location.pathname])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  }

  return (
    <Tabs
      className={classes.tabs}
      indicatorColor="secondary"
      textColor="secondary"
      value={tabValue}
      variant="standard"
      onChange={handleChange}
    >
      <Tab onClick={() => history.push("/reports")} label="REPORTS" {...a11yProps(0)} />
      <Tab onClick={() => history.push("/monitoring")} label="MONITORING" {...a11yProps(1)} />
      <Tab onClick={() => history.push("/search")} label="SEARCH" {...a11yProps(2)} />
      <Tab onClick={() => history.push("/statistics")} label="STATISTICS" {...a11yProps(3)} />
      {isAdmin() ? <Tab onClick={() => history.push("/admin")} label="ADMIN" {...a11yProps(4)} /> : null}
    </Tabs>
  );
};

export default Header;
