/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ToldsynRequest,
    ToldsynRequestFromJSON,
    ToldsynRequestFromJSONTyped,
    ToldsynRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChartToPdfRequest
 */
export interface ChartToPdfRequest {
    /**
     * 
     * @type {ToldsynRequest}
     * @memberof ChartToPdfRequest
     */
    params?: ToldsynRequest;
    /**
     * 
     * @type {string}
     * @memberof ChartToPdfRequest
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChartToPdfRequest
     */
    imageString?: string | null;
}

export function ChartToPdfRequestFromJSON(json: any): ChartToPdfRequest {
    return ChartToPdfRequestFromJSONTyped(json, false);
}

export function ChartToPdfRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChartToPdfRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'params': !exists(json, 'params') ? undefined : ToldsynRequestFromJSON(json['params']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'imageString': !exists(json, 'imageString') ? undefined : json['imageString'],
    };
}

export function ChartToPdfRequestToJSON(value?: ChartToPdfRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'params': ToldsynRequestToJSON(value.params),
        'title': value.title,
        'imageString': value.imageString,
    };
}


