/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChartToPdfRequest,
    ChartToPdfRequestFromJSON,
    ChartToPdfRequestToJSON,
    ChartVinsToCsvRequest,
    ChartVinsToCsvRequestFromJSON,
    ChartVinsToCsvRequestToJSON,
    GetChartRequest,
    GetChartRequestFromJSON,
    GetChartRequestToJSON,
    InitialGraphData,
    InitialGraphDataFromJSON,
    InitialGraphDataToJSON,
    ToldsynByCvrResult,
    ToldsynByCvrResultFromJSON,
    ToldsynByCvrResultToJSON,
    ToldsynByLeasingYearEndResult,
    ToldsynByLeasingYearEndResultFromJSON,
    ToldsynByLeasingYearEndResultToJSON,
    ToldsynByMakeResult,
    ToldsynByMakeResultFromJSON,
    ToldsynByMakeResultToJSON,
    ToldsynByModelYearResult,
    ToldsynByModelYearResultFromJSON,
    ToldsynByModelYearResultToJSON,
    ToldsynByStateResult,
    ToldsynByStateResultFromJSON,
    ToldsynByStateResultToJSON,
    ToldsynByYearResult,
    ToldsynByYearResultFromJSON,
    ToldsynByYearResultToJSON,
} from '../models';

export interface ApiStatisticsCsvVinPostRequest {
    chartVinsToCsvRequest?: ChartVinsToCsvRequest;
}

export interface ApiStatisticsCvrPostRequest {
    getChartRequest?: GetChartRequest;
}

export interface ApiStatisticsLeasingyearPostRequest {
    getChartRequest?: GetChartRequest;
}

export interface ApiStatisticsMakePostRequest {
    getChartRequest?: GetChartRequest;
}

export interface ApiStatisticsModelyearPostRequest {
    getChartRequest?: GetChartRequest;
}

export interface ApiStatisticsPdfPostRequest {
    chartToPdfRequest?: ChartToPdfRequest;
}

export interface ApiStatisticsStatePostRequest {
    getChartRequest?: GetChartRequest;
}

export interface ApiStatisticsYearPostRequest {
    getChartRequest?: GetChartRequest;
}

/**
 * 
 */
export class StatisticsApi extends runtime.BaseAPI {

    /**
     */
    async apiStatisticsCsvVinPostRaw(requestParameters: ApiStatisticsCsvVinPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Statistics/csv-vin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChartVinsToCsvRequestToJSON(requestParameters.chartVinsToCsvRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiStatisticsCsvVinPost(requestParameters: ApiStatisticsCsvVinPostRequest): Promise<string> {
        const response = await this.apiStatisticsCsvVinPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiStatisticsCvrPostRaw(requestParameters: ApiStatisticsCvrPostRequest): Promise<runtime.ApiResponse<Array<ToldsynByCvrResult>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Statistics/cvr`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetChartRequestToJSON(requestParameters.getChartRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ToldsynByCvrResultFromJSON));
    }

    /**
     */
    async apiStatisticsCvrPost(requestParameters: ApiStatisticsCvrPostRequest): Promise<Array<ToldsynByCvrResult>> {
        const response = await this.apiStatisticsCvrPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiStatisticsInitValuesPostRaw(): Promise<runtime.ApiResponse<InitialGraphData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Statistics/InitValues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InitialGraphDataFromJSON(jsonValue));
    }

    /**
     */
    async apiStatisticsInitValuesPost(): Promise<InitialGraphData> {
        const response = await this.apiStatisticsInitValuesPostRaw();
        return await response.value();
    }

    /**
     */
    async apiStatisticsLeasingyearPostRaw(requestParameters: ApiStatisticsLeasingyearPostRequest): Promise<runtime.ApiResponse<Array<ToldsynByLeasingYearEndResult>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Statistics/leasingyear`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetChartRequestToJSON(requestParameters.getChartRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ToldsynByLeasingYearEndResultFromJSON));
    }

    /**
     */
    async apiStatisticsLeasingyearPost(requestParameters: ApiStatisticsLeasingyearPostRequest): Promise<Array<ToldsynByLeasingYearEndResult>> {
        const response = await this.apiStatisticsLeasingyearPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiStatisticsMakePostRaw(requestParameters: ApiStatisticsMakePostRequest): Promise<runtime.ApiResponse<Array<ToldsynByMakeResult>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Statistics/make`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetChartRequestToJSON(requestParameters.getChartRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ToldsynByMakeResultFromJSON));
    }

    /**
     */
    async apiStatisticsMakePost(requestParameters: ApiStatisticsMakePostRequest): Promise<Array<ToldsynByMakeResult>> {
        const response = await this.apiStatisticsMakePostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiStatisticsModelyearPostRaw(requestParameters: ApiStatisticsModelyearPostRequest): Promise<runtime.ApiResponse<Array<ToldsynByModelYearResult>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Statistics/modelyear`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetChartRequestToJSON(requestParameters.getChartRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ToldsynByModelYearResultFromJSON));
    }

    /**
     */
    async apiStatisticsModelyearPost(requestParameters: ApiStatisticsModelyearPostRequest): Promise<Array<ToldsynByModelYearResult>> {
        const response = await this.apiStatisticsModelyearPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiStatisticsPdfPostRaw(requestParameters: ApiStatisticsPdfPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Statistics/pdf`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChartToPdfRequestToJSON(requestParameters.chartToPdfRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiStatisticsPdfPost(requestParameters: ApiStatisticsPdfPostRequest): Promise<string> {
        const response = await this.apiStatisticsPdfPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiStatisticsStatePostRaw(requestParameters: ApiStatisticsStatePostRequest): Promise<runtime.ApiResponse<Array<ToldsynByStateResult>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Statistics/state`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetChartRequestToJSON(requestParameters.getChartRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ToldsynByStateResultFromJSON));
    }

    /**
     */
    async apiStatisticsStatePost(requestParameters: ApiStatisticsStatePostRequest): Promise<Array<ToldsynByStateResult>> {
        const response = await this.apiStatisticsStatePostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiStatisticsYearPostRaw(requestParameters: ApiStatisticsYearPostRequest): Promise<runtime.ApiResponse<Array<ToldsynByYearResult>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Statistics/year`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetChartRequestToJSON(requestParameters.getChartRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ToldsynByYearResultFromJSON));
    }

    /**
     */
    async apiStatisticsYearPost(requestParameters: ApiStatisticsYearPostRequest): Promise<Array<ToldsynByYearResult>> {
        const response = await this.apiStatisticsYearPostRaw(requestParameters);
        return await response.value();
    }

}
