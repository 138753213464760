/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectionChanges
 */
export interface InspectionChanges {
    /**
     * 
     * @type {boolean}
     * @memberof InspectionChanges
     */
    skippedPeriodicInspection?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InspectionChanges
     */
    failedInspection?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InspectionChanges
     */
    dateOfChange?: Date;
}

export function InspectionChangesFromJSON(json: any): InspectionChanges {
    return InspectionChangesFromJSONTyped(json, false);
}

export function InspectionChangesFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionChanges {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skippedPeriodicInspection': !exists(json, 'skippedPeriodicInspection') ? undefined : json['skippedPeriodicInspection'],
        'failedInspection': !exists(json, 'failedInspection') ? undefined : json['failedInspection'],
        'dateOfChange': !exists(json, 'dateOfChange') ? undefined : (new Date(json['dateOfChange'])),
    };
}

export function InspectionChangesToJSON(value?: InspectionChanges | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skippedPeriodicInspection': value.skippedPeriodicInspection,
        'failedInspection': value.failedInspection,
        'dateOfChange': value.dateOfChange === undefined ? undefined : (value.dateOfChange.toISOString()),
    };
}


