/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventType,
    EventTypeFromJSON,
    EventTypeFromJSONTyped,
    EventTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetEventRequest
 */
export interface GetEventRequest {
    /**
     * 
     * @type {string}
     * @memberof GetEventRequest
     */
    eventId?: string;
    /**
     * 
     * @type {EventType}
     * @memberof GetEventRequest
     */
    eventType?: EventType;
}

export function GetEventRequestFromJSON(json: any): GetEventRequest {
    return GetEventRequestFromJSONTyped(json, false);
}

export function GetEventRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEventRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventType': !exists(json, 'eventType') ? undefined : EventTypeFromJSON(json['eventType']),
    };
}

export function GetEventRequestToJSON(value?: GetEventRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'eventType': EventTypeToJSON(value.eventType),
    };
}


