import { Hidden, makeStyles, Typography } from "@material-ui/core";
import { BaseComponentProps } from "@material-ui/data-grid";
import React from "react";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { IconButton } from '@material-ui/core';
import { useHelper } from "../../../hooks/helper";

const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: "50%",
    border: "1px solid rgba(0, 0, 0, 0.26)"
  },
  footerText: {
    color: "#647481"
  }
}));

export const CustomDataGridFooter: React.FC<{
  totalString: string;
  data: React.PropsWithChildren<BaseComponentProps>;
}> = (props) => {
  const classes = useStyles();
  const helper = useHelper();
  
  return (
    <div className="MuiDataGrid-footer">
      <div className={"MuiDataGrid-rowCount" + " " + classes.footerText}>
        <Hidden smDown>
        <Typography variant="body2">Total {props.totalString}: {props.data.options.rowCount != undefined ? helper.getAsThousandSeperator(props.data.options.rowCount) : null}</Typography>
        </Hidden>
      </div>
      <div className="MuiTablePagination-root">
        <div className="MuiToolbar-root MuiToolbar-regular MuiTablePagination-toolbar">
          <div className="MuiTablePagination-spacer"></div>
          <p className={"MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit" + " " + classes.footerText}>
            {props.data.options.rowCount != 0 ? helper.getAsThousandSeperator((((props.data.options.page! - 1) * props.data.options.pageSize!) + 1)) : 0} - {" "}
            {helper.getAsThousandSeperator(((props.data.options.page! - 1) * props.data.options.pageSize!) + props.data.rows.length)} {" "}
   of {props.data.options.rowCount != undefined ? helper.getAsThousandSeperator(props.data.options.rowCount) : null}
          </p>
          <div className="MuiTablePagination-actions">
            <IconButton
              disabled={props.data.options.page == 1}
              onClick={() => props.data.api.current.setPage(props.data.options.page! - 1)}
              >
              <NavigateBeforeIcon className={classes.icon}>
              </NavigateBeforeIcon>
            </IconButton>
            <IconButton
              disabled={props.data.options.page == Math.ceil(props.data.options.rowCount! / props.data.options.pageSize!)}
              onClick={() => props.data.api.current.setPage(props.data.options.page! + 1)}
              >
              <NavigateNextIcon className={classes.icon}>
              </NavigateNextIcon>
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};
