/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventRemoteType,
    EventRemoteTypeFromJSON,
    EventRemoteTypeFromJSONTyped,
    EventRemoteTypeToJSON,
    VehicleInformationEquipmentReportData,
    VehicleInformationEquipmentReportDataFromJSON,
    VehicleInformationEquipmentReportDataFromJSONTyped,
    VehicleInformationEquipmentReportDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleInformationReportData
 */
export interface VehicleInformationReportData {
    /**
     * 
     * @type {string}
     * @memberof VehicleInformationReportData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformationReportData
     */
    vehicleReportId: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformationReportData
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformationReportData
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformationReportData
     */
    variant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformationReportData
     */
    modelYear?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformationReportData
     */
    vehicleType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformationReportData
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformationReportData
     */
    country?: string | null;
    /**
     * 
     * @type {EventRemoteType}
     * @memberof VehicleInformationReportData
     */
    remoteType?: EventRemoteType;
    /**
     * 
     * @type {Array<VehicleInformationEquipmentReportData>}
     * @memberof VehicleInformationReportData
     */
    equipment?: Array<VehicleInformationEquipmentReportData> | null;
}

export function VehicleInformationReportDataFromJSON(json: any): VehicleInformationReportData {
    return VehicleInformationReportDataFromJSONTyped(json, false);
}

export function VehicleInformationReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleInformationReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vehicleReportId': json['vehicleReportId'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'variant': !exists(json, 'variant') ? undefined : json['variant'],
        'modelYear': !exists(json, 'modelYear') ? undefined : json['modelYear'],
        'vehicleType': !exists(json, 'vehicleType') ? undefined : json['vehicleType'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'remoteType': !exists(json, 'remoteType') ? undefined : EventRemoteTypeFromJSON(json['remoteType']),
        'equipment': !exists(json, 'equipment') ? undefined : (json['equipment'] === null ? null : (json['equipment'] as Array<any>).map(VehicleInformationEquipmentReportDataFromJSON)),
    };
}

export function VehicleInformationReportDataToJSON(value?: VehicleInformationReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vehicleReportId': value.vehicleReportId,
        'make': value.make,
        'model': value.model,
        'variant': value.variant,
        'modelYear': value.modelYear,
        'vehicleType': value.vehicleType,
        'color': value.color,
        'country': value.country,
        'remoteType': EventRemoteTypeToJSON(value.remoteType),
        'equipment': value.equipment === undefined ? undefined : (value.equipment === null ? null : (value.equipment as Array<any>).map(VehicleInformationEquipmentReportDataToJSON)),
    };
}


