import { Button, Grid, makeStyles, MenuItem } from "@material-ui/core";
import { ColDef, RowParams, RowsProp, SortItem, ValueFormatterParams } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useApi from "../../../../hooks/api";
import { useAdminVinInfo } from "../../../../hooksAdmin/vins";
import { CustomDataGrid } from "../../../shared/datagrid/CustomDataGrid";

const AdminVehiclesPage: React.FC<{
}> = () => {

  var [page, setPage] = useState<number>(1);
  var [pageSize] = useState<number>(20);
  var [searchQuery, setSearchQuery] = useState<string>("");
  var { totalVins, results, searchState, addClonedVIN } = useAdminVinInfo(page, pageSize, searchQuery);

  const [rows, setRows] = useState<RowsProp>([]);

  useEffect(() => {
    setRows(results.length !== 0 ? (
      results.map((team, index) => {
        return {
          id: index,
          vin: team.vin,
          cloned: team.isCloned
        }
      })) : []);
  }, [results]);

  const columns: ColDef[] = [
    { field: 'vin', headerName: 'VIN', flex: 10 },
    { field: 'cloned', headerName: 'Cloned', flex: 1, renderCell: (params) => <>{(params.value as boolean) ? "Ja" : "Nej"}</>}
  ];

  return (
    <>
    <CustomDataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      page={page}
      setPage={(page: number) => { setPage(page) }}
      loadingState={searchState}
      totalRows={totalVins}
      dataDisplayText="vins"
      headerProps={{
        filters: [
        {
          name: "Add cloned vin",
          onSubmitVehicleId: async(vin: string) => {await addClonedVIN(vin)},
          type: "submit",
          position: "right",
          placeholder: "VIN"
        },
        {
          name: "Search",
          onChange: (e) => setSearchQuery(e.target.value),
          type: "search",
          position: "right",
        }],
        displayHeadline: "Cloned vins",
        isTab: true
      }}
    ></CustomDataGrid>
    </>
  );
};

export default AdminVehiclesPage;
