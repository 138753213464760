/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InitSearchFields,
    InitSearchFieldsFromJSON,
    InitSearchFieldsFromJSONTyped,
    InitSearchFieldsToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchInitDataResponse
 */
export interface SearchInitDataResponse {
    /**
     * 
     * @type {InitSearchFields}
     * @memberof SearchInitDataResponse
     */
    initSearchFields?: InitSearchFields;
    /**
     * 
     * @type {string}
     * @memberof SearchInitDataResponse
     */
    name?: string | null;
}

export function SearchInitDataResponseFromJSON(json: any): SearchInitDataResponse {
    return SearchInitDataResponseFromJSONTyped(json, false);
}

export function SearchInitDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchInitDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initSearchFields': !exists(json, 'initSearchFields') ? undefined : InitSearchFieldsFromJSON(json['initSearchFields']),
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function SearchInitDataResponseToJSON(value?: SearchInitDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initSearchFields': InitSearchFieldsToJSON(value.initSearchFields),
        'name': value.name,
    };
}


