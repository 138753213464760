/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleLeasingData
 */
export interface VehicleLeasingData {
    /**
     * 
     * @type {string}
     * @memberof VehicleLeasingData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleLeasingData
     */
    vehicleReportId?: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleLeasingData
     */
    fromDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof VehicleLeasingData
     */
    toDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleLeasingData
     */
    currentlyLeased?: boolean;
}

export function VehicleLeasingDataFromJSON(json: any): VehicleLeasingData {
    return VehicleLeasingDataFromJSONTyped(json, false);
}

export function VehicleLeasingDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleLeasingData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vehicleReportId': !exists(json, 'vehicleReportId') ? undefined : json['vehicleReportId'],
        'fromDate': !exists(json, 'fromDate') ? undefined : (json['fromDate'] === null ? null : new Date(json['fromDate'])),
        'toDate': !exists(json, 'toDate') ? undefined : (json['toDate'] === null ? null : new Date(json['toDate'])),
        'currentlyLeased': !exists(json, 'currentlyLeased') ? undefined : json['currentlyLeased'],
    };
}

export function VehicleLeasingDataToJSON(value?: VehicleLeasingData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vehicleReportId': value.vehicleReportId,
        'fromDate': value.fromDate === undefined ? undefined : (value.fromDate === null ? null : value.fromDate.toISOString()),
        'toDate': value.toDate === undefined ? undefined : (value.toDate === null ? null : value.toDate.toISOString()),
        'currentlyLeased': value.currentlyLeased,
    };
}


