/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ToldsynRequest,
    ToldsynRequestFromJSON,
    ToldsynRequestFromJSONTyped,
    ToldsynRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetChartRequest
 */
export interface GetChartRequest {
    /**
     * 
     * @type {ToldsynRequest}
     * @memberof GetChartRequest
     */
    request?: ToldsynRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetChartRequest
     */
    init?: boolean;
}

export function GetChartRequestFromJSON(json: any): GetChartRequest {
    return GetChartRequestFromJSONTyped(json, false);
}

export function GetChartRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChartRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request': !exists(json, 'request') ? undefined : ToldsynRequestFromJSON(json['request']),
        'init': !exists(json, 'init') ? undefined : json['init'],
    };
}

export function GetChartRequestToJSON(value?: GetChartRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request': ToldsynRequestToJSON(value.request),
        'init': value.init,
    };
}


