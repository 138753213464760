/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserSort {
    Default = 'Default',
    Name = 'Name',
    NameDesc = 'NameDesc',
    Email = 'Email',
    EmailDesc = 'EmailDesc'
}

export function UserSortFromJSON(json: any): UserSort {
    return UserSortFromJSONTyped(json, false);
}

export function UserSortFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSort {
    return json as UserSort;
}

export function UserSortToJSON(value?: UserSort | null): any {
    return value as any;
}

