/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PackageDto,
    PackageDtoFromJSON,
    PackageDtoFromJSONTyped,
    PackageDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TeamMetaData
 */
export interface TeamMetaData {
    /**
     * 
     * @type {number}
     * @memberof TeamMetaData
     */
    activeSearchAgents?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMetaData
     */
    activeVINCheckers?: number;
    /**
     * 
     * @type {PackageDto}
     * @memberof TeamMetaData
     */
    _package?: PackageDto;
}

export function TeamMetaDataFromJSON(json: any): TeamMetaData {
    return TeamMetaDataFromJSONTyped(json, false);
}

export function TeamMetaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamMetaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeSearchAgents': !exists(json, 'activeSearchAgents') ? undefined : json['activeSearchAgents'],
        'activeVINCheckers': !exists(json, 'activeVINCheckers') ? undefined : json['activeVINCheckers'],
        '_package': !exists(json, 'package') ? undefined : PackageDtoFromJSON(json['package']),
    };
}

export function TeamMetaDataToJSON(value?: TeamMetaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeSearchAgents': value.activeSearchAgents,
        'activeVINCheckers': value.activeVINCheckers,
        'package': PackageDtoToJSON(value._package),
    };
}


