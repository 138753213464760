import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

const AuthenticatedRoute: React.FC<
  { isAuthenticated: boolean } & RouteProps
> = (props) => {
  return (
    <>
      {props.isAuthenticated ? (
        <Route {...props}>{props.children}</Route>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default AuthenticatedRoute;
