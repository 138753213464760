/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PortfolioSort {
    Default = 'Default',
    Make = 'Make',
    MakeDesc = 'MakeDesc',
    Model = 'Model',
    ModelDesc = 'ModelDesc'
}

export function PortfolioSortFromJSON(json: any): PortfolioSort {
    return PortfolioSortFromJSONTyped(json, false);
}

export function PortfolioSortFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioSort {
    return json as PortfolioSort;
}

export function PortfolioSortToJSON(value?: PortfolioSort | null): any {
    return value as any;
}

