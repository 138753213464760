import { AppBar, Grid, makeStyles, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ImportedByCvrChart } from "./ImportedByCvrChart";
import { useHistory } from "react-router";
import { a11yProps, TabPanel } from "../../../shared/tabs/TabPanel";
import { ImportedByLeasingYearEndChart } from "./ImportedByLeasingYearEndChart";
import { ImportedByMakeChart } from "./ImportedByMakeChart";
import { ImportedByYearChart } from "./ImportedByYearChart";
import { ImportedByModelYearChart } from "./ImportedByModelYearChart";
import { ImportedByStateChart } from "./ImportedByStateChart";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  },
  center: {
    textAlign: "center"
  },
  form: theme.form,
  tab: {
    padding: "0px",
    borderRadius: "0px 0px 3px 3px",
    borderTop: "none"
  },
  chartView: {
    marginTop: "24px"
  }
}));

const TaxInspectionsTab = () => {
  const classes = useStyles();
  const history = useHistory();

  const getInitValue = () => {
    if (history.location.pathname.startsWith("/statistics/taxinspections/cvr"))
      return 0;
    if (history.location.pathname.startsWith("/statistics/taxinspections/make"))
      return 1;
    if (history.location.pathname.startsWith("/statistics/taxinspections/state"))
      return 2;
    if (history.location.pathname.startsWith("/statistics/taxinspections/leasingyear"))
      return 3;
    if (history.location.pathname.startsWith("/statistics/taxinspections/year"))
      return 4;
    if (history.location.pathname.startsWith("/statistics/taxinspections/modelyear"))
      return 5;

      return 0;
  }

  const [value, setTabValue] = React.useState(getInitValue());

  useEffect(() => {
    setTabValue(getInitValue());
  }, [history.location.pathname]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Paper className={classes.tab}>
        <Grid container spacing={2} alignItems="center">
          <Grid item style={{ flexGrow: 1, width: "100%" }}>
            <Tabs 
            value={value} 
            onChange={handleChange} 
            variant="scrollable"
            scrollButtons="auto"
            aria-label="simple tabs example">
              <Tab onClick={() => history.push("/statistics/taxinspections/cvr")} label="Inspection station" {...a11yProps(0)} />
              <Tab onClick={() => history.push("/statistics/taxinspections/make")} label="Make" {...a11yProps(1)} />
              <Tab onClick={() => history.push("/statistics/taxinspections/state")} label="State" {...a11yProps(2)} />
              <Tab onClick={() => history.push("/statistics/taxinspections/leasingyear")} label="Leasing expiration year" {...a11yProps(3)} />
              <Tab onClick={() => history.push("/statistics/taxinspections/year")} label="Year" {...a11yProps(4)} />
              <Tab onClick={() => history.push("/statistics/taxinspections/modelyear")} label="Model year" {...a11yProps(5)} />
            </Tabs>
          </Grid>
        </Grid>
      </Paper>

      <Grid container className={classes.chartView}>
        <Grid item xs={12}>
          <TabPanel value={value} index={0}>
            <ImportedByCvrChart amount={20}></ImportedByCvrChart>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ImportedByMakeChart amount={20}></ImportedByMakeChart>
      </TabPanel>
          <TabPanel value={value} index={2}>
            <ImportedByStateChart amount={20}></ImportedByStateChart>
      </TabPanel>
          <TabPanel value={value} index={3}>
            <ImportedByLeasingYearEndChart amount={20}></ImportedByLeasingYearEndChart>
      </TabPanel>
          <TabPanel value={value} index={4}>
            <ImportedByYearChart amount={20}></ImportedByYearChart>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <ImportedByModelYearChart amount={20}></ImportedByModelYearChart>
      </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default TaxInspectionsTab;
