import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import { a11yProps, TabPanel } from "../../shared/tabs/TabPanel";
import { useHistory } from "react-router";
import TaxInspectionsTab from "./taxinspections/TaxInspectionsTab";

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: "0px",
    borderRadius: "3px 3px 0px 0px"
  },
  form: theme.form,
  icon: {
    color: "grey",
    paddingRight: "10px"
  }
}));

export const useChartStyles = makeStyles((theme) => ({
  skeleton: (props: { width: number }) => ({
    width: props.width,
    height: props.width / 2
  }),
  icons: theme.icon,
  header: {
    borderRadius: "3px 3px 0px 0px"
  },
  filter:{
    borderRadius: "0px",
    borderTop: "none"
  },
  chart: {
    backgroundColor: "white",
    borderRadius: "0px 0px 3px 3px",
    borderTop: "none"
  }
}));

function StatisticsPage() {

  const history = useHistory();

  const classes = useStyles();

  const getInitValue = () => {
    if (history.location.pathname.startsWith("/statistics/taxinspections"))
      return 0;

      return 0;
  }

  const [value, setTabValue] = useState(getInitValue());

  useEffect(() => {
    setTabValue(getInitValue());
  }, [history.location.pathname]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.tab}>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{ flexGrow: 1 }}>
                <Tabs 
                value={value} 
                onChange={handleChange} 
                aria-label="simple tabs example">
                  <Tab onClick={() => history.push("/statistics/taxinspections")} label="TAX INSPECTIONS" {...a11yProps(0)} />
                </Tabs>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <TaxInspectionsTab></TaxInspectionsTab>
      </TabPanel>
    </>
  );
}

export default StatisticsPage;
