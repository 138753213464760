import { useFormikContext } from "formik";
import { FormControlProps } from "@material-ui/core";
import React from "react";
import { SearchForm } from "../../../lib-api";
import { useModelSuggestions } from "../../../hooks/modelSuggestions";
import { AutoSuggestField } from "./AutoSuggestField";

export const ModelField: React.FC<
  {
    name: string;
    placeholder: string;
    labelText?: string | undefined;
    tooltip?: string;
    onFinish?: () => void;
  } & FormControlProps
> = (props) => {
  //get value of different form object
  const { values } = useFormikContext<SearchForm>();
  const { suggestions, suggestionsState } = useModelSuggestions(values.make?.trim(), values.model?.trim());
  return (
    <AutoSuggestField 
    {...props} 
    disabled={values.make == undefined || values.make == ""} 
    suggestions={values.model ? values.model.includes("*") ? suggestions.concat(values.model) : suggestions.concat(values.model + "*") : suggestions} 
    suggestionsState={suggestionsState}
    tooltip={props.tooltip}></AutoSuggestField>
  );
};
