import { Hidden, makeStyles } from "@material-ui/core";
import { ColDef, RowsProp, SortItem } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import useApi from "../../../hooks/api";
import useAuth from "../../../hooks/auth";
import { useExports } from "../../../hooks/exports";
import { CustomDataGrid } from "../../shared/datagrid/CustomDataGrid";
import {
  CustomDataGridHeader,
  DataGridHeaderFilter,
} from "../../shared/datagrid/CustomDataGridHeader";
import {
  ExportSort,
  ExportStatus,
  ExportType,
  PortfolioSort,
  ProjectSort,
} from "../../../lib-api";
import { useParams } from "react-router";
import { useProjects } from "../../../hooks/projects";
import { useWindowSize } from "../../../hooks/windowSize";
import { ExportTaxDataToolbar } from "./exports/ExportTaxDataToolbar";
import { ExportLeasingDataToolbar } from "./exports/ExportLeasingDataToolbar";
import { ExportVinDataToolbar } from "./exports/ExportVinDataToolbar";
import { ExportInsuranceDataToolbar } from "./exports/ExportInsuranceDataToolbar";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
}));

export const Exports: React.FunctionComponent<{}> = (props) => {
  var [page, setPage] = useState<number>(1);
  var [searchQuery, setSearchQuery] = useState<string>("");
  var [pageSize] = useState<number>(20);
  const { projectId } = useParams<{ projectId: string }>();
  var [sortMode, setSortMode] = useState<ExportSort>(ExportSort.Default);
  var [projectFolderId, setProjectFolderId] = useState<string>(projectId ?? "");
  var { exportApi } = useApi();
  var { totalVins, results, searchState, reload } = useExports(
    page,
    pageSize,
    sortMode,
    searchQuery,
    projectFolderId
  );

  var { projects } = useProjects(1, 20, ProjectSort.Default, "");

  const [rows, setRows] = useState<RowsProp>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { xsDown } = useWindowSize();

  useEffect(() => {
    setRows(
      results.length !== 0
        ? results.map((result, index) => {
            return {
              id: index,
              name: result.name,
              status: mapStatus(result.exportStatus!),
              date: result.createdDate?.toDateString(),
            };
          })
        : []
    );
  }, [results]);

  const uploadVins = async (
    vins: string[],
    exportType: ExportType,
    projectFolderId?: string,
    name?: string
  ) => {
    try {
      await exportApi.apiExportAddManyPost({
        createRegTaxVinRequest: {
          vins: vins,
          projectId: projectFolderId,
          name: name,
          exportType: exportType,
        },
      });
      enqueueSnackbar(
        "VINs uploaded. You will recieve an e-mail, when the file is ready",
        {
          variant: "success",
        }
      );
      reload();
    } catch {}
  };

  const getFilters = () => {
    var filters: DataGridHeaderFilter[] = [];

    filters.push({
      name: "Choose project",
      position: "right",
      type: "select",
      initVal: projectFolderId,
      onChange: (e) =>
        setProjectFolderId(e.target.value == "-1" ? "" : e.target.value),
      selectOptions: projects.map((project) => {
        return { name: project.name!, value: project.id! };
      }),
    });
    filters.push({
      type: "search",
      onChange: (e) => setSearchQuery(e.target.value),
      position: "right",
      name: "Search",
    });
    return filters;
  };

  const mapStatus = (status: ExportStatus) => {
    switch (status) {
      case ExportStatus.NotStarted:
        return "Not started.";
      case ExportStatus.Processing:
        return "Processing...";
      case ExportStatus.Finished:
        return "Finished."; // tilføj url at hente fil
      default:
        return "";
    }
  };

  const handleSortModelChange = (model: SortItem) => {
    if (model == null || model == undefined) {
      setSortMode(ExportSort.Default);
      return;
    }
    if (model.field == "name" && model.sort == "asc") {
      setSortMode(ExportSort.Name);
      return;
    }
    if (model.field == "name" && model.sort == "desc") {
      setSortMode(ExportSort.NameDesc);
      return;
    }
    if (model.field == "date" && model.sort == "asc") {
      setSortMode(ExportSort.Created);
      return;
    }
    if (model.field == "date" && model.sort == "desc") {
      setSortMode(ExportSort.CreatedDesc);
      return;
    }
  };

  const columns: ColDef[] = [
    { field: "name", headerName: "NAME", flex: 2, sortable: false },
    { field: "status", headerName: "STATUS", flex: 4 },
    { field: "date", headerName: "DATE", flex: 2, hide: xsDown },
  ];

  return (
    <>
      <Hidden xsDown>
        <ExportTaxDataToolbar uploadVins={uploadVins} />
        <ExportLeasingDataToolbar uploadVins={uploadVins} />
        <ExportVinDataToolbar uploadVins={uploadVins} />
        <ExportInsuranceDataToolbar uploadVins={uploadVins} />
      </Hidden>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        page={page}
        setPage={(pageNumber: number) => {
          setPage(pageNumber);
        }}
        loadingState={searchState}
        totalRows={totalVins}
        handleSortModelChange={(params) =>
          handleSortModelChange(params.sortModel[0])
        }
        dataDisplayText="vehicles"
        headerProps={{
          filters: getFilters(),
          isTab: true,
        }}
      ></CustomDataGrid>
    </>
  );
};
