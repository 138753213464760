/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamMemberDto,
    TeamMemberDtoFromJSON,
    TeamMemberDtoFromJSONTyped,
    TeamMemberDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UsersResponse
 */
export interface UsersResponse {
    /**
     * 
     * @type {Array<TeamMemberDto>}
     * @memberof UsersResponse
     */
    all: Array<TeamMemberDto>;
    /**
     * 
     * @type {number}
     * @memberof UsersResponse
     */
    totalCount: number;
}

export function UsersResponseFromJSON(json: any): UsersResponse {
    return UsersResponseFromJSONTyped(json, false);
}

export function UsersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'all': ((json['all'] as Array<any>).map(TeamMemberDtoFromJSON)),
        'totalCount': json['totalCount'],
    };
}

export function UsersResponseToJSON(value?: UsersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all': ((value.all as Array<any>).map(TeamMemberDtoToJSON)),
        'totalCount': value.totalCount,
    };
}


