import React, { useEffect, useMemo, useState } from "react";
import { ChartOptions } from "chart.js";
import { LoadingState } from "../../../../hooks/loading";
import { ElementLoading } from "../../../shared/other/Loading";
import { Bar, ChartData, Line, Scatter } from "react-chartjs-2";
import { Skeleton } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core";
import { useDimensions } from "../../../../hooks/dimensions";
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import useAuth from "../../../../hooks/auth";
import { VehicleMileageReportData } from "../../../../lib-api";
import { useChartOptions } from "../../../../hooks/chartOptions";

const useStyles = makeStyles((theme) => ({
  skeleton: (props: {width: number}) => ({
    width: props.width,
    height: props.width / 2
  }),
  pdfIcon: theme.icon
}));

export const MileageHistory: React.FC<{
  loadingState: LoadingState;
  data?: Array<VehicleMileageReportData> | null;
  initialLoading: boolean;
}> = (props) => {

  const { width } = useDimensions("mileagehistory-width");
  
  const classes = useStyles({ width: width as number });
  const theme = useTheme();

  useEffect(() => {
    if (props.data != null && props.data != undefined) {
      createChart();
    }
  }, [props.data]);

  const chartRef = useMemo(() => {
    return React.createRef<Line>();
  }, []);

  const [chartData, setChartData] = useState<ChartData<Chart.ChartData>>({});
  
  const [options] = useChartOptions<Line>(chartRef, true, false);

  const createChart = () => {
    var chartData = props.data?.sort((p, p1) => (p.date?.getTime() as number) - (p1.date?.getTime() as number));
    
    var data = {
      datasets: [{
        label: 'Mileage for vehicle',
        data: chartData?.map(p => {return {"x": p.date?.toDateString(), "y": p.mileage}}),
        borderColor: theme.primaryChart.background,
      }]
    };
    setChartData(data);
  }

  return (<div id="mileagehistory-width" style={{ position: "relative" }}> 
  {props.initialLoading ? <Skeleton variant="rect" className={classes.skeleton}></Skeleton>
    :
    <>
      {props.loadingState == LoadingState.Loading ?
        <ElementLoading position="absolute"></ElementLoading> : null}
      <Line ref={chartRef} data={chartData} options={options}></Line>
    </>} </div>)
}
