import { EventRemoteType, EventType } from "../lib-api";
import {
  AccidentIcon,
  BankruptcyIcon,
  InspectionIcon,
  InsuranceIcon,
  ManufacturingIcon,
  MileageIcon,
  RegistrationIcon,
  SaleIcon,
  ServiceIcon,
  TaxIcon,
  ValuationIcon,
  WantedIcon,
} from "../components/shared/other/CustomIcons";
import React from "react";

export function useHelper() {
  const getAsThousandSeperator = (number: any) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const getContryFromCode = (countryCode?: string | null) => {
    switch (countryCode) {
      case "DNK":
        return "Denmark";
      case "SWE":
        return "Sweden";
      case "GB":
        return "Great britain";
      case "DEU":
        return "Germany";
      case "BEL":
        return "Belgium";
      case "IT":
        return "Italy";
      case "FRA":
        return "France";
      case "ESP":
        return "Spain";
      case "USA":
        return "USA";
      case "POL":
        return "Poland";
      case "AUT":
        return "Austria";
      case "SVK":
        return "Slovakia";
      case "GRC":
        return "Greece";
      case "ARE":
        return "United Arab Emirates";
      case "LTU":
        return "Lithuania";
      case "CHE":
        return "The Czech Republic";
      case "NLD":
        return "Netherlands";
      case "RUS":
        return "Russia";
      case "FIN":
        return "Finland";
      default:
        return "Unknown";
    }
  };

  const getFlagFromCode = (countryCode?: string | null, size: number = 40) => {
    switch (countryCode) {
      case "DNK":
        return `https://flagcdn.com/h${size}/dk.png`;
      case "SWE":
        return `https://flagcdn.com/h${size}/se.png`;
      case "DEU":
        return `https://flagcdn.com/h${size}/de.png`;
      case "BEL":
        return `https://flagcdn.com/h${size}/be.png`;
      case "IT":
        return `https://flagcdn.com/h${size}/it.png`;
      case "FRA":
        return `https://flagcdn.com/h${size}/fr.png`;
      case "ESP":
        return `https://flagcdn.com/h${size}/es.png`;
      case "USA":
        return `https://flagcdn.com/h${size}/us.png`;
      case "POL":
        return `https://flagcdn.com/h${size}/pl.png`;
      case "AUT":
        return `https://flagcdn.com/h${size}/at.png`;
      case "SVK":
        return `https://flagcdn.com/h${size}/sk.png`;
      case "GRC":
        return `https://flagcdn.com/h${size}/gr.png`;
      case "LTU":
        return `https://flagcdn.com/h${size}/lt.png`;
      case "CHE":
        return `https://flagcdn.com/h${size}/cz.png`;
      case "NLD":
        return `https://flagcdn.com/h${size}/nl.png`;
      case "RUS":
        return `https://flagcdn.com/h${size}/ru.png`;
      case "FIN":
        return `https://flagcdn.com/h${size}/fi.png`;
      case "ARE":
        return `https://flagcdn.com/h${size}/ae.png`;
      case "GB":
        return `https://flagcdn.com/h${size}/gb.png`;
      default:
        return "Unknown";
    }
  };

  const getIconFromEventType = (eventType?: EventType) => {
    switch (eventType) {
      case EventType.MileageReading:
        return <MileageIcon hoverText="" />;
      case EventType.Manufactured:
        return <ManufacturingIcon hoverText="" />;
      case EventType.ManufacturedDontShow:
        return <ManufacturingIcon hoverText="" />;
      case EventType.Maintenance:
        return <ServiceIcon hoverText="" />;
      case EventType.Registration:
        return <RegistrationIcon hoverText="" />;
      case EventType.RegistrationRestriction:
        return <RegistrationIcon hoverText="" />;
      case EventType.WantedStatus:
        return <WantedIcon hoverText="" />;
      case EventType.InspectionReport:
        return <InspectionIcon hoverText="" />;
      case EventType.Accident:
        return <AccidentIcon hoverText="" />;
      case EventType.InsuranceStatus:
        return <InsuranceIcon hoverText="" />;
      case EventType.TaxTransaction:
        return <TaxIcon hoverText="" />;
      case EventType.Assessment:
        return <ValuationIcon hoverText="" />;
      case EventType.SalesAd:
        return <SaleIcon hoverText="" />;
      case EventType.BankRuptcy:
        return <BankruptcyIcon hoverText="" />;
      case EventType.Loan:
        return <BankruptcyIcon hoverText="" />;
      case EventType.Other:
        return <ServiceIcon hoverText="" />;
      case EventType.Ownership:
        return null;
      case EventType.SearchAgentResult:
        return null;
      default:
        return null;
    }
  };

  const getRemoteSourceFromEnum = (source?: EventRemoteType) => {
    switch (source) {
      case EventRemoteType.TjekBilDmr:
        return "Motorregisteret";
      case EventRemoteType.TjekBilPolitiet:
        return "The police";
      case EventRemoteType.TjekBilTinglysningen:
        return "Tinglysningen";
      case EventRemoteType.TjekBilTrafikstyrrelsen:
        return "Trafikstyrrelsen";
      case EventRemoteType.TheParkingAutomobileFr:
        return "Automobile.fr";
      case EventRemoteType.TheParkingBilbasen:
        return "Bilbasen.dk";
      case EventRemoteType.TheParkingClassicTraderCom:
        return "Classic-trader.com";
      case EventRemoteType.TheParkingDbaDk:
        return "Dba.dk";
      case EventRemoteType.TheParkingGuloggratisDk:
        return "Guloggratis.dk";
      case EventRemoteType.TheParkingKlassikermarkedetDk:
        return "Klassikermarkedet.dk";
      case EventRemoteType.TheParkingMobileDe:
        return "Mobile.de";
      case EventRemoteType.TheParkingUnknown:
        return "TheParkingEu";
      case EventRemoteType.CarVertical:
        return "International data supplier";
      default:
        return source?.toString();
    }
  };

  return {
    getAsThousandSeperator,
    getContryFromCode,
    getRemoteSourceFromEnum,
    getIconFromEventType,
    getFlagFromCode,
  };
}
