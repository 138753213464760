import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useApi from "../../../../hooks/api";
import useAuth from "../../../../hooks/auth";
import {
  VehicleBlockedStatusData,
  VehicleEvent,
  VehicleEventInfo,
  VehicleLeasingData,
  VehicleNextInspectionData,
} from "../../../../lib-api";

export const SpecSummary: React.FC<{
  leasingData?: VehicleLeasingData | null;
  latestRegistrationEvent?: VehicleEvent | null;
  nextInspection?: VehicleNextInspectionData | null;
  blockedData?: VehicleBlockedStatusData | null;
}> = (props) => {
  const { reportApi } = useApi();
  const { isAdmin } = useAuth();
  const [regInfo, setRegInfo] = useState<VehicleEventInfo | undefined>(
    undefined
  );

  useEffect(() => {
    (async () => {
      if (props.latestRegistrationEvent) {
        const result = await reportApi.apiReportCompletedOrderEventPost({
          getEventRequest: {
            eventId: props?.latestRegistrationEvent?.id,
            eventType: props?.latestRegistrationEvent?.eventType,
          },
        });
        setRegInfo(result);
      }
    })();
  }, []);

  return (
    <>
      {props.leasingData != null ? (
        <Grid item xs={12}>
          <Typography>
            Status:{" "}
            {props.leasingData.currentlyLeased
              ? props.leasingData.fromDate
                ? `Leased from ${props.leasingData.fromDate?.toDateString()} to ${props.leasingData.toDate?.toDateString()}`
                : "Leased"
              : "Not leased"}
          </Typography>
        </Grid>
      ) : null}
      {regInfo != undefined ? (
        <Grid item xs={12}>
          <Typography>Latest registrationstatus: {regInfo.info}</Typography>
        </Grid>
      ) : null}
      {props.nextInspection != null && props.nextInspection.date != null ? (
        <Grid item xs={12}>
          {props.nextInspection.date! > new Date() ? (
            <Typography>
              Next inspection: {props.nextInspection.date?.toDateString()}
            </Typography>
          ) : (
            <Typography>
              Skipped inspection: {props.nextInspection.date?.toDateString()}
            </Typography>
          )}
        </Grid>
      ) : null}
      {props.blockedData != null ? (
        <Grid item xs={12}>
          {props.blockedData.blocked! ? (
            <Typography>
              Status: Blocked by Motorregisteret -{" "}
              {isAdmin() ? props.blockedData.blockedDescription : null}
            </Typography>
          ) : (
            <Typography>Status: Not blocked by Motorregisteret</Typography>
          )}
        </Grid>
      ) : null}
    </>
  );
};
