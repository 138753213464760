import React from "react";
import { ExportType } from "../../../../lib-api";
import { CustomDataGridHeader } from "../../../shared/datagrid/CustomDataGridHeader";

export const ExportTaxDataToolbar: React.FunctionComponent<{
  uploadVins: (
    vins: string[],
    exportType: ExportType,
    projectFolderId?: string | undefined,
    name?: string | undefined
  ) => Promise<void>;
}> = (props) => {
  return (
    <CustomDataGridHeader
      headerProps={{
        displayHeadline: "Tax lookup",
        filters: [
          {
            type: "submitmultiplewithprojects",
            loadingText: "Adding VINs..",
            name: "Upload multiple VINs",
            placeholder: "Name of data",
            onSubmitVehicleId: (ids, projectId, name) => {
              props.uploadVins(
                ids,
                ExportType.RegistrationTax,
                projectId == "-1" ? undefined : projectId,
                name
              );
            },
            position: "right",
          },
        ],
        isTab: true,
      }}
    ></CustomDataGridHeader>
  );
};
