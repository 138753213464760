/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsuranceChanges
 */
export interface InsuranceChanges {
    /**
     * 
     * @type {string}
     * @memberof InsuranceChanges
     */
    newInsuranceCompany?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof InsuranceChanges
     */
    dateOfChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof InsuranceChanges
     */
    status?: string | null;
}

export function InsuranceChangesFromJSON(json: any): InsuranceChanges {
    return InsuranceChangesFromJSONTyped(json, false);
}

export function InsuranceChangesFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceChanges {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newInsuranceCompany': !exists(json, 'newInsuranceCompany') ? undefined : json['newInsuranceCompany'],
        'dateOfChange': !exists(json, 'dateOfChange') ? undefined : (new Date(json['dateOfChange'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function InsuranceChangesToJSON(value?: InsuranceChanges | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newInsuranceCompany': value.newInsuranceCompany,
        'dateOfChange': value.dateOfChange === undefined ? undefined : (value.dateOfChange.toISOString()),
        'status': value.status,
    };
}


