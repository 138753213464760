import React from "react";
import { LoadingState } from "../../../../hooks/loading";
import { ElementLoading } from "../../../shared/other/Loading";
import { Skeleton } from "@material-ui/lab";
import { GridList, GridListTile, GridListTileBar, makeStyles } from "@material-ui/core";
import { VehiclePictureReportData } from "../../../../lib-api";
import useAuth from "../../../../hooks/auth";
import { useHelper } from "../../../../hooks/helper";
import { useWindowSize } from "../../../../hooks/windowSize";
import ModalImage from "react-modal-image";

const useStyles = makeStyles((theme) => ({
  pdfIcon: theme.icon
}));

export const PictureHistory: React.FC<{
  loadingState: LoadingState;
  data?: Array<VehiclePictureReportData> | null;
  initialLoading: boolean;
}> = (props) => {

  const classes = useStyles();
  const {isAdmin} = useAuth();
  const {getRemoteSourceFromEnum} = useHelper();
  const {xsDown} = useWindowSize();

  return props.initialLoading ? <Skeleton variant="rect"></Skeleton>
    :
    <>
      {props.loadingState == LoadingState.Loading ?
        <ElementLoading position="absolute"></ElementLoading> : null}
      <GridList cellHeight={260} cols={xsDown ? 1 : 3}>
        {props.data?.map((picture, index) => (
          <GridListTile key={index} rows={1} cols={1}>
            <ModalImage
                  small={picture.url}
                  large={picture.url}
                  hideDownload
                  hideZoom
                  ></ModalImage>
            {isAdmin() ? <GridListTileBar
                    title={`Origin: ${getRemoteSourceFromEnum(picture.source)}`}
                  /> : null}
          </GridListTile>
        ))}
      </GridList>
    </>;
}
