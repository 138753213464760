/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PingOrderRequest
 */
export interface PingOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof PingOrderRequest
     */
    vehicleOrderId: string;
}

export function PingOrderRequestFromJSON(json: any): PingOrderRequest {
    return PingOrderRequestFromJSONTyped(json, false);
}

export function PingOrderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PingOrderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleOrderId': json['vehicleOrderId'],
    };
}

export function PingOrderRequestToJSON(value?: PingOrderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleOrderId': value.vehicleOrderId,
    };
}


