import { useField } from "formik";
import { FormControlProps, Checkbox, FormControlLabel, Tooltip } from "@material-ui/core";
import React from "react";

export const CheckboxField: React.FC<{ 
  name: string; 
  type: string; 
  tooltip?: string;
} & FormControlProps> = (
  props
) => {
  const [field, meta] = useField(props.name);
  return (
    <Tooltip title={props.tooltip ?? ""}>
      <FormControlLabel control={<Checkbox color="primary" defaultChecked={meta.initialValue} {...field} />} label={props.children}/>
    </Tooltip>
  );
};
