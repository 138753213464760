/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    VinInfo,
    VinInfoFromJSON,
    VinInfoToJSON,
    VinInfoResponse,
    VinInfoResponseFromJSON,
    VinInfoResponseToJSON,
} from '../models';

export interface ApiAdminVininfoAddClonedPostRequest {
    vin?: string | null;
}

export interface ApiAdminVininfoAllinfoPostRequest {
    page?: number;
    size?: number;
    searchQuery?: string | null;
}

export interface ApiAdminVininfoInfoPostRequest {
    vin?: string | null;
}

/**
 * 
 */
export class AdminVinInfoApi extends runtime.BaseAPI {

    /**
     */
    async apiAdminVininfoAddClonedPostRaw(requestParameters: ApiAdminVininfoAddClonedPostRequest): Promise<runtime.ApiResponse<Array<VinInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.vin !== undefined) {
            queryParameters['vin'] = requestParameters.vin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/vininfo/addCloned`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VinInfoFromJSON));
    }

    /**
     */
    async apiAdminVininfoAddClonedPost(requestParameters: ApiAdminVininfoAddClonedPostRequest): Promise<Array<VinInfo>> {
        const response = await this.apiAdminVininfoAddClonedPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminVininfoAllinfoPostRaw(requestParameters: ApiAdminVininfoAllinfoPostRequest): Promise<runtime.ApiResponse<VinInfoResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/vininfo/allinfo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VinInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminVininfoAllinfoPost(requestParameters: ApiAdminVininfoAllinfoPostRequest): Promise<VinInfoResponse> {
        const response = await this.apiAdminVininfoAllinfoPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdminVininfoInfoPostRaw(requestParameters: ApiAdminVininfoInfoPostRequest): Promise<runtime.ApiResponse<VinInfo>> {
        const queryParameters: any = {};

        if (requestParameters.vin !== undefined) {
            queryParameters['vin'] = requestParameters.vin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/vininfo/info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VinInfoFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminVininfoInfoPost(requestParameters: ApiAdminVininfoInfoPostRequest): Promise<VinInfo> {
        const response = await this.apiAdminVininfoInfoPostRaw(requestParameters);
        return await response.value();
    }

}
