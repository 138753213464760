/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountDto,
    AccountDtoFromJSON,
    AccountDtoFromJSONTyped,
    AccountDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuthInfo
 */
export interface AuthInfo {
    /**
     * 
     * @type {string}
     * @memberof AuthInfo
     */
    userId?: string | null;
    /**
     * 
     * @type {AccountDto}
     * @memberof AuthInfo
     */
    user?: AccountDto;
    /**
     * 
     * @type {string}
     * @memberof AuthInfo
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthInfo
     */
    refreshToken?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthInfo
     */
    expirationDate?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthInfo
     */
    roles?: Array<string> | null;
}

export function AuthInfoFromJSON(json: any): AuthInfo {
    return AuthInfoFromJSONTyped(json, false);
}

export function AuthInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : AccountDtoFromJSON(json['user']),
        'token': !exists(json, 'token') ? undefined : json['token'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (new Date(json['expirationDate'])),
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
    };
}

export function AuthInfoToJSON(value?: AuthInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'user': AccountDtoToJSON(value.user),
        'token': value.token,
        'refreshToken': value.refreshToken,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate.toISOString()),
        'roles': value.roles,
    };
}


