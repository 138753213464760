import { useState, useEffect } from "react";
import { ProjectFolderDto, ProjectSort } from "../lib-api/models";
import { LoadingState } from "./loading";
import useApi from "./api";
import { useQueryDelay } from "./queryDelay";
import useAuth from "./auth";

export function useProjects(page: number, amount: number, sortMode: ProjectSort, searchQuery: string) {
  const [state, setState] = useState(LoadingState.None);
  const [results, setResults] = useState<
  ProjectFolderDto[]
  >([]);
  const [totalProjects, setTotalProjects] = useState<number|undefined>(undefined);
  const { delay } = useQueryDelay(searchQuery);
  const { reportApi } = useApi();
  const {user} = useAuth();

  const load = async () => {
    setState(LoadingState.Loading);

    try {
      const searchResult = await reportApi.apiReportAllFoldersPost({
        searchProjectRequest: {
          page: page,
          pageSize: amount,
          sortMode: sortMode,
          searchQuery: searchQuery
        }
      });
      setResults(searchResult.all);
      setTotalProjects(searchResult.totalCount);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    load();
  }, [page, amount, sortMode, delay, user?.teamId]);

  return {
    projects: results,
    totalProjects: totalProjects,
    searchState: state,
    reload: load
  };
}
