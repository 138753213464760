import { useState, useEffect } from "react";
import { ReportSort, VehicleOrderDto } from "../lib-api/models";
import { LoadingState } from "./loading";
import useApi from "./api";
import { useQueryDelay } from "./queryDelay";
import useAuth from "./auth";
import { useHistory } from "react-router";

export function useReports(page: number, amount: number, sortMode: ReportSort, searchQuery: string, hidePending: boolean, onlyMine: boolean, projectFolderId: string) {
  const [state, setState] = useState(LoadingState.None);
  const [results, setResults] = useState<
    VehicleOrderDto[]
  >([]);
  const [totalOrders, setTotalOrders] = useState<number|undefined>(undefined);
  const { delay } = useQueryDelay(searchQuery);
  const { reportApi } = useApi();
  const {user} = useAuth();
  const history = useHistory();

  const load = async () => {
    setState(LoadingState.Loading);

    try {
      const searchResult = await reportApi.apiReportAllReportsPost({
        searchOrderRequest: {
          page: page,
          pageSize: amount,
          sortMode: sortMode,
          onlyMyReports: onlyMine,
          onlyCompleted: hidePending,
          searchQuery: searchQuery,
          folderId: projectFolderId
        }
      });
      setResults(searchResult.all);
      setTotalOrders(searchResult.totalCount);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {

    const params = new URLSearchParams();
    params.set("page", page.toString());
    params.set("sortMode", sortMode.toString());
    params.set("searchQuery", searchQuery.toString());
    params.set("hidePending", hidePending.toString());
    history.push({search: params.toString()});

    load();
  }, [page, amount, sortMode, onlyMine, hidePending, projectFolderId, delay, user?.teamId]);

  return {
    results: results,
    totalOrders: totalOrders,
    searchState: state,
    reload: load
  };
}
