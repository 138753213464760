import { Button, Grid, makeStyles, Radio } from "@material-ui/core";
import { ColDef, RowsProp, SortItem, ValueFormatterParams } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useApi from "../../../../hooks/api";
import { useWindowSize } from "../../../../hooks/windowSize";
import { useAdminUser } from "../../../../hooksAdmin/user";
import { TeamDto, TeamMemberDto, UserSort } from "../../../../lib-api";
import { CustomDataGrid } from "../../../shared/datagrid/CustomDataGrid";
import { CustomDataGridHeader } from "../../../shared/datagrid/CustomDataGridHeader";
import { CustomSwitch } from "../../../shared/forms/CustomSwitch";
import { Breadcrumb } from "../../../shared/other/Breadcrumb";
import { DeletionIcon } from "../../../shared/other/Icons";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  },
  form: theme.form,
  radioButton: {
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: theme.palette.success.main
    }
  }
}));

const AdminUsersPage: React.FC<{
}> = () => {
  var params = useParams<{ teamId: string }>();
  var [page, setPage] = useState<number>(1);
  var [pageSize] = useState<number>(20);
  var [searchQuery, setSearchQuery] = useState<string>("");
  var [sortMode, setSortMode] = useState<UserSort>(UserSort.Default);
  var { adminUserApi } = useApi();
  var { totalUsers, results, searchState, reload } = useAdminUser(params.teamId, page, pageSize, sortMode, searchQuery);
  var [team, setTeam] = useState<TeamDto | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(undefined);
  const history = useHistory();
  const [rows, setRows] = useState<RowsProp>([]);
  const [activeWritePermissions, setActiveWritePermissions] = useState<Array<{ id: string, value: boolean }>>([]);
  const classes = useStyles();
  const { xsDown } = useWindowSize();

  useEffect(() => {
    setActiveWritePermissions(results.map((user) => { return { id: user?.id!, value: user.roles?.includes("Write")! } }));
    setRows(results.length !== 0 ? (
      results.map((user, index) => {
        return {
          id: index,
          first: user.id,
          name: user.screenName,
          email: user.email,
          writePermission: user,
          teamOwner: user
        }
      })) : []);
  }, [results]);

  useEffect(() => {
    getTeamName();
  }, []);

  const handleSortModelChange = (model: SortItem) => {
    if (model == null || model == undefined) {
      setSortMode(UserSort.Default);
      return;
    }
    if (model.field == "name" && model.sort == "asc") {
      setSortMode(UserSort.Name);
      return;
    }
    if (model.field == "name" && model.sort == "desc") {
      setSortMode(UserSort.NameDesc);
      return;
    }
    if (model.field == "email" && model.sort == "asc") {
      setSortMode(UserSort.Email);
      return;
    }
    if (model.field == "email" && model.sort == "desc") {
      setSortMode(UserSort.EmailDesc);
      return;
    }
  }

  const getTeamName = async () => {
    var result = await adminUserApi.apiAdminUserGetteamPost({ adminGetTeamRequest: { teamId: params.teamId } });
    setTeam(result);
  }

  const updateTeamName = async (newName: string) => {
    var result = await adminUserApi.apiAdminUserUpdateteamnamePost({ adminUpdateTeamNameRequest: { teamId: params.teamId, name: newName } });
    setTeam(result);
  }

  const deleteMember = async (userId: string) => {
    await adminUserApi.apiAdminUserDeleteDelete({ deleteRequest: { teamMemberId: userId } });
    reload();
  }

  const updateTeamOwner = async (user: TeamMemberDto) => {
    await adminUserApi.apiAdminUserUpdateteamownerPut({ adminUpdateTeamOwnerRequest: { teamMemberId: user.id! } });
    reload();
  }

  const updateWritePermissionForMember = async (event: any, params: ValueFormatterParams) => {
    var temp = activeWritePermissions.filter(p => p.id != (params.value as TeamMemberDto)?.id);
    temp.push({ value: event.target.checked, id: (params.value as TeamMemberDto)?.id! });
    setActiveWritePermissions(temp);

    await adminUserApi.apiAdminUserUpdatepermissionsPut({
      adminUpdatePermissionsRequest: {
        value: event.target.checked,
        teamMemberId: (params.value as TeamMemberDto).id!,
        permission: "Write"
      }
    });
  }

  const columns: ColDef[] = [
    { field: 'name', headerName: 'NAME', flex: 3 },
    { field: 'email', headerName: 'EMAIL', flex: 3 },
    {
      field: 'teamOwner', headerName: 'TEAM OWNER', hide: xsDown, flex: 2, disableClickEventBubbling: true, renderCell: (params: ValueFormatterParams) => (
        <>
          <div className={classes.radioButton}>
            <Radio
              checked={(params.value as TeamMemberDto)?.roles?.includes("TeamOwner")}
              onChange={() => updateTeamOwner(params.value as TeamMemberDto)}
              value={(params.value as TeamMemberDto)?.id}
              name="radio-button-teamowner"
            />
          </div>
        </>
      )
    },
    {
      field: 'writePermission', headerName: 'WRITE ACCESS', hide: xsDown, flex: 2, renderCell: (params: ValueFormatterParams) => (<>
        <CustomSwitch
          checked={activeWritePermissions.find(p => p.id == (params.value as TeamMemberDto)?.id)?.value}
          onClick={(event) => {
            updateWritePermissionForMember(event, params);
          }}></CustomSwitch>
      </>), sortable: false, disableClickEventBubbling: true
    },
    { field: 'first', disableClickEventBubbling: true, headerName: ' ', flex: 1, renderCell: (params: ValueFormatterParams) => (<DeletionIcon onClick={() => deleteMember(params.value as string)} hoverText="Delete member"></DeletionIcon>), sortable: false },
  ];

  return (
    <>
    <CustomDataGridHeader headerProps={{
      isTab: true,
      displayHtml: <Breadcrumb onCurrentPathEdit={(newName: string) => updateTeamName(newName)} currentPath={team?.name}></Breadcrumb>
    }}></CustomDataGridHeader>
    <CustomDataGrid
      onRowSelected={(data) => setSelectedUserId(data.data.teamOwner.userId)}
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      page={page}
      setPage={(page: number) => { setPage(page) }}
      loadingState={searchState}
      totalRows={totalUsers}
      handleSortModelChange={(params: any) => handleSortModelChange(params.sortModel[0])}
      dataDisplayText="users"
      headerProps={{
        filters: [
          {
            name: "Add to more teams",
            onClick: (e) => history.push(`/admin/users/${selectedUserId}/connecttoteams`),
            type: "button",
            position: "right",
            loadingText: "",
            isLoading: false,
            show: selectedUserId != undefined
          },
          {
            name: "Add user",
            onClick: (e) => history.push(`/admin/teams/${params.teamId}/createuser`),
            type: "button",
            position: "right",
            loadingText: "",
            isLoading: false
          },
          {
            name: "Search",
            onChange: (e) => setSearchQuery(e.target.value),
            type: "search",
            position: "right",
          }],
        displayHeadline: team?.name!,
        isTab: true
      }}
    ></CustomDataGrid>
    </>
  );
};

export default AdminUsersPage;
