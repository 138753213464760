import React from "react";
import useAuth from "../../../hooks/auth";
import LoginForm from "../../shared/forms/authentication/LoginForm";
import ResetPasswordForm from "../../shared/forms/authentication/ResetPasswordForm";
import { useHistory } from "react-router";
import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { CreateReport } from "../prevention/CreateReport";
import PreventionPage from "../prevention/PreventionPage";

const useStyles = makeStyles((theme) => ({
  bgImage: {
    backgroundImage: "url('/Bg.png')",
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "right"
  },
  rootContainer: {
    marginTop: "0px",
    marginBottom: "0px",
  },
  bgImageContainer: {
    marginTop: "20vh"
  },
  triImage:{
    backgroundImage: "url('/pattern_tri.png')",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "55vw",
    backgroundPositionY: "60vh",
    backgroundSize: "300px"
  }
}));

const HomePage: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const history = useHistory();

  const classes = useStyles();

  const getPage = () => {
    var result;
    switch (history.location.pathname) {
      case "/reset":
        result = <ResetPasswordForm></ResetPasswordForm>;
        break;
      default:
        result = <LoginForm onLoggedIn={() => history.push("/")}></LoginForm>;
        break;
    }

    return <Grid className={classes.rootContainer} container>
      <Hidden xsDown>
      <Grid className={classes.bgImage} item sm={4}>
        <Grid className={classes.bgImageContainer} container>
          <Grid item xs={2}></Grid>
          <Grid item xs={6}>
            <Typography variant="h5" color="textSecondary">The leading automotive fraud detection system</Typography>
          </Grid>
        </Grid>
      </Grid>
      </Hidden>
      <Grid className={classes.triImage} item xs={12} sm={8}>
        <Grid className={classes.bgImageContainer} container>
          <Grid item xs={2}>
          </Grid>
          <Grid item sm={5} xs={8}>
            {result}
          </Grid>
        </Grid>
      </Grid></Grid>;
  }

  return (
    <div>
      {!isLoggedIn() && getPage()}
      {isLoggedIn() ?
        <>
          <PreventionPage></PreventionPage>
        </>
        : null}
    </div>
  );
};

export default HomePage;
