/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchVehicleIdentifier
 */
export interface SearchVehicleIdentifier {
    /**
     * 
     * @type {string}
     * @memberof SearchVehicleIdentifier
     */
    regNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchVehicleIdentifier
     */
    chassisNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchVehicleIdentifier
     */
    name?: string | null;
}

export function SearchVehicleIdentifierFromJSON(json: any): SearchVehicleIdentifier {
    return SearchVehicleIdentifierFromJSONTyped(json, false);
}

export function SearchVehicleIdentifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchVehicleIdentifier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'chassisNumber': !exists(json, 'chassisNumber') ? undefined : json['chassisNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function SearchVehicleIdentifierToJSON(value?: SearchVehicleIdentifier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regNr': value.regNr,
        'chassisNumber': value.chassisNumber,
        'name': value.name,
    };
}


