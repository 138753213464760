import { createStyles, TextField, Theme, withStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

export const CustomTextField = withStyles((theme: Theme) =>
createStyles({
  root: {
    'label + &': {
      marginTop: theme.spacing(2),
      minHeight: "44px"
    },
    '& .MuiInputBase-root':{
      minHeight: "44px",
      height: "44px",
      fontSize: "14px",
      backgroundColor: "white"
    },
    '& .MuiInputBase-input.MuiAutocomplete-input':{
      padding: "0px 4px!important",
      textTransform: "uppercase"
    },
    '& .MuiOutlinedInput-multiline':{
      height: "unset!important"
    },
    '& fieldset:hover':{
      borderColor: "rgb(178, 182, 185)"
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: "3px",
    },
    '& fieldset': {
      borderColor: "#b2b6b9"
    }
  }
}),
)(TextField);

export const CustomDatePicker = withStyles((theme: Theme) =>
createStyles({
  root: {
    'label + &': {
      marginTop: theme.spacing(2),
      height: "44px"
    },
    '& .MuiInputBase-root':{
      height: "44px",
      fontSize: "14px",
      backgroundColor: "white"
    },
    '& .MuiInputBase-input':{
      height: "44px",
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: "0px"
    },
    '& fieldset:hover':{
      borderColor: "rgb(178, 182, 185)"
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: "3px",
    },
    '& fieldset': {
      borderColor: "#b2b6b9"
    }
  }
}),
)(KeyboardDatePicker);