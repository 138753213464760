import { ExportApi, PortfolioApi } from "../lib-api";
import {
  AdminReportApi,
  AdminUserApi,
  AuthApi,
  Configuration, ReportApi, SearchAgentApi, SearchApi, StatisticsApi, UserApi, VINCheckerApi, BreadcrumbApi, AdminVinInfoApi
} from "../lib-api";
import useMiddleware from "./middleware";

const basePath = `${window.location.protocol}//${window.location.host}`;
export const defaultApiConfiguration = new Configuration({
  basePath,
});

export const useApiConfiguration = () => {
  const {middleware, state} = useMiddleware();
  if (state.authInfo != null) {
    return new Configuration({
      basePath: basePath,
      headers: {
        Authorization: `Bearer ${state.authInfo.token!}`,
      },
      middleware: [middleware],
    });
  }
  return defaultApiConfiguration;
};

const useApi = () => {
  const apiConfiguration = useApiConfiguration();
  return {
    searchAgentApi: new SearchAgentApi(apiConfiguration),
    searchApi: new SearchApi(apiConfiguration),
    vinCheckersApi: new VINCheckerApi(apiConfiguration),
    userApi: new UserApi(apiConfiguration),
    reportApi: new ReportApi(apiConfiguration),
    adminUserApi: new AdminUserApi(apiConfiguration),
    adminReportApi: new AdminReportApi(apiConfiguration),
    adminVinInfoApi: new AdminVinInfoApi(apiConfiguration),
    statisticsApi: new StatisticsApi(apiConfiguration),
    authApi: new AuthApi(apiConfiguration),
    breadCrumbApi: new BreadcrumbApi(apiConfiguration),
    portfolioApi: new PortfolioApi(apiConfiguration),
    exportApi: new ExportApi(apiConfiguration)
  };
};

export default useApi;
