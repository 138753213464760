import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Form, Formik, useFormik } from "formik";
import { Grid, Link, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import { PasswordSignInRequest } from "../../../../lib-api";
import useAuth from "../../../../hooks/auth";
import { object, string } from "yup";
import useApi from "../../../../hooks/api";
import { InputField } from "../InputField";

const useStyles = makeStyles((theme) => ({
  dataGridWrapper: {
    "& > div": {
      height: "auto!important"
    }
  },
  end: {
    textAlign: "end"
  },
  form: theme.form
}));

const ResetPasswordForm: React.FC = (props) => {
  const { authApi } = useApi();

  const classes = useStyles();

  const [resetTokenSent, setResetTokenSent] = useState(false);

  return (
    <>
      <>
      <Typography variant="h5" color="textPrimary">CUSTOMER LOGIN</Typography>
        <Typography variant="h3" color="primary">Reset password</Typography>
        <Typography variant="body1">Send an e-mail with a link to reset your password.</Typography>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={object({
            email: string().required("No email provided").email(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await authApi.apiAuthResettokenPost({ email: values.email });
              setResetTokenSent(true);
              setSubmitting(false);
            } catch {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form className={classes.form}>
              <Grid container className={classes.dataGridWrapper}>
                <Grid item xs={12}>
                  <InputField
                    name="email"
                    type="email"
                    placeholder="Email Address"
                  ></InputField>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                    <Button type="submit">
                      {isSubmitting ? "Sending e-mail..." : "Send link"}
                    </Button>
                  {resetTokenSent ?
                    <Typography align="center" variant="body1">E-mail sent. Didn't recieve one? <Link onClick={() => handleSubmit()}>Send again</Link></Typography>
                    : null}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </>
    </>
  );
};

export default ResetPasswordForm;
