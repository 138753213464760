/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VINCheckerMeta,
    VINCheckerMetaFromJSON,
    VINCheckerMetaFromJSONTyped,
    VINCheckerMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface VINCheckerDto
 */
export interface VINCheckerDto {
    /**
     * 
     * @type {string}
     * @memberof VINCheckerDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VINCheckerDto
     */
    vin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VINCheckerDto
     */
    teamMemberId?: string;
    /**
     * 
     * @type {string}
     * @memberof VINCheckerDto
     */
    creatorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VINCheckerDto
     */
    vinCheckerMetaId?: string;
    /**
     * 
     * @type {VINCheckerMeta}
     * @memberof VINCheckerDto
     */
    vinCheckerMeta?: VINCheckerMeta;
    /**
     * 
     * @type {Date}
     * @memberof VINCheckerDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VINCheckerDto
     */
    lastChecked?: Date | null;
}

export function VINCheckerDtoFromJSON(json: any): VINCheckerDto {
    return VINCheckerDtoFromJSONTyped(json, false);
}

export function VINCheckerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VINCheckerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'teamMemberId': !exists(json, 'teamMemberId') ? undefined : json['teamMemberId'],
        'creatorName': !exists(json, 'creatorName') ? undefined : json['creatorName'],
        'vinCheckerMetaId': !exists(json, 'vinCheckerMetaId') ? undefined : json['vinCheckerMetaId'],
        'vinCheckerMeta': !exists(json, 'vinCheckerMeta') ? undefined : VINCheckerMetaFromJSON(json['vinCheckerMeta']),
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'lastChecked': !exists(json, 'lastChecked') ? undefined : (json['lastChecked'] === null ? null : new Date(json['lastChecked'])),
    };
}

export function VINCheckerDtoToJSON(value?: VINCheckerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vin': value.vin,
        'teamMemberId': value.teamMemberId,
        'creatorName': value.creatorName,
        'vinCheckerMetaId': value.vinCheckerMetaId,
        'vinCheckerMeta': VINCheckerMetaToJSON(value.vinCheckerMeta),
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'lastChecked': value.lastChecked === undefined ? undefined : (value.lastChecked === null ? null : value.lastChecked.toISOString()),
    };
}


