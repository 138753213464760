import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { ColDef, DataGrid, RowsProp, SortItem, ValueFormatterParams } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import useApi from "../../../hooks/api";
import useAuth from "../../../hooks/auth";
import { useUser } from "../../../hooks/user";
import { DeletionIcon } from "../../shared/other/Icons";
import {CustomSwitch} from "../../shared/forms/CustomSwitch";
import {CustomDataGrid} from "../../shared/datagrid/CustomDataGrid";
import {DataGridHeaderFilter} from "../../shared/datagrid/CustomDataGridHeader";
import { TeamMemberDto, UserSort } from "../../../lib-api";
import { useWindowSize } from "../../../hooks/windowSize";

export const Members: React.FunctionComponent<{

}> = (props) => {
  var { isTeamOwner } = useAuth();
  var [page, setPage] = useState<number>(1);
  var [searchQuery, setSearchQuery] = useState<string>("");
  var [pageSize] = useState<number>(20);
  var [sortMode, setSortMode] = useState<UserSort>(UserSort.Default);
  var { userApi } = useApi();
  var { totalUsers, results, searchState, reload } = useUser(page, pageSize, sortMode, searchQuery);
  const {xsDown} = useWindowSize();

  const [activeWritePermissions, setActiveWritePermissions] = useState<Array<{ id: string, value: boolean }>>([]);

  const [rows, setRows] = useState<RowsProp>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setActiveWritePermissions(results.map((user) => { return { id: user?.id!, value: user.roles?.includes("Write")! } }));
    setRows(results.length !== 0 ? (
      results.map((user, index) => {
        return {
          id: index,
          first: user.id,
          name: user.screenName,
          email: user.email,
          writePermission: user
        }
      })) : []);
  }, [results]);

  const getFilters = () => {
    var filters: DataGridHeaderFilter[] = [];
    if(isTeamOwner()){
      filters.push({type: "link", link: "/myaccount/addmember", name: "+ Add member", position: "right"});
    }
    filters.push({type: "search", onChange: (e) => setSearchQuery(e.target.value), position: "right", name: "Search"});

    return filters;
  }

  const handleSortModelChange = (model: SortItem) => {
    if (model == null || model == undefined) {
      setSortMode(UserSort.Default);
      return;
    }
    if (model.field == "name" && model.sort == "asc") {
      setSortMode(UserSort.Name);
      return;
    }
    if (model.field == "name" && model.sort == "desc") {
      setSortMode(UserSort.NameDesc);
      return;
    }
    if (model.field == "email" && model.sort == "asc") {
      setSortMode(UserSort.Email);
      return;
    }
    if (model.field == "email" && model.sort == "desc") {
      setSortMode(UserSort.EmailDesc);
      return;
    }
  }

  const deleteMember = async (teamMemberId: string) => {
    try {
      if (isTeamOwner()) {
        await userApi.apiUserDeleteDelete({ deleteRequest: { teamMemberId: teamMemberId } });
        enqueueSnackbar("Member has been removed!", { variant: "success" });
        reload();
      } else {
        enqueueSnackbar("Only the team owner can remove members.", { variant: "error" });
      }
    } catch {
    }
  }

  const updateWritePermissionForMember = async (event: any, params: ValueFormatterParams) => {
    var temp = activeWritePermissions.filter(p => p.id != (params.value as TeamMemberDto)?.id);
    temp.push({ value: !event.target.checked, id: (params.value as TeamMemberDto)?.id! });
    setActiveWritePermissions(temp);

    await userApi.apiUserUpdatepermissionsPut({
      updatePermissionsRequest: {
        value: !event.target.checked,
        teamMemberId: (params.value as TeamMemberDto).id!,
        permission: "Write"
      }
    });
  }

  const columns: ColDef[] = [
    { field: 'name', headerName: 'NAME', flex: 2 },
    { field: 'email', headerName: 'EMAIL', flex: 2 },
    {
      field: 'writePermission', headerName: 'FULL ACCESS', flex: 1, renderCell: (params: ValueFormatterParams) => (<>
        <CustomSwitch
          disabled={!isTeamOwner()}
          checked={activeWritePermissions.find(p => p.id == (params.value as TeamMemberDto)?.id)?.value}
          onClick={(event) => {
            updateWritePermissionForMember(event, params);
          }}></CustomSwitch>
      </>), sortable: false, hide: xsDown
    },
    { field: 'first', headerName: " ", disableClickEventBubbling: true, flex: 1, renderCell: (params: ValueFormatterParams) => (<DeletionIcon onClick={() => deleteMember(params.value as string)} hoverText="Delete member"></DeletionIcon>), sortable: false }
  ];

  return (
    <>
        <CustomDataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          page={page}
          setPage={(pageNumber: number) => { setPage(pageNumber) }}
          loadingState={searchState}
          totalRows={totalUsers}
          handleSortModelChange={(params: any) => handleSortModelChange(params.sortModel[0])}
          dataDisplayText="team members"
          headerProps={{displayHeadline: "TEAM MEMBERS", filters: getFilters(), isTab: true
        }}
        ></CustomDataGrid>
    </>
  );
};
