import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { boolean, object, string } from "yup";
import useApi from "../../../../hooks/api";
import { AdminCreateUserRequest } from "../../../../lib-api";
import { CheckboxField } from "../../../shared/forms/CheckboxField";
import { InputField } from "../../../shared/forms/InputField";
import { Breadcrumb } from "../../../shared/other/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end",
  },
  form: theme.form,
}));

const AdminAddUserPage: React.FC<{}> = (props) => {
  var params = useParams<{ teamId: string }>();
  var { adminUserApi } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item sm={3}></Grid>
      <Grid item sm={9}>
        <Breadcrumb currentPath="New user"></Breadcrumb>
      </Grid>
      <Grid item sm={3}></Grid>
      <Grid item sm={6}>
        <Paper>
          <Typography align="center" variant="h4">
            Add user
          </Typography>
          <Formik
            enableReinitialize={true}
            initialValues={{
              newName: "",
              newEmail: "",
              newPassword: "",
              isTeamOwner: false,
            }}
            validationSchema={object({
              newName: string().required("No name provided"),
              newPassword: string()
                .required("No password provided")
                .min(14, "Password is too short - min 14 chars"),
              newEmail: string()
                .required("No email provided")
                .email("Not a valid email"),
              isTeamOwner: boolean(),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              var request: AdminCreateUserRequest = {
                name: values.newName,
                password: values.newPassword,
                email: values.newEmail,
                isTeamOwner: values.isTeamOwner,
                teamId: params.teamId,
              };
              try {
                const user =
                  await adminUserApi.apiAdminUserCreateandconnectPost({
                    adminCreateUserRequest: request,
                  });
                enqueueSnackbar(user.screenName + " has been created!", {
                  variant: "success",
                });
                history.push(`/admin/teams/${params.teamId}`);
                setSubmitting(false);
              } catch (error) {
                resetForm();
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className={classes.form}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputField
                      name="newName"
                      type="text"
                      placeholder="Name"
                    ></InputField>
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      name="newEmail"
                      type="text"
                      placeholder="Email"
                    ></InputField>
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      name="newPassword"
                      type="text"
                      placeholder="Password"
                    ></InputField>
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxField
                      name="isTeamOwner"
                      type="checkbox"
                      placeholder="Team owner"
                    >
                      Team owner
                    </CheckboxField>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.end}>
                      <Button color="primary" type="submit">
                        {isSubmitting ? "Adding new member..." : "Add"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AdminAddUserPage;
