/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PopulationFields,
    PopulationFieldsFromJSON,
    PopulationFieldsFromJSONTyped,
    PopulationFieldsToJSON,
    SearchForm,
    SearchFormFromJSON,
    SearchFormFromJSONTyped,
    SearchFormToJSON,
} from './';

/**
 * 
 * @export
 * @interface InitSearchFields
 */
export interface InitSearchFields {
    /**
     * 
     * @type {SearchForm}
     * @memberof InitSearchFields
     */
    fraudSearchForm?: SearchForm;
    /**
     * 
     * @type {PopulationFields}
     * @memberof InitSearchFields
     */
    populationFields?: PopulationFields;
}

export function InitSearchFieldsFromJSON(json: any): InitSearchFields {
    return InitSearchFieldsFromJSONTyped(json, false);
}

export function InitSearchFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitSearchFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fraudSearchForm': !exists(json, 'fraudSearchForm') ? undefined : SearchFormFromJSON(json['fraudSearchForm']),
        'populationFields': !exists(json, 'populationFields') ? undefined : PopulationFieldsFromJSON(json['populationFields']),
    };
}

export function InitSearchFieldsToJSON(value?: InitSearchFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fraudSearchForm': SearchFormToJSON(value.fraudSearchForm),
        'populationFields': PopulationFieldsToJSON(value.populationFields),
    };
}


