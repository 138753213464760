/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChartType,
    ChartTypeFromJSON,
    ChartTypeFromJSONTyped,
    ChartTypeToJSON,
    ToldsynRequest,
    ToldsynRequestFromJSON,
    ToldsynRequestFromJSONTyped,
    ToldsynRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChartVinsToCsvRequest
 */
export interface ChartVinsToCsvRequest {
    /**
     * 
     * @type {ToldsynRequest}
     * @memberof ChartVinsToCsvRequest
     */
    params?: ToldsynRequest;
    /**
     * 
     * @type {ChartType}
     * @memberof ChartVinsToCsvRequest
     */
    chartType?: ChartType;
}

export function ChartVinsToCsvRequestFromJSON(json: any): ChartVinsToCsvRequest {
    return ChartVinsToCsvRequestFromJSONTyped(json, false);
}

export function ChartVinsToCsvRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChartVinsToCsvRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'params': !exists(json, 'params') ? undefined : ToldsynRequestFromJSON(json['params']),
        'chartType': !exists(json, 'chartType') ? undefined : ChartTypeFromJSON(json['chartType']),
    };
}

export function ChartVinsToCsvRequestToJSON(value?: ChartVinsToCsvRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'params': ToldsynRequestToJSON(value.params),
        'chartType': ChartTypeToJSON(value.chartType),
    };
}


