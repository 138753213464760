import { ChartOptions } from "chart.js";
import { useCallback, useState } from "react";
import {useHelper} from "./helper";
import { Line } from "react-chartjs-2";
import { useTheme } from "@material-ui/core";

export function useChartOptions<T>(chartRef: React.RefObject<Line>, isTimeChart: boolean = false, yPriceSuffix: boolean = false, displayLegend: boolean = false) {

  const { getAsThousandSeperator } = useHelper();
  const theme = useTheme();

  const options = useCallback(() => {
    return {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: function(value) {if ((value as number) % 1 === 0) {return getAsThousandSeperator(value);}},
            fontFamily: theme.typography.fontFamily
          }
        }],
        xAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            fontFamily: theme.typography.fontFamily,
            beginAtZero: false
          },
          type: isTimeChart ? "time" : "category",
          time: {
            minUnit: "month"
          },
          bounds: "ticks"
          
        }]
      },
      elements: {
        line: {
          tension: 0,
          fill: false,
          borderWidth: 2
        },
        point: {
          backgroundColor: 'white',
          radius: 7,
          borderWidth: 2,
          hoverRadius: 7,
          hoverBorderWidth: 2
        }
      },
      legend: {
        labels: {
          fontFamily: theme.typography.fontFamily
        },
        display: displayLegend
      },
      tooltips: {
        callbacks: {
          label: function(tooltip, data) {return data.datasets != undefined && tooltip.datasetIndex != undefined ? data.datasets[tooltip.datasetIndex].label + ": " + getAsThousandSeperator(tooltip.yLabel) + (yPriceSuffix ? " kr." : "") : "";}
        },
        footerFontFamily: theme.typography.fontFamily,
        bodyFontFamily: theme.typography.fontFamily,
        titleFontFamily: theme.typography.fontFamily
      },
      animation: {
        onComplete: () => {
          var ctx = chartRef.current?.chartInstance.ctx;
          // if(ctx != null){
          //   ctx.font = "12px " + theme.typography.fontFamily as string;
          // }
        }
      },
      layout: {
        
      }
    } as ChartOptions;
  }, [displayLegend])();

  return [options];
}
