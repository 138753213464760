import { Avatar, Grid, LinearProgress, makeStyles, Slide, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useReport } from "../../../hooks/report";
import { useTimer } from "../../../hooks/timer";
import PublicIcon from '@material-ui/icons/Public';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  },
  form: theme.form,
  tabContainer: {
    marginTop: theme.spacing(3)
  },
  link: theme.link
}));

const PreviewPage = () => {

  const classes = useStyles();

  const params = useParams<{ orderId: string }>();
  const history = useHistory();

  const [globalProgess, setGlobalProgress] = useState(0);
  const [danishDataProgess, setDanishProgress] = useState(0);
  const [nonDanishDataProgess, setNonDanishProgress] = useState(0);
  const [otherProgess, setOtherProgress] = useState(0);

  const { pingOrder, orderFinished } = useReport();

  const globalTimer = useTimer(1);
  const fastTimer = useTimer(1);

  useEffect(() => {
    if (orderFinished) {
      setGlobalProgress(100);
      setTimeout(() => {
        history.push(`/reports/${params.orderId}`);
      }, 500);
    }
  }, [orderFinished])

  useEffect(() => {
    if (orderFinished) {
      return;
    }

    if (globalTimer.timer >= 5 && globalTimer.timer % 5 == 0) { //check om rapport er færdig hvert 5. sekund
      pingOrder(params.orderId);
    }

    updateGlobalProgress(globalTimer.timer);

    if(danishDataProgess != 100){
      updateDanishProgress(globalTimer.timer);
    }
  }, [globalTimer.timer]);

  useEffect(() => {
    if (orderFinished) {
      return;
    }

    if (danishDataProgess == 100 && nonDanishDataProgess != 100) {
      updateNonDanishProgress(fastTimer.timer);
    }
    if (nonDanishDataProgess == 100 && otherProgess != 100) {
      updateOtherProgress(fastTimer.timer);
    }
  }, [fastTimer.timer]);

  const updateGlobalProgress = (timer: number) => {
    const MaxSeconds = 50;
    const MinSeconds = 0;

    var random = Math.random() * 2;
    var value = ((timer - MinSeconds) * 100 / (MaxSeconds - MinSeconds)) + random;
    setGlobalProgress(value > 100 ? 100 : parseInt(value.toFixed(0)));
  }

  const updateDanishProgress = (timer: number) => {
    const MaxSeconds = 12;
    const MinSeconds = 0;

    if (globalTimer.timer % 3 == 0) {
      var value = ((timer - MinSeconds) * 100 / (MaxSeconds - MinSeconds));
      setDanishProgress(value > 100 ? 100 : parseInt(value.toFixed(0)));
    }
  }

  const updateNonDanishProgress = (timer: number) => {
    const MaxSeconds = 22;
    const MinSeconds = 0;

    var value = ((timer - MinSeconds) * 100 / (MaxSeconds - MinSeconds));
    setNonDanishProgress(value > 100 ? 100 : parseInt(value.toFixed(0)));
  }

  const updateOtherProgress = (timer: number) => {
    const MaxSeconds = 16;
    const MinSeconds = 0;

    if (globalTimer.timer % 2 == 0) {
      var value = ((timer - MinSeconds) * 100 / (MaxSeconds - MinSeconds));
      setOtherProgress(value > 100 ? 100 : parseInt(value.toFixed(0)));
    }
  }

  const getDanishRegistry = useMemo(() => {
    switch (danishDataProgess) {
      case 0:
        return "Motorregisteret";
      case 25:
        return "Trafikstyrrelsen";
      case 50:
        return "Tinglysningen";
      case 75:
        return "Politiet";
      default:
        return "Done";
    }
  }, [danishDataProgess]);

  const getEuropeanRegistry = useMemo(() => {
    var value = parseInt((nonDanishDataProgess / (1 * 100 / 22)).toFixed(0));
    switch (value) {
      case 0:
        return "Sweden";
      case 1:
        return "Norway";
      case 2:
        return "Finland";
      case 3:
        return "Italy";
      case 4:
        return "France";
      case 5:
        return "Germany";
      case 6:
        return "Belgium";
      case 7:
        return "Spain";
      case 8:
        return "Portugal";
      case 9:
        return "Croatia";
      case 10:
        return "Czech Republic";
      case 11:
        return "The netherlands";
      case 12:
        return "Greece";
      case 13:
        return "Poland";
      case 14:
        return "Ukraine";
      case 15:
        return "Romania";
      case 16:
        return "Bulgaria";
      case 17:
        return "Iceland";
      case 18:
        return "Great Britain";
      case 19:
        return "Hungary";
      case 20:
        return "Slovenia";
      case 21:
        return "Slovakia";
      case 22:
        return "Lithuania";
      case 23:
        return "Estonia";
      case 24:
        return "Latvia";
      case 25:
        return "Ireland";
      default:
        return "Done";
    }
  }, [nonDanishDataProgess]);

  const getOtherRegistry = useMemo(() => {
    var value = parseInt((otherProgess / (1 * 100 / 16)).toFixed(0));
    switch (value) {
      case 0:
        return "Bilhandel";
      case 1:
        return "Other sales ads";
      case 2:
        return "TjekBil";
      case 3:
        return "Manufacturers";
      case 4:
        return "Insurers";
      case 5:
        return "Taxreports";
      case 6:
        return "Inspection stations";
      case 7:
        return "Dealers";
      case 8:
        return "Other databases";
      default:
        return "Done";
    }
  }, [otherProgess]);

  return (
    <>
      <Grid justify="center" container spacing={3}>
        <Grid item xs={12}>
          <Typography align="center" variant="h4">{globalProgess == 100 ? "Report generated!" : "Generating report.."}</Typography>
        </Grid>
        <Grid item xs={6}>
          <LinearProgress color="primary" variant="determinate" value={globalProgess}></LinearProgress>
          <Typography align="center" variant="h5">{globalProgess}%</Typography>
        </Grid>
      </Grid>

      <Grid className={classes.tabContainer} justify="center" container spacing={3}>
        <Grid item xs={6}>
          <Slide onExit={() => { fastTimer.setTimer(0); }} direction={danishDataProgess < 50 && globalProgess != 100 ? "up" : "down"} in={danishDataProgess != 100 && globalProgess != 100} mountOnEnter unmountOnExit>
            <Grid container className={classes.tabContainer} alignItems="center">
              <Grid item xs={1}>
                <Avatar>
                  <PublicIcon color="primary" />
                </Avatar>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h5">Danish registries</Typography>
                <Typography variant="h5">Checking: {getDanishRegistry}</Typography>
                <LinearProgress color="primary" variant="determinate" value={danishDataProgess}></LinearProgress>
              </Grid>
              <Grid item xs={2}>
                <Typography align="right" variant="h5">{danishDataProgess}%</Typography>
              </Grid>
            </Grid>
          </Slide>
          <Slide onExit={() => { fastTimer.setTimer(0); }} direction={danishDataProgess == 100 && nonDanishDataProgess < 50 && globalProgess != 100 ? "up" : "down"} in={danishDataProgess == 100 && nonDanishDataProgess != 100 && globalProgess != 100} mountOnEnter unmountOnExit>
            <Grid container className={classes.tabContainer} alignItems="center">
              <Grid item xs={1}>
                <Avatar>
                  <LanguageIcon color="primary" />
                </Avatar>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h5">European registries</Typography>
                <Typography variant="h5">Checking: {getEuropeanRegistry}</Typography>
                <LinearProgress color="primary" variant="determinate" value={nonDanishDataProgess}></LinearProgress>
              </Grid>
              <Grid item xs={2}>
                <Typography align="right" variant="h5">{nonDanishDataProgess}%</Typography>
              </Grid>
            </Grid>
          </Slide>
          <Slide direction={nonDanishDataProgess == 100 && otherProgess < 50 && globalProgess != 100 ? "up" : "down"} in={nonDanishDataProgess == 100 && otherProgess != 100 && globalProgess != 100} mountOnEnter unmountOnExit>
            <Grid container className={classes.tabContainer} alignItems="center">
              <Grid item xs={1}>
                <Avatar>
                  <LanguageIcon color="primary" />
                </Avatar>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h5">Other sources</Typography>
                <Typography variant="h5">Checking: {getOtherRegistry}</Typography>
                <LinearProgress color="primary" variant="determinate" value={otherProgess}></LinearProgress>
              </Grid>
              <Grid item xs={2}>
                <Typography align="right" variant="h5">{otherProgess}%</Typography>
              </Grid>
            </Grid>
          </Slide>
        </Grid>
      </Grid>
    </>
  );
};

export default PreviewPage;
