/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WimCheckResponse
 */
export interface WimCheckResponse {
    /**
     * 
     * @type {string}
     * @memberof WimCheckResponse
     */
    manufacturer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WimCheckResponse
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WimCheckResponse
     */
    checkStatus?: string | null;
}

export function WimCheckResponseFromJSON(json: any): WimCheckResponse {
    return WimCheckResponseFromJSONTyped(json, false);
}

export function WimCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WimCheckResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'checkStatus': !exists(json, 'checkStatus') ? undefined : json['checkStatus'],
    };
}

export function WimCheckResponseToJSON(value?: WimCheckResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'manufacturer': value.manufacturer,
        'country': value.country,
        'checkStatus': value.checkStatus,
    };
}


