import { Grid, makeStyles } from "@material-ui/core";
import { CellParams, ColDef, RowsProp, SortItem, SortModelParams, ValueFormatterParams } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useApi from "../../../hooks/api";
import useAuth from "../../../hooks/auth";
import { useProjects } from "../../../hooks/projects";
import { useQueryParams } from "../../../hooks/queryParams";
import { useReports } from "../../../hooks/reports";
import { ProjectSort, ReportSort, VehicleOrderDto } from "../../../lib-api";
import { CustomDataGrid } from "../../shared/datagrid/CustomDataGrid";
import { DeletionIcon, ResetIcon, ToolTipIcon } from "../../shared/other/Icons";
import { CreateReport } from "./CreateReport";
import { UploadCarverticalUrl } from "./UploadCarverticalUrl";
import { DataGridHeaderFilter } from "../../shared/datagrid/CustomDataGridHeader";
import { LoadingState } from "../../../hooks/loading";
import { useWindowSize } from "../../../hooks/windowSize";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  },
  form: theme.form,
  link: theme.link,
  headerContainer: {
    display: "inline-flex",
    alignItems: "center",
  },
  icon: {
    paddingLeft: "2px"
  }
}));

const PreventionPage = () => {
  const params = useQueryParams();

  var [page, setPage] = useState<number>(params.get("page") != null && parseInt(params.get("page")!) ? parseInt(params.get("page")!) : 1);
  const { projectId } = useParams<{ projectId: string }>();
  const { isAdmin } = useAuth();
  var [pageSize] = useState<number>(20);
  var [projectFolderId, setProjectFolderId] = useState<string>(projectId ?? "");

  var [sortMode, setSortMode] = useState<ReportSort>(params.get("sortMode") ? params.get("sortMode") as ReportSort : ReportSort.Default);
  var [searchQuery, setSearchQuery] = useState<string>(params.get("searchQuery") ?? "");
  var [hidePending, setHidePending] = useState<boolean>(params.get("hidePending") ? params.get("hidePending") == "true" : false);
  var [onlyMine, setOnlyMine] = useState<boolean>(!isAdmin());

  var { totalOrders, results, searchState, reload } = useReports(page, pageSize, sortMode, searchQuery, hidePending, onlyMine, projectFolderId);
  var { projects } = useProjects(1, 20, ProjectSort.Default, "");
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<RowsProp>([]);
  const { adminReportApi } = useApi();
  const [resettingAll, setResettingAll] = useState<LoadingState>(LoadingState.None);
  const { xsDown } = useWindowSize();

  const classes = useStyles();

  useEffect(() => {
    setRows(results.length !== 0 ? (
      results.map((order, index) => {
        var temp: any = {
          id: index,
          first: order,
          vin: order.vin,
          make: order.make,
          model: order.model,
          creator: order.teamMember?.screenName,
          created: order.createdDate,
          completed: order,
          score: order.vehicleReport?.score
        };
        if (isAdmin()) {
          temp.delete = order;
        }
        return temp;
      })) : []);
  }, [results]);

  const handleSortModelChange = (model: SortItem) => {
    if (model == null || model == undefined) {
      setSortMode(ReportSort.Default);
      return;
    }
    if (model.field == "score" && model.sort == "asc") {
      setSortMode(ReportSort.Score);
      return;
    }
    if (model.field == "score" && model.sort == "desc") {
      setSortMode(ReportSort.ScoreDesc);
      return;
    }
    if (model.field == "vin" && model.sort == "asc") {
      setSortMode(ReportSort.Vin);
      return;
    }
    if (model.field == "vin" && model.sort == "desc") {
      setSortMode(ReportSort.VinDesc);
      return;
    }
    if (model.field == "created" && model.sort == "asc") {
      setSortMode(ReportSort.DateOrder);
      return;
    }
    if (model.field == "created" && model.sort == "desc") {
      setSortMode(ReportSort.DateOrderDesc);
      return;
    }
    if (model.field == "creator" && model.sort == "asc") {
      setSortMode(ReportSort.GeneratedBy);
      return;
    }
    if (model.field == "creator" && model.sort == "desc") {
      setSortMode(ReportSort.GeneratedByDesc);
      return;
    }
  }

  const getScoreImage = (params: CellParams) => {
    var score = params.row["score"] as number;
    if (score == undefined) {
      return <></>;
    }
    if (0 <= score && score <= 33) {
      return <img src="/red.png"></img>
    }
    if (34 <= score && score <= 66) {
      return <img src="/yellow.png"></img>
    }
    if (67 <= score && score <= 100) {
      return <img src="/green.png"></img>
    }
    return <></>;
  }

  const resetAll = async () => {
    try {
      if (window.confirm("This will reset all reports and my take a few minutes. Perfect time to grab a coffee. Are you sure?")) {
        setResettingAll(LoadingState.Loading);
        await adminReportApi.apiAdminReportResetallPost();
        enqueueSnackbar("All reports reset", { variant: "success" });
        setResettingAll(LoadingState.Success);
      }
    } catch {
      setResettingAll(LoadingState.Error);
    }
  }

  const getFilters = () => {
    var result: DataGridHeaderFilter[] = [
      { name: "Hide pending reports", position: "left", onChange: (e) => setHidePending(e.target.checked), type: "bool", initVal: hidePending },
      { name: "Only show reports for my team", position: "left", onChange: (e) => setOnlyMine(e.target.checked), disabled: !isAdmin(), type: "bool", initVal: onlyMine },
      { name: "Reload data", position: "right", onClick: () => reload(), type: "reload" }
    ];

    if (isAdmin()) {
      result.push({ name: "Reset all", position: "right", type: "button", onClick: () => resetAll(), loadingText: "Resetting..", isLoading: resettingAll == LoadingState.Loading });
    }

    result.push({
      name: "Choose project",
      position: "right",
      type: "select",
      initVal: projectFolderId,
      onChange: (e) => setProjectFolderId(e.target.value == "-1" ? "" : e.target.value),
      selectOptions: projects.map((project) => { return { name: project.name!, value: project.id! } })
    });

    result.push({
      name: "Search",
      onChange: (e) => { setSearchQuery(e.target.value); setPage(1); },
      type: "search",
      position: "right",
      initVal: searchQuery
    })

    return result;
  }

  const columns: ColDef[] = [
    {
      field: 'vin', headerName: 'VIN', flex: 4
    },
    { field: 'make', headerName: 'MAKE', flex: 3, sortable: false, hide: xsDown },
    { field: 'model', headerName: 'MODEL', flex: 3, sortable: false, hide: xsDown },
    { field: 'creator', headerName: 'GENERATED BY', flex: 3, hide: xsDown },
    { field: 'created', headerName: 'ORDER DATE', hide: xsDown, flex: 2, valueFormatter: (params: ValueFormatterParams) => params.value != null ? (params.value as Date).toLocaleDateString("da") : null },
    {
      field: 'completed', hide: xsDown, headerName: 'STATUS', flex: 2, sortable: false, disableClickEventBubbling: true,
      renderCell: (params) => <>{(params.value as VehicleOrderDto).completed != null ? (params.value as VehicleOrderDto).completed ?
        <>Completed
          {isAdmin() ? <ResetIcon onClick={async () => {
            await adminReportApi.apiAdminReportResetorderPost({ vehicleOrderId: (params.value as VehicleOrderDto).id });
            enqueueSnackbar(`Report reset`, { variant: "success" });
            reload();
          }} hoverText="Reset report (All data from order is removed)"></ResetIcon> : null}
        </>
        :
        <>Pending
        </> : null}</>
    },
    {
      field: 'score', headerName: 'SCORE', flex: 2,
      renderHeader: () => <div className={classes.headerContainer}><div>SCORE</div> <ToolTipIcon className={classes.icon} hoverText="This indicates how likely it is for a vehicle to be involved in fraud."></ToolTipIcon></div>,
      renderCell: (params) => <>{getScoreImage(params)}</>
    },
  ];

  const getColumns = (columns: ColDef[]) => {
    if (isAdmin()) {
      var first = columns.shift();
      columns.unshift({
        field: 'delete', headerName: 'D', flex: 1, disableClickEventBubbling: true, renderCell: (params) => <>
          {(params.value as VehicleOrderDto).completed != null && !(params.value as VehicleOrderDto).completed ?
            <>
              <DeletionIcon onClick={async () => {
                await adminReportApi.apiAdminReportDeleteorderPost({ vehicleOrderId: (params.value as VehicleOrderDto).id });
                enqueueSnackbar(`Order deleted`, { variant: "success" });
                reload();
              }} hoverText="Delete order (Removes order from system)">
              </DeletionIcon>
            </>
            : <></>}
        </>
      });
      columns.unshift(first!);
    }
    return columns;
  }

  return (
    <Grid container spacing={3}>
      {isAdmin() ? 
      <Grid item xs={12}>
        <UploadCarverticalUrl></UploadCarverticalUrl>
      </Grid> 
      : null}
      <Grid item xs={12}>
        <CreateReport></CreateReport>
      </Grid>
      <Grid item xs={12}>
        <CustomDataGrid
          rows={rows}
          columns={getColumns(columns)}
          pageSize={pageSize}
          page={page}
          setPage={(pageNumber: number) => setPage(pageNumber)}
          loadingState={searchState}
          totalRows={totalOrders}
          handleSortModelChange={(params: SortModelParams) => handleSortModelChange(params.sortModel[0])}
          onRowClick={(row) => (row.row["first"] as VehicleOrderDto).completed ? history.push(`/reports/${row.row["first"].id}`) : null}
          dataDisplayText="reports"
          headerProps={{
            filters: getFilters()
          }}
        ></CustomDataGrid>
      </Grid>
    </Grid>
  );
};

export default PreventionPage;
