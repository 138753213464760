import { Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useParams } from "react-router";
import { LoadingState } from "../../../../hooks/loading";
import { useVINChecker } from "../../../../hooks/VINChecker";
import { RegistrationType } from "../../../../lib-api";
import { VINCheckerChangesDto } from "../../../../lib-api/models/VINCheckerChangesDto";
import { Breadcrumb } from "../../../shared/other/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  },
  form: theme.form,
  paper: {
    marginBottom: theme.spacing(3)
  },
  link: theme.link
}));

const ChangesResultPage = () => {

  const classes = useStyles();

  const params = useParams<{ VINCheckerId: string }>();
  const { vinChecker, vinCheckerState } = useVINChecker(params.VINCheckerId);

  const getEvent = (change: VINCheckerChangesDto) => {
    var result: Array<{ date: Date, eventText: string }> = [];

    for (var i = 0; i < change.inspectionChanges?.length; i++) {
      if (change.inspectionChanges[i].dateOfChange != undefined) {
        var eventText = "";
        if (change.inspectionChanges[i].failedInspection) {
          eventText = "Failed inspection";
        }
        if (change.inspectionChanges[i].skippedPeriodicInspection) {
          eventText = "Skipped periodic inspection";
        }
        var date = change.inspectionChanges[i].dateOfChange;
        result.push({ date: date!, eventText: eventText });
      }
    }
    for (var i = 0; i < change.insuranceChanges?.length; i++) {
      if (change.insuranceChanges[i].dateOfChange != undefined) {
        var eventText = `The owner ${change.insuranceChanges[i].status == "Aktiv" ? "has taken out" : "no longer has"} insurance at ${change.insuranceChanges[i].newInsuranceCompany}`;
        var date = change.insuranceChanges[i].dateOfChange;
        result.push({ date: date!, eventText: eventText });
      }
    }
    for (var i = 0; i < change.ownerChanges?.length; i++) {
      if (change.ownerChanges[i].dateOfChange != undefined) {
        var eventText = "";
        switch (change.ownerChanges[i].newStatus) {
          case RegistrationType.Disabled:
            eventText = `The vehicle is no longer registered`;
            break;
          case RegistrationType.FirstRegistration:
            eventText = `The vehicle is registered for the first time ${change.ownerChanges[i].regNr != undefined ? "with registrationnumber: " + change.ownerChanges[i].regNr : ""}`;
            break;
          case RegistrationType.Registered:
            eventText = `The vehicle is registered with registrationnumber: ${change.ownerChanges[i].regNr}`;
            break;
          default:
            eventText = `The status of the vehicle has changed to ${change.ownerChanges[i].newStatus}`;
            break;
        }
        var date = change.ownerChanges[i].dateOfChange;
        result.push({ date: date!, eventText: eventText });
      }
    }
    for (var i = 0; i < change.taxChanges?.length; i++) {
      if (change.taxChanges[i].dateOfChange != undefined) {
        var eventText = `A new tax has been payed, for ${change.taxChanges[i].newPrice}`;
        var date = change.taxChanges[i].dateOfChange;
        result.push({ date: date!, eventText: eventText });
      }
    }

    return (<>{result.sort((p, p1) => p.date.getTime() - p1.date.getTime()).map((data, index) => (
      <TableRow key={index}>
        <TableCell>
          {index + 1}
        </TableCell><TableCell align="right">{data.eventText}</TableCell><TableCell align="right">{data.date.toDateString()}</TableCell></TableRow>
    ))}</>);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
              <Breadcrumb
              currentPath={vinChecker?.vinChecker.vin}
              ></Breadcrumb>
            </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">{vinCheckerState == LoadingState.Loading ? <Skeleton /> : "Changes for " + vinChecker?.vinChecker.vin + " since " + vinChecker?.vinChecker.createdDate?.toLocaleDateString()}</Typography>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="right">Event</TableCell>
                  <TableCell align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vinChecker?.changes != undefined ? getEvent(vinChecker?.changes) :
                  <TableRow>
                    <TableCell>
                    </TableCell><TableCell align="right"></TableCell><TableCell align="right">No significant changes since {vinChecker?.vinChecker.createdDate?.toLocaleDateString()}</TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Typography align="right">{vinCheckerState == LoadingState.Loading ? null : "Last check: " + vinChecker?.vinChecker.lastChecked?.toLocaleDateString()}</Typography>
      </Grid>
    </Grid>
  );
};

export default ChangesResultPage;
