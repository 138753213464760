/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePermissionsRequest
 */
export interface UpdatePermissionsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePermissionsRequest
     */
    teamMemberId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePermissionsRequest
     */
    permission?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePermissionsRequest
     */
    value?: boolean;
}

export function UpdatePermissionsRequestFromJSON(json: any): UpdatePermissionsRequest {
    return UpdatePermissionsRequestFromJSONTyped(json, false);
}

export function UpdatePermissionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePermissionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamMemberId': !exists(json, 'teamMemberId') ? undefined : json['teamMemberId'],
        'permission': !exists(json, 'permission') ? undefined : json['permission'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function UpdatePermissionsRequestToJSON(value?: UpdatePermissionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamMemberId': value.teamMemberId,
        'permission': value.permission,
        'value': value.value,
    };
}


