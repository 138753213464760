/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TeamSort {
    Default = 'Default',
    Name = 'Name',
    NameDesc = 'NameDesc'
}

export function TeamSortFromJSON(json: any): TeamSort {
    return TeamSortFromJSONTyped(json, false);
}

export function TeamSortFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamSort {
    return json as TeamSort;
}

export function TeamSortToJSON(value?: TeamSort | null): any {
    return value as any;
}

