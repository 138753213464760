/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateRegTaxVinRequest,
    CreateRegTaxVinRequestFromJSON,
    CreateRegTaxVinRequestToJSON,
    ExportSort,
    ExportSortFromJSON,
    ExportSortToJSON,
    ExportUrlDto,
    ExportUrlDtoFromJSON,
    ExportUrlDtoToJSON,
    ExportsResponse,
    ExportsResponseFromJSON,
    ExportsResponseToJSON,
} from '../models';

export interface ApiExportAddManyPostRequest {
    createRegTaxVinRequest?: CreateRegTaxVinRequest;
}

export interface ApiExportAllGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortMode?: ExportSort;
    searchQuery?: string | null;
    projectFolderId?: string | null;
}

export interface ApiExportExportIdGetRequest {
    exportId: string;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     */
    async apiExportAddManyPostRaw(requestParameters: ApiExportAddManyPostRequest): Promise<runtime.ApiResponse<ExportUrlDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Export/AddMany`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRegTaxVinRequestToJSON(requestParameters.createRegTaxVinRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportUrlDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiExportAddManyPost(requestParameters: ApiExportAddManyPostRequest): Promise<ExportUrlDto> {
        const response = await this.apiExportAddManyPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiExportAllGetRaw(requestParameters: ApiExportAllGetRequest): Promise<runtime.ApiResponse<ExportsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortMode !== undefined) {
            queryParameters['sortMode'] = requestParameters.sortMode;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        if (requestParameters.projectFolderId !== undefined) {
            queryParameters['projectFolderId'] = requestParameters.projectFolderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Export/All`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportsResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiExportAllGet(requestParameters: ApiExportAllGetRequest): Promise<ExportsResponse> {
        const response = await this.apiExportAllGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiExportExportIdGetRaw(requestParameters: ApiExportExportIdGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exportId === null || requestParameters.exportId === undefined) {
            throw new runtime.RequiredError('exportId','Required parameter requestParameters.exportId was null or undefined when calling apiExportExportIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Export/{exportId}`.replace(`{${"exportId"}}`, encodeURIComponent(String(requestParameters.exportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiExportExportIdGet(requestParameters: ApiExportExportIdGetRequest): Promise<void> {
        await this.apiExportExportIdGetRaw(requestParameters);
    }

}
