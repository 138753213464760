/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamSort,
    TeamSortFromJSON,
    TeamSortFromJSONTyped,
    TeamSortToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminSearchTeamsRequest
 */
export interface AdminSearchTeamsRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminSearchTeamsRequest
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminSearchTeamsRequest
     */
    pageSize?: number;
    /**
     * 
     * @type {TeamSort}
     * @memberof AdminSearchTeamsRequest
     */
    sortMode?: TeamSort;
    /**
     * 
     * @type {string}
     * @memberof AdminSearchTeamsRequest
     */
    searchQuery?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSearchTeamsRequest
     */
    packageName?: string | null;
}

export function AdminSearchTeamsRequestFromJSON(json: any): AdminSearchTeamsRequest {
    return AdminSearchTeamsRequestFromJSONTyped(json, false);
}

export function AdminSearchTeamsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminSearchTeamsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'sortMode': !exists(json, 'sortMode') ? undefined : TeamSortFromJSON(json['sortMode']),
        'searchQuery': !exists(json, 'searchQuery') ? undefined : json['searchQuery'],
        'packageName': !exists(json, 'packageName') ? undefined : json['packageName'],
    };
}

export function AdminSearchTeamsRequestToJSON(value?: AdminSearchTeamsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'sortMode': TeamSortToJSON(value.sortMode),
        'searchQuery': value.searchQuery,
        'packageName': value.packageName,
    };
}


