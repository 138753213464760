import {
  Button,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useAdminAssets } from "../../../../hooksAdmin/assets";
import { AssetType, VehicleReportManagedAssetDto } from "../../../../lib-api";
import { DeletionIcon } from "../../../shared/other/Icons";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import useAuth from "../../../../hooks/auth";
import { useWindowSize } from "../../../../hooks/windowSize";
import ModalImage from "react-modal-image";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end",
  },
  tabContainer: {
    marginTop: theme.spacing(3),
  },
}));

export const Assets: React.FC<{
  vehicleOrderId?: string;
  connectToReport?: () => void;
  managedAssets?: VehicleReportManagedAssetDto[] | null;
}> = (props) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [imageAssets, setImageAssets] = useState<
    VehicleReportManagedAssetDto[]
  >(
    props.managedAssets == null || props.managedAssets == undefined
      ? []
      : props.managedAssets
  );
  const { uploadAsset, uploadedAsset, removeAsset } = useAdminAssets();
  const { isAdmin } = useAuth();
  const { xsDown } = useWindowSize();

  useEffect(() => {
    if (uploadedAsset != undefined) {
      setImageAssets(imageAssets.concat(uploadedAsset));
    }
  }, [uploadedAsset]);

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      for (var i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        uploadAsset(file, props.vehicleOrderId);
      }
    }
  };

  const deleteAsset = (connectionId?: string) => {
    removeAsset(connectionId);
    setImageAssets(imageAssets.filter((p) => p.id != connectionId));
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <GridList cellHeight={260} cols={xsDown ? 1 : 4}>
            {imageAssets.map((connection, index) =>
              connection.managedAsset?.type == AssetType.Pdf ? (
                <GridListTile key={index} rows={3} cols={2}>
                  <embed
                    height={"100%"}
                    width={"100%"}
                    src={
                      connection.managedAsset?.url! +
                      "?origin=" +
                      window.location.host
                    }
                  />
                  {isAdmin() ? (
                    <GridListTileBar
                      actionIcon={
                        <IconButton onClick={() => deleteAsset(connection.id)}>
                          <DeletionIcon hoverText="Remove pdf"></DeletionIcon>
                        </IconButton>
                      }
                    />
                  ) : null}
                </GridListTile>
              ) : (
                <GridListTile key={index} rows={2} cols={2}>
                  <ModalImage
                    small={connection.managedAsset?.url!}
                    large={connection.managedAsset?.url!}
                    hideDownload
                    hideZoom
                  ></ModalImage>
                  {isAdmin() ? (
                    <GridListTileBar
                      actionIcon={
                        <IconButton onClick={() => deleteAsset(connection.id)}>
                          <DeletionIcon hoverText="Remove image"></DeletionIcon>
                        </IconButton>
                      }
                    />
                  ) : null}
                </GridListTile>
              )
            )}
          </GridList>
        </Grid>
        {isAdmin() ? (
          <Grid
            className={classes.end + " " + classes.tabContainer}
            item
            xs={12}
          >
            <input
              accept=".pdf,image/*"
              multiple
              onChange={(data) => {
                handleUpload(data);
              }}
              ref={inputRef}
              type="file"
              hidden
            />
            <Button
              color="primary"
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              Upload files to report
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};
