/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountDto,
    AccountDtoFromJSON,
    AccountDtoToJSON,
    CreateRequest,
    CreateRequestFromJSON,
    CreateRequestToJSON,
    DeleteRequest,
    DeleteRequestFromJSON,
    DeleteRequestToJSON,
    SearchUserRequest,
    SearchUserRequestFromJSON,
    SearchUserRequestToJSON,
    TeamMemberDto,
    TeamMemberDtoFromJSON,
    TeamMemberDtoToJSON,
    TeamMetaData,
    TeamMetaDataFromJSON,
    TeamMetaDataToJSON,
    UpdatePasswordRequest,
    UpdatePasswordRequestFromJSON,
    UpdatePasswordRequestToJSON,
    UpdatePermissionsRequest,
    UpdatePermissionsRequestFromJSON,
    UpdatePermissionsRequestToJSON,
    UpdateRequest,
    UpdateRequestFromJSON,
    UpdateRequestToJSON,
    UsersResponse,
    UsersResponseFromJSON,
    UsersResponseToJSON,
} from '../models';

export interface ApiUserAllPostRequest {
    searchUserRequest?: SearchUserRequest;
}

export interface ApiUserCreatePostRequest {
    createRequest?: CreateRequest;
}

export interface ApiUserDeleteDeleteRequest {
    deleteRequest?: DeleteRequest;
}

export interface ApiUserUpdatepasswordPutRequest {
    updatePasswordRequest?: UpdatePasswordRequest;
}

export interface ApiUserUpdatepermissionsPutRequest {
    updatePermissionsRequest?: UpdatePermissionsRequest;
}

export interface ApiUserUpdateuserPutRequest {
    updateRequest?: UpdateRequest;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async apiUserAllPostRaw(requestParameters: ApiUserAllPostRequest): Promise<runtime.ApiResponse<UsersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/user/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchUserRequestToJSON(requestParameters.searchUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserAllPost(requestParameters: ApiUserAllPostRequest): Promise<UsersResponse> {
        const response = await this.apiUserAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUserCreatePostRaw(requestParameters: ApiUserCreatePostRequest): Promise<runtime.ApiResponse<TeamMemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/user/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRequestToJSON(requestParameters.createRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUserCreatePost(requestParameters: ApiUserCreatePostRequest): Promise<TeamMemberDto> {
        const response = await this.apiUserCreatePostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUserDeleteDeleteRaw(requestParameters: ApiUserDeleteDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/user/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteRequestToJSON(requestParameters.deleteRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserDeleteDelete(requestParameters: ApiUserDeleteDeleteRequest): Promise<void> {
        await this.apiUserDeleteDeleteRaw(requestParameters);
    }

    /**
     */
    async apiUserMeGetRaw(): Promise<runtime.ApiResponse<AccountDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUserMeGet(): Promise<AccountDto> {
        const response = await this.apiUserMeGetRaw();
        return await response.value();
    }

    /**
     */
    async apiUserTeaminfoGetRaw(): Promise<runtime.ApiResponse<TeamMetaData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/teaminfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetaDataFromJSON(jsonValue));
    }

    /**
     */
    async apiUserTeaminfoGet(): Promise<TeamMetaData> {
        const response = await this.apiUserTeaminfoGetRaw();
        return await response.value();
    }

    /**
     */
    async apiUserUpdatepasswordPutRaw(requestParameters: ApiUserUpdatepasswordPutRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/user/updatepassword`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePasswordRequestToJSON(requestParameters.updatePasswordRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUserUpdatepasswordPut(requestParameters: ApiUserUpdatepasswordPutRequest): Promise<string> {
        const response = await this.apiUserUpdatepasswordPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUserUpdatepermissionsPutRaw(requestParameters: ApiUserUpdatepermissionsPutRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/user/updatepermissions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePermissionsRequestToJSON(requestParameters.updatePermissionsRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUserUpdatepermissionsPut(requestParameters: ApiUserUpdatepermissionsPutRequest): Promise<string> {
        const response = await this.apiUserUpdatepermissionsPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUserUpdateuserPutRaw(requestParameters: ApiUserUpdateuserPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/user/updateuser`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRequestToJSON(requestParameters.updateRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserUpdateuserPut(requestParameters: ApiUserUpdateuserPutRequest): Promise<void> {
        await this.apiUserUpdateuserPutRaw(requestParameters);
    }

}
