import React, { useMemo } from "react";
import { LoadingState } from "../../../../hooks/loading";
import { ElementLoading } from "../../../shared/other/Loading";
import { Skeleton } from "@material-ui/lab";
import { Card, CardActions, CardContent, Grid, makeStyles } from "@material-ui/core";
import { useDimensions } from "../../../../hooks/dimensions";
import { VehicleEventInfo, VehicleInformationEquipmentReportData } from "../../../../lib-api";
import { useHelper } from "../../../../hooks/helper";

const useStyles = makeStyles((theme) => ({
  skeleton: (props: { width: number }) => ({
    width: props.width,
    height: props.width / 2
  }),
  pdfIcon: theme.icon,
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    flexBasis: '5%'
  },
  end: {
    textAlign: "right"
  }
}));

const equipmentCountryMap = [
  { "key": "german", "country": "DEU" },
  { "key": "finland", "country": "FIN" },
  { "key": "finnish", "country": "FIN" },
  { "key": "denmark", "country": "DNK" },
  { "key": "italy", "country": "IT" },
  { "key": "italian", "country": "IT" },
  { "key": "sweden", "country": "SWE" },
  { "key": "swedish", "country": "SWE" },
  { "key": "belgium", "country": "BEL" },
  { "key": "belgian", "country": "BEL" },
  { "key": "france", "country": "FRA" },
  { "key": "frence", "country": "FRA" },
  { "key": "spain", "country": "ESP" },
  { "key": "spanish", "country": "ESP" },
  { "key": " usa ", "country": "USA" },
  { "key": "america", "country": "USA" },
  { "key": "poland", "country": "POL" },
  { "key": "polish", "country": "POL" },
  { "key": "austria", "country": "AUT" },
  { "key": "slovakia", "country": "SVK" },
  { "key": "greece", "country": "GRC" },
  { "key": "greek", "country": "GRC" },
  { "key": "lithuania", "country": "LTU" },
  { "key": "czech", "country": "CHE" },
  { "key": "netherland", "country": "NLD" },
  { "key": "russia", "country": "RUS" }
];

export const CountryHistoryFromEquipment: React.FC<{
  loadingState: LoadingState;
  initialLoading: boolean;
  equipments?: VehicleInformationEquipmentReportData[][];
}> = (props) => {

  const { width } = useDimensions("countryhistory-width");
  const classes = useStyles({ width: width as number });
  const {getContryFromCode, getFlagFromCode} = useHelper();

  const countriesFromEquipment = useMemo(() => {
    var result = [];
    if (props.equipments != null) {
      for (var i = 0; i < props.equipments?.length; i++) {
        for (var j = 0; j < props.equipments[i].length; j++) {
          var equipname = props.equipments[i][j].name?.toLowerCase();
          if (equipname != null) {
            for (var k = 0; k < equipmentCountryMap.length; k++) {
              if (equipname.includes(equipmentCountryMap[k].key)) {
                result.push(equipmentCountryMap[k].country);
              }
            }
          }
        }
      }
    }
    return result.filter((value, index, array) => array.indexOf(value) == index);
  }, [props.equipments]);

  return (<div id="countryhistory-width" style={{ position: "relative" }}>
    {props.initialLoading ? <Skeleton variant="rect" className={classes.skeleton}></Skeleton>
      :
      <>
        {props.loadingState == LoadingState.Loading ?
          <ElementLoading position="absolute"></ElementLoading> : null}
        <Grid container spacing={2}>
          {countriesFromEquipment?.map((country, index) =>
            <Grid item xs={2}>
              <Card>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                {getContryFromCode(country)}
                </Grid>
                <Grid item xs={6} style={{display: "flex"}} justify="flex-end" alignItems="center">
                <img src={getFlagFromCode(country, 40)}></img>
                </Grid>
              </Grid>
              </Card>
              </Grid>
          )}
        </Grid>
      </>} </div>)
}
