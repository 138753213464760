import { useState } from "react";
import { LoadingState } from "../hooks/loading";
import useApi from "../hooks/api";
import { ManagedAssetDto } from "../lib-api";

export function useAdminAssets() {
  const [state, setState] = useState(LoadingState.None);
  const [uploadedAsset, setUploadedAsset] = useState<ManagedAssetDto|undefined>(undefined);

  const { adminReportApi } = useApi();

  const uploadAsset = async (file: File, vehicleOrderId?: string) => {
    try{
      setState(LoadingState.Loading);
      var asset = await adminReportApi.apiAdminReportUploadAssetsPost({
          file: file,
          vehicleOrderId: vehicleOrderId
      });
      setUploadedAsset(asset);
      setState(LoadingState.Success);
    }catch{
      setState(LoadingState.Error);
    }
  }

  const removeAsset = async (vehicleReportManagedAssetId?: string) => {
    try{
      setState(LoadingState.Loading);
      var asset = await adminReportApi.apiAdminReportRemoveAssetDelete({
          vehicleReportManagedId: vehicleReportManagedAssetId
      });
      setUploadedAsset(asset);
      setState(LoadingState.Success);
    }catch{
      setState(LoadingState.Error);
    }
  }

  return {
    uploadAsset,
    state,
    uploadedAsset,
    removeAsset
  };
}
