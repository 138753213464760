import { useEffect, useState } from "react";
import useApi from "./api";
import { LoadingState } from "./loading";
import useAuth from "./auth";
import { TeamMetaData } from "../lib-api";

export function useTeamInfo() {
  const [teaminfo, setTeamInfo] = useState<TeamMetaData | null>(null);
  const { isLoggedIn, user } = useAuth();
  const [state, setState] = useState<LoadingState>(LoadingState.None);
  const { userApi } = useApi();

  const loadTeamInfo = async () => {
    setState(LoadingState.Loading);
    try {
      const teaminfo = await userApi.apiUserTeaminfoGet();
      setTeamInfo(teaminfo);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  const incrementActiveVehicleCount = () => {
    if (teaminfo?.activeVINCheckers != undefined) {
      setTeamInfo({
        ...teaminfo,
        activeVINCheckers: teaminfo?.activeVINCheckers + 1
      });
    }
  }

  const incrementActiveSearchAgentCount = () => {
    if (teaminfo?.activeSearchAgents != undefined) {
      setTeamInfo({
        ...teaminfo,
        activeSearchAgents: teaminfo?.activeSearchAgents + 1
      });
    }
  }

  const decrementActiveVehicleCount = () => {
    if (teaminfo?.activeVINCheckers != undefined) {
      setTeamInfo({
        ...teaminfo,
        activeVINCheckers: teaminfo?.activeVINCheckers - 1
      });
    }
  }

  const decrementActiveSearchAgentCount = () => {
    if (teaminfo?.activeSearchAgents != undefined) {
      setTeamInfo({
        ...teaminfo,
        activeSearchAgents: teaminfo?.activeSearchAgents - 1
      });
    }
  }

  useEffect(() => {
    if (isLoggedIn()) {
      loadTeamInfo();
    }
  }, [isLoggedIn(), user?.teamId]);

  return {
    teamInfo: teaminfo,
    teamInfoState: state,
    reloadTeamInfo: loadTeamInfo,
    incrementActiveVehicleCount,
    incrementActiveSearchAgentCount,
    decrementActiveSearchAgentCount,
    decrementActiveVehicleCount
  };
}
