/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleBlockedStatusData
 */
export interface VehicleBlockedStatusData {
    /**
     * 
     * @type {string}
     * @memberof VehicleBlockedStatusData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleBlockedStatusData
     */
    vehicleReportId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleBlockedStatusData
     */
    blocked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleBlockedStatusData
     */
    blockedDescription?: string | null;
}

export function VehicleBlockedStatusDataFromJSON(json: any): VehicleBlockedStatusData {
    return VehicleBlockedStatusDataFromJSONTyped(json, false);
}

export function VehicleBlockedStatusDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleBlockedStatusData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vehicleReportId': !exists(json, 'vehicleReportId') ? undefined : json['vehicleReportId'],
        'blocked': !exists(json, 'blocked') ? undefined : json['blocked'],
        'blockedDescription': !exists(json, 'blockedDescription') ? undefined : json['blockedDescription'],
    };
}

export function VehicleBlockedStatusDataToJSON(value?: VehicleBlockedStatusData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vehicleReportId': value.vehicleReportId,
        'blocked': value.blocked,
        'blockedDescription': value.blockedDescription,
    };
}


