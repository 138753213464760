/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExportType {
    RegistrationTax = 'RegistrationTax',
    LeasingData = 'LeasingData',
    VinData = 'VinData',
    InsuranceData = 'InsuranceData'
}

export function ExportTypeFromJSON(json: any): ExportType {
    return ExportTypeFromJSONTyped(json, false);
}

export function ExportTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportType {
    return json as ExportType;
}

export function ExportTypeToJSON(value?: ExportType | null): any {
    return value as any;
}

