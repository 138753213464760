import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paperLoading: {
    position: "absolute",
    top: "50%",
    marginTop: "-20px",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto"
  }
}));

export const ElementLoading: React.FunctionComponent<{
  position: "relative" | "absolute";
}> = (props) => {
  const classes = useStyles();

  const loader = (): JSX.Element|null => {
    if(props.position == "relative"){
      return (<div style={{position: "relative", top: "30px", width: "100%"}}>
        <CircularProgress className={ classes.paperLoading}></CircularProgress>
      </div>)
    }
    if(props.position == "absolute"){
      return <CircularProgress className={ classes.paperLoading}></CircularProgress>
    }
    return null;
  }

  return loader();
};
