import React from "react";
import Button from "@material-ui/core/Button";
import { Form, Formik, useFormik } from "formik";
import { Grid, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import { PasswordSignInRequest } from "../../../../lib-api";
import useAuth from "../../../../hooks/auth";
import { object, string } from "yup";
import { Link } from "react-router-dom";
import { InputField } from "../InputField";

interface LoginFormProps {
  onLoggedIn?: () => void;

}

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  },
  form: theme.form,
  link: {
    color: "#006287"
  }
}));

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { login } = useAuth();

  const classes = useStyles();

  return (
      <>
      <Typography variant="h5" color="textPrimary">CUSTOMER LOGIN</Typography>
      <Typography variant="h3" color="primary">Sign in to Vindicate.io</Typography>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={object({
          email: string().required("No email provided").email(),
          password: string().required(),
        })}
        onSubmit={async (values) => {
          await login(values);
          if (props.onLoggedIn) {
            props.onLoggedIn();
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.form}>
            <Grid container>
              <Grid item xs={12}>
                <InputField
                  name="email"
                  type="email"
                  placeholder="Email Address"
                ></InputField>
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="password"
                  type="password"
                  placeholder="Password"
                ></InputField>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                  <Button type="submit">
                    {isSubmitting ? "Authenticating..." : "Login"}
                  </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Typography variant="body1">Forgot password? Click <Link className={classes.link} to="/reset">here</Link></Typography>
    </>
  );
};

export default LoginForm;
