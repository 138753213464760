import React from "react";
import { Grid, Hidden, makeStyles, useTheme } from "@material-ui/core";
import useAuth from "../../../hooks/auth";
import { PdfIcon, DownloadIcon, FilterIcon } from "../../shared/other/Icons";
import DialogHelper from "../../shared/dialogs/DialogHelper";
import { UpgradePackageDialog } from "../../shared/dialogs/UpgradePackageDialog";

export const ChartFunctions: React.FC<{
  downloadPdf: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  downloadVins: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onToggleFilters: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  toggled: boolean;
}> = (props) => {

  const { canAccessGold } = useAuth();

  return (
    <DialogHelper
      render={(dialog) => (
        <>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <PdfIcon hoverText="Download as pdf" onClick={canAccessGold() ? props.downloadPdf : () => dialog.setOpen(true)} />
            </Grid>
            <Hidden xsDown>
              <Grid item>
                <DownloadIcon
                  hoverText="Download vins from chart"
                  onClick={canAccessGold() ? props.downloadVins : () => dialog.setOpen(true)} />
              </Grid>
            </Hidden>
            <Grid item>
              <FilterIcon
                toggled={props.toggled}
                hoverTextToggled="Hide filter"
                hoverText="Show filter"
                onClick={props.onToggleFilters}
              ></FilterIcon>
            </Grid>
          </Grid>
          <UpgradePackageDialog packageToUpgradeTo="Gold" open={dialog.open} onClose={() => dialog.setOpen(false)}></UpgradePackageDialog>
        </>
      )}
    />)
}
