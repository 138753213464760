import { Button, Grid, makeStyles, MenuItem, Paper, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { object, string } from "yup";
import useApi from "../../../../hooks/api";
import { AdminCreateTeamRequest, PackageDto } from "../../../../lib-api";
import { InputField } from "../../../shared/forms/InputField";
import { SelectField } from "../../../shared/forms/SelectField";
import { Breadcrumb } from "../../../shared/other/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  },
  form: theme.form,
  link: theme.link
}));

const AdminAddTeamPage: React.FC<{
}> = () => {
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [packages, setPackages] = useState<PackageDto[]>();

  var { adminUserApi } = useApi();

  useEffect(() => {
    savePackages();
  }, []);

  const savePackages = async() => {
    var packages = await adminUserApi.apiAdminUserAllpackagesPost();
    setPackages(packages);
  }

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item sm={3}></Grid>
      <Grid item sm={9}>
        <Breadcrumb currentPath="New team"></Breadcrumb>
      </Grid>
      <Grid item sm={3}></Grid>
      <Grid item sm={6}>
        <Paper>
          <Typography align="center" variant="h4">Add team</Typography>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: "",
              packageId: ""
            }}
            validationSchema={object<AdminCreateTeamRequest>({
              name: string()
                .required("No name provided"),
              packageId: string()
                .required("Package required")
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              var request: AdminCreateTeamRequest = {
                name: values.name,
                packageId: values.packageId
              };
              try {
                var team = await adminUserApi.apiAdminUserCreateteamPost({
                  adminCreateTeamRequest: request
                });
                enqueueSnackbar(team.name + " has been created!", {variant: "success"});
                history.push(`/admin/teams/${team.id}`)
                setSubmitting(false);
              } catch (error) {
                resetForm();
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className={classes.form}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputField
                      name="name"
                      type="text"
                      placeholder="Name"
                    ></InputField>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectField
                      name="packageId"
                      placeholder="Package"
                    >
                      {packages?.map((packageItem, index) => (
                        <MenuItem key={index} value={packageItem.id}>{packageItem.name}</MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.end}>
                      <Button color="primary" type="submit">
                        {isSubmitting ? "Adding new team..." : "Add"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AdminAddTeamPage;
