import {
  Box,
  Button,
  Divider,
  Grid,
  Input,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useReport } from "../../../hooks/report";
import { MileageHistory } from "./reportContent/MileageHistory";
import { EventHistory } from "./reportContent/EventHistory";
import { CountryHistory } from "./reportContent/CountryHistory";
import { CountryHistoryFromEquipment } from "./reportContent/CountryHistoryFromEquipment";
import { PictureHistory } from "./reportContent/PictureHistory";
import { VinValidity } from "./reportContent/VinValidity";
import { VehicleInfo } from "./reportContent/VehicleInfo";
import { SpecSummary } from "./reportContent/SpecSummary";
import { Assets } from "./reportContent/Assets";
import { useHelper } from "../../../hooks/helper";
import useApi from "../../../hooks/api";
import useAuth from "../../../hooks/auth";
import { useSnackbar } from "notistack";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import { InputField } from "../../shared/forms/InputField";
import { EventRemoteType, EventType } from "../../../lib-api";
import { LoadingState } from "../../../hooks/loading";
import { ValuationHistory } from "./reportContent/ValuationHistory";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end",
  },
  form: theme.form,
  tabContainer: {
    marginTop: theme.spacing(3),
  },
  link: theme.link,
}));

const ReportPage = () => {
  const classes = useStyles();

  const params = useParams<{ orderId: string }>();
  const { getRemoteSourceFromEnum } = useHelper();
  const { adminReportApi } = useApi();
  const { isAdmin } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const {
    completedOrder,
    loadingState,
    loadOrder,
    orderExpandedAllEvents,
    completedOrderAssets,
    completedOrderMileage,
    completedOrderPictures,
    completedOrderValuation,
  } = useReport();
  const regEvents = completedOrder?.vehicleReport?.vehicleEvents?.filter(
    (p) => p.eventType == EventType.Registration
  ).length;
  const latestRegEvent = completedOrder?.vehicleReport?.vehicleEvents?.filter(
    (p) => p.eventType == EventType.Registration
  )[regEvents! - 1];

  useEffect(() => {
    loadOrder(params.orderId);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid className={classes.end} item xs={12}>
        <Button
          disabled={loadingState != LoadingState.Success}
          color="primary"
          onClick={() => window.print()}
        >
          Export as pdf
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <Typography align="center" variant="h4">
                Report - {completedOrder?.vin}
              </Typography>
            </Grid>
            {completedOrder != null ? (
              <>
                <VehicleInfo
                  vehicleInformation={
                    completedOrder.vehicleReport?.vehicleInformation
                  }
                ></VehicleInfo>

                <SpecSummary
                  leasingData={completedOrder?.vehicleReport?.leasingData}
                  latestRegistrationEvent={latestRegEvent}
                  nextInspection={
                    completedOrder?.vehicleReport?.nextInspectionData
                  }
                  blockedData={completedOrder.vehicleReport?.blockedStatusData}
                ></SpecSummary>
              </>
            ) : null}

            <Grid item xs={12} sm={6}>
              {completedOrder?.vehicleReport != null ? (
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    score: completedOrder?.vehicleReport?.score,
                    summary: completedOrder?.vehicleReport?.summary,
                  }}
                  validationSchema={object({
                    score: string().required(),
                    summary: string(),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    try {
                      await adminReportApi.apiAdminReportSetscorePost({
                        score: values.score!,
                        summary: values.summary,
                        vehicleOrderId: params.orderId,
                      });
                      enqueueSnackbar("Score has been set!", {
                        variant: "success",
                      });
                      setSubmitting(false);
                    } catch (error) {
                      resetForm();
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form className={classes.form}>
                      <Grid container>
                        <Grid item xs={12} sm={4}>
                          <InputField
                            disabled={isAdmin()}
                            name="score"
                            type="number"
                            placeholder="Score"
                          ></InputField>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <InputField
                            disabled={isAdmin()}
                            name="summary"
                            type="textarea"
                            textarea={8}
                            placeholder="Summary"
                          ></InputField>
                        </Grid>
                      </Grid>
                      {isAdmin() ? (
                        <Grid container>
                          <Grid item xs={12}>
                            <div className={classes.end}>
                              <Button color="primary" type="submit">
                                {isSubmitting
                                  ? "Updating score..."
                                  : "Update score"}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Form>
                  )}
                </Formik>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {completedOrder?.vehicleReport != null ? (
                <VinValidity
                  orderId={completedOrder.id}
                  validityCount={completedOrder.vehicleReport.vinValidityCount}
                  validityFields={
                    completedOrder.vehicleReport.fieldSimiliarAmounts
                  }
                  vin={completedOrder.vin}
                ></VinValidity>
              ) : null}
            </Grid>
          </Grid>
        </Paper>

        {completedOrder != null ? (
          <Paper className={classes.tabContainer}>
            <Grid justify="center" container>
              <Grid item xs={10}>
                <Typography align="center" variant="h5">
                  Country history
                </Typography>
              </Grid>
            </Grid>
            <CountryHistory
              allEvents={orderExpandedAllEvents}
              loadingState={loadingState}
              initialLoading={completedOrder?.vehicleReport == null}
            ></CountryHistory>
          </Paper>
        ) : null}

        {completedOrder != null && isAdmin() ? (
          <Paper className={classes.tabContainer}>
            <Grid justify="center" container>
              <Grid item xs={10}>
                <Typography align="center" variant="h5">
                  Countries from equipment
                </Typography>
              </Grid>
            </Grid>
            <CountryHistoryFromEquipment
              equipments={completedOrder?.vehicleReport?.vehicleInformation?.map(
                (p) => p.equipment!
              )}
              loadingState={loadingState}
              initialLoading={completedOrder?.vehicleReport == null}
            ></CountryHistoryFromEquipment>
          </Paper>
        ) : null}

        {completedOrderAssets != null ? (
          <Paper className={classes.tabContainer}>
            <Grid justify="center" container>
              <Grid item xs={10}>
                <Typography align="center" variant="h5">
                  Extra attachments
                </Typography>
              </Grid>
            </Grid>
            <Assets
              managedAssets={completedOrderAssets}
              vehicleOrderId={completedOrder?.id}
            ></Assets>
          </Paper>
        ) : null}

        <Paper className={classes.tabContainer}>
          <Grid justify="center" container>
            <Grid item xs={10}>
              <Typography align="center" variant="h5">
                {completedOrder?.vehicleReport?.vehicleEvents == undefined ? (
                  <Skeleton />
                ) : (
                  "Event history"
                )}
              </Typography>
            </Grid>
          </Grid>
          <EventHistory
            allEvents={orderExpandedAllEvents}
            loadingState={loadingState}
            initialLoading={completedOrder?.vehicleReport == null}
          ></EventHistory>
        </Paper>

        {completedOrderMileage?.length != 0 ? (
          <Paper className={classes.tabContainer}>
            <Grid justify="center" container>
              <Grid item xs={10}>
                <Typography align="center" variant="h5">
                  {completedOrderMileage == undefined ? (
                    <Skeleton />
                  ) : (
                    "Mileage history"
                  )}
                </Typography>
              </Grid>
            </Grid>
            <MileageHistory
              data={completedOrderMileage}
              loadingState={loadingState}
              initialLoading={completedOrder?.vehicleReport == null}
            ></MileageHistory>
          </Paper>
        ) : null}

        {completedOrderValuation?.valuationData?.length != 0 ? (
          <Paper className={classes.tabContainer}>
            <Grid justify="center" container>
              <Grid item xs={10}>
                <Typography align="center" variant="h5">
                  {completedOrderValuation?.valuationData == undefined ? (
                    <Skeleton />
                  ) : (
                    "Valuation history"
                  )}
                </Typography>
              </Grid>
            </Grid>
            <ValuationHistory
              estimatedData={completedOrderValuation?.estimatedValuationData}
              dataWithoutTax={completedOrderValuation?.valuationData}
              dataWithTax={completedOrderValuation?.taxValuationData}
              loadingState={loadingState}
              initialLoading={completedOrder?.vehicleReport == null}
            ></ValuationHistory>
            {completedOrder != null ? (
              <SpecSummary
                leasingData={completedOrder?.vehicleReport?.leasingData}
                latestRegistrationEvent={latestRegEvent}
                nextInspection={
                  completedOrder?.vehicleReport?.nextInspectionData
                }
                blockedData={completedOrder.vehicleReport?.blockedStatusData}
              ></SpecSummary>
            ) : null}
          </Paper>
        ) : null}

        {completedOrderPictures?.length != 0 ? (
          <Paper className={classes.tabContainer}>
            <Grid justify="center" container>
              <Grid item xs={10}>
                <Typography align="center" variant="h5">
                  {completedOrderPictures == undefined ? (
                    <Skeleton />
                  ) : (
                    "Pictures"
                  )}
                </Typography>
              </Grid>
            </Grid>
            <PictureHistory
              data={completedOrderPictures}
              loadingState={loadingState}
              initialLoading={completedOrder?.vehicleReport == null}
            ></PictureHistory>
          </Paper>
        ) : null}

        {isAdmin() ? (
          <Paper className={classes.tabContainer}>
            <Grid justify="center" container>
              <Grid item xs={10}>
                <Typography align="center" variant="h5">
                  {completedOrder?.vehicleReport?.pictures == undefined ? (
                    <Skeleton />
                  ) : (
                    "Equipment (Only admin right now)"
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              {completedOrder?.vehicleReport?.vehicleInformation?.length == 1 &&
              completedOrder.vehicleReport.vehicleInformation.find(
                (p) => p.remoteType == EventRemoteType.CarVertical
              ) ? (
                <Grid item xs={6}></Grid>
              ) : null}
              {completedOrder?.vehicleReport?.vehicleInformation?.map(
                (info, index) => (
                  <Grid key={index} item xs={6}>
                    <Typography variant="h5">
                      Equipment from {getRemoteSourceFromEnum(info.remoteType)}
                    </Typography>
                    <List>
                      {info.equipment?.map((equipment, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            key={index}
                            primary={equipment.name}
                          ></ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )
              )}
            </Grid>
          </Paper>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ReportPage;
