declare const window: {
  dataLayer: any[];
};

export function trackGaEvent(
  eventName: string,
  events: Array<{name: string, value?: string | null}>
) {
  window.dataLayer = window.dataLayer || [];
  
  var event: any = {
    event: eventName
  }

  for(var i = 0; i < events.length; i++){
    event[events[i].name] = events[i].value;
  }
  window.dataLayer.push(event);
}

export function trackGaPageView(virtualUrl: string) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "PageChange",
    VirtualPageUrl: virtualUrl,
  });
}
