import { useField } from "formik";
import { FormControl, FormControlProps, InputLabel } from "@material-ui/core";
import React, { ComponentType, PropsWithChildren } from "react";
import {
  KeyboardDatePicker, KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { CustomDatePicker } from "./CustomTextField";

export const DateField: React.FC<
  {
    name: string;
    placeholder: string;
    type: string;
    onFinished?: () => void;
  } & FormControlProps & Partial<KeyboardDatePickerProps>
> = (props) => {
  const [field, meta, helpers] = useField(props.name);
  return (
    <>
          <FormControl>
            <InputLabel shrink htmlFor={props.name}>{props.placeholder.toUpperCase()}</InputLabel>
            <CustomDatePicker
            value={field.value == undefined ? null : field.value}
            onChange={(val) => helpers.setValue(val)}
            disableFuture={props.disableFuture}
            disablePast={props.disablePast}
            clearable={true}
            onAccept={(e) => props.onFinished != undefined ? props.onFinished() : null}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onBlur={(e) => props.onFinished != undefined ? props.onFinished() : null}
            variant="dialog"
            format="YYYY-MM-DD"
            inputVariant="outlined"
            size="small"
          />
          </FormControl>
    </>
  );
};
