import { useState, useEffect, useContext } from "react";
import { ToldsynRequest } from "../lib-api/models";
import { LoadingState } from "./loading";
import useApi from "./api";
import { InitialGraphData } from "../lib-api/models/InitialGraphData";
import { StatisticsStateContext } from "../state/statistics";

export interface StatisticsRequest {
  taxRequest: {
    cvrRequest?: ToldsynRequest;
    makeRequest?: ToldsynRequest;
    yearRequest?: ToldsynRequest;
    leasingStateYearRequest?: ToldsynRequest;
    stateRequest?: ToldsynRequest;
    modelYearRequest?: ToldsynRequest;
  }
}

export function useStatistics(statisticsRequest: StatisticsRequest) {
  const [context, dispatch] = useContext(StatisticsStateContext);

  const [initialDataState, setInitialDataState] = useState(LoadingState.None);
  const [makeState, setMakeState] = useState(LoadingState.None);
  const [modelYearState, setModelYearState] = useState(LoadingState.None);
  const [yearState, setYearState] = useState(LoadingState.None);
  const [leasingYearState, setLeasingYearState] = useState(LoadingState.None);
  const [stateState, setStateState] = useState(LoadingState.None);
  const [cvrState, setCvrState] = useState(LoadingState.None);

  const { statisticsApi } = useApi();

  const shouldReload = (request: ToldsynRequest, prev?: ToldsynRequest) => {
    return request.make != prev?.make || request.model != prev?.model ||
    request.inspectionDateFrom != prev?.inspectionDateFrom || request.inspectionDateTo != prev?.inspectionDateTo ||
    request.inspectionStationId != prev?.inspectionStationId || request.insuranceCompanies != prev?.insuranceCompanies ||
    request.leasingState != prev?.leasingState || request.year != prev?.year;
  }

  const loadInit = async () => {
    setInitialDataState(LoadingState.Loading);

    try {
      const searchResult = await statisticsApi.apiStatisticsInitValuesPost();
      dispatch({type: "init", filterData: searchResult.initialFilterData});
      setInitialDataState(LoadingState.Success);
    } catch (e) {
      setInitialDataState(LoadingState.BadRequest);
    }
  };

  const loadCvr = async (init: boolean) => {
    setCvrState(LoadingState.Loading);
    try {
      const searchResult = await statisticsApi.apiStatisticsCvrPost({ getChartRequest: { request: statisticsRequest.taxRequest.cvrRequest, init: init }});
      dispatch({type: "cvr", cvrData: searchResult});
      setCvrState(LoadingState.Success);
    } catch (e) {
      setCvrState(LoadingState.BadRequest);
    }
  };

  const loadMake = async (init: boolean) => {
    setMakeState(LoadingState.Loading);
    try {
      const searchResult = await statisticsApi.apiStatisticsMakePost({ getChartRequest: { request: statisticsRequest.taxRequest.makeRequest, init: init }});
      dispatch({type: "make", makeData: searchResult});
      setMakeState(LoadingState.Success);
    } catch (e) {
      setMakeState(LoadingState.BadRequest);
    }
  };

  const loadYear = async (init: boolean) => {
    setYearState(LoadingState.Loading);
    try {
      const searchResult = await statisticsApi.apiStatisticsYearPost({ getChartRequest: { request: statisticsRequest.taxRequest.yearRequest, init: init }});
      dispatch({type: "year", yearData: searchResult});
      setYearState(LoadingState.Success);
    } catch (e) {
      setYearState(LoadingState.BadRequest);
    }
  };

  const loadModelYear = async (init: boolean) => {
    setModelYearState(LoadingState.Loading);
    try {
      const searchResult = await statisticsApi.apiStatisticsModelyearPost({ getChartRequest: { request: statisticsRequest.taxRequest.modelYearRequest, init: init }});
      dispatch({type: "modelyear", modelYearData: searchResult});
      setModelYearState(LoadingState.Success);
    } catch (e) {
      setModelYearState(LoadingState.BadRequest);
    }
  };

  const loadState = async (init: boolean) => {
    setStateState(LoadingState.Loading);
    try {
      const searchResult = await statisticsApi.apiStatisticsStatePost({ getChartRequest: { request: statisticsRequest.taxRequest.stateRequest, init: init }});
      dispatch({type: "state", stateData: searchResult});
      setStateState(LoadingState.Success);
    } catch (e) {
      setStateState(LoadingState.BadRequest);
    }
  };

  const loadLeasingStateYear = async (init: boolean) => {
    setLeasingYearState(LoadingState.Loading);
    try {
      const searchResult = await statisticsApi.apiStatisticsLeasingyearPost({ getChartRequest: { request: statisticsRequest.taxRequest.leasingStateYearRequest, init: init }});
      dispatch({type: "leasingyear", leasingYearData: searchResult});
      setLeasingYearState(LoadingState.Success);
    } catch (e) {
      setLeasingYearState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    if (statisticsRequest.taxRequest.cvrRequest != null && shouldReload(statisticsRequest.taxRequest.cvrRequest, context.filterData.cvr.prevRequest)) {
      loadCvr(false);
    }else if(statisticsRequest.taxRequest.cvrRequest != null && context.graphData.toldsynByCvrResults == undefined){
      loadCvr(true);
    }
  }, [statisticsRequest.taxRequest.cvrRequest]);

  useEffect(() => {
    if (statisticsRequest.taxRequest.modelYearRequest != null && shouldReload(statisticsRequest.taxRequest.modelYearRequest, context.filterData.modelYear.prevRequest)) {
      loadModelYear(false);
    }else if(statisticsRequest.taxRequest.modelYearRequest != null && context.graphData.toldsynByModelYearResults == undefined){
      loadModelYear(true);
    }
  }, [statisticsRequest.taxRequest.modelYearRequest]);

  useEffect(() => {
    if (statisticsRequest.taxRequest.yearRequest != null && shouldReload(statisticsRequest.taxRequest.yearRequest, context.filterData.year.prevRequest)) {
      loadYear(false);
    }else if(statisticsRequest.taxRequest.yearRequest != null && context.graphData.toldsynByYearResults == undefined){
      loadYear(true);
    }
  }, [statisticsRequest.taxRequest.yearRequest]);

  useEffect(() => {
    if (statisticsRequest.taxRequest.leasingStateYearRequest != null && shouldReload(statisticsRequest.taxRequest.leasingStateYearRequest, context.filterData.leasingYear.prevRequest)) {
      loadLeasingStateYear(false);
    }else if(statisticsRequest.taxRequest.leasingStateYearRequest != null && context.graphData.toldsynByLeasingYearEndResults == undefined){
      loadLeasingStateYear(true);
    }
  }, [statisticsRequest.taxRequest.leasingStateYearRequest]);

  useEffect(() => {
    if (statisticsRequest.taxRequest.makeRequest != null && shouldReload(statisticsRequest.taxRequest.makeRequest, context.filterData.make.prevRequest)) {
      loadMake(false);
    }else if(statisticsRequest.taxRequest.makeRequest != null && context.graphData.toldsynByMakeResults == undefined){
      loadMake(true);
    }
  }, [statisticsRequest.taxRequest.makeRequest]);

  useEffect(() => {
    if (statisticsRequest.taxRequest.stateRequest != null && shouldReload(statisticsRequest.taxRequest.stateRequest, context.filterData.state.prevRequest)) {
      loadState(false);
    }else if(statisticsRequest.taxRequest.stateRequest != null && context.graphData.toldsynByStateResults == undefined){
      loadState(true);
    }
  }, [statisticsRequest.taxRequest.stateRequest]);

  useEffect(() => {
    const load = async() => {
      if(context.graphData.initialFilterData == undefined){
        await loadInit();
      }
    };
    load();
  }, []);

  return {
    initialData: context.graphData,
    initialDataState: initialDataState,
    cvrState: cvrState,
    stateState: stateState,
    yearState: yearState,
    makeState: makeState,
    leasingYearState: leasingYearState,
    modelYearState: modelYearState,
    reloadInitialData: loadInit,
  };
}
