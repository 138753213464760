import { useState, useEffect } from "react";
import { TeamMemberDto, UserSort } from "../lib-api/models";
import { LoadingState } from "../hooks/loading";
import useApi from "../hooks/api";
import { useQueryDelay } from "../hooks/queryDelay";

export function useAdminUser(teamId: string, page: number, amount: number, sortMode: UserSort, searchQuery: string) {
  const [state, setState] = useState(LoadingState.None);
  const [results, setResults] = useState<
  TeamMemberDto[]
  >([]);
  const { delay } = useQueryDelay(searchQuery);
  const [totalUsers, setTotalUsers] = useState<number|undefined>(undefined);

  const { adminUserApi } = useApi();

  const load = async () => {
    setState(LoadingState.Loading);

    try {
      const searchResult = await adminUserApi.apiAdminUserAllusersPost({
        adminSearchUsersRequest: {
          page: page,
          pageSize: amount,
          sortMode: sortMode,
          teamId: teamId,
          searchQuery: searchQuery
        }
      });
      setResults(searchResult.all);
      setTotalUsers(searchResult.totalCount);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    load();
  }, [page, amount, sortMode, delay]);

  return {
    results: results,
    totalUsers: totalUsers,
    searchState: state,
    reload: load
  };
}
