import { useField } from "formik";
import { CircularProgress, FormControl, FormControlProps, InputLabel, makeStyles, Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import { CustomTextField } from "./CustomTextField";
import { Autocomplete } from "@material-ui/lab";
import { LoadingState } from "../../../hooks/loading";

export const AutoSuggestField: React.FC<
  {
    name: string;
    placeholder: string;
    suggestions: string[];
    suggestionsState: LoadingState;
    tooltip?: string;
    onFinish?: () => void;
  } & FormControlProps
> = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<string[]>([]);
  const loading = props.suggestionsState == LoadingState.Loading;

  useEffect(() => {
    setOptions(props.suggestions);
  }, [props.suggestions]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (props.disabled) {
      helpers.setValue("");
    }
  }, [props.disabled]);

  return (
    <Tooltip placement="top" title={props.tooltip ?? ""}>
    <div>
      <FormControl>
        <InputLabel shrink htmlFor={props.name}>{props.placeholder.toUpperCase()}</InputLabel>
        <Autocomplete
          style={{ marginTop: "16px", cursor: "pointer" }}
          noOptionsText="Begin typing.."
          autoSelect
          autoHighlight
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={(e: any) => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={options}
          value={field.value} //display purpose when changing tabs
          disabled={props.disabled}
          blurOnSelect
          openOnFocus
          loading={loading}
          onInputChange={(data, value) => {
            helpers.setValue(value);
            if ((options.includes(value) || value == "") && props.onFinish != undefined) {
              props.onFinish();
            }
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              value={(field.value as string)?.toUpperCase()}
              name={field.name}
              onChange={(e) => helpers.setValue(e.target.value?.toUpperCase())}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </FormControl>

      <p>{meta.touched && meta.error ? meta.error : null}</p>
    </div>
    </Tooltip>
  );
};
