/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PortfolioVinDto,
    PortfolioVinDtoFromJSON,
    PortfolioVinDtoFromJSONTyped,
    PortfolioVinDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PortfolioVinsResponse
 */
export interface PortfolioVinsResponse {
    /**
     * 
     * @type {Array<PortfolioVinDto>}
     * @memberof PortfolioVinsResponse
     */
    all: Array<PortfolioVinDto>;
    /**
     * 
     * @type {number}
     * @memberof PortfolioVinsResponse
     */
    totalCount: number;
}

export function PortfolioVinsResponseFromJSON(json: any): PortfolioVinsResponse {
    return PortfolioVinsResponseFromJSONTyped(json, false);
}

export function PortfolioVinsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioVinsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'all': ((json['all'] as Array<any>).map(PortfolioVinDtoFromJSON)),
        'totalCount': json['totalCount'],
    };
}

export function PortfolioVinsResponseToJSON(value?: PortfolioVinsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all': ((value.all as Array<any>).map(PortfolioVinDtoToJSON)),
        'totalCount': value.totalCount,
    };
}


