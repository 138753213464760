/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VehicleEvent,
    VehicleEventFromJSON,
    VehicleEventFromJSONTyped,
    VehicleEventToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetEventsRequest
 */
export interface GetEventsRequest {
    /**
     * 
     * @type {Array<VehicleEvent>}
     * @memberof GetEventsRequest
     */
    vehicleEvents?: Array<VehicleEvent> | null;
}

export function GetEventsRequestFromJSON(json: any): GetEventsRequest {
    return GetEventsRequestFromJSONTyped(json, false);
}

export function GetEventsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEventsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleEvents': !exists(json, 'vehicleEvents') ? undefined : (json['vehicleEvents'] === null ? null : (json['vehicleEvents'] as Array<any>).map(VehicleEventFromJSON)),
    };
}

export function GetEventsRequestToJSON(value?: GetEventsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleEvents': value.vehicleEvents === undefined ? undefined : (value.vehicleEvents === null ? null : (value.vehicleEvents as Array<any>).map(VehicleEventToJSON)),
    };
}


