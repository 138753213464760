import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { CustomTextField } from "../forms/CustomTextField";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import VehicleIdSearchForm from "../forms/search/VehicleIdSearchForm";
import LoopIcon from "@material-ui/icons/Loop";
import { useWindowSize } from "../../../hooks/windowSize";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: "0px",
    marginBottom: "0px",
    padding: "4px",
    border: "1px solid #c9c9d1!important",
    borderBottom: "none",
    margin: "0px!important",
    width: "100%!important",
    justifyContent: "flex-end",
  },
  searchField: {
    alignItems: "flex-end",
    justifyContent: "center",
    "& svg": {
      position: "absolute",
      marginRight: "10px",
      color: "gray",
      cursor: "auto",
    },
  },
  checkbox: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  tab: {
    borderTop: "none!important",
    borderRadius: "0px!important",
  },
}));

export interface DataGridHeaderProps {
  displayHeadline?: string;
  displayHtml?: React.ReactNode;
  filters?: Array<DataGridHeaderFilter>;
  isTab?: boolean;
}

export type DataGridHeaderFilter =
  | {
      name: string;
      onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
      onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
      onSubmitVehicleId?: (id: string, projectId?: string) => void;
      disabled?: boolean;
      position: "right" | "left";
      type: "select" | "link" | "reload" | "submitwithprojects";
      selectOptions?: Array<{ name: string; value: string }>;
      initVal?: string;
      link?: string;
      tooltip?: string;
    }
  | {
      name: string;
      onSubmitVehicleId?: (id: string[], projectId?: string) => void;
      disabled?: boolean;
      position: "right" | "left";
      type: "submitmultiple";
      initVal?: string;
      tooltip?: string;
      loadingText: string;
    }
  | {
      name: string;
      onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
      disabled?: boolean;
      position: "right" | "left";
      type: "bool";
      initVal?: boolean;
      link?: string;
      tooltip?: string;
    }
  | {
      name: string;
      onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
      position: "right" | "left";
      type: "search";
      initVal?: string;
      tooltip?: string;
    }
  | {
      name: string;
      onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
      disabled?: boolean;
      position: "right" | "left";
      type: "button";
      initVal?: string;
      tooltip?: string;
      loadingText: string;
      isLoading: boolean;
      show?: boolean;
    }
  | {
      name: string;
      onSubmitVehicleId?: (id: string, projectId?: string) => void;
      position: "right" | "left";
      type: "submit";
      placeholder?: string;
    }
  | {
      name: string;
      placeholder?: string;
      onSubmitVehicleId?: (
        id: string[],
        projectId?: string,
        name?: string
      ) => void;
      disabled?: boolean;
      position: "right" | "left";
      type: "submitmultiplewithprojects";
      initVal?: string;
      tooltip?: string;
      loadingText: string;
    };

export const CustomDataGridHeader: React.FC<{
  headerProps: DataGridHeaderProps;
}> = (props) => {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const { smUp } = useWindowSize();

  const getFilterOption = (filter: DataGridHeaderFilter) => {
    switch (filter.type) {
      case "bool":
        return (
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                color="primary"
                checked={filter.disabled ?? filter.initVal}
                disabled={filter.disabled}
                onChange={filter.onChange}
              />
            }
            label={filter.name}
          />
        );
      case "select":
        return (
          <FormControl>
            <CustomTextField
              disabled={filter.disabled}
              onChange={filter.onChange}
              select
              defaultValue={filter.initVal == "" ? -1 : filter.initVal}
              variant="outlined"
            >
              <MenuItem value={-1}>{filter.name}</MenuItem>
              {filter.selectOptions?.map((option, index) => (
                <MenuItem value={option.value} key={index}>
                  {option.name}
                </MenuItem>
              ))}
            </CustomTextField>
          </FormControl>
        );
      case "link":
        return (
          <Link className={classes.link} to={filter.link!} color="primary">
            {filter.name}
          </Link>
        );
      case "search":
        return (
          <FormControl className={classes.searchField}>
            <CustomTextField
              variant="outlined"
              type="text"
              value={filter.initVal}
              placeholder={filter.name}
              onChange={filter.onChange}
            ></CustomTextField>
            <SearchIcon></SearchIcon>
          </FormControl>
        );
      case "submit":
        return (
          <VehicleIdSearchForm
            dense
            submitText={filter.name}
            placeholderText={filter.placeholder!}
            showIdField
            showProjects={false}
            onSubmit={filter.onSubmitVehicleId}
          ></VehicleIdSearchForm>
        );
      case "submitwithprojects":
        return (
          <VehicleIdSearchForm
            dense
            submitText={filter.name}
            placeholderText={filter.link!}
            showIdField
            showProjects
            onSubmit={filter.onSubmitVehicleId}
          ></VehicleIdSearchForm>
        );
      case "submitmultiple":
        return (
          <VehicleIdSearchForm
            dense
            submitText={filter.name}
            showIdField={false}
            showProjects
            submittingText={filter.loadingText}
            onSubmitMultiple={filter.onSubmitVehicleId}
          ></VehicleIdSearchForm>
        );
      case "submitmultiplewithprojects":
        return (
          <VehicleIdSearchForm
            dense
            submitText={filter.name}
            placeholderText={filter.placeholder}
            showIdField={true}
            showProjects
            submittingText={filter.loadingText}
            onSubmitMultiple={filter.onSubmitVehicleId}
          ></VehicleIdSearchForm>
        );
      case "reload":
        return (
          <Tooltip title={filter.name}>
            <IconButton color="secondary" onClick={filter.onClick}>
              <LoopIcon></LoopIcon>
            </IconButton>
          </Tooltip>
        );
      case "button":
        return filter.show === false ? null : (
          <Tooltip title={filter.tooltip ?? filter.name}>
            <Button size="large" color="primary" onClick={filter.onClick}>
              {filter.isLoading ? filter.loadingText : filter.name}
            </Button>
          </Tooltip>
        );
      default:
        return <></>;
    }
  };

  return (
    <Grid
      alignItems="center"
      className={
        classes.rootContainer +
        " MuiDataGrid-header" +
        (props.headerProps.isTab ? " " + classes.tab : "")
      }
      container
      spacing={3}
    >
      <Hidden smUp={props.headerProps.displayHeadline == undefined}>
        <Grid item xs={12} sm="auto">
          <Grid
            justify="space-between"
            container
            alignItems="center"
            style={{ marginTop: "0px", marginBottom: "0px" }}
          >
            <Grid item>
              {props.headerProps.displayHeadline != undefined ? (
                <Typography variant="h6">
                  {props.headerProps.displayHeadline}
                </Typography>
              ) : null}
            </Grid>
            <Hidden smUp>
              <Grid item>
                <Button
                  size="large"
                  color="primary"
                  onClick={() => setShowFilters(!showFilters)}
                >
                  {showFilters ? "Hide filters" : "Show filters"}
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp={props.headerProps.displayHtml == undefined}>
        <Grid item xs={12} sm="auto">
          <Grid
            justify="space-between"
            container
            alignItems="center"
            style={{ marginTop: "0px", marginBottom: "0px" }}
          >
            <Grid item>{props.headerProps.displayHtml}</Grid>
          </Grid>
        </Grid>
      </Hidden>

      {showFilters || smUp ? (
        <>
          {props.headerProps.filters
            ?.filter((p) => p.position == "left")
            .map((filter, index) => {
              return (
                <Grid xs={12} sm="auto" item key={index}>
                  {getFilterOption(filter)}
                </Grid>
              );
            })}

          <Hidden xsDown>
            <div style={{ flexGrow: 1 }}></div>
          </Hidden>

          {props.headerProps.filters
            ?.filter((p) => p.position == "right")
            .map((filter, index) => {
              return (
                <Grid sm="auto" item key={index}>
                  {getFilterOption(filter)}
                </Grid>
              );
            })}
        </>
      ) : null}
    </Grid>
  );
};
