/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventRemoteType,
    EventRemoteTypeFromJSON,
    EventRemoteTypeFromJSONTyped,
    EventRemoteTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehiclePictureReportData
 */
export interface VehiclePictureReportData {
    /**
     * 
     * @type {string}
     * @memberof VehiclePictureReportData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclePictureReportData
     */
    vehicleReportId?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclePictureReportData
     */
    url: string;
    /**
     * 
     * @type {EventRemoteType}
     * @memberof VehiclePictureReportData
     */
    source?: EventRemoteType;
    /**
     * 
     * @type {number}
     * @memberof VehiclePictureReportData
     */
    position?: number | null;
}

export function VehiclePictureReportDataFromJSON(json: any): VehiclePictureReportData {
    return VehiclePictureReportDataFromJSONTyped(json, false);
}

export function VehiclePictureReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehiclePictureReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vehicleReportId': !exists(json, 'vehicleReportId') ? undefined : json['vehicleReportId'],
        'url': json['url'],
        'source': !exists(json, 'source') ? undefined : EventRemoteTypeFromJSON(json['source']),
        'position': !exists(json, 'position') ? undefined : json['position'],
    };
}

export function VehiclePictureReportDataToJSON(value?: VehiclePictureReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vehicleReportId': value.vehicleReportId,
        'url': value.url,
        'source': EventRemoteTypeToJSON(value.source),
        'position': value.position,
    };
}


