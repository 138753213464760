import React, { useEffect } from "react";
import { Grid, makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import { a11yProps, TabPanel } from "../../../shared/tabs/TabPanel";
import MonitorLookalikesTab from "./MonitorLookalikesTab";
import MonitorChangesTab from "./MonitorChangesTab";
import { useHistory } from "react-router";
import { ToolTipIcon } from "../../../shared/other/Icons";

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: "0px",
    borderRadius: "3px 3px 0px 0px"
  },
  form: theme.form,
  icon: {
    color: "grey",
    paddingRight: "10px"
  }
}));

function MonitorPage() {

  const history = useHistory();

  const classes = useStyles();

  const getInitValue = () => {
    if (history.location.pathname.startsWith("/monitoring/searchagents"))
      return 0;
    if (history.location.pathname.startsWith("/monitoring/vinmonitors"))
      return 1;

      return 0;
  }

  const [value, setTabValue] = React.useState(getInitValue());
  const infoTexts = [
    "When a vehichle is marked, it is checked once a day if there are any other vehicles that match the criteria set up in the search agent. If so, an email is sent to you with a list of the lookalikes",
    "Once a day the vehicle is checked if it has missed an inspectiondate, changed owner or skipped paying a tax."
  ];

  useEffect(() => {
    setTabValue(getInitValue());
  }, [history.location.pathname])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
        <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <Paper className={classes.tab}>
          <Grid container spacing={2} alignItems="center">
            <Grid item style={{flexGrow: 1}}>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab onClick={() => history.push("/monitoring/searchagents")} label="SEARCH AGENTS" {...a11yProps(0)} />
          <Tab onClick={() => history.push("/monitoring/vinmonitors")} label="VIN MONITOR" {...a11yProps(1)} />
        </Tabs>
            </Grid>
            <Grid item>
            <ToolTipIcon className={classes.icon} hoverText={infoTexts[value]}></ToolTipIcon>
            </Grid>
          </Grid>
        </Paper>
          </Grid>
        </Grid>
        <TabPanel value={value} index={0}>
        <MonitorLookalikesTab></MonitorLookalikesTab>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MonitorChangesTab></MonitorChangesTab>
      </TabPanel>
        </>
  );
}

export default MonitorPage;
