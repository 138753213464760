/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PackageDto,
    PackageDtoFromJSON,
    PackageDtoFromJSONTyped,
    PackageDtoToJSON,
    TeamDto,
    TeamDtoFromJSON,
    TeamDtoFromJSONTyped,
    TeamDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TeamsResponse
 */
export interface TeamsResponse {
    /**
     * 
     * @type {Array<TeamDto>}
     * @memberof TeamsResponse
     */
    all: Array<TeamDto>;
    /**
     * 
     * @type {number}
     * @memberof TeamsResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<PackageDto>}
     * @memberof TeamsResponse
     */
    packages?: Array<PackageDto> | null;
}

export function TeamsResponseFromJSON(json: any): TeamsResponse {
    return TeamsResponseFromJSONTyped(json, false);
}

export function TeamsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'all': ((json['all'] as Array<any>).map(TeamDtoFromJSON)),
        'totalCount': json['totalCount'],
        'packages': !exists(json, 'packages') ? undefined : (json['packages'] === null ? null : (json['packages'] as Array<any>).map(PackageDtoFromJSON)),
    };
}

export function TeamsResponseToJSON(value?: TeamsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all': ((value.all as Array<any>).map(TeamDtoToJSON)),
        'totalCount': value.totalCount,
        'packages': value.packages === undefined ? undefined : (value.packages === null ? null : (value.packages as Array<any>).map(PackageDtoToJSON)),
    };
}


