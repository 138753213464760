import { Button, Grid, IconButton, makeStyles, MenuItem, Paper, Typography } from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { Fragment, useState } from "react";
import { useHistory, useParams } from "react-router";
import { array, object } from "yup";
import useApi from "../../../../hooks/api";
import { useAdminTeam } from "../../../../hooksAdmin/team";
import { TeamSort } from "../../../../lib-api";
import { SelectField } from "../../../shared/forms/SelectField";
import { Breadcrumb } from "../../../shared/other/Breadcrumb";
import { CreateIcon, DeletionIcon } from "../../../shared/other/Icons";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end"
  },
  form: theme.form
}));

const AdminConnectToTeamsPage: React.FC<{
}> = (props) => {
  var params = useParams<{ userId: string }>();
  var { adminUserApi } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  var { results } = useAdminTeam(1, 100, TeamSort.Default, "", "");
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item sm={3}></Grid>
      <Grid item sm={9}>
        <Breadcrumb currentPath="Connect to teams"></Breadcrumb>
      </Grid>
      <Grid item sm={3}></Grid>
      <Grid item sm={6}>
        <Paper>
          <Typography align="center" variant="h4">Connect user to teams</Typography>
          <Formik
            enableReinitialize={true}
            initialValues={{
              teamIds: [""]
            } as { teamIds: string[] }}
            validationSchema={object({
              teamIds: array<string>().required("Atleast one team must be picked")
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const screenName = await adminUserApi.apiAdminUserAddToTeamsPut({
                  adminConnectToTeamsRequest: {
                    userId: params.userId,
                    teamIds: values.teamIds.filter(p => p != "")
                  }
                });
                enqueueSnackbar(screenName + " has been added to teams.", { variant: "success" });
                setSubmitting(false);
                history.push(`/admin/teams`);
              } catch (error) {
                resetForm();
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, values, setValues }) => (
              <Form className={classes.form}>
                <Grid container alignItems="center">
                  <FieldArray
                    name="teamIds"
                    render={arrayHelpers => values.teamIds && values.teamIds.length > 0 ? (
                      values.teamIds.map((teamId, index) => (
                        <Fragment key={index}>
                          {index == values.teamIds.length - 1 ? <Grid item sm={1} xs={2}>
                            <IconButton onClick={() => { arrayHelpers.push("")}}>
                              <CreateIcon hoverText="Add team"></CreateIcon>
                            </IconButton>
                          </Grid> :
                            <Grid item sm={1} xs={2}>
                              <IconButton onClick={() => { arrayHelpers.remove(index)}}>
                                <DeletionIcon hoverText="Remove team"></DeletionIcon>
                              </IconButton>
                            </Grid>}
                          <Grid item sm={11} xs={10}>
                            <SelectField
                              name={`teamIds[${index}]`}
                              placeholder="Team"
                            >
                              {results.map((team, index) => (
                                <MenuItem key={index} value={team.id}>{team.name}</MenuItem>
                              ))}
                            </SelectField>
                          </Grid>
                        </Fragment>
                      ))
                    ) : (
                        <Grid item sm={1} xs={2}>
                          <IconButton onClick={() => { arrayHelpers.push("") }}>
                            <CreateIcon hoverText="Add team"></CreateIcon>
                          </IconButton>
                        </Grid>
                      )}
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.end}>
                      <Button color="primary" type="submit">
                        {isSubmitting ? "Connecting to teams..." : "Connect"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AdminConnectToTeamsPage;
