/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VehicleEstimatedValuationReportData,
    VehicleEstimatedValuationReportDataFromJSON,
    VehicleEstimatedValuationReportDataFromJSONTyped,
    VehicleEstimatedValuationReportDataToJSON,
    VehicleValuationReportData,
    VehicleValuationReportDataFromJSON,
    VehicleValuationReportDataFromJSONTyped,
    VehicleValuationReportDataToJSON,
    VehicleValuationTaxReportData,
    VehicleValuationTaxReportDataFromJSON,
    VehicleValuationTaxReportDataFromJSONTyped,
    VehicleValuationTaxReportDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface ValuationDataResponse
 */
export interface ValuationDataResponse {
    /**
     * 
     * @type {Array<VehicleValuationReportData>}
     * @memberof ValuationDataResponse
     */
    valuationData?: Array<VehicleValuationReportData> | null;
    /**
     * 
     * @type {Array<VehicleValuationTaxReportData>}
     * @memberof ValuationDataResponse
     */
    taxValuationData?: Array<VehicleValuationTaxReportData> | null;
    /**
     * 
     * @type {Array<VehicleEstimatedValuationReportData>}
     * @memberof ValuationDataResponse
     */
    estimatedValuationData?: Array<VehicleEstimatedValuationReportData> | null;
}

export function ValuationDataResponseFromJSON(json: any): ValuationDataResponse {
    return ValuationDataResponseFromJSONTyped(json, false);
}

export function ValuationDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValuationDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valuationData': !exists(json, 'valuationData') ? undefined : (json['valuationData'] === null ? null : (json['valuationData'] as Array<any>).map(VehicleValuationReportDataFromJSON)),
        'taxValuationData': !exists(json, 'taxValuationData') ? undefined : (json['taxValuationData'] === null ? null : (json['taxValuationData'] as Array<any>).map(VehicleValuationTaxReportDataFromJSON)),
        'estimatedValuationData': !exists(json, 'estimatedValuationData') ? undefined : (json['estimatedValuationData'] === null ? null : (json['estimatedValuationData'] as Array<any>).map(VehicleEstimatedValuationReportDataFromJSON)),
    };
}

export function ValuationDataResponseToJSON(value?: ValuationDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valuationData': value.valuationData === undefined ? undefined : (value.valuationData === null ? null : (value.valuationData as Array<any>).map(VehicleValuationReportDataToJSON)),
        'taxValuationData': value.taxValuationData === undefined ? undefined : (value.taxValuationData === null ? null : (value.taxValuationData as Array<any>).map(VehicleValuationTaxReportDataToJSON)),
        'estimatedValuationData': value.estimatedValuationData === undefined ? undefined : (value.estimatedValuationData === null ? null : (value.estimatedValuationData as Array<any>).map(VehicleEstimatedValuationReportDataToJSON)),
    };
}


