import { useState, useEffect } from "react";
import { SearchForm, PopulationFields } from "../lib-api/models";
import { LoadingState } from "./loading";
import useApi from "./api";

export function useSearchInit(searchAgentId: number | undefined) {
  const [initialValues, setInitialValues] = useState<SearchForm|undefined>(undefined);
  const [populationValues, setPopulationValues] = useState<PopulationFields|undefined|null>({});
  const [state, setState] = useState(LoadingState.None);
  const [fetchInitState, setFetchInitState] = useState(LoadingState.None);
  const [searchAgentName, setSearchAgentName] = useState<null|string|undefined>(undefined);

  const { searchApi, searchAgentApi } = useApi();

  const addSearchAgent = async (vehicleId: string) => {
    setFetchInitState(LoadingState.Loading);
    var regNr = null;
    var chassisNr = null;
    if (vehicleId.length > 10) {
      chassisNr = vehicleId;
    } else {
      regNr = vehicleId;
    }

    try {
      var formData = await searchAgentApi.apiSearchAgentAutoCreateSearchAgentPost({
        searchVehicleIdentifier: {
          regNr: regNr,
          chassisNumber: chassisNr,
          name: ""
        },
      });
      setInitialValues(formData);
      setFetchInitState(LoadingState.Success);
    } catch (error) {
      setFetchInitState(LoadingState.BadRequest);
    }
  };

  const load = async () => {
    setState(LoadingState.Loading);

    try {
      const searchResult = await searchApi.apiSearchInitValuesPost({
        searchAgentId: searchAgentId,
      });
      setInitialValues(searchResult.initSearchFields?.fraudSearchForm);
      setPopulationValues(searchResult.initSearchFields?.populationFields);
      setSearchAgentName(searchResult?.name);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    load();
  }, [searchAgentId]);

  return {
    searchAgentName,
    setSearchAgentName,
    initialValues,
    initialValuesState: state,
    populationValues,
    reloadInitialValues: load,
    addSearchAgent: addSearchAgent
  };
}
