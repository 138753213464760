/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchForm
 */
export interface SearchForm {
    /**
     * 
     * @type {string}
     * @memberof SearchForm
     */
    regNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchForm
     */
    chassisNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchForm
     */
    gearType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchForm
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchForm
     */
    registrationStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchForm
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchForm
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchForm
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchForm
     */
    fuelType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchForm
     */
    weightFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchForm
     */
    weightTo?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchForm
     */
    technicalTotalWeightFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchForm
     */
    technicalTotalWeightTo?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchForm
     */
    horsePowerFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchForm
     */
    horsePowerTo?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SearchForm
     */
    firstRegDateFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SearchForm
     */
    firstRegDateTo?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SearchForm
     */
    motorSizeFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchForm
     */
    motorSizeTo?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchForm
     */
    parallelImported?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SearchForm
     */
    leasingDateFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SearchForm
     */
    leasingDateTo?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchForm
     */
    currentlyLeased?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SearchForm
     */
    newInDenmarkFrom?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SearchForm
     */
    inspectionStationId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchForm
     */
    onlyInspectedAfterHours?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchForm
     */
    onlyImpossibleModelYear?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchForm
     */
    onlyMismatchInModelYearAndFirstRegistrationYear?: boolean;
}

export function SearchFormFromJSON(json: any): SearchForm {
    return SearchFormFromJSONTyped(json, false);
}

export function SearchFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'chassisNr': !exists(json, 'chassisNr') ? undefined : json['chassisNr'],
        'gearType': !exists(json, 'gearType') ? undefined : json['gearType'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'registrationStatus': !exists(json, 'registrationStatus') ? undefined : json['registrationStatus'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
        'weightFrom': !exists(json, 'weightFrom') ? undefined : json['weightFrom'],
        'weightTo': !exists(json, 'weightTo') ? undefined : json['weightTo'],
        'technicalTotalWeightFrom': !exists(json, 'technicalTotalWeightFrom') ? undefined : json['technicalTotalWeightFrom'],
        'technicalTotalWeightTo': !exists(json, 'technicalTotalWeightTo') ? undefined : json['technicalTotalWeightTo'],
        'horsePowerFrom': !exists(json, 'horsePowerFrom') ? undefined : json['horsePowerFrom'],
        'horsePowerTo': !exists(json, 'horsePowerTo') ? undefined : json['horsePowerTo'],
        'firstRegDateFrom': !exists(json, 'firstRegDateFrom') ? undefined : (json['firstRegDateFrom'] === null ? null : new Date(json['firstRegDateFrom'])),
        'firstRegDateTo': !exists(json, 'firstRegDateTo') ? undefined : (json['firstRegDateTo'] === null ? null : new Date(json['firstRegDateTo'])),
        'motorSizeFrom': !exists(json, 'motorSizeFrom') ? undefined : json['motorSizeFrom'],
        'motorSizeTo': !exists(json, 'motorSizeTo') ? undefined : json['motorSizeTo'],
        'parallelImported': !exists(json, 'parallelImported') ? undefined : json['parallelImported'],
        'leasingDateFrom': !exists(json, 'leasingDateFrom') ? undefined : (json['leasingDateFrom'] === null ? null : new Date(json['leasingDateFrom'])),
        'leasingDateTo': !exists(json, 'leasingDateTo') ? undefined : (json['leasingDateTo'] === null ? null : new Date(json['leasingDateTo'])),
        'currentlyLeased': !exists(json, 'currentlyLeased') ? undefined : json['currentlyLeased'],
        'newInDenmarkFrom': !exists(json, 'newInDenmarkFrom') ? undefined : (json['newInDenmarkFrom'] === null ? null : new Date(json['newInDenmarkFrom'])),
        'inspectionStationId': !exists(json, 'inspectionStationId') ? undefined : json['inspectionStationId'],
        'onlyInspectedAfterHours': !exists(json, 'onlyInspectedAfterHours') ? undefined : json['onlyInspectedAfterHours'],
        'onlyImpossibleModelYear': !exists(json, 'onlyImpossibleModelYear') ? undefined : json['onlyImpossibleModelYear'],
        'onlyMismatchInModelYearAndFirstRegistrationYear': !exists(json, 'onlyMismatchInModelYearAndFirstRegistrationYear') ? undefined : json['onlyMismatchInModelYearAndFirstRegistrationYear'],
    };
}

export function SearchFormToJSON(value?: SearchForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regNr': value.regNr,
        'chassisNr': value.chassisNr,
        'gearType': value.gearType,
        'color': value.color,
        'registrationStatus': value.registrationStatus,
        'type': value.type,
        'make': value.make,
        'model': value.model,
        'fuelType': value.fuelType,
        'weightFrom': value.weightFrom,
        'weightTo': value.weightTo,
        'technicalTotalWeightFrom': value.technicalTotalWeightFrom,
        'technicalTotalWeightTo': value.technicalTotalWeightTo,
        'horsePowerFrom': value.horsePowerFrom,
        'horsePowerTo': value.horsePowerTo,
        'firstRegDateFrom': value.firstRegDateFrom === undefined ? undefined : (value.firstRegDateFrom === null ? null : value.firstRegDateFrom.toISOString()),
        'firstRegDateTo': value.firstRegDateTo === undefined ? undefined : (value.firstRegDateTo === null ? null : value.firstRegDateTo.toISOString()),
        'motorSizeFrom': value.motorSizeFrom,
        'motorSizeTo': value.motorSizeTo,
        'parallelImported': value.parallelImported,
        'leasingDateFrom': value.leasingDateFrom === undefined ? undefined : (value.leasingDateFrom === null ? null : value.leasingDateFrom.toISOString()),
        'leasingDateTo': value.leasingDateTo === undefined ? undefined : (value.leasingDateTo === null ? null : value.leasingDateTo.toISOString()),
        'currentlyLeased': value.currentlyLeased,
        'newInDenmarkFrom': value.newInDenmarkFrom === undefined ? undefined : (value.newInDenmarkFrom === null ? null : value.newInDenmarkFrom.toISOString()),
        'inspectionStationId': value.inspectionStationId,
        'onlyInspectedAfterHours': value.onlyInspectedAfterHours,
        'onlyImpossibleModelYear': value.onlyImpossibleModelYear,
        'onlyMismatchInModelYearAndFirstRegistrationYear': value.onlyMismatchInModelYearAndFirstRegistrationYear,
    };
}


