import { Button, Grid, Hidden, makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import React, { useState } from "react";
import useApi from "../../../hooks/api";
import VehicleIdSearchForm from "../../shared/forms/search/VehicleIdSearchForm";
import { TransferList } from "../../shared/forms/TransferList";
import * as XLSX from 'xlsx';
import { useSnackbar } from "notistack";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0)
  },
  multipleButton: {
    marginTop: "16px",
    padding: "8px 11px !important"
  }
}));

export const UploadCarverticalUrl: React.FC<{
}> = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const { adminReportApi } = useApi();
  const classes = useStyles();

  const [vinsFromFile, setVinsFromFile] = useState<string[]>([]);

  const uploadUrl = async (url: string) => {
    try {
      const order = await adminReportApi.apiAdminReportUploadUrlPost({url: url});
      enqueueSnackbar("Url uploaded!", {variant: "success"});
    } catch {
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container alignItems="center">
      <Grid item xs={12} sm={12}>
        <VehicleIdSearchForm showIdField showProjects={false} submitText="Upload url" label="Upload url from carVertical" placeholderText="Example: https://www.carvertical.com/en/report?id=c0e38cbf-97b2-459b-b196-f8d93b651a45&vin=WDD2042771G302728" onSubmit={uploadUrl}></VehicleIdSearchForm>
      </Grid>
    </Grid>
    </Paper>
  );
};
