/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ChartType {
    TaxPerInspectionstation = 'TaxPerInspectionstation',
    TaxPerMake = 'TaxPerMake',
    TaxPerState = 'TaxPerState',
    TaxPerExpirationYear = 'TaxPerExpirationYear',
    TaxPerInspectionYear = 'TaxPerInspectionYear',
    TaxPerModelYear = 'TaxPerModelYear'
}

export function ChartTypeFromJSON(json: any): ChartType {
    return ChartTypeFromJSONTyped(json, false);
}

export function ChartTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChartType {
    return json as ChartType;
}

export function ChartTypeToJSON(value?: ChartType | null): any {
    return value as any;
}

