import { Theme, useMediaQuery } from "@material-ui/core";

export function useWindowSize() {
  return {
    "xsDown": useMediaQuery((theme: Theme) => theme.breakpoints.down("xs")),
    "smDown": useMediaQuery((theme: Theme) => theme.breakpoints.down("sm")),
    "mdDown": useMediaQuery((theme: Theme) => theme.breakpoints.down("md")),
    "lgDown": useMediaQuery((theme: Theme) => theme.breakpoints.down("lg")),
    "xlDown": useMediaQuery((theme: Theme) => theme.breakpoints.down("xl")),
    "xsUp": useMediaQuery((theme: Theme) => theme.breakpoints.up("xs")),
    "smUp": useMediaQuery((theme: Theme) => theme.breakpoints.up("sm")),
    "mdUp": useMediaQuery((theme: Theme) => theme.breakpoints.up("md")),
    "lgUp": useMediaQuery((theme: Theme) => theme.breakpoints.up("lg")),
    "xlUp": useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"))
  }
}
