/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TjekBilInspectionError,
    TjekBilInspectionErrorFromJSON,
    TjekBilInspectionErrorFromJSONTyped,
    TjekBilInspectionErrorToJSON,
    TjekBilInspectionStation,
    TjekBilInspectionStationFromJSON,
    TjekBilInspectionStationFromJSONTyped,
    TjekBilInspectionStationToJSON,
} from './';

/**
 * 
 * @export
 * @interface TjekBilInspection
 */
export interface TjekBilInspection {
    /**
     * 
     * @type {number}
     * @memberof TjekBilInspection
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TjekBilInspection
     */
    inspectionStationId?: number;
    /**
     * 
     * @type {TjekBilInspectionStation}
     * @memberof TjekBilInspection
     */
    inspectionStation?: TjekBilInspectionStation;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspection
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspection
     */
    category?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspection
     */
    regNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspection
     */
    result?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TjekBilInspection
     */
    createdDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof TjekBilInspection
     */
    mileage?: number;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspection
     */
    vin?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilInspection
     */
    remoteId?: number;
    /**
     * 
     * @type {Array<TjekBilInspectionError>}
     * @memberof TjekBilInspection
     */
    errors?: Array<TjekBilInspectionError> | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspection
     */
    endTime?: string | null;
}

export function TjekBilInspectionFromJSON(json: any): TjekBilInspection {
    return TjekBilInspectionFromJSONTyped(json, false);
}

export function TjekBilInspectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TjekBilInspection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'inspectionStationId': !exists(json, 'inspectionStationId') ? undefined : json['inspectionStationId'],
        'inspectionStation': !exists(json, 'inspectionStation') ? undefined : TjekBilInspectionStationFromJSON(json['inspectionStation']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'remoteId': !exists(json, 'remoteId') ? undefined : json['remoteId'],
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : (json['errors'] as Array<any>).map(TjekBilInspectionErrorFromJSON)),
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
    };
}

export function TjekBilInspectionToJSON(value?: TjekBilInspection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'inspectionStationId': value.inspectionStationId,
        'inspectionStation': TjekBilInspectionStationToJSON(value.inspectionStation),
        'type': value.type,
        'category': value.category,
        'regNr': value.regNr,
        'result': value.result,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'mileage': value.mileage,
        'vin': value.vin,
        'remoteId': value.remoteId,
        'errors': value.errors === undefined ? undefined : (value.errors === null ? null : (value.errors as Array<any>).map(TjekBilInspectionErrorToJSON)),
        'endTime': value.endTime,
    };
}


