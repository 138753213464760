import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackGaPageView } from "../../../util/gtm";

export function PageViewTracker() {
  const { pathname } = useLocation();

  useEffect(() => {
    trackGaPageView(pathname);
  }, [pathname]);

  return null;
}
