/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportSort,
    ReportSortFromJSON,
    ReportSortFromJSONTyped,
    ReportSortToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchOrderRequest
 */
export interface SearchOrderRequest {
    /**
     * 
     * @type {number}
     * @memberof SearchOrderRequest
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchOrderRequest
     */
    pageSize?: number;
    /**
     * 
     * @type {ReportSort}
     * @memberof SearchOrderRequest
     */
    sortMode?: ReportSort;
    /**
     * 
     * @type {boolean}
     * @memberof SearchOrderRequest
     */
    onlyCompleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderRequest
     */
    folderId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderRequest
     */
    searchQuery?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchOrderRequest
     */
    onlyMyReports?: boolean;
}

export function SearchOrderRequestFromJSON(json: any): SearchOrderRequest {
    return SearchOrderRequestFromJSONTyped(json, false);
}

export function SearchOrderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchOrderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'sortMode': !exists(json, 'sortMode') ? undefined : ReportSortFromJSON(json['sortMode']),
        'onlyCompleted': !exists(json, 'onlyCompleted') ? undefined : json['onlyCompleted'],
        'folderId': !exists(json, 'folderId') ? undefined : json['folderId'],
        'searchQuery': !exists(json, 'searchQuery') ? undefined : json['searchQuery'],
        'onlyMyReports': !exists(json, 'onlyMyReports') ? undefined : json['onlyMyReports'],
    };
}

export function SearchOrderRequestToJSON(value?: SearchOrderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'sortMode': ReportSortToJSON(value.sortMode),
        'onlyCompleted': value.onlyCompleted,
        'folderId': value.folderId,
        'searchQuery': value.searchQuery,
        'onlyMyReports': value.onlyMyReports,
    };
}


