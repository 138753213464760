/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminUpdatePackageRequest
 */
export interface AdminUpdatePackageRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdatePackageRequest
     */
    teamId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdatePackageRequest
     */
    packageName?: string | null;
}

export function AdminUpdatePackageRequestFromJSON(json: any): AdminUpdatePackageRequest {
    return AdminUpdatePackageRequestFromJSONTyped(json, false);
}

export function AdminUpdatePackageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUpdatePackageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
        'packageName': !exists(json, 'packageName') ? undefined : json['packageName'],
    };
}

export function AdminUpdatePackageRequestToJSON(value?: AdminUpdatePackageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamId': value.teamId,
        'packageName': value.packageName,
    };
}


