/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventRemoteType,
    EventRemoteTypeFromJSON,
    EventRemoteTypeFromJSONTyped,
    EventRemoteTypeToJSON,
    EventType,
    EventTypeFromJSON,
    EventTypeFromJSONTyped,
    EventTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleEvent
 */
export interface VehicleEvent {
    /**
     * 
     * @type {string}
     * @memberof VehicleEvent
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleEvent
     */
    vin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleEvent
     */
    regNr?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof VehicleEvent
     */
    eventDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VehicleEvent
     */
    seenDate?: Date;
    /**
     * 
     * @type {EventType}
     * @memberof VehicleEvent
     */
    eventType?: EventType;
    /**
     * 
     * @type {EventRemoteType}
     * @memberof VehicleEvent
     */
    remoteType?: EventRemoteType;
    /**
     * 
     * @type {string}
     * @memberof VehicleEvent
     */
    remoteId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleEvent
     */
    country?: string | null;
}

export function VehicleEventFromJSON(json: any): VehicleEvent {
    return VehicleEventFromJSONTyped(json, false);
}

export function VehicleEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'eventDate': !exists(json, 'eventDate') ? undefined : (new Date(json['eventDate'])),
        'seenDate': !exists(json, 'seenDate') ? undefined : (new Date(json['seenDate'])),
        'eventType': !exists(json, 'eventType') ? undefined : EventTypeFromJSON(json['eventType']),
        'remoteType': !exists(json, 'remoteType') ? undefined : EventRemoteTypeFromJSON(json['remoteType']),
        'remoteId': !exists(json, 'remoteId') ? undefined : json['remoteId'],
        'country': !exists(json, 'country') ? undefined : json['country'],
    };
}

export function VehicleEventToJSON(value?: VehicleEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vin': value.vin,
        'regNr': value.regNr,
        'eventDate': value.eventDate === undefined ? undefined : (value.eventDate.toISOString()),
        'seenDate': value.seenDate === undefined ? undefined : (value.seenDate.toISOString()),
        'eventType': EventTypeToJSON(value.eventType),
        'remoteType': EventRemoteTypeToJSON(value.remoteType),
        'remoteId': value.remoteId,
        'country': value.country,
    };
}


