import React, { useMemo } from "react";
import { LoadingState } from "../../../../hooks/loading";
import { ElementLoading } from "../../../shared/other/Loading";
import { Skeleton } from "@material-ui/lab";
import { Card, CardActions, CardContent, Grid, makeStyles } from "@material-ui/core";
import { useDimensions } from "../../../../hooks/dimensions";
import { VehicleEventInfo, VehicleInformationEquipmentReportData } from "../../../../lib-api";
import { useHelper } from "../../../../hooks/helper";

const useStyles = makeStyles((theme) => ({
  skeleton: (props: { width: number }) => ({
    width: props.width,
    height: props.width / 2
  }),
  pdfIcon: theme.icon,
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    flexBasis: '5%'
  },
  end: {
    textAlign: "right"
  }
}));

export const CountryHistory: React.FC<{
  loadingState: LoadingState;
  initialLoading: boolean;
  allEvents?: VehicleEventInfo[];
}> = (props) => {

  const { width } = useDimensions("countryhistory-width");
  const classes = useStyles({ width: width as number });
  const {getContryFromCode, getFlagFromCode} = useHelper();

  const countriesFromEvents = useMemo(() => {
    return props.allEvents?.filter(p => p.country != null).filter((v, i, a) => i == 0 || a[i - 1].country != v.country).map(p => p.country);
  }, [props.allEvents]);

  return (<div id="countryhistory-width" style={{ position: "relative" }}>
    {props.initialLoading ? <Skeleton variant="rect" className={classes.skeleton}></Skeleton>
      :
      <>
        {props.loadingState == LoadingState.Loading ?
          <ElementLoading position="absolute"></ElementLoading> : null}
        <Grid container spacing={2}>
          {countriesFromEvents?.map((country, index) =>
            <Grid item xs={2}>
              <Card>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                {getContryFromCode(country)}
                </Grid>
                <Grid item xs={6} style={{display: "flex"}} justify="flex-end" alignItems="center">
                <img src={getFlagFromCode(country, 40)}></img>
                </Grid>
              </Grid>
              </Card>
              </Grid>
          )}
        </Grid>
      </>} </div>)
}
