/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminCreateUserRequest
 */
export interface AdminCreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateUserRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateUserRequest
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateUserRequest
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateUserRequest
     */
    isTeamOwner?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateUserRequest
     */
    teamId?: string;
}

export function AdminCreateUserRequestFromJSON(json: any): AdminCreateUserRequest {
    return AdminCreateUserRequestFromJSONTyped(json, false);
}

export function AdminCreateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminCreateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isTeamOwner': !exists(json, 'isTeamOwner') ? undefined : json['isTeamOwner'],
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
    };
}

export function AdminCreateUserRequestToJSON(value?: AdminCreateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'name': value.name,
        'isTeamOwner': value.isTeamOwner,
        'teamId': value.teamId,
    };
}


