import { useField } from "formik";
import { FormControl, FormControlProps, InputLabel, MenuItem, Select, TextField, Tooltip } from "@material-ui/core";
import React from "react";
import { CustomTextField } from "../forms/CustomTextField";

export const SelectField: React.FC<
  {
    name: string;
    placeholder: string;
    tooltip?: string;
    onFinished?: () => void;
  } & FormControlProps
> = (props) => {
  const [field, meta] = useField(props.name);

  return (
    <>
      <Tooltip title={props.tooltip ?? ""}>
        <FormControl>
          <InputLabel shrink htmlFor={field.name}>{props.placeholder.toUpperCase()}</InputLabel>
          <CustomTextField
            name={field.name}
            onBlur={field.onBlur}
            onChange={(data) => { 
              field.onChange(data); 
              if(props.onFinished != undefined){
                props.onFinished();
              }
            }}
            value={field.value == undefined ? "" : field.value}
            placeholder={props.placeholder}
            select={true}
            SelectProps={{
              displayEmpty: true,
              SelectDisplayProps: {
                
              }
            }}
            variant="outlined"
            size="small"
          >
            <MenuItem value={""}>{props.placeholder}</MenuItem>
            {props.children}
          </CustomTextField>
        </FormControl>
      </Tooltip>
      <p>{meta.touched && meta.error ? meta.error : null}</p>
    </>
  );
};
