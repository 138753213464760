import React, { useRef } from "react";
import Button from "@material-ui/core/Button";
import { Form, Formik, FormikErrors } from "formik";
import {
  Box,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { array, object, string } from "yup";
import { CustomTextField } from "../CustomTextField";
import { ProjectSort } from "../../../../lib-api";
import { useProjects } from "../../../../hooks/projects";
import * as XLSX from "xlsx";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { useWindowSize } from "../../../../hooks/windowSize";

const CustomButtonGroup = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& button": {
        padding: "9px 10px!important",
        [theme.breakpoints.up("sm")]: {
          borderRadius: "0px 3px 3px 0px",
          borderLeft: "none",
          marginTop: "16px",
        },
      },
      [theme.breakpoints.up("sm")]: {
        "& .MuiOutlinedInput-root": {
          borderRadius: "0px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRight: "none",
          },
        },
      },
      "& > .MuiGrid-root": {
        flexGrow: 1,
        [theme.breakpoints.up("sm")]: {
          "&:first-child": {
            "& .MuiOutlinedInput-root": {
              borderRadius: "3px 0px 0px 3px!important",
            },
          },
        },
      },
    },
  })
)(Grid);

const useStyles = makeStyles((theme) => ({
  form: theme.form,
  noLabel: {
    [theme.breakpoints.up("sm")]: {
      "& button": {
        marginTop: "0px!important",
      },
    },
  },
  multipleButton: {
    marginTop: "16px",
    padding: "9px 11px !important",
  },
}));

const VehicleIdSearchForm: React.FC<{
  onSubmit?: (id: string, projectId?: string) => void;
  onSubmitMultiple?: (id: string[], projectId?: string, name?: string) => void;
  submitText: string;
  submittingText?: string;
  placeholderText?: string;
  label?: string;
  showProjects: boolean;
  showIdField: boolean;
  dense?: boolean;
}> = (props) => {
  var { projects } = useProjects(1, 20, ProjectSort.Default, "");
  const classes = useStyles();
  const { xsDown } = useWindowSize();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    setValues: (
      values: {
        vehicleId: string;
        vehicleIds: string[];
        projectId: string;
      },
      shouldValidate?: boolean | undefined
    ) => void,
    values: {
      vehicleId: string;
      vehicleIds: string[];
      projectId: string;
    },
    handleSubmit: () => void,
    setErrors: (
      errors: FormikErrors<{
        vehicleIds: string[];
        vehicleId: string;
        projectId: string;
      }>
    ) => void
  ) => {
    const file = event.target.files![0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { skipHidden: true, FS: ";" });
      var list = processData(data);
      setValues({ ...values, vehicleIds: list });
      handleSubmit();
    };
    reader.readAsBinaryString(file);
  };

  const processData = (dataString: string) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    var amountOfRows = dataStringLines.length; //Math.min(dataStringLines.length, 101); //sæt maks antal uploads
    const list: string[] = [];
    for (let i = 1; i < amountOfRows; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        for (let j = 0; j < headers.length; j++) {
          let d = row[j].split(";")[0];
          if (d == "\x00") {
            continue;
          }
          d = d.replace(/\x00/g, "").replace(/\u0000/g, "");
          if (d != "") {
            list.push(d);
          }
        }
      }
    }
    return list;
  };

  return (
    <Formik
      initialValues={
        {
          vehicleId: "",
          vehicleIds: [],
          name: "",
          projectId: "",
        } as { vehicleIds: string[]; vehicleId: string; projectId: string }
      }
      validationSchema={object({
        vehicleId: props.showIdField
          ? string().required("Field must be filled")
          : string(),
        projectId: string(),
        vehicleIds: array<string>(),
      })}
      onSubmit={async (values, { setSubmitting, setValues }) => {
        try {
          if (props.onSubmit) {
            if (props.showProjects) {
              await props.onSubmit(values.vehicleId, values.projectId);
            } else {
              await props.onSubmit(values.vehicleId);
            }
          }
          if (props.onSubmitMultiple) {
            if (props.showProjects) {
              await props.onSubmitMultiple(
                values.vehicleIds,
                values.projectId,
                props.showIdField ? values.vehicleId : undefined
              );
            } else {
              await props.onSubmitMultiple(values.vehicleIds);
            }
          }
          setSubmitting(false);
          setValues({ ...values, vehicleId: "" });
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        isSubmitting,
        handleBlur,
        handleChange,
        setValues,
        handleSubmit,
        errors,
        setErrors,
      }) => (
        <Form className={classes.form}>
          <CustomButtonGroup
            style={props.dense ? { marginTop: "0px", marginBottom: "0px" } : {}}
            className={props.label ? "" : classes.noLabel}
            container
            spacing={xsDown ? 1 : 0}
          >
            {props.showIdField ? (
              <Grid item xs={12} sm="auto">
                <FormControl>
                  {props.label ? (
                    <InputLabel shrink htmlFor="vehicleId">
                      {props.label?.toUpperCase()}
                    </InputLabel>
                  ) : null}
                  <CustomTextField
                    name="vehicleId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={
                      values.vehicleId == undefined ? "" : values.vehicleId
                    }
                    type="text"
                    placeholder={props.placeholderText}
                    variant="outlined"
                    size="small"
                  />
                  <Box position="absolute" bottom="-17px">
                    <Typography variant="caption" color="error">
                      {errors.vehicleId}
                    </Typography>
                  </Box>
                </FormControl>
              </Grid>
            ) : null}
            {props.showProjects ? (
              <Grid item xs={12} sm="auto">
                <FormControl>
                  {props.label ? (
                    <InputLabel shrink htmlFor="projectId">
                      ADD TO PROJECT
                    </InputLabel>
                  ) : null}
                  <CustomTextField
                    name="projectId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={-1}
                    type="text"
                    select
                    placeholder={props.placeholderText}
                    variant="outlined"
                    size="small"
                  >
                    <MenuItem value={-1}>Choose project</MenuItem>
                    {projects.map((project, index) => (
                      <MenuItem value={project.id} key={index}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </FormControl>
              </Grid>
            ) : null}
            {props.onSubmitMultiple ? (
              <Grid item xs={12} sm="auto">
                <input
                  accept=".csv,.txt"
                  onChange={(data) =>
                    handleUpload(
                      data,
                      setValues,
                      values,
                      handleSubmit,
                      setErrors
                    )
                  }
                  ref={inputRef}
                  id="inputRef"
                  type="file"
                  hidden
                />
                <Tooltip
                  placement="top-start"
                  title={
                    <>
                      <Typography variant="body1">
                        Upload .csv or .txt file, to choose which to order
                        reports for.
                      </Typography>
                      <Typography variant="body1">
                        See example below. This can take a few seconds. Be
                        patient.
                      </Typography>
                      <img width="100%" src="/csv_example.png" />
                    </>
                  }
                >
                  <Button
                    className={classes.multipleButton}
                    style={{ border: "1px solid #FF7A11" }}
                    color="secondary"
                    onClick={() => {
                      inputRef.current?.click();
                    }}
                  >
                    <PlaylistAddIcon></PlaylistAddIcon>
                    {isSubmitting && props.submittingText
                      ? props.submittingText
                      : props.submitText}
                  </Button>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item xs={12} sm="auto" style={{ flexGrow: 0 }}>
                <Button size="large" color="primary" type="submit">
                  {isSubmitting && props.submittingText
                    ? props.submittingText
                    : props.submitText}
                </Button>
              </Grid>
            )}
          </CustomButtonGroup>
        </Form>
      )}
    </Formik>
  );
};

export default VehicleIdSearchForm;
