import { useState, useEffect } from "react";

export function useTimer(seconds: number) {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    var id = setInterval(() => {
      setTimer(timer + seconds);
    }, seconds * 1000);

    return () => {
      clearInterval(id);
    };
  }, [timer]);

  return {
    timer: timer,
    setTimer
  };
}
