/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleMileageReportData
 */
export interface VehicleMileageReportData {
    /**
     * 
     * @type {string}
     * @memberof VehicleMileageReportData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleMileageReportData
     */
    vehicleReportId?: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleMileageReportData
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof VehicleMileageReportData
     */
    mileage?: number;
}

export function VehicleMileageReportDataFromJSON(json: any): VehicleMileageReportData {
    return VehicleMileageReportDataFromJSONTyped(json, false);
}

export function VehicleMileageReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleMileageReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vehicleReportId': !exists(json, 'vehicleReportId') ? undefined : json['vehicleReportId'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
    };
}

export function VehicleMileageReportDataToJSON(value?: VehicleMileageReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vehicleReportId': value.vehicleReportId,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'mileage': value.mileage,
    };
}


