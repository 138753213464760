/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PackageDto
 */
export interface PackageDto {
    /**
     * 
     * @type {string}
     * @memberof PackageDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PackageDto
     */
    priceMonthly?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageDto
     */
    pricePerLookup?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageDto
     */
    canLookup?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PackageDto
     */
    maxActiveSearchAgents?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageDto
     */
    maxActiveVINCheckers?: number | null;
}

export function PackageDtoFromJSON(json: any): PackageDto {
    return PackageDtoFromJSONTyped(json, false);
}

export function PackageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'priceMonthly': !exists(json, 'priceMonthly') ? undefined : json['priceMonthly'],
        'pricePerLookup': !exists(json, 'pricePerLookup') ? undefined : json['pricePerLookup'],
        'canLookup': !exists(json, 'canLookup') ? undefined : json['canLookup'],
        'maxActiveSearchAgents': !exists(json, 'maxActiveSearchAgents') ? undefined : json['maxActiveSearchAgents'],
        'maxActiveVINCheckers': !exists(json, 'maxActiveVINCheckers') ? undefined : json['maxActiveVINCheckers'],
    };
}

export function PackageDtoToJSON(value?: PackageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'priceMonthly': value.priceMonthly,
        'pricePerLookup': value.pricePerLookup,
        'canLookup': value.canLookup,
        'maxActiveSearchAgents': value.maxActiveSearchAgents,
        'maxActiveVINCheckers': value.maxActiveVINCheckers,
    };
}


