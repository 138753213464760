import {
  Button,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { object, ref, string } from "yup";
import useApi from "../../../hooks/api";
import useAuth from "../../../hooks/auth";
import { UpdatePasswordRequest } from "../../../lib-api";
import { InputField } from "../../shared/forms/InputField";
import { Breadcrumb } from "../../shared/other/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  end: {
    textAlign: "end",
  },
  form: theme.form,
}));

const EditPasswordPage = () => {
  const { authApi, userApi } = useApi();
  const { user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const [resetTokenSent, setResetTokenSent] = useState(false);

  const sendResetToken = async () => {
    await authApi.apiAuthResettokenPost({ email: user?.email });
    setResetTokenSent(true);
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={3}></Grid>
      <Grid item sm={9}>
        <Breadcrumb currentPath="Edit password"></Breadcrumb>
      </Grid>
      <Grid item sm={3}></Grid>
      <Grid item sm={6}>
        <Paper>
          <Typography variant="h5">Change password</Typography>
          <Formik
            enableReinitialize={true}
            initialValues={{
              oldPassword: "",
              newPassword: "",
              newPasswordValidate: "",
            }}
            validationSchema={object({
              oldPassword: string()
                .required("No password provided")
                .min(8, "Password is too short - min 8 chars"),
              newPassword: string()
                .required("No password provided")
                .min(14, "Password is too short - min 14 chars"),
              newPasswordValidate: string()
                .required("No password provided")
                .min(14, "Password is too short - min 14 chars")
                .oneOf([ref("newPassword")], "Passwords must match"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              var request: UpdatePasswordRequest = {
                newPassword: values.newPassword,
                oldPassword: values.oldPassword,
              };
              try {
                const response = await userApi.apiUserUpdatepasswordPut({
                  updatePasswordRequest: request,
                });
                //update members
                enqueueSnackbar("Password updated!", { variant: "success" });
                history.push("/myaccount");
                resetForm();
                setSubmitting(false);
              } catch (error) {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className={classes.form}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputField
                      name="oldPassword"
                      type="text"
                      placeholder="Current password"
                    ></InputField>
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      name="newPassword"
                      type="password"
                      placeholder="New password"
                    ></InputField>
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      name="newPasswordValidate"
                      type="password"
                      placeholder="Confirm password"
                    ></InputField>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.end}>
                      <Button color="primary" type="submit">
                        {isSubmitting ? "Updating password..." : "Edit"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {resetTokenSent ? (
            <Typography align="center" variant="body1">
              E-mail sent. Didn't recieve one?{" "}
              <Link onClick={() => sendResetToken()}>Send again</Link>
            </Typography>
          ) : (
            <Typography align="center" variant="body1">
              Forgot password? Click{" "}
              <Link onClick={() => sendResetToken()}>here</Link> to send a
              resetlink to your e-mail.
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EditPasswordPage;
