/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ToldsynByModelYearResult
 */
export interface ToldsynByModelYearResult {
    /**
     * 
     * @type {string}
     * @memberof ToldsynByModelYearResult
     */
    year?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ToldsynByModelYearResult
     */
    toldsynResultsImported?: number;
    /**
     * 
     * @type {number}
     * @memberof ToldsynByModelYearResult
     */
    toldsynResultsExported?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ToldsynByModelYearResult
     */
    vinsImported?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ToldsynByModelYearResult
     */
    vinsExported?: Array<string> | null;
}

export function ToldsynByModelYearResultFromJSON(json: any): ToldsynByModelYearResult {
    return ToldsynByModelYearResultFromJSONTyped(json, false);
}

export function ToldsynByModelYearResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ToldsynByModelYearResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': !exists(json, 'year') ? undefined : json['year'],
        'toldsynResultsImported': !exists(json, 'toldsynResultsImported') ? undefined : json['toldsynResultsImported'],
        'toldsynResultsExported': !exists(json, 'toldsynResultsExported') ? undefined : json['toldsynResultsExported'],
        'vinsImported': !exists(json, 'vinsImported') ? undefined : json['vinsImported'],
        'vinsExported': !exists(json, 'vinsExported') ? undefined : json['vinsExported'],
    };
}

export function ToldsynByModelYearResultToJSON(value?: ToldsynByModelYearResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'toldsynResultsImported': value.toldsynResultsImported,
        'toldsynResultsExported': value.toldsynResultsExported,
        'vinsImported': value.vinsImported,
        'vinsExported': value.vinsExported,
    };
}


