/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateManyPortfolioVinRequest,
    CreateManyPortfolioVinRequestFromJSON,
    CreateManyPortfolioVinRequestToJSON,
    CreatePortfolioVinRequest,
    CreatePortfolioVinRequestFromJSON,
    CreatePortfolioVinRequestToJSON,
    PortfolioSort,
    PortfolioSortFromJSON,
    PortfolioSortToJSON,
    PortfolioVinDto,
    PortfolioVinDtoFromJSON,
    PortfolioVinDtoToJSON,
    PortfolioVinsResponse,
    PortfolioVinsResponseFromJSON,
    PortfolioVinsResponseToJSON,
} from '../models';

export interface ApiPortfolioAddManyPostRequest {
    createManyPortfolioVinRequest?: CreateManyPortfolioVinRequest;
}

export interface ApiPortfolioAddPostRequest {
    createPortfolioVinRequest?: CreatePortfolioVinRequest;
}

export interface ApiPortfolioAllGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortMode?: PortfolioSort;
    searchQuery?: string | null;
    projectFolderId?: string | null;
}

export interface ApiPortfolioRemoveDeleteRequest {
    portfolioVinId?: string;
}

/**
 * 
 */
export class PortfolioApi extends runtime.BaseAPI {

    /**
     */
    async apiPortfolioAddManyPostRaw(requestParameters: ApiPortfolioAddManyPostRequest): Promise<runtime.ApiResponse<Array<PortfolioVinDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Portfolio/AddMany`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateManyPortfolioVinRequestToJSON(requestParameters.createManyPortfolioVinRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioVinDtoFromJSON));
    }

    /**
     */
    async apiPortfolioAddManyPost(requestParameters: ApiPortfolioAddManyPostRequest): Promise<Array<PortfolioVinDto>> {
        const response = await this.apiPortfolioAddManyPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiPortfolioAddPostRaw(requestParameters: ApiPortfolioAddPostRequest): Promise<runtime.ApiResponse<PortfolioVinDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Portfolio/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePortfolioVinRequestToJSON(requestParameters.createPortfolioVinRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioVinDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPortfolioAddPost(requestParameters: ApiPortfolioAddPostRequest): Promise<PortfolioVinDto> {
        const response = await this.apiPortfolioAddPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiPortfolioAllGetRaw(requestParameters: ApiPortfolioAllGetRequest): Promise<runtime.ApiResponse<PortfolioVinsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortMode !== undefined) {
            queryParameters['sortMode'] = requestParameters.sortMode;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        if (requestParameters.projectFolderId !== undefined) {
            queryParameters['projectFolderId'] = requestParameters.projectFolderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Portfolio/All`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioVinsResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPortfolioAllGet(requestParameters: ApiPortfolioAllGetRequest): Promise<PortfolioVinsResponse> {
        const response = await this.apiPortfolioAllGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiPortfolioRemoveDeleteRaw(requestParameters: ApiPortfolioRemoveDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.portfolioVinId !== undefined) {
            queryParameters['portfolioVinId'] = requestParameters.portfolioVinId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Portfolio/Remove`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPortfolioRemoveDelete(requestParameters: ApiPortfolioRemoveDeleteRequest): Promise<void> {
        await this.apiPortfolioRemoveDeleteRaw(requestParameters);
    }

}
