import React, { createContext, useReducer, Dispatch } from "react";
import { useWindowSize } from "../../hooks/windowSize";
import { InitialFilterData, InitialGraphData, ToldsynByCvrResult, ToldsynByLeasingYearEndResult, ToldsynByMakeResult, ToldsynByModelYearResult, ToldsynByStateResult, ToldsynByYearResult, ToldsynRequest } from "../../lib-api";

export interface FiltersToDisplay {
  inspectionDateFrom: boolean;
  inspectionDateTo: boolean;
  insuranceCompany: boolean;
  year: boolean;
  inspectionStation: boolean;
  leasingState: boolean;
  make: boolean;
  model: boolean;
}

export interface ChartInfo {
  request: ToldsynRequest;
  prevRequest?: ToldsynRequest;
  filters: FiltersToDisplay;
  showFilters: boolean;
}

export const createNewRequest = () => {
  const request: ToldsynRequest = { make: "", model: "", inspectionDateFrom: undefined, inspectionDateTo: undefined, inspectionStationId: undefined, insuranceCompanies: undefined, leasingState: undefined, year: undefined };
  return request;
}
export const createNewFilters = () => {
  const filters: FiltersToDisplay = { insuranceCompany: false, make: false, model: false, inspectionDateFrom: false, inspectionDateTo: false, inspectionStation: false, year: false, leasingState: false };
  return filters;
}

const defaultStatisticsState: {
  graphData: InitialGraphData;
  filterData: {
    cvr: ChartInfo;
    make: ChartInfo;
    modelYear: ChartInfo;
    year: ChartInfo;
    leasingYear: ChartInfo;
    state: ChartInfo;
  }
} = {
  graphData: {},
  filterData: {
    cvr: {
      request: createNewRequest(),
      prevRequest: createNewRequest(),
      filters: { ...createNewFilters(), insuranceCompany: true, make: true, model: true, inspectionDateFrom: true, inspectionDateTo: true },
      showFilters: true
    },
    year: {
      request: createNewRequest(),
      prevRequest: createNewRequest(),
      filters: { ...createNewFilters(), insuranceCompany: true, make: true, model: true, inspectionDateFrom: true, inspectionDateTo: true, inspectionStation: true, leasingState: true },
      showFilters: true
    },
    modelYear: {
      request: createNewRequest(),
      prevRequest: createNewRequest(),
      filters: { ...createNewFilters(), insuranceCompany: true, make: true, model: true, inspectionDateFrom: true, inspectionDateTo: true, inspectionStation: true, leasingState: true },
      showFilters: true
    },
    state: {
      request: { ...createNewRequest(), amount: 3 },
      prevRequest: { ...createNewRequest(), amount: 3 },
      filters: { ...createNewFilters(), insuranceCompany: true, make: true, model: true, inspectionDateFrom: true, inspectionDateTo: true, inspectionStation: true, year: true, leasingState: true },
      showFilters: true
    },
    leasingYear: {
      request: createNewRequest(),
      prevRequest: createNewRequest(),
      filters: { ...createNewFilters(), insuranceCompany: true, make: true, model: true, inspectionDateFrom: true, inspectionDateTo: true, inspectionStation: true, year: true, leasingState: true },
      showFilters: true
    },
    make: {
      request: createNewRequest(),
      prevRequest: createNewRequest(),
      filters: { ...createNewFilters(), insuranceCompany: true, inspectionDateFrom: true, inspectionDateTo: true, inspectionStation: true, year: true, leasingState: true },
      showFilters: true
    }
  }
};

export type StatisticsState = typeof defaultStatisticsState;

type StatisticsAction = { type: "init"; filterData?: InitialFilterData} | 
{ type: "cvr"; cvrData: ToldsynByCvrResult[]} |
{ type: "make"; makeData: ToldsynByMakeResult[]} |
{ type: "modelyear"; modelYearData: ToldsynByModelYearResult[]} |
{ type: "year"; yearData: ToldsynByYearResult[]} |
{ type: "state"; stateData: ToldsynByStateResult[]} |
{ type: "leasingyear"; leasingYearData: ToldsynByLeasingYearEndResult[]} |
{ type: "setCvrRequest"; request: ToldsynRequest} |
{ type: "setMakeRequest"; request: ToldsynRequest} |
{ type: "setModelyearRequest"; request: ToldsynRequest} |
{ type: "setYearRequest"; request: ToldsynRequest} |
{ type: "setStateRequest"; request: ToldsynRequest} |
{ type: "setLeasingyearRequest"; request: ToldsynRequest} | 
{ type: "setShowCvrFilter"} | 
{ type: "setShowMakeFilter"} | 
{ type: "setShowModelyearFilter"} | 
{ type: "setShowYearFilter"} | 
{ type: "setShowStateFilter"} | 
{ type: "setShowLeasingyearFilter"} |
{ type: "cleanupFilters"};

const statisticsReducer = (state: StatisticsState, action: StatisticsAction): StatisticsState => {
  switch (action.type) {
    case "init":
      return {
        ...state,
        graphData: {
          ...state.graphData,
          initialFilterData: action.filterData
        }
      };
      case "cleanupFilters":
      return {
        ...state,
        filterData: {
          cvr: {
            ...state.filterData.cvr,
            prevRequest: state.filterData.cvr.request
          },
          make: {
            ...state.filterData.make,
            prevRequest: state.filterData.make.request
          },
          modelYear: {
            ...state.filterData.modelYear,
            prevRequest: state.filterData.modelYear.request
          },
          year: {
            ...state.filterData.year,
            prevRequest: state.filterData.year.request
          },
          leasingYear: {
            ...state.filterData.leasingYear,
            prevRequest: state.filterData.leasingYear.request
          },
          state: {
            ...state.filterData.state,
            prevRequest: state.filterData.state.request
          }
        }
      };
      case "cvr":
      return {
        ...state,
        graphData: {
          ...state.graphData,
          toldsynByCvrResults: action.cvrData
        }
      };
      case "setCvrRequest":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          cvr: {
            ...state.filterData.cvr,
            prevRequest: state.filterData.cvr.request,
            request: action.request
          }
        }
      };
      case "setShowCvrFilter":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          cvr: {
            ...state.filterData.cvr,
            showFilters: !state.filterData.cvr.showFilters
          }
        }
      };
      case "make":
      return {
        ...state,
        graphData: {
          ...state.graphData,
          toldsynByMakeResults: action.makeData
        }
      };
      case "setMakeRequest":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          make: {
            ...state.filterData.make,
            prevRequest: state.filterData.make.request,
            request: action.request
          }
        }
      };
      case "setShowMakeFilter":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          make: {
            ...state.filterData.make,
            showFilters: !state.filterData.make.showFilters
          }
        }
      };
      case "modelyear":
      return {
        ...state,
        graphData: {
          ...state.graphData,
          toldsynByModelYearResults: action.modelYearData
        }
      };
      case "setModelyearRequest":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          modelYear: {
            ...state.filterData.modelYear,
            prevRequest: state.filterData.modelYear.request,
            request: action.request
          }
        }
      };
      case "setShowModelyearFilter":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          modelYear: {
            ...state.filterData.modelYear,
            showFilters: !state.filterData.modelYear.showFilters
          }
        }
      };
      case "leasingyear":
      return {
        ...state,
        graphData: {
          ...state.graphData,
          toldsynByLeasingYearEndResults: action.leasingYearData
        }
      };
      case "setLeasingyearRequest":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          leasingYear: {
            ...state.filterData.leasingYear,
            prevRequest: state.filterData.leasingYear.request,
            request: action.request
          }
        }
      };
      case "setShowLeasingyearFilter":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          leasingYear: {
            ...state.filterData.leasingYear,
            showFilters: !state.filterData.leasingYear.showFilters
          }
        }
      };
      case "year":
      return {
        ...state,
        graphData: {
          ...state.graphData,
          toldsynByYearResults: action.yearData
        }
      };
      case "setYearRequest":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          year: {
            ...state.filterData.year,
            prevRequest: state.filterData.year.request,
            request: action.request
          }
        }
      };
      case "setShowYearFilter":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          year: {
            ...state.filterData.year,
            showFilters: !state.filterData.year.showFilters
          }
        }
      };
      case "state":
      return {
        ...state,
        graphData: {
          ...state.graphData,
          toldsynByStateResults: action.stateData
        }
      };
      case "setStateRequest":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          state: {
            ...state.filterData.state,
            prevRequest: state.filterData.state.request,
            request: action.request
          }
        }
      };
      case "setShowStateFilter":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          state: {
            ...state.filterData.state,
            showFilters: !state.filterData.state.showFilters
          }
        }
      };
    default:
      throw new Error(`Case not handled in reducer`);
  }
};

export const StatisticsStateContext = createContext<[StatisticsState, Dispatch<StatisticsAction>]>(
  {} as [StatisticsState, Dispatch<StatisticsAction>]
);

export const StatisticsStateContextProvider: React.FC = (props) => {
  const {xsDown} = useWindowSize();

  const getFiltering = () => {
    defaultStatisticsState.filterData.cvr.showFilters = !xsDown;
    defaultStatisticsState.filterData.year.showFilters = !xsDown;
    defaultStatisticsState.filterData.modelYear.showFilters = !xsDown;
    defaultStatisticsState.filterData.leasingYear.showFilters = !xsDown;
    defaultStatisticsState.filterData.make.showFilters = !xsDown;
    defaultStatisticsState.filterData.state.showFilters = !xsDown;

    return defaultStatisticsState;
  }

  const [state, dispatch] = useReducer(statisticsReducer, getFiltering());

  return (
    <StatisticsStateContext.Provider value={[state, dispatch]}>
      {props.children}
    </StatisticsStateContext.Provider>
  );
};
