/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RegistrationType {
    FirstRegistration = 'FirstRegistration',
    Registered = 'Registered',
    Disabled = 'Disabled',
    CantMap = 'CantMap'
}

export function RegistrationTypeFromJSON(json: any): RegistrationType {
    return RegistrationTypeFromJSONTyped(json, false);
}

export function RegistrationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationType {
    return json as RegistrationType;
}

export function RegistrationTypeToJSON(value?: RegistrationType | null): any {
    return value as any;
}

