import { useState, useEffect } from "react";
import { LoadingState } from "./loading";
import useApi from "./api";

export function useMakeSuggestions(searchPhrase: string | undefined | null) {
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const [state, setState] = useState(LoadingState.None);

  const { searchApi } = useApi();

  const loadMakes = async () => {
    if(!searchPhrase){
      return;
    }
    setState(LoadingState.Loading);

    try {
      const suggestions = await searchApi.apiSearchMakeSuggestionsPost({
        searchPhrase: searchPhrase,
      });
      setSuggestions(suggestions);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    loadMakes();
  }, [searchPhrase]);

  return {
    suggestions,
    suggestionsState: state,
    reloadInitialValues: loadMakes,
  };
}
