/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamMemberDto,
    TeamMemberDtoFromJSON,
    TeamMemberDtoFromJSONTyped,
    TeamMemberDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PortfolioVinDto
 */
export interface PortfolioVinDto {
    /**
     * 
     * @type {string}
     * @memberof PortfolioVinDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioVinDto
     */
    vin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PortfolioVinDto
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PortfolioVinDto
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PortfolioVinDto
     */
    teamMemberId?: string;
    /**
     * 
     * @type {TeamMemberDto}
     * @memberof PortfolioVinDto
     */
    teamMember?: TeamMemberDto;
}

export function PortfolioVinDtoFromJSON(json: any): PortfolioVinDto {
    return PortfolioVinDtoFromJSONTyped(json, false);
}

export function PortfolioVinDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioVinDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'teamMemberId': !exists(json, 'teamMemberId') ? undefined : json['teamMemberId'],
        'teamMember': !exists(json, 'teamMember') ? undefined : TeamMemberDtoFromJSON(json['teamMember']),
    };
}

export function PortfolioVinDtoToJSON(value?: PortfolioVinDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vin': value.vin,
        'make': value.make,
        'model': value.model,
        'teamMemberId': value.teamMemberId,
        'teamMember': TeamMemberDtoToJSON(value.teamMember),
    };
}


