/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TjekBilInspection,
    TjekBilInspectionFromJSON,
    TjekBilInspectionFromJSONTyped,
    TjekBilInspectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface TjekBilInspectionError
 */
export interface TjekBilInspectionError {
    /**
     * 
     * @type {number}
     * @memberof TjekBilInspectionError
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspectionError
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspectionError
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilInspectionError
     */
    description?: string | null;
    /**
     * 
     * @type {TjekBilInspection}
     * @memberof TjekBilInspectionError
     */
    inspection?: TjekBilInspection;
    /**
     * 
     * @type {number}
     * @memberof TjekBilInspectionError
     */
    inspectionId?: number;
}

export function TjekBilInspectionErrorFromJSON(json: any): TjekBilInspectionError {
    return TjekBilInspectionErrorFromJSONTyped(json, false);
}

export function TjekBilInspectionErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): TjekBilInspectionError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'inspection': !exists(json, 'inspection') ? undefined : TjekBilInspectionFromJSON(json['inspection']),
        'inspectionId': !exists(json, 'inspectionId') ? undefined : json['inspectionId'],
    };
}

export function TjekBilInspectionErrorToJSON(value?: TjekBilInspectionError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'title': value.title,
        'description': value.description,
        'inspection': TjekBilInspectionToJSON(value.inspection),
        'inspectionId': value.inspectionId,
    };
}


