import { useState, useEffect } from "react";
import { PortfolioSort, PortfolioVinDto } from "../lib-api/models";
import { LoadingState } from "./loading";
import useApi from "./api";
import { useQueryDelay } from "./queryDelay";
import useAuth from "./auth";

export function usePortfolio(page: number, amount: number, sortMode: PortfolioSort, searchQuery: string, projectFolderId: string) {
  const [state, setState] = useState(LoadingState.None);
  const [results, setResults] = useState<
    PortfolioVinDto[]
  >([]);
  const [totalVins, setTotalVins] = useState<number|undefined>(undefined);
  const { delay } = useQueryDelay(searchQuery);
  const { portfolioApi } = useApi();
  const {user} = useAuth();

  const load = async () => {
    setState(LoadingState.Loading);

    try {
      const searchResult = await portfolioApi.apiPortfolioAllGet({
          pageNumber: page,
          pageSize: amount,
          sortMode: sortMode,
          searchQuery: searchQuery,
          projectFolderId: projectFolderId
      });
      setResults(searchResult.all);
      setTotalVins(searchResult.totalCount);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    load();
  }, [page, amount, sortMode, projectFolderId, delay, user?.teamId]);

  return {
    results: results,
    totalVins,
    searchState: state,
    reload: load
  };
}
