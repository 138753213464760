import { Breadcrumbs, createStyles, Grid, Input, makeStyles, TextField, Theme, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import ArrowRight from '@material-ui/icons/ArrowRight';
import { Link } from "react-router-dom";
import { CompleteIcon, PensilIcon } from "./Icons";
import { CustomInlineTextField } from "../forms/CustomInlineTextField";
import useApi from "../../../hooks/api";

const useStyles = makeStyles((theme) => ({
  link: theme.link,
  arrowColor: {
    color: theme.palette.common.black
  },
  currentBox: {
    display: "flex",
  },
  icon: {
    color: "#c9c9d1",
  },
  cursorText: {
    cursor: "text"
  }
}));

export const BreadCrumbHeadline = withStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      display: 'flex',
      "& .MuiSvgIcon-root": {
        color: "#84959d"
      }
    }
  })
)(Grid);

interface BreadCrumbs {
  path: string;
  name: string;
}

const breadcrumbNameMap: { [key: string]: string } = {
  '/admin': 'Admin',
  '/admin/teams': 'Teams',
  '/admin/vehicles': 'Vehicles',
  '/reports': 'Reports',
  '/monitoring': 'Monitoring',
  '/monitoring/searchagents': 'Search agents',
  '/monitoring/vinmonitors': 'VIN monitors',
  '/statistics': 'Statistics',
  '/myaccount': 'My page',
};

export const Breadcrumb: React.FunctionComponent<{
  currentPath?: string | null;
  onCurrentPathEdit?: (name: string) => void;
}> = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const paths = history.location.pathname.substring(1).split("/");
  const [breadCrumbList, setBreadCrumbList] = useState<BreadCrumbs[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newName, setNewName] = useState(props.currentPath);
  const {breadCrumbApi} = useApi();

  useEffect(() => {
    setNewName(props.currentPath);
    getBreadCrumbNames();
  }, [props.currentPath]);

  const enableEditing = () => {
    setIsEditing(true);
  }

  const disableEditing = async() => {
    if(newName != undefined && newName != null && newName != ""){
      await props.onCurrentPathEdit!(newName);
      setIsEditing(false);
    }
  }

  const getBreadCrumbNames = async() => {
    var result = new Array<BreadCrumbs>();
    for(var i = 0; i < paths.length; i++){
      const to = `/${paths.slice(0, i + 1).join('/')}`;
      var previousPath = paths[i - 1];
      var path = paths[i];
      var name = breadcrumbNameMap[to] ?? await breadCrumbApi.apiBreadcrumbBreadcrumbnamePost({getBreadCrumbNameRequest: {type: previousPath, id: path}});
      if(name != "-" && name != props.currentPath) {
        result.push({name: name, path: to});
      }
    }
    setBreadCrumbList(result);
  }

  const handleOnNewNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNewName(event.target.value);
  }

  return breadCrumbList != [] ? 
  <Breadcrumbs separator={<ArrowRight className={classes.arrowColor} fontSize="small" />} aria-label="breadcrumb">
  {breadCrumbList.map((crumb, index) => {
    if (crumb != undefined) {
      return <Link key={index} className={classes.link} to={crumb.path}><Typography color="primary">{crumb.name}</Typography></Link>
    } else {
      return null;
    }
  })}
  {props.currentPath != undefined && props.currentPath != null ?
    <div className={classes.currentBox}>
      {props.currentPath == "Heymate-admins" || props.onCurrentPathEdit == undefined ?
        <Typography color="textPrimary">{props.currentPath}</Typography>
        :
        isEditing ?
          <>
            <CustomInlineTextField
            style={{width: newName?.length + "ch"}}
            autoFocus
            type="text"
            value={newName}
            onChange={(event) => handleOnNewNameChange(event)}
            onKeyDown={(event) => event.key == "Enter" ? disableEditing() : null}
            ></CustomInlineTextField>
            <CompleteIcon
            className={classes.icon}
            onClick={async() => disableEditing()}
            hoverText={"Edit " + props.currentPath}></CompleteIcon>
          </>
          :
          <>
            <Typography onClick={() => enableEditing()} align="justify" color="textPrimary">{props.currentPath}</Typography>
            <PensilIcon className={classes.icon + " " + classes.cursorText} onClick={() => enableEditing()} hoverText={"Edit " + props.currentPath}></PensilIcon>
          </>
      }
    </div>
    : null}
</Breadcrumbs> : null
};
