import React, { useEffect } from "react";
import { Grid, makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import { a11yProps, TabPanel } from "../../shared/tabs/TabPanel";
import AdminTeamsPage from "./Teams/AdminTeamsPage";
import AdminUsersPage from "./Teams/AdminUsersPage";
import AdminVehiclesPage from "./Vehicles/AdminVehiclesPage";
import { useHistory, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: "0px",
    borderRadius: "3px 3px 0px 0px"
  },
  form: theme.form,
  icon: {
    color: "grey",
    paddingRight: "10px"
  }
}));

function AdminPage() {
  var params = useParams<{ teamId: string }>();
  const history = useHistory();

  const classes = useStyles();

  const getInitValue = () => {
    if (history.location.pathname.startsWith("/admin/teams"))
      return 0;
    if (history.location.pathname.startsWith("/admin/vehicles"))
      return 1;

      return 0;
  }

  const [value, setTabValue] = React.useState(getInitValue());

  useEffect(() => {
    setTabValue(getInitValue());
  }, [history.location.pathname])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
        <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <Paper className={classes.tab}>
          <Grid container spacing={2} alignItems="center">
            <Grid item style={{flexGrow: 1}}>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab onClick={() => history.push("/admin/teams")} label="TEAMS" {...a11yProps(0)} />
          <Tab onClick={() => history.push("/admin/vehicles")} label="VEHICLES" {...a11yProps(1)} />
        </Tabs>
            </Grid>
          </Grid>
        </Paper>
          </Grid>
        </Grid>
        <TabPanel value={value} index={0}>
        {params.teamId != null ? <AdminUsersPage></AdminUsersPage> : <AdminTeamsPage></AdminTeamsPage>}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AdminVehiclesPage></AdminVehiclesPage>
      </TabPanel>
        </>
  );
}

export default AdminPage;
