/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleValuationTaxReportData
 */
export interface VehicleValuationTaxReportData {
    /**
     * 
     * @type {string}
     * @memberof VehicleValuationTaxReportData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleValuationTaxReportData
     */
    vehicleReportId?: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleValuationTaxReportData
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof VehicleValuationTaxReportData
     */
    vehicleWithTaxValue?: number;
}

export function VehicleValuationTaxReportDataFromJSON(json: any): VehicleValuationTaxReportData {
    return VehicleValuationTaxReportDataFromJSONTyped(json, false);
}

export function VehicleValuationTaxReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleValuationTaxReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vehicleReportId': !exists(json, 'vehicleReportId') ? undefined : json['vehicleReportId'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'vehicleWithTaxValue': !exists(json, 'vehicleWithTaxValue') ? undefined : json['vehicleWithTaxValue'],
    };
}

export function VehicleValuationTaxReportDataToJSON(value?: VehicleValuationTaxReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vehicleReportId': value.vehicleReportId,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'vehicleWithTaxValue': value.vehicleWithTaxValue,
    };
}


