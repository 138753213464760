/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VinInfo
 */
export interface VinInfo {
    /**
     * 
     * @type {string}
     * @memberof VinInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VinInfo
     */
    vin?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VinInfo
     */
    isCloned?: boolean;
}

export function VinInfoFromJSON(json: any): VinInfo {
    return VinInfoFromJSONTyped(json, false);
}

export function VinInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VinInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'isCloned': !exists(json, 'isCloned') ? undefined : json['isCloned'],
    };
}

export function VinInfoToJSON(value?: VinInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vin': value.vin,
        'isCloned': value.isCloned,
    };
}


