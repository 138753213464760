import { useState } from "react";
import { ChartType, ToldsynRequest } from "../lib-api/models";
import { LoadingState } from "./loading";
import useApi from "./api";
import { saveAs } from "file-saver";

export function useDownloadStatistics() {
  const [initialDataState, setInitialDataState] = useState(LoadingState.None);
  const [makeState, setMakeState] = useState(LoadingState.None);
  const [modelYearState, setModelYearState] = useState(LoadingState.None);
  const [yearState, setYearState] = useState(LoadingState.None);
  const [leasingYearState, setLeasingYearState] = useState(LoadingState.None);
  const [stateState, setStateState] = useState(LoadingState.None);
  const [cvrState, setCvrState] = useState(LoadingState.None);

  const { statisticsApi } = useApi();

  const downloadCvrVins = async (cvrRequest: ToldsynRequest) => {
    setCvrState(LoadingState.Loading);
    try {
      const data = await statisticsApi.apiStatisticsCsvVinPost({ chartVinsToCsvRequest: { params: {...cvrRequest, includeVins: true}, chartType: ChartType.TaxPerInspectionstation}});
      saveAs("data:application/csv;base64," + data, "vins by inspection station.csv");
      setCvrState(LoadingState.Success);
    } catch (e) {
      setCvrState(LoadingState.BadRequest);
    }
  };

  const downloadMakeVins = async (makeRequest: ToldsynRequest) => {
    setMakeState(LoadingState.Loading);
    try {
      const data = await statisticsApi.apiStatisticsCsvVinPost({ chartVinsToCsvRequest: { params: {...makeRequest, includeVins: true}, chartType: ChartType.TaxPerMake}});
      saveAs("data:application/csv;base64," + data, "vins by make.csv");
      setMakeState(LoadingState.Success);
    } catch (e) {
      setMakeState(LoadingState.BadRequest);
    }
  };

  const downloadYearVins = async (yearRequest: ToldsynRequest) => {
    setYearState(LoadingState.Loading);
    try {
      const data = await statisticsApi.apiStatisticsCsvVinPost({ chartVinsToCsvRequest: { params: {...yearRequest, includeVins: true}, chartType: ChartType.TaxPerInspectionYear}});
      saveAs("data:application/csv;base64," + data, "vins inspection year.csv");
      setYearState(LoadingState.Success);
    } catch (e) {
      setYearState(LoadingState.BadRequest);
    }
  };

  const downloadModelYearVins = async (modelYearRequest: ToldsynRequest) => {
    setModelYearState(LoadingState.Loading);
    try {
      const data = await statisticsApi.apiStatisticsCsvVinPost({ chartVinsToCsvRequest: { params: {...modelYearRequest, includeVins: true}, chartType: ChartType.TaxPerModelYear}});
      saveAs("data:application/csv;base64," + data, "vins by model year.csv");
      setModelYearState(LoadingState.Success);
    } catch (e) {
      setModelYearState(LoadingState.BadRequest);
    }
  };

  const downloadStateVins = async (stateRequest: ToldsynRequest) => {
    setStateState(LoadingState.Loading);
    try {
      const data = await statisticsApi.apiStatisticsCsvVinPost({ chartVinsToCsvRequest: { params: {...stateRequest, includeVins: true}, chartType: ChartType.TaxPerState}});
      saveAs("data:application/csv;base64," + data, "vins by inspection state.csv");
      setStateState(LoadingState.Success);
    } catch (e) {
      setStateState(LoadingState.BadRequest);
    }
  };

  const downloadLeasingYearVins = async (leasingStateYearRequest: ToldsynRequest) => {
    setLeasingYearState(LoadingState.Loading);
    try {
      const data = await statisticsApi.apiStatisticsCsvVinPost({ chartVinsToCsvRequest: { params: {...leasingStateYearRequest, includeVins: true}, chartType: ChartType.TaxPerExpirationYear}});
      saveAs("data:application/csv;base64," + data, "vins by leasing expiration year.csv");
      setLeasingYearState(LoadingState.Success);
    } catch (e) {
      setLeasingYearState(LoadingState.BadRequest);
    }
  };

  return {
    downloadCvrVins,
    downloadCvrState: cvrState,
    downloadLeasingYearVins,
    downloadLeasingYearState: leasingYearState,
    downloadMakeVins,
    downloadMakeState: makeState,
    downloadModelYearVins,
    downloadModelYearState: modelYearState,
    downloadStateVins,
    downloadStateState: stateState,
    downloadYearVins,
    downloadYearState: yearState
  };
}
