import React, { useContext, useEffect, useMemo, useState } from "react";
import { LoadingState } from "../../../../hooks/loading";
import { ElementLoading } from "../../../shared/other/Loading";
import { Bar, ChartData } from "react-chartjs-2";
import { Skeleton } from "@material-ui/lab";
import { Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { useDimensions } from "../../../../hooks/dimensions";
import { useChartOptions } from "../../../../hooks/chartOptions";
import { useDownloadStatistics } from "../../../../hooks/downloadStatistics";
import { usePdfHelper } from "../../../../hooks/pdfhelper";
import { ChartFunctions } from "../ChartFunctions";
import { useChartStyles } from "../StatisticsPage";
import { StatisticsStateContext } from "../../../../state/statistics";
import { useStatistics } from "../../../../hooks/statistics";
import { BreadCrumbHeadline } from "../../../shared/other/Breadcrumb";
import ArrowRight from "@material-ui/icons/ArrowRight";
import { ChartFilters } from "../ChartFilters";

export const ImportedByModelYearChart: React.FC<{
  amount: number;
}> = (props) => {
  const [context, dispatch] = useContext(StatisticsStateContext);
  const { modelYearState, initialData } = useStatistics({taxRequest: {modelYearRequest: context.filterData.modelYear.request}});
  const { width } = useDimensions("tax-inspection-by-model-year-paper-width");
  const classes = useChartStyles({ width: width as number });
  const theme = useTheme();
  const { downloadModelYearVins, downloadModelYearState } = useDownloadStatistics();

  useEffect(() => {
    if (context.graphData.toldsynByModelYearResults != null && context.graphData.toldsynByModelYearResults != undefined) {
      createChart();
    }
  }, [context.graphData.toldsynByModelYearResults, props.amount]);

  const chartRef = useMemo(() => {
    return React.createRef<Bar>();
  }, []);
  
  const [options] = useChartOptions<Bar>(chartRef, false, false);
  const [chartData, setChartData] = useState<ChartData<Chart.ChartData>>({});
  
  const { getAsPdf } = usePdfHelper();

  const downloadPdf = async() => {
    var title = "Amount of tax inspections distributed over model year";
    var data = await getAsPdf(chartRef.current?.chartInstance.toBase64Image() as string, context.filterData.modelYear.request, title);
    saveAs("data:application/pdf;base64," + data, title + ".pdf");
  }

  const createChart = () => {
    var data = {
      labels: context.graphData.toldsynByModelYearResults?.slice(0, props.amount).map(p => p.year as string),
      datasets: [{
        label: '# of imported tax inspections',
        data: context.graphData.toldsynByModelYearResults?.slice(0, props.amount).map(p => p.toldsynResultsImported),
        backgroundColor: theme.primaryChart.color,
        borderColor: theme.primaryChart.background,
        borderWidth: 1,
      },
      {
        label: '# of exported tax inspections',
        data: context.graphData.toldsynByModelYearResults?.slice(0, props.amount).map(p => p.toldsynResultsExported),
        backgroundColor: theme.secondaryChart.color,
        borderColor: theme.secondaryChart.background,
        borderWidth: 1
      }]
    };
    setChartData(data);
  }
  return (<>
  <Paper className={classes.header}>
    <Grid container spacing={1} alignItems="center">
      <BreadCrumbHeadline item xs={12} sm={6}>
      <Typography variant="h5">Tax inspections</Typography>
      <ArrowRight fontSize="default" />
        <Typography variant="h5">Per model year</Typography>
      </BreadCrumbHeadline>
      <Grid item xs={12} sm={6}>
      <ChartFunctions toggled={context.filterData.modelYear.showFilters} onToggleFilters={() => dispatch({type: "setShowModelyearFilter"})} downloadPdf={downloadPdf} downloadVins={() => downloadModelYearVins(context.filterData.modelYear.request)}></ChartFunctions>
      </Grid>
    </Grid>
  </Paper>

  <Paper hidden={!context.filterData.modelYear.showFilters} className={classes.filter}>
  <ChartFilters
        initialFilterData={initialData.initialFilterData}
        request={{info: context.filterData.modelYear, setRequest: (request) => dispatch({type: "setModelyearRequest", request: request})}}
        initialLoading={initialData.toldsynByModelYearResults == undefined} />
  </Paper>
  
  <Paper className={classes.chart}><div id="tax-inspection-by-model-year-paper-width" style={{ position: "relative" }}> 
  {initialData.toldsynByModelYearResults == undefined ? <Skeleton variant="rect" className={classes.skeleton}></Skeleton>
    :
    <>
      {modelYearState == LoadingState.Loading || downloadModelYearState == LoadingState.Loading ?
        <ElementLoading position="absolute"></ElementLoading> : null}
      <Bar ref={chartRef} data={chartData} options={options}></Bar>
    </>} </div></Paper></>)
}
