import { useState, useEffect } from "react";
import { VehicleDocumentWithToldsyn, SearchForm } from "../lib-api/models";
import { LoadingState } from "./loading";
import useApi from "./api";

export function useSearch(page: number, amount: number) {
  const [state, setState] = useState(LoadingState.None);
  const [results, setResults] = useState<
    VehicleDocumentWithToldsyn[] | null | undefined
  >(null);
  const [totalCars, setTotalCars] = useState<number|undefined>(undefined);
  const [searchValues, setSearchValues] = useState<SearchForm | undefined>(
    undefined
  );

  const { searchApi } = useApi();

  const search = async (values: SearchForm) => {
    setSearchValues(values);
    setState(LoadingState.Loading);

    try {
      const searchResult = await searchApi.apiSearchSearchPost({
        searchForm: values,
        page: page,
        amount: amount,
      });
      setResults(searchResult.cars);
      setTotalCars(searchResult.totalHits);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  useEffect(() => {
    if (searchValues !== undefined) {
      search(searchValues);
    }
  }, [page]);

  return {
    results: results,
    totalCars: totalCars,
    searchState: state,
    search: search
  };
}
