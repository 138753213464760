/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminUpdateTeamNameRequest
 */
export interface AdminUpdateTeamNameRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateTeamNameRequest
     */
    teamId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateTeamNameRequest
     */
    name?: string | null;
}

export function AdminUpdateTeamNameRequestFromJSON(json: any): AdminUpdateTeamNameRequest {
    return AdminUpdateTeamNameRequestFromJSONTyped(json, false);
}

export function AdminUpdateTeamNameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUpdateTeamNameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function AdminUpdateTeamNameRequestToJSON(value?: AdminUpdateTeamNameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamId': value.teamId,
        'name': value.name,
    };
}


