/* tslint:disable */
/* eslint-disable */
/**
 * FraudFighter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InspectionStationName,
    InspectionStationNameFromJSON,
    InspectionStationNameFromJSONTyped,
    InspectionStationNameToJSON,
} from './';

/**
 * 
 * @export
 * @interface PopulationFields
 */
export interface PopulationFields {
    /**
     * 
     * @type {Array<string>}
     * @memberof PopulationFields
     */
    colors?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PopulationFields
     */
    fuelTypes?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PopulationFields
     */
    registrationStatuses?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PopulationFields
     */
    gearTypes?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PopulationFields
     */
    types?: Array<string> | null;
    /**
     * 
     * @type {Array<InspectionStationName>}
     * @memberof PopulationFields
     */
    inspectionStationNames?: Array<InspectionStationName> | null;
}

export function PopulationFieldsFromJSON(json: any): PopulationFields {
    return PopulationFieldsFromJSONTyped(json, false);
}

export function PopulationFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PopulationFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'fuelTypes': !exists(json, 'fuelTypes') ? undefined : json['fuelTypes'],
        'registrationStatuses': !exists(json, 'registrationStatuses') ? undefined : json['registrationStatuses'],
        'gearTypes': !exists(json, 'gearTypes') ? undefined : json['gearTypes'],
        'types': !exists(json, 'types') ? undefined : json['types'],
        'inspectionStationNames': !exists(json, 'inspectionStationNames') ? undefined : (json['inspectionStationNames'] === null ? null : (json['inspectionStationNames'] as Array<any>).map(InspectionStationNameFromJSON)),
    };
}

export function PopulationFieldsToJSON(value?: PopulationFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'colors': value.colors,
        'fuelTypes': value.fuelTypes,
        'registrationStatuses': value.registrationStatuses,
        'gearTypes': value.gearTypes,
        'types': value.types,
        'inspectionStationNames': value.inspectionStationNames === undefined ? undefined : (value.inspectionStationNames === null ? null : (value.inspectionStationNames as Array<any>).map(InspectionStationNameToJSON)),
    };
}


