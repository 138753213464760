import { useState } from "react";
import { ValuationDataResponse, VehicleEventInfo, VehicleMileageReportData, VehicleOrderDto, VehiclePictureReportData, VehicleReportManagedAssetDto } from "../lib-api/models";
import { LoadingState } from "./loading";
import useApi from "./api";

export function useReport() {
  const [state, setState] = useState(LoadingState.None);
  const [completedOrder, setCompletedOrder] = useState<
    VehicleOrderDto | null
  >(null);

  const [completedOrderPictures, setCompletedOrderPictures] = useState<
    VehiclePictureReportData[] | null
  >(null);

  const [completedOrderValuation, setCompletedOrderValuation] = useState<
    ValuationDataResponse | null
  >(null);

  const [completedOrderAssets, setCompletedOrderAssets] = useState<
    VehicleReportManagedAssetDto[] | null
  >(null);

  const [completedOrderMileage, setCompletedOrderMileage] = useState<
    VehicleMileageReportData[] | null
  >(null);

  const [orderFinished, setOrderFinished] = useState<boolean>(false);
  const [orderExpandedAllEvents, setOrderExpandedAllEvents] = useState<VehicleEventInfo[]>();

  const { reportApi } = useApi();

  const load = async (vehicleOrderId: string) => {
    setState(LoadingState.Loading);
    try {
      const searchResult = await reportApi.apiReportCompletedOrderPost({
        orderId: vehicleOrderId
      });
      setCompletedOrder(searchResult);
      setState(LoadingState.Success);
      loadAllOrderEvents(searchResult);
      loadAssets(vehicleOrderId);
      loadPictures(vehicleOrderId);
      loadMileage(vehicleOrderId);
      loadValuation(vehicleOrderId);

    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  const loadPictures = async (vehicleOrderId: string) => {
    try {
      const searchResult = await reportApi.apiReportCompletedOrderPicturesPost({
        orderId: vehicleOrderId
      });
      setCompletedOrderPictures(searchResult);  
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  const loadValuation = async (vehicleOrderId: string) => {
    try {
      const searchResult = await reportApi.apiReportCompletedOrderValuationDataPost({
        orderId: vehicleOrderId
      });
      setCompletedOrderValuation(searchResult);  
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  const loadAssets = async (vehicleOrderId: string) => {
    try {
      const searchResult = await reportApi.apiReportCompletedOrderAssetsPost({
        orderId: vehicleOrderId
      });
      setCompletedOrderAssets(searchResult);  
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  const loadMileage = async (vehicleOrderId: string) => {
    try {
      const searchResult = await reportApi.apiReportCompletedOrderMileageHistoryPost({
        orderId: vehicleOrderId
      });
      setCompletedOrderMileage(searchResult);  
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  const pingOrder = async (vehicleOrderId: string) => {
    try {
      const result = await reportApi.apiReportPingPost({
        pingOrderRequest: {
          vehicleOrderId: vehicleOrderId
        }
      });
      setOrderFinished(result.completed!);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  const loadAllOrderEvents = async (order: VehicleOrderDto) => {
    try {
      const result = await reportApi.apiReportAllCompletedOrderEventsPost({
        getEventsRequest: { vehicleEvents: order?.vehicleReport?.vehicleEvents}
      });
      setOrderExpandedAllEvents(result);
    } catch (e) {
      setState(LoadingState.BadRequest);
    }
  };

  return {
    completedOrder: completedOrder,
    completedOrderAssets,
    completedOrderMileage,
    completedOrderPictures,
    completedOrderValuation,
    loadingState: state,
    loadOrder: load,
    orderExpandedAllEvents,
    pingOrder,
    orderFinished
  };
}
