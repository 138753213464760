import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://9a20d744e1e0460790af8a03c4b94134@o55209.ingest.sentry.io/5505421",
    ignoreErrors: [
      "Can't execute code from freed script",
      "SecurityError",
      "Failed to fetch",
      "ga.getAll is not a function",
      "Unable to get property 'sqrt' of undefined or null reference",
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
